import React from "react";
import { StatItem } from "./StatItem";
import { StatTable } from "./StatTable";

export const StatisticsAdmin = (props) => {
  return (
    <div className="row">
      <div className="row" style={{marginBottom:0}}>
        <div className="col s12">
          <select className="browser-default select-sp" value={props.state.tools}  id="tools" onChange={props.handleSelect}>
            <option value="PD">Distress Predictor</option>
            <option value="NIS">News Impact Score</option>
            <option value="SM">Sector Meter</option>
            <option value="SP">Stock Pulse</option>
            <option value="Ann">Announcement</option>
            <option value="CC">Conference Call</option>
          </select>
        </div>
      </div>
      {
        props.state.tools == "PD" ?
        <div className="row">
          <div className="row stat-row">
            <StatItem loading={props.loading} label="Total Reports" figure={props.overall?props.overall.total:""}/>
            <StatItem loading={props.loading} label="Parsed Reports"  figure={props.overall?props.overall.goodCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Un-Parsed Reports"  figure={props.overall?props.overall.badCount:""} type="negative"/>
          </div>
          <StatTable
            headers={
              [
                {width:300, name:"Year", f:(d)=>{return d.year} },
                {width:300, name:"Total Reports", f:(d)=>{return d.totalCount} },
                {width:300, name:"Parsed Reports", f:(d)=>{return d.goodCount} },
                {width:300, name:"Un-parsed Reports", f:(d)=>{return d.badCount} },
              ]
            }
            data={props.pdData}
          />
        </div>
        :props.state.tools == "NIS" ?
        <div className="row">
          <div className="row stat-row">
            <StatItem loading={props.loading} label="Total News"  figure={props.overall?props.overall.totalCount:""}/>
            <StatItem loading={props.loading} label="Parsed news"  figure={props.overall?props.overall.parsedCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Un-Parsed news" figure={props.overall?props.overall.unparsedCount:""} type="negative"/>
            <StatItem loading={props.loading} label="Effective news" figure={props.overall?props.overall.usefullCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Non-effeective news" figure={props.overall?props.overall.uselessCount:""} type="negative"/>
          </div>
          <StatTable
            headers={
              [
                {width:200, name:"Company", f:(d)=>{return d.company} },
                {width:150, name:"last month", f:(d)=>{return d.lastMonthData} },
                {width:150, name:"last 6 month", f:(d)=>{return d.lastSixMonthData} },
                {width:150, name:"Total News", f:(d)=>{return d.totalCount} },
                {width:150, name:"Parsed News", f:(d)=>{return d.parsedCount} },
                {width:150, name:"Un-parsed News", f:(d)=>{return d.unparsedCount} },
                {width:150, name:"Effective News", f:(d)=>{return d.usefullCount} },
                {width:150, name:"Non effective News", f:(d)=>{return d.uselessCount} },
              ]
            }
            data={props.nisData}
          />
        </div>
        :props.state.tools == "SM" ?
        <div className="row">
          <div className="row stat-row">
            <StatItem loading={props.loading} label="Total News" figure={props.overall?props.overall.totalCount:""}/>
            <StatItem loading={props.loading} label="Parsed news" figure={props.overall?props.overall.parsedCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Un-Parsed news" figure={props.overall?props.overall.unparsedCount:""} type="negative"/>
            <StatItem loading={props.loading} label="Effective news" figure={props.overall?props.overall.usefullCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Non-effeective news" figure={props.overall?props.overall.uselessCount:""} type="negative"/>
          </div>
          <StatTable
            headers={
              [
                {width:200, name:"Company", f:(d)=>{return d.company} },
                {width:200, name:"Total News", f:(d)=>{return d.totalCount} },
                {width:200, name:"Parsed News", f:(d)=>{return d.parsedCount} },
                {width:200, name:"Un-parsed News", f:(d)=>{return d.unparsedCount} },
                {width:200, name:"Un-parsed News", f:(d)=>{return d.usefullCount} },
                {width:200, name:"Un-parsed News", f:(d)=>{return d.uselessCount} },
              ]
            }
            data={props.smData}
          />
        </div>
        :props.state.tools == "SP" ?
        <div className="row">
          <div className="row stat-row">
            <StatItem loading={props.loading} label="Total News" figure={props.overall?props.overall.totalCount:""}/>
            <StatItem loading={props.loading} label="Parsed news" figure={props.overall?props.overall.parsedCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Un-Parsed news" figure={props.overall?props.overall.unparsedCount:""} type="negative"/>
            <StatItem loading={props.loading} label="Effective news" figure={props.overall?props.overall.usefullCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Non-effeective news" figure={props.overall?props.overall.uselessCount:""} type="negative"/>
          </div>
          <StatTable
            headers={
              [
                {width:200, name:"Year", f:(d)=>{return d.year} },
                {width:200, name:"Total Reports", f:(d)=>{return d.totalCount} },
                {width:200, name:"Parsed Reports", f:(d)=>{return d.goodCount} },
                {width:200, name:"Un-parsed Reports", f:(d)=>{return d.badCount} },
              ]
            }
            data={props.spData}
          />
        </div>
        :
        props.state.tools == "Ann" ?
        <div className="row">
          <div className="row stat-row">
            <StatItem loading={props.loading} label="Total Announcements"  figure={props.overall?props.overall:""}/>
          </div>
          <StatTable
            headers={
              [
                {width:500, name:"Company", f:(d)=>{return d.company} },
                {width:500, name:"Total Announcements", f:(d)=>{return d.totalCount} }
              ]
            }
            data={props.annData}
          />
        </div>
        :props.state.tools == "CC" ?
        <div className="row">
          <div className="row stat-row">
            <StatItem loading={props.loading} label="Total Concalls" figure={props.overall?props.overall.totalCount:""}/>
            <StatItem loading={props.loading} label="Parsed Concalls" figure={props.overall?props.overall.parsedCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Un-Parsed Concalls" figure={props.overall?props.overall.unparsedCount:""} type="negative"/>
            <StatItem loading={props.loading} label="Effective Concalls" figure={props.overall?props.overall.usefullCount:""} type="positive"/>
            <StatItem loading={props.loading} label="Non-effeective Concalls" figure={props.overall?props.overall.uselessCount:""} type="negative"/>
          </div>
          <StatTable
            headers={
              [
                {width:350, name:"Company", f:(d)=>{return d.company} },
                {width:200, name:"Parsed Announcements", f:(d)=>{return d.parsedCount} },
                {width:200, name:"Unparsed Announcements", f:(d)=>{return d.unparsedCount} },
                {width:200, name:"Usefull Annoucements", f:(d)=>{return d.usefullCount} },
                {width:200, name:"Useless Annoucements", f:(d)=>{return d.uselessCount} },
              ]
            }
            data={props.ccData}
          />
        </div>
        :null
      }
    </div>
  );
};
