import React from "react";
import GeoChart from "./laylout/GeoChart";
import { useState } from "react";
import { useEffect } from "react";
import {
  getReportDataFiltered,
  getReportDataSegment,
} from "../../../utils/nlHelper";
import { NL_TYPE_GIC } from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { getStatePop } from "../../../store/action/nlActions";

export default function GeoDashboardLife({ data }) {
  const accessToken = useSelector((state) => state?.auth?.accessToken);
  const states = useSelector((state) => state?.nl?.states);
  const [reportData, setReportData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [selState, setSelState] = useState(null);
  const [capitaFlag, setCapitaFlag] = useState(false);
  const [initialSet, setInitialSet] = useState(false);

  const [customerType, setCustomerType] = useState("Individual");
  const [businessType, setBusinessType] = useState("Overall");
  const [numberType, setNumberType] = useState("Premium");
  const [geologicalType, setGeologicalType] = useState("Overall");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatePop({}, accessToken));
  }, []);

  useEffect(() => {
    if (data && data.Individual && data.Group) {
      const overall_col = getOverllCol();

      let temp = getReportDataSegment(
        data[customerType][overall_col],
        capitaFlag,
        states,
      );

      setReportData(temp);
      setTableData(
        getReportDataFiltered(
          overall_col,
          temp[1][0],
          data[customerType],
          capitaFlag,
          states,
        ),
      );
      setSelState(temp[1][0]);
      setInitialSet(true);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.Individual && data.Group && initialSet) {
      const overall_col = getOverllCol();

      let temp = getReportDataSegment(
        data[customerType][overall_col],
        capitaFlag,
        states,
      );
      setReportData(temp);
      setTableData(
        getReportDataFiltered(
          overall_col,
          selState,
          data[customerType],
          capitaFlag,
          states,
        ),
      );
    }
  }, [capitaFlag, customerType, businessType, numberType, geologicalType]);

  const getOverllCol = () => {
    let col = "Total Premium (New Business and Renewal)";

    if (businessType === "Overall") {
      if (numberType === "Premium") {
        if (geologicalType == "Urban") {
          col = "Premium (Urban)";
        } else if (geologicalType == "Rural") {
          col = "Premium (Rural)";
        } else if (geologicalType == "Overall") {
          col = "Total Premium (New Business and Renewal)";
        }
      } else {
        if (geologicalType == "Urban") {
          col = "Num Policies (Urban)";
        } else if (geologicalType == "Rural") {
          col = "Num Policies (Rural)";
        } else if (geologicalType == "Overall") {
          col = "Num Policies (Total)";
        }
      }
    } else if (businessType === "New Business") {
      if (numberType === "Premium") {
        if (geologicalType == "Urban") {
          col = "Premium (Urban)";
        } else if (geologicalType == "Rural") {
          col = "Premium (Rural)";
        } else if (geologicalType == "Overall") {
          col = "Premium (Total)";
        }
      } else {
        if (geologicalType == "Urban") {
          col = "Num Policies (Urban)";
        } else if (geologicalType == "Rural") {
          col = "Num Policies (Rural)";
        } else if (geologicalType == "Overall") {
          col = "Num Policies (Total)";
        }
      }
    } else if (businessType === "Renewal") {
      col = "Renewal Premium";
    }
    return col;
  };

  const OnStateSelect = (state) => {
    let temp = state[0] === "Orissa" ? "Odisha" : state[0];
    setSelState(temp);
    setTableData(
      getReportDataFiltered(
        customerType,
        temp,
        data.segments,
        capitaFlag,
        states,
      ),
    );
  };

  const handleSwitch = () => {
    setCapitaFlag(!capitaFlag);
  };

  console.log("REP DATA", reportData);
  return (
    <>
      <div className="row">
        <div className="col s2">
          <table className="tableFixed">
            <thead>
              <tr>
                <th>Filters</th>
              </tr>
            </thead>
            <tbody style={{ height: 460 }}>
              <>
                <tr
                  onClick={() => {
                    setCustomerType("Individual");
                  }}
                  style={
                    customerType === "Individual"
                      ? { backgroundColor: "#dcdce2", marginTop: 16 }
                      : { marginTop: 16 }
                  }
                >
                  <td>Individual</td>
                </tr>
                <tr
                  style={
                    customerType === "Group"
                      ? { backgroundColor: "#dcdce2" }
                      : {}
                  }
                  onClick={() => {
                    setCustomerType("Group");
                  }}
                >
                  <td>Group</td>
                </tr>
              </>
              <>
                <tr
                  onClick={() => {
                    setNumberType("Premium");
                  }}
                  style={
                    numberType === "Premium"
                      ? { backgroundColor: "#dcdce2", marginTop: 16 }
                      : { marginTop: 16 }
                  }
                >
                  <td>Premium</td>
                </tr>
                <tr
                  onClick={() => {
                    setNumberType("Num Policies");
                  }}
                  style={
                    numberType === "Num Policies"
                      ? { backgroundColor: "#dcdce2" }
                      : {}
                  }
                >
                  <td>Num Policies</td>
                </tr>
              </>
              {numberType === "Premium" && (
                <>
                  <tr
                    onClick={() => {
                      setBusinessType("Overall");
                    }}
                    style={
                      businessType === "Overall"
                        ? { backgroundColor: "#dcdce2", marginTop: 16 }
                        : { marginTop: 16 }
                    }
                  >
                    <td>Overall</td>
                  </tr>
                  <tr
                    onClick={() => {
                      setBusinessType("New Business");
                    }}
                    style={
                      businessType === "New Business"
                        ? { backgroundColor: "#dcdce2" }
                        : {}
                    }
                  >
                    <td>New Business</td>
                  </tr>
                  <tr
                    onClick={() => {
                      setBusinessType("Renewal");
                    }}
                    style={
                      businessType === "Renewal"
                        ? { backgroundColor: "#dcdce2" }
                        : {}
                    }
                  >
                    <td>Renewal</td>
                  </tr>
                </>
              )}
              <>
                <tr
                  onClick={() => {
                    setGeologicalType("Overall");
                  }}
                  style={
                    geologicalType === "Overall"
                      ? { backgroundColor: "#dcdce2", marginTop: 16 }
                      : { marginTop: 16 }
                  }
                >
                  <td>Overall</td>
                </tr>
                <tr
                  onClick={() => {
                    setGeologicalType("Urban");
                  }}
                  style={
                    geologicalType === "Urban"
                      ? { backgroundColor: "#dcdce2" }
                      : {}
                  }
                >
                  <td>Urban</td>
                </tr>
                <tr
                  onClick={() => {
                    setGeologicalType("Rural");
                  }}
                  style={
                    geologicalType === "Rural"
                      ? { backgroundColor: "#dcdce2" }
                      : {}
                  }
                >
                  <td>Rural</td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
        <div className="col s7">
          <div className="col s6 switch">
            <label>
              None
              <input type="checkbox" onChange={handleSwitch} />
              <span class="lever"></span>
              Per Capita
            </label>
          </div>
          <div className="col s6">
            {selState && (
              <p className="right right-align" style={{ marginBottom: 0 }}>
                <strong>State: {selState}</strong>
              </p>
            )}
          </div>
          <GeoChart
            key={capitaFlag ? "CapitaChart" : "RevChart"}
            height={460}
            width="100%"
            data={reportData || []}
            ChartSelect={OnStateSelect}
          />
        </div>
        <div className="col s3">
          {tableData && (
            <table className="tableFixed">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Revenue</th>
                </tr>
              </thead>
              <tbody style={{ height: 460 }}>
                {tableData?.map((td) => (
                  <tr>
                    <td title={td?.company}>
                      {td?.company?.length > 12
                        ? td.company.slice(0, 12) + "..."
                        : td.company}
                    </td>
                    <td className="right right-align">
                      {td.revenue.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <p className="notes-text">
            ** Initially the state with highest revenue is selected and company
            shares are shown in right panel
          </p>
        </div>
      </div>
    </>
  );
}
