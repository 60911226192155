import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getConfig, updateConfig } from "../../store/action/authActions";
import { Icon, IconButton } from "@mui/material";
import M from "materialize-css";

export default function NLConfigModal(props) {
  const token = useSelector((state) => state.auth.accessToken);
  const config = useSelector((state) => state.auth.config);
  const companies = useSelector((state) => state.NIS.companyList);
  const sectors = useSelector((state) => state.SM.sectorList);
  const configUpdateSuccess = useSelector(
    (state) => state.auth.configUpdateSuccess,
  );
  const configUpdateError = useSelector(
    (state) => state.auth.configUpdateError,
  );

  const [primary, setPrimaryCompany] = useState(null);
  const [secondary, setSecondaries] = useState([{}]);
  const dispatch = useDispatch();

  useEffect(() => {
    setPrimaryCompany(null);
    setSecondaries([{}]);
  }, []);

  useEffect(() => {
    if (configUpdateSuccess) {
      setPrimaryCompany(null);
      setSecondaries([{}]);
      dispatch(getConfig(token));
      var elem = document.getElementById("nl-config-modal");
      try {
        var instance = M.Modal.getInstance(elem);
        if (instance.isOpen) instance.close();
      } catch (e) {
        console.error("unwanted error");
      }
    } else if (configUpdateError) {
      alert("Configuration update error");
    }
  }, [configUpdateSuccess, configUpdateError]);

  const onChagePrimary = (selOption) => {
    setPrimaryCompany(selOption);
  };

  const onAddSecondary = (selOption) => {
    let secs = [...secondary];
    secs.push({});
    if (secs.length > 5) {
      alert("Maximum 5 secondaries can be added");
      return;
    }
    setSecondaries(secs);
  };
  const onChageSecondary = (selOption, i) => {
    let secs = [...secondary];
    secs[i] = selOption;
    setSecondaries(secs);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateConfig(
        {
          replace: true,
          insComType: "gic",
          configCompanies: [
            {
              primary: primary?.value,
              secondary: secondary.map((sec) => {
                return { id: sec.value };
              }),
            },
          ],
        },
        token,
      ),
    );
  };

  const insSector = sectors?.find((sec) => sec.name === "Insurance");
  console.log("comp", secondary);

  return (
    <div>
      <div id="nl-config-modal" className="modal modal-fixed-footer">
        <div className="row modal-content">
          <div className="row modal-title">
            <h3 style={{ color: "rgb(93, 27, 143)" /* , paddingTop:20 */ }}>
              Configuration
            </h3>
          </div>
          {/* <hr /> */}
          <div className="row">
            <div className="col s12">
              <form id="config" onSubmit={onSubmit}>
                <div className="row">
                  <span>
                    <strong>Select Primary</strong>
                  </span>
                  <Select
                    id="selectedBrokers"
                    options={companies
                      ?.filter((com) => com.sector_id === insSector?.id)
                      ?.map((com) => {
                        return { value: com.id, label: com.name };
                      })}
                    onChange={onChagePrimary}
                    value={primary}
                  />
                </div>
                <div className="row">
                  <span>
                    <strong>Select Secondaries</strong>
                  </span>
                  {secondary.map((newCompany, index) => (
                    <div className="row">
                      <div className="col s11">
                        <Select
                          id="selectedBrokers"
                          options={companies
                            ?.filter((com) => com.sector_id === insSector?.id)
                            ?.map((com) => {
                              return { value: com.id, label: com.name };
                            })}
                          onChange={(val) => {
                            onChageSecondary(val, index);
                          }}
                          value={secondary[index]?.value ? newCompany : null}
                        />
                      </div>
                      <div className="col s1">
                        <IconButton onClick={onAddSecondary}>
                          <Icon>add</Icon>
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="modal-close waves-effect waves btn-flat">
            CANCEL
          </button>
          <button
            type="submit"
            form="config"
            className="waves-effect waves btn-flat"
          >
            Update Config
          </button>
        </div>
      </div>
    </div>
  );
}
