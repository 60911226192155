import React from "react";

export default function Segments({ lobs, handleLobChange, selLob }) {
  return (
    <div className="col s12 stat-row">
      {lobs?.map((lob) => (
        <div
          key={lob}
          className="btn btn-small"
          onClick={() => {
            handleLobChange(lob);
          }}
          style={
            lob === selLob
              ? {
                  marginRight: 8,
                  marginBottom: 8,
                  backgroundColor: "#124265",
                }
              : {
                  marginRight: 8,
                  marginBottom: 8,
                  backgroundColor: "#dcdce2",
                  color: "black",
                }
          }
        >
          {lob}
        </div>
      ))}
    </div>
  );
}
