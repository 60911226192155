import React from "react";
import News from "./News";

export const AddCompanyModal = (props) => {
  console.log("here", props.state);
  return (
    <div>
      <div id="add-company-modal" className="modal modal-fixed-footer">
        <div className="modal-content">
          <div
            className="modal-title dashboard-modal-title valign-wrapper"
            style={{ backgroundColor: "#124265", minHeight: 10 }}
          >
            <span className="dashboard-modal-name">Add Company</span>
          </div>
          <div className="row" style={{ marginTop: 23 }}>
            <form id="addCompany" className="col s12" onSubmit={props.addCompany}>
              <div className="row">
                <div className="input-field col s12 l3 m3">
                  <h5>Company Name:</h5>
                </div>
                <div className="input-field col s12 l9 m9">
                  <input
                    id="newCompany"
                    type="text"
                    className="validate"
                    value={props.state.newCompany}
                    onChange={props.onChange}
                    required
                  />
                  <label for="company"> Please input full name of the Company</label>
                </div>


                <div className="col s12 l3 m3">
                  <h5>Also known as:</h5>
                </div>
                <div className="input-field col s12 l9 m9">
                  <textarea
                    id="synonyms"
                    type="text"
                    className="validate materialize-textarea"
                    value={props.state.synonyms}
                    onChange={props.onChange}
                    height={100}
                    required
                  />
                  <label for="company">
                    Please input alternative names or abbreviations
                    {/* seperated by comma ( , ). e.g. add TCS for Tata Consultancy Services */}
                  </label>
                </div>
               

                <div className="input-field col s12 l3 m3">
                  <h5>Company's sector:</h5>
                </div>
                <div className="col s12 l9 m9">
                  <select
                    className="browser-default"
                    id="sectorId"
                    onChange={props.onChange}
                    value={props.state.sectorId}
                    required
                  >
                    <option value="" disabled selected>
                      Choose sector
                    </option>
                    {props.state.allSector.length != 0 &&
                      props.state.allSector.map((sector) => (
                        <option value={sector.value} key={sector.value}>
                          {sector.label}
                        </option>
                      ))}
                  </select>
                </div>
                
               
                <div className="col s12 l12 m12">
                  <h5>Upload the annual Reports. (At least 2 consecutive Year)</h5>
                  <table>
                    <thead>
                      <tr>
                        {!props.off&&<td width="40%">Select File</td>}
                        <td width="20%">Enter year</td>
                        <td width="20%">upload</td>
                        <td width="20%">Add another</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        props.state.userAr?
                        props.state.userAr.map((a, i) => 
                          <tr>
                            {!props.off&&<td width="40%">
                              <input
                              type="file"
                              accept=".pdf"
                              id={"ar-"+i}
                              onChange={props.handleFileChange}
                              />
                            </td>}
                            <td width="20%">
                              <input
                              type="number"
                              id={"year-"+i}
                              onChange={props.handleFileChange}
                              value={props.state.userAr[i].year}
                              />
                            </td>
                            <td width="20%">
                              {
                                props.customArLoading?
                                <i id={"tick-"+i} className="material-icons grey-text">remove_circle</i>
                                :
                                a.filePath?
                                  <i id={"tick-"+i} className="material-icons green-text">check_circle</i>
                                  :
                                  <i id={"upload-"+i} onClick={props.fileUpload} className="material-icons" style={{cursor:'pointer'}}>upload</i>}
                            </td>
                            <td width="20%">
                              {i==1&&
                              <i onClick={props.addAR} className="material-icons" style={{cursor:'pointer'}}>add</i>}
                              {i>1&&
                              <i id={"remove-"+i} onClick={props.removeAR} className="material-icons" style={{cursor:'pointer', marginLeft:10}}>remove</i>}
                            </td>
                          </tr>
                        )
                        :null
                      }
                    </tbody>
                  </table>
                </div>
                {
                  props.error?
                  <div className="col s12 l12 m12 red-text">
                    <p>{props.message}</p>
                  </div>:
                  <div className="col s12 l12 m12 green-text">
                    <p>{props.message}</p>
                  </div>
                }
                {/* {props.success&&
                <div className="col s12 l12 m12">
                  <h6 className="center-align">
                    <strong> News About Company</strong>
                  </h6>
                  <table>
                    <thead>
                      <tr>
                        <td className="center-align">Date</td>
                        <td className="center-align">Headline</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        props.news&&props.news.map(n => 
                          <tr>
                            <td>{n.date}</td>
                            <td>
                              <a
                                href={n.newsURL}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="collection-item"
                              >
                                {n.heading}
                              </a>
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>} */}
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <button className="modal-close waves-effect waves-green btn-flat">
            Close
          </button>
          <button
            className="waves-effect waves-green btn-flat"
            form="addCompany"
            type="submit"
            disabled={props.customArLoading}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
