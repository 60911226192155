import React from "react";

export default function IndicationCard({ label, value }) {
  return (
    <div className="row" style={{ padding: 5 }}>
      <div className="col s6" style={{ marginTop: 30 }}>
        {label}
      </div>
      <div className="col s6">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          {value?.type === "up" ? (
            <p className="green-text">
              <h5>{value?.diff}</h5>
            </p>
          ) : value?.type === "down" ? (
            <p className="red-text">
              <h5>{value?.diff}</h5>
            </p>
          ) : (
            <p>{value?.diff}</p>
          )}

          {value?.type === "up" ? (
            <i style={{ marginTop: 0 }} className="material-icons green-text">
              arrow_upward
            </i>
          ) : value?.type === "down" ? (
            <i style={{ marginTop: 0 }} className="material-icons red-text">
              arrow_downward
            </i>
          ) : (
            <i style={{ marginTop: 0 }} className="material-icons">
              minus
            </i>
          )}
        </div>
      </div>
    </div>
  );
}
