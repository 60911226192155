import React, { Component } from "react";
// import {isBrowser,isMobile
// } from "react-device-detect";
import MultiLineChart from "../layout/MultiLineChart";
import PDTable from "../layout/PDTable";
import { connect } from "react-redux";
// import { initResponse } from "../../store/action/uploadAction";
// import Dashboard from "./Dashboard";
import { getNewsNIS, getNISTopNews } from "../../store/action/nisActions";
import {
  getData,
  getTranscriptScores,
  getUserReport,
} from "../../store/action/combineDataActions";
import {
  updateConfig,
  getConfig,
  configUpdateReset,
} from "../../store/action/authActions";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import moment from "moment";

import "./style.css";
import ConfigModal from "../layout/ConfigModal";
import News from "../layout/News";
import CompanyComponent from "../layout/CompanyComponent";
import Announcements from "../layout/Announcements";
import SecCompanyChart from "../layout/SecCompanyChart";
import StockPulse from "../layout/StockPulse";
import { getStockPulse } from "../../store/action/stockPulseAction";
import {
  getAuditYears,
  getSecondaryComAudit,
  submitAudit,
} from "../../store/action/auditAction";
import AuditMyDashboard from "../layout/AuditMyDashboard";
import { TimelineHorizontal } from "../layout/TimelineHorizontal";

class MyDashboard extends Component {
  state = {
    data: [12, 5],
    width: 1050,
    height: 300,
    id: "root",
    comCount: 1,
    frequency: "daily",
    company: null,
    years: 5,
    months: 6,
    startDate: moment().subtract("6", "months").format("MMM DD, YYYY"),
    endDate: moment().format("MMM DD, YYYY"),
    relevance: 0.8,
    novelty: 0.4,
    companies: [],
    boxplot: false,
    transcriptTopics: [],
    tts: [
      "AGM/EGM",
      "Business Discontinuity",
      "Meeting Notice",
      "Corp. Action",
      "Auditors",
      "AGM Proceedings",
      "Acquisition",
      "Ratings",
      "Media",
      "Debentures",
      " Corp Action",
      "Voting results",
      "Investor presentation",
      "Company Update",
      "Analyst call/Meet",
      "Compliance",
      "Change in Management",
      "Fraud",
      "Result",
      "Dividend",
      "Financial Distress",
      "Legal",
      "Trading window/book closure",
      "Annual report",
      "miscellaneous",
      "Alteration of Memorandum/Articles",
      "Financial Results",
      "Shareholding ",
      "Insider Trading / SAST",
      "Covid-19",
      "Board Meeting",
      "RPTs",
      "Scheme of Arrangement",
      "Promoters",
    ],

    companyComponent: [],
    configCompanies: [],
    selectedBrokers: [],
  };

  componentDidMount = () => {
    // //console.log("nis", this.state)
    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      onClose: () => {
        this.onDateClose();
      },
    });
    elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {
      onCloseStart: () => {
        this.props.GetConfig(this.props.auth.accessToken);
      },
      onCloseEnd: () => {
        this.props.ConfigUpdateReset();
      },
      onOpenStart: () => M.updateTextFields(),
      preventScrolling: false,
    });
    elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    var Broker = this.props.broker.data;
    var broker = [];
    if (Broker) {
      for (var i = 0; i < Broker.length; i++) {
        broker.push({
          value: Broker[i].id,
          label: Broker[i].name,
        });
        // this.setState({broker:broker})
      }
    }
    if (this.props.config) {
      this.setState({
        frequency: this.props.config.frequency,
        years: this.props.config.years ? this.props.config.years : 5,
        months: this.props.config.months ? this.props.config.months : 6,
        relevance: this.props.config.relevance
          ? this.props.config.relevance
          : 0.8,
        novelty: this.props.config.novelty ? this.props.config.novelty : 0.4,
        transcriptTopics: this.props.config.transcriptTopic
          ? this.props.config.transcriptTopic
          : [],
        brokers: broker,
      });
    }
  };
  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
    });
    // //console.log(this.state)
  };
  componentDidUpdate = () => {
    var elem = document.getElementById("config-modal");
    try {
      var instance = M.Modal.getInstance(elem);
      if (instance.isOpen && this.props.configUpdated) instance.close();
      // this.props.ConfigUpdateReset()
      M.updateTextFields();
    } catch (e) {
      console.error("unwanted error");
    }
  };
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };

  ChangeHandler = (event) => {
    // //console.log(event.target.id, event.target.value)
    if (event.target.id == "companies") {
      var elem = document.getElementById("companies");
      var instance = M.FormSelect.getInstance(elem);
      this.setState({
        [event.target.id]: instance.getSelectedValues(),
      });
    } else if (event.target.id == "transcriptTopics") {
      var elem = document.getElementById("transcriptTopics");
      var instance = M.FormSelect.getInstance(elem);
      var val = instance.getSelectedValues();
      if (val.includes("all")) {
        this.setState({ transcriptTopics: this.state.tts });
      } else
        this.setState({
          [event.target.id]: val,
        });
    } else
      this.setState({
        [event.target.id]: event.target.value,
      });
  };

  onBrokerChange = (selectedOptions) => {
    this.setState({
      selectedBrokers: selectedOptions,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    var id = e.target.id;
    await this.setState({ ...this.state, company: id });

    this.props.GetNewsNIS(id, this.props.auth.accessToken);
    this.props.GetTranscripts(this.state, this.props.auth.accessToken);

    var data = {
      // startDate: this.formatDate(this.state.startDate),
      startDate: this.state.startDate,
      // endDate: this.formatDate(this.state.endDate),
      endDate: this.state.endDate,
      novelty: Number(this.state.novelty),
      type: "company",
      id: id,
      brokerId:
        this.state.selectedBrokers && this.state.selectedBrokers.length > 0
          ? this.state.selectedBrokers.map((d) => {
              return d.value;
            })
          : null,
    };
    this.props.GetStockPulse(data, this.props.auth.accessToken);
    this.props.SubmitAudit(
      id,
      moment().year() - 1,
      "company",
      {},
      this.props.auth.accessToken,
    );
    var secIds = this.props.userCompanies.find(
      (c) => c.company_id == this.state.company,
    ).secondaries;
    secIds = secIds.map((s) => {
      return s.id;
    });
    this.props.GetSecondaryComAudit(
      secIds,
      moment().year() - 1,
      this.props.auth.accessToken,
    );
    this.props.GetAuditYears(id, this.props.auth.accessToken);

    // //console.log(this.props)
  };

  OnConfig = (e) => {
    e.preventDefault();
    // //console.log("here", this.state.transcriptTopics);
    var data = { ...this.state, companyComponent: [] };
    var flag = false;
    if (
      !this.state.transcriptTopics ||
      this.state.transcriptTopics.length < 1
    ) {
      alert("select atleast one transcript topic");
      flag = true;
    }
    for (var i = 0; i < this.state.configCompanies.length; i++) {
      if (this.state.configCompanies[i].secondary.length > 5) {
        // //console.log("here");
        alert(
          "One of your company has more than 5 secondaris. Sorry! can not do that",
        );
        flag = true;
        break;
      }
    }
    if (!flag) this.props.UpdateConfig(data, this.props.auth.accessToken);
  };

  updateDimensions() {
    // //console.log("WIDTH", window.innerWidth)
    if (window.innerWidth <= 500) {
      this.setState({
        ...this.state,
        width: window.innerWidth - 60,
        height: 250,
        tickRotation: true,
      });
    } else if (window.innerWidth < 1400 && window.innerWidth > 1000) {
      this.setState({ ...this.state, width: 700, height: 350 });
    } else {
      this.setState({
        ...this.state,
        width: 1050,
        height: 300,
        tickRotation: false,
      });
    }
    // //console.log("here", this.state);
  }
  OnClick = (d, selected) => {
    // //console.log("CLICKED", d);
    var data = {
      date: d,
      relevance: this.state.relevance,
      novelty: this.state.novelty,
      frequency: this.state.frequency,
      company: this.state.company,
    };
    this.props.GetNISTopNews(data, selected, this.props.auth.accessToken);
    this.props.GetTranscripts(this.state, this.props.auth.accessToken);
  };

  CompanyChangeHandlerPri = (e, index) => {
    // //console.log("index", index, e);
    var newData = this.state.configCompanies;
    newData[index - 1]["primary"] = e.target.value;
    this.setState({ ...this.state, configCompanies: newData });
  };
  CompanyChangeHandlerSec = (data, index) => {
    // //console.log("index", index, data);
    var newData = this.state.configCompanies;
    newData[index - 1]["secondary"] = data;
    this.setState({ ...this.state, configCompanies: newData });
  };

  OnAddCompany = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      comCount: this.state.comCount + 1,
      companyComponent: [
        ...this.state.companyComponent,
        <CompanyComponent
          // HandleChange={this.ChangeHandler}
          companies={this.props.allCompanies}
          state={this.state}
          HandleChange={this.CompanyChangeHandlerPri}
          HandleChangeSec={this.CompanyChangeHandlerSec}
          index={this.state.comCount}
        />,
      ],
      configCompanies: [
        ...this.state.configCompanies,
        { primary: "", secondary: [] },
      ],
    });
  };

  ChangeGraph = (e) => {
    this.setState({ boxplot: !this.state.boxplot });
  };

  OnDownloadPdf = (e) => {
    this.props.GetReport(this.state.company, this.props.auth.accessToken);
  };
  /**
   * Remove event listener
   */
  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateDimensions.bind(this));
  // }

  render() {
    console.log("props", this.props);
    console.log("state", this.state);
    const {
      auth,
      ChartData,
      TopNews,
      userCompanies,
      secChartData,
      secCompanies,
      primaryCompany,
      loading,
      error,
      rolesAllowed,
    } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    return (
      <div className="row" style={{ marginBottom: 0 }}>
        <ConfigModal
          HandleChange={this.ChangeHandler}
          companies={this.props.allCompanies}
          state={this.state}
          OnSubmit={this.OnConfig}
          OnAddCompany={this.OnAddCompany}
          BrokerChange={this.onBrokerChange}
        />
        <div className="col s12 l2 m5" style={{}}>
          <div
            className="row center-align"
            style={{
              position: "fixed",
              top: 60,
              width: "inherit",
              backgroundColor: "#5d1b8f",
              height: "100vh",
            }}
          >
            <div className="row center">
              <button
                data-target="config-modal"
                className="btn btn-sp modal-trigger"
              >
                <i className="material-icons">settings</i>
              </button>
              {this.props.reportLoading ? (
                <button className="btn btn-sp">
                  <div className="preloader-wrapper small active">
                    <div className="spinner-layer spinner-blue-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </button>
              ) : (
                <button
                  disabled={!this.state.company}
                  className="btn btn-sp"
                  onClick={this.OnDownloadPdf}
                >
                  <i className="material-icons">file_download</i>
                </button>
              )}
            </div>
            <div className="col s10 m10 l10 offset-s1 offset-l1 offset-l1 ">
              <div className="row" style={{ height: 550, overflowY: "auto" }}>
                <div className="col s12 userComs">
                  {userCompanies.map((com, index) => (
                    <div
                      id={com.company_id}
                      key={com.company_id}
                      onClick={this.onSubmit}
                      className={
                        this.state.company == com.company_id
                          ? "cname-active"
                          : "cname"
                      }
                    >
                      {com.detail.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s12 l10 m7 full-div">
          <div className="row center-align  full-div">
            <div
              className="col s10 m10 l10 offset-s1 offset-l1 offset-m1  full-div"
              style={{ marginBottom: 10 }}
            >
              <div className="center center-align">
                {loading ? (
                  <div
                    className="preloader-wrapper big active"
                    style={{ marginTop: 200 }}
                  >
                    <div className="spinner-layer spinner-green-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                ) : userCompanies.length == 0 ? (
                  <div className="directions">
                    <p>
                      <i className="material-icons">done_all</i>Once configured,
                      Dashboard displays annual reports, news with sentiment
                      score, announcements for those selected companies.
                    </p>
                    <p>
                      <i className="material-icons">done_all</i>Dashboard allows
                      comparison with sector and competitors.
                    </p>
                    <p>
                      <i className="material-icons">done_all</i>Personalized
                      dashboard where users can choose companies of their choice
                      and define own parameters.
                    </p>
                  </div>
                ) : this.props.reportData &&
                  this.props.reportData.length > 0 ? null : (
                  <div className="directions">
                    <p>
                      <i className="material-icons">done_all</i>Click on one of
                      companies to get your report.
                    </p>
                  </div>
                )}
                <div className="red-text">
                  {error ? <p>Data not available</p> : null}
                </div>
              </div>
              {this.props.reportData && this.props.reportData.length > 0
                ? (rolesAllowed &&
                    rolesAllowed.PDRole &&
                    !this.checkExpiry(rolesAllowed.PDRole.expiry)) ||
                  (auth.isAdmin && (
                    <PDTable
                      pCom={this.props.primaryCompany}
                      data={this.props.reportData}
                      secData={this.props.secReportData}
                    />
                  ))
                : null}

              {ChartData && ChartData.length > 0
                ? (rolesAllowed &&
                    rolesAllowed.NISRole &&
                    !this.checkExpiry(rolesAllowed.NISRole.expiry)) ||
                  (auth.isAdmin && (
                    <>
                      <div
                        className="row"
                        style={{
                          borderBottom: "solid",
                          fontWeight: "bolder",
                          marginTop: 10,
                          marginBottom: 5,
                          marginLeft: 0,
                        }}
                      >
                        <span className="left left-align">News</span>
                        <button
                          className="btn-small btn-floating right right-align"
                          onClick={this.ChangeGraph}
                          style={{
                            marginBottom: 10,
                            backgroundColor: "#5d1b8f",
                            color: "black",
                          }}
                        >
                          {this.state.boxplot ? (
                            <i
                              className="material-icons"
                              style={{ marginLeft: 5 }}
                            >
                              arrow_backword
                            </i>
                          ) : (
                            <i className="material-icons">arrow_forward</i>
                          )}
                        </button>
                      </div>
                      {!this.state.boxplot ? (
                        <MultiLineChart
                          can_height={this.state.height}
                          can_width={this.state.width}
                          dataset1={ChartData}
                          dataset2={this.props.ChartData2}
                          onClick={this.OnClick}
                          Company={this.props.companyName}
                          Sector={this.props.sectorName}
                          selected={
                            this.props.selected ? this.props.selected : null
                          }
                        />
                      ) : (
                        <SecCompanyChart
                          can_height={this.state.height}
                          can_width={this.state.width}
                          data={secChartData}
                          companies={secCompanies}
                          primary={primaryCompany}
                        />
                      )}
                      <News
                        height={300}
                        marginTop={50}
                        TopNews={TopNews}
                        component="my_dashboard"
                      />
                    </>
                  ))
                : null}
              {ChartData && ChartData.length > 0 ? (
                <>
                  <div>
                    <div
                      className="row"
                      style={{
                        borderBottom: "solid",
                        fontWeight: "bolder",
                        marginTop: 20,
                        marginBottom: 5,
                        marginLeft: 0,
                      }}
                    >
                      <span className="left left-align">Announcements</span>
                    </div>
                    <Announcements
                      announcements={
                        this.props.transcript ? this.props.transcript : []
                      }
                      component="my_dashboard"
                    />
                  </div>
                  {((rolesAllowed &&
                    rolesAllowed.stockRole &&
                    !this.checkExpiry(rolesAllowed.stockRole.expiry)) ||
                    auth.isAdmin) && (
                    <div>
                      <div
                        className="row"
                        style={{
                          borderBottom: "solid",
                          fontWeight: "bolder",
                          marginTop: 20,
                          marginBottom: 5,
                          marginLeft: 0,
                        }}
                      >
                        <span className="left left-align">Stock Pulse</span>
                      </div>
                      <StockPulse
                        announcements={
                          this.props.stockPulse ? this.props.stockPulse : []
                        }
                        component="my_dashboard"
                      />
                    </div>
                  )}
                  {((rolesAllowed &&
                    (rolesAllowed.auditRole ||
                      rolesAllowed.auditMatterRole ||
                      rolesAllowed.broadFinanceRole ||
                      rolesAllowed.accPolicyRole) &&
                    (!this.checkExpiry(rolesAllowed.auditRole.expiry) ||
                      !this.checkExpiry(rolesAllowed.accPolicyRole.expiry) ||
                      !this.checkExpiry(rolesAllowed.broadFinanceRole.expiry) ||
                      !this.checkExpiry(
                        rolesAllowed.auditMatterRole.expiry,
                      ))) ||
                    auth.isAdmin) &&
                    this.props.auditData &&
                    this.props.secAuditData &&
                    this.props.auditData.find(
                      (d) => d.Company.id == this.state.company,
                    ) &&
                    this.state.company && (
                      <div>
                        <div
                          className="row"
                          style={{
                            borderBottom: "solid",
                            fontWeight: "bolder",
                            marginTop: 20,
                            marginBottom: 5,
                            marginLeft: 0,
                          }}
                        >
                          <span className="left left-align">Audit Data</span>
                        </div>
                        <AuditMyDashboard
                          auditData={
                            this.props.auditData
                              ? this.props.auditData.find(
                                  (d) => d.Company.id == this.state.company,
                                )
                              : null
                          }
                          secAuditData={this.props.secAuditData}
                          component="my_dashboard"
                        />
                        <TimelineHorizontal
                          data={
                            this.props.auditYear
                              ? this.props.auditYear
                                  .sort((a, b) => b.year - a.year)
                                  .map((a) => {
                                    return {
                                      auditFirm: this.props.auditFirms.find(
                                        (af) => af.id == a.audit_firm_id,
                                      ),
                                      auditPartner:
                                        this.props.auditPartners.find(
                                          (af) => af.id == a.audit_partner_id,
                                        ),
                                      year: a.year,
                                    };
                                  })
                              : []
                          }
                        />
                      </div>
                    )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // //console.log("State", state);
  return {
    auth: state.auth,
    rolesAllowed: state.auth.rolesAllowed,
    profile: state.auth,
    config: state.auth.config,
    allCompanies:
      state.NIS && state.NIS.companyList ? state.NIS.companyList : [],
    userCompanies:
      state.auth.config && state.auth.config.userCompany
        ? state.auth.config.userCompany
        : [],
    ChartData:
      state.combine && state.combine.companyData
        ? state.combine.companyData
        : [],
    ChartData2:
      state.combine && state.combine.sectorData ? state.combine.sectorData : [],
    reportData:
      state.combine && state.combine.reportData ? state.combine.reportData : [],
    TopNews: state.combine && state.combine.news ? state.combine.news : null,
    loading:
      state.combine && state.combine.loading ? state.combine.loading : false,
    error: state.NIS && state.NIS.NISError ? true : false,
    configUpdated: state.auth.configUpdateSuccess,
    companyName: state.combine && state.combine.companyName,
    sectorName: state.combine && state.combine.sectorName,
    primaryCompany:
      state.combine && state.combine.companyName
        ? state.combine.companyName
        : null,
    secReportData:
      state.combine && state.combine.secReportData
        ? state.combine.secReportData
        : [],

    transcript: state.transcript.transcript,
    secChartData: state.combine.secChartData,
    secCompanies: state.combine.secCompanies,

    stockPulse:
      state.stockPulse && state.stockPulse.stock.data
        ? state.stockPulse.stock.data
        : [],
    broker: state.stockPulse.broker,
    auditData: state.audit.auditData,
    secAuditData: state.audit.secAuditData,
    auditFirms: state.audit.auditFirmsData,
    auditPartners: state.audit.auditPartnersData,
    auditYear: state.audit.auditYearsData,

    reportLoading: state.combine.reportLoading,
    reportSuccess: state.combine.reportSuccess,
    reportError: state.combine.reportError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetNewsNIS: (company, token) => {
      dispatch(getData(company, token));
    },
    GetNISTopNews: (data, selected, token) => {
      dispatch(getNISTopNews(data, selected, token));
    },
    UpdateConfig: (data, token) => {
      dispatch(updateConfig(data, token));
    },
    GetConfig: (token) => {
      dispatch(getConfig(token));
    },
    GetTranscripts: (data, token) => {
      dispatch(getTranscriptScores(data, token));
    },
    ConfigUpdateReset: () => {
      dispatch(configUpdateReset());
    },
    GetStockPulse: (body, token) => {
      dispatch(getStockPulse(body, token));
    },
    SubmitAudit: (id, year, type, extra, token) => {
      dispatch(submitAudit(id, year, type, extra, token));
    },
    GetSecondaryComAudit: (secIds, year, token) => {
      dispatch(getSecondaryComAudit(secIds, year, token));
    },
    GetAuditYears: (company, token) => {
      dispatch(getAuditYears(company, token));
    },
    GetReport: (company, token) => {
      dispatch(getUserReport(company, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDashboard);
