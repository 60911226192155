import React, { useEffect, useState } from "react";
import {
  specialColorMap,
  specialColorMapLife,
} from "../../../../config/constants";
import BarChartGeneric from "../../../layout/BarChartGeneric";
import Legends from "../laylout/Legends";
import StackBar from "../laylout/StackBar";
import * as d3 from "d3";

export default function BarTwoStackCombination({
  barOneDetails,
  stackBarDetails,
  stackBarTwoDetails,
  life,
  handleSwitch,
  switchActive,
}) {
  return (
    <div className="row">
      <div className="col s4">
        {barOneDetails && (
          <div className="col s12">
            <div style={{ fontSize: 18 }}>
              <strong>{barOneDetails?.label}</strong>
            </div>
            <BarChartGeneric
              id={barOneDetails?.id}
              data={barOneDetails?.data || []}
              dimensions={barOneDetails?.dimensions}
              measures={barOneDetails?.measures}
              height={barOneDetails?.height}
              width={barOneDetails?.width}
              hideLegend={true}
              margins={{ left: 80, bottom: 10 }}
              fontSize={12}
              boldFont={true}
              noLabelWrapY={true}
              noXLabel={true}
              labelInBar={false}
              barFontSize={10}
            />
          </div>
        )}

        {stackBarTwoDetails && (
          <div className="col s12">
            <div style={{ fontSize: 18 }}>
              <strong>{stackBarTwoDetails?.label}</strong>
            </div>
            <Legends
              marginTop={16}
              maxChar={10}
              gic={stackBarTwoDetails?.gic}
              life={life}
              colSize={4}
              colorMapCustom={{
                first_year: "rgb(66, 136, 181)",
                renewal: "rgb(84, 170, 175)",
                single: "rgb(159, 217, 163)",
              }}
            />
            <StackBar
              id={stackBarTwoDetails?.id}
              data={stackBarTwoDetails?.data || []}
              dimensions={stackBarTwoDetails?.dimensions}
              measures={stackBarTwoDetails?.measures}
              group={stackBarTwoDetails?.group}
              can_height={stackBarTwoDetails?.height}
              can_width={stackBarTwoDetails?.width}
              hideLegend={true}
              margins={{ bottom: 20, left: 75 }}
              colorMap={{
                GDP_first_year: "rgb(66, 136, 181)",
                GDP_renewal: "rgb(84, 170, 175)",
                GDP_single: "rgb(159, 217, 163)",
              }}
              fontSize={12}
              life={life}
            />
          </div>
        )}
      </div>
      <div className="col s8">
        <div className="" style={{ fontSize: 18 }}>
          {switchActive ? (
            <div>
              <div className="col s8">{stackBarDetails?.label}</div>
              <div className="col s4 switch">
                <label>
                  None
                  <input type="checkbox" onChange={handleSwitch} />
                  <span class="lever"></span>
                  Per GDP
                </label>
              </div>
            </div>
          ) : (
            <strong>{stackBarDetails?.label}</strong>
          )}
        </div>
        <Legends
          marginTop={16}
          maxChar={8}
          gic={stackBarDetails?.gic}
          life={life}
        />
        <StackBar
          id={stackBarDetails?.id}
          data={stackBarDetails?.data || []}
          dimensions={stackBarDetails?.dimensions}
          measures={stackBarDetails?.measures}
          group={stackBarDetails?.group}
          can_height={stackBarDetails?.height}
          can_width={stackBarDetails?.width}
          hideLegend={true}
          margins={{ bottom: 20, left: 60 }}
          colorMap={life ? specialColorMapLife : specialColorMap}
          fontSize={12}
          life={life}
        />
      </div>
    </div>
  );
}
