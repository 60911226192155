import config from "../../config/config"

var backendUrl = config.backendUrl

export const getBroker = (token) => {
    return dispatch => {
    //   dispatch({type: 'LOADING_BROKER'});
      var url = backendUrl + "/brokers";
      fetch(url,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "BROKER_FETCH_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "BROKER_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "BROKER_FETCH_ERROR"
        });
      });
    };
  };

export const getStockPulse = (body, token) => {
    return dispatch => {
      dispatch({type: 'LOADING_STOCK'});
      var url = backendUrl + "/getStockReport";
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            data['body'] = body
            // //console.log("action", data)
            dispatch({
              type: "STOCK_FETCH_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "STOCK_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "STOCK_FETCH_ERROR"
        });
      });
    };
  };

export const getSecCompanies = (id, token) => {
    return dispatch => {
      dispatch({type: 'LOADING_SEC_COMPANIES'});
      var url = backendUrl + "/userStockCompany?primary_company_id="+id;
      fetch(url,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            //console.log("action", data)
            dispatch({
              type: "SEC_COMPANIES_FETCH_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "SEC_COMPANIES_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "SEC_COMPANIES_FETCH_ERROR"
        });
      });
    };
  };

export const addSecCompanies = (body, token) => {
    return dispatch => {
      dispatch({type: 'LOADING_ADD_SEC_COMPANIES'});
      var url = backendUrl + "/userStockCompany"
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            //console.log("action", data)
            dispatch({
              type: "ADD_SEC_COMPANIES_SUCCESS",
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "ADD_SEC_COMPANIES_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "ADD_SEC_COMPANIES_ERROR"
        });
      });
    };
  };

  export const getPieChart = (data) => {
    return dispatch => {
      if(data){
        dispatch({
          type:"PIE_CHART_SUCCESS",
          payload: data
        });
      }else{
        dispatch({
          type:"PIE_CHART_ERROR"
        });
      }

    }
  }

  
export const getStockTweetReport = (body, token) => {
  return dispatch => {
    dispatch({type: 'STOCK_TWEET_FETCH_LOADING'});
    var url = backendUrl + "/getStockTweetReport";
    fetch(url,{
      method: "post",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    })
    .then(response =>
      response.json().then(data => {
        if (response.status === 200) {
          data['body'] = body
          // //console.log("action", data)
          dispatch({
            type: "STOCK_TWEET_FETCH_SUCCESS",
            payload: data
          });
        } 
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: "STOCK_TWEET_FETCH_ERROR"
          });
        }
      })
    )
    .catch(err =>{
      dispatch({
        type: "STOCK_TWEET_FETCH_ERROR"
      });
    });
  };
};
