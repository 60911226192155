import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BarPieCombination from "./commonDashboards/BarPieCombination";
import { useSelector } from "react-redux";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../../config/constants";
import BarPieCombinationGic from "./commonDashboards/BarPieCombinationGic";

export default function LobPerf({ label, data }) {
  const [type, setType] = useState("NOP");

  console.log("REPORT DATA", data);

  const BAR_CHART_HEIGHT = 270;
  const BAR_CHART_WIDTH = 380;

  const handleTypeChange = (changedType) => {
    setType(changedType);
  };

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  console.log("REPORT DATA", data);
  console.log("DATA", data);
  return (
    <div className="row">
      <div className="col s2 offset-s10">
        <label>
          <input
            name="group1"
            type="radio"
            checked={type === "NOP"}
            onChange={() => {
              handleTypeChange("NOP");
            }}
          />
          <span>NOP</span>
        </label>
        <label>
          <input
            name="group1"
            type="radio"
            checked={type === "Premium"}
            onChange={() => {
              handleTypeChange("Premium");
            }}
          />
          <span>Premium</span>
        </label>
      </div>
      <div className="col s12">
        {/* <DataTable data={reportData} minRowInPage={10} /> */}
        <BarPieCombinationGic
          barDetails={{
            data: data
              ? data["channel-total"].filter((row) => row.segment === type)
              : [],
            height: BAR_CHART_HEIGHT,
            width: BAR_CHART_WIDTH,
            dimensions: [{ text: "company" }],
            measures: [{ text: "value" }],
            label: type,
          }}
          pieDetails={{
            data: data
              ? data["channel"].filter((row) => row.segment === type)
              : [],
            dimensions: [{ text: "particular" }],
            measures: [{ text: "value" }],
            label: "Channel wise distribution",
          }}
          lineChartDetails={{
            id: "channel-trend",
            height: BAR_CHART_HEIGHT,
            width: 800,
            dimensions: [{ text: "fyq" }],
            measures: [{ text: "add" }],
            group: "company",
            label: type + " trend",
            data: data
              ? data["channel-trend"]
                  ?.map((row) => {
                    return { ...row, fyq: row.year + ", " + row.quarter };
                  })
                  .filter((row) => row.segment === type)
              : [],
            gic: false,
          }}
          lengendMaxChar={15}
        />
        {/* <p className="notes-text">
          ** All segment wise figures are in percentage of total for the segment
        </p> */}
      </div>
    </div>
  );
}
