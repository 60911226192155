import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../layout/DataTable/DataTable";
import {
  downloadCompanyPdf,
  getNLValidationIssues,
  getNlList,
  markNlIssue,
  runExcelGeneration,
  runNlValidation,
} from "../../../store/action/nlActions";
import "../style.css";
import {
  getCompanyListForLifeReport,
  getCompanyListForNLReport,
} from "../../../utils/nlHelper";
import {
  Alert,
  Skeleton,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import QueryHead from "./QueryHead";
import M from "materialize-css";
import { SET_NL_REDUCER } from "../../../store/constants/nlConstants";
import swal from "sweetalert";
import {
  GENERATE_EXCEL_SETTINGS,
  NL_TYPE_GIC,
  NL_TYPE_LIFE,
} from "../../../config/constants";
import { Redirect, useHistory } from "react-router-dom";

export default function NlValidator(props) {
  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);
  const uid = useSelector((state) => state.auth.uid);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const settingsMeta = useSelector((state) => state.auth.settingsMeta);
  const markNlIssueLoading = useSelector(
    (state) => state.nl.markNlIssueLoading,
  );
  const markNlIssueSuccess = useSelector(
    (state) => state.nl.markNlIssueSuccess,
  );
  const markNlIssueError = useSelector((state) => state.nl.markNlIssueError);
  const nlList = useSelector((state) => state.nl.nlList);
  const nlYearQuarter = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const validationIssues = useSelector((state) => state.nl.validationIssues);
  const validationIssuesLoading = useSelector(
    (state) => state.nl.validationIssuesLoading,
  );
  const validateLoading = useSelector((state) => state.nl.validateLoading);
  const validateSuccess = useSelector((state) => state.nl.validateSuccess);
  const validateError = useSelector((state) => state.nl.validateError);
  const nlPdfLoading = useSelector((state) => state.nl.nlPdfLoading);
  const nlPdfError = useSelector((state) => state.nl.nlPdfError);
  const errorMessage = useSelector((state) => state.nl.message);
  const nlTemplates = useSelector((state) => state.nl.nlTemplates);
  const excelGenerateError = useSelector(
    (state) => state.nl.excelGenerateError,
  );

  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [nl, setNl] = useState("");
  const [error, setError] = useState(null);
  const [company, setCompany] = useState("");
  const [nlOrderMap, setNlOrderMap] = useState({});
  const [selectedCompanies, setExcelCompany] = useState([]);
  const [selectedTemplate, setNlTemplate] = useState(null);
  const [selectedInsuranceType, setSelectedInsuranceType] =
    useState(NL_TYPE_GIC);

  const dispatch = useDispatch();
  const INACTIVE = "inactive";
  const PDF_ISSUE = "pdf_issue";

  useEffect(() => {
    let years = [];
    let quarters = [];
    let fys = [];
    let qs = [];
    for (let i = 0; i < nlYearQuarter?.length; i++) {
      if (!fys.includes(nlYearQuarter[i].fyq.split("-")[0])) {
        years.push({
          value: nlYearQuarter[i].fyq.split("-")[0],
          label: nlYearQuarter[i].label.split(",")[0],
        });
      }
      if (!qs.includes(nlYearQuarter[i].fyq.split("-")[1])) {
        quarters.push({
          value: nlYearQuarter[i].fyq.split("-")[1],
          label: nlYearQuarter[i].label.split(",")[1],
        });
      }
      fys.push(nlYearQuarter[i].fyq.split("-")[0]);
      qs.push(nlYearQuarter[i].fyq.split("-")[1]);
    }
    setYears(years);
    setQuarters(quarters);
    if (years?.length > 0 && quarters?.length > 0) {
      setYear(years[years.length - 1].value);
      setQuarter(quarters[quarters.length - 1].value);
    }
    dispatch(getNlList({}, accessToken));
    let elem = document.getElementById("generate-excel");
    M.Modal.init(elem, { preventScrolling: false });
    createNlOrder();
  }, []);

  useEffect(() => {
    let payload = {};
    if (markNlIssueError) {
      handleError("Error in update", "error");
      payload["markNlIssueError"] = false;
    }
    if (markNlIssueSuccess) {
      handleError("Updated successfully", "success");
      getIssues();
      payload["markNlIssueSuccess"] = false;
    }
    if (validateSuccess) {
      handleError("Validation completed", "success");
      getIssues();
      payload["validateSuccess"] = false;
    }
    if (validateError) {
      handleError("Validation Error", "error");
      payload["validateError"] = false;
    }
    if (nlPdfError) {
      handleError(errorMessage || "Download Error", "error");
      payload["nlPdfError"] = false;
      payload["message"] = null;
    }
    if (excelGenerateError) {
      handleError(errorMessage || "EXEL GENERATION ERROR", "error");
      payload["excelGenerateError"] = false;
      payload["message"] = null;
    }
    dispatch({ type: SET_NL_REDUCER, payload });
  }, [
    markNlIssueSuccess,
    markNlIssueError,
    validateLoading,
    validateSuccess,
    validateError,
    nlPdfError,
    excelGenerateError,
  ]);

  useEffect(() => {
    createNlOrder();
  }, [nlList]);

  const createNlOrder = () => {
    let orderMap = {};
    for (let i = 0; i < nlList?.length; i++) {
      orderMap[nlList[i].lable.replace(" ", "")] = nlList[i].order;
    }
    setNlOrderMap(orderMap);
  };

  const changeYear = (e) => {
    setYear(e.target.value);
  };
  const changeQuarter = (e) => {
    setQuarter(e.target.value);
  };
  const changeNL = (e) => {
    setNl(e.target.value);
  };
  const changeCompany = (e) => {
    setCompany(e.target.value);
  };

  const getIssues = () => {
    dispatch(getNLValidationIssues({ year, quarter }, accessToken));
  };

  const markIssue = (selectedIds, type) => {
    console.log("SELC", selectedIds);
    let data = selectedIds?.map((id) => {
      return { id, status: type };
    });
    dispatch(markNlIssue(data, accessToken));
  };

  const isInsuranceCompany = (company) => {
    return getCompanyListForNLReport(company, sectors);
  };

  const isLifeCompany = (company) => {
    return getCompanyListForLifeReport(company, sectors);
  };

  const getPdf = () => {
    console.log("YEars", year);
    dispatch(
      downloadCompanyPdf(
        {
          year,
          quarter,
          nl_id: nl,
          company_id: company,
        },
        accessToken,
      ),
    );
  };
  const generateExcel = () => {
    let serverUrl = settingsMeta?.find(
      (setting) => setting.name === GENERATE_EXCEL_SETTINGS,
    )?.data?.url;
    let companyIds = selectedCompanies?.map((com) => {
      return com.value;
    });

    if (!serverUrl) {
      swal("Error", "Server URL not present in settings meta", "error");
    } else {
      swal({
        title: "Choose generation type?" + serverUrl,
        text: "Force generate will regenerate excel(developer) even if the entry exists and file will be replaced ?",
        icon: "warning",
        buttons: ["Generate", "Force Generate"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Excel force generation triggered", {
            icon: "success",
          });
          dispatch(
            runExcelGeneration(
              {
                year,
                quarter,
                company_id_list: companyIds,
                template: selectedTemplate?.value,
                forceGenerate: true,
              },
              serverUrl,
              accessToken,
            ),
          );
        } else {
          dispatch(
            runExcelGeneration(
              {
                year,
                quarter,
                company_id_list: companyIds,
                template: selectedTemplate?.value,
              },
              serverUrl,
              accessToken,
            ),
          );
          swal("Excel generation triggered", {
            icon: "success",
          });
        }
      });
    }
  };

  const runValidation = () => {
    if (selectedTemplate?.value && selectedTemplate?.value > 0)
      dispatch(
        runNlValidation(
          null,
          {
            year,
            quarter,
            template: selectedTemplate?.value,
            type: NL_TYPE_GIC,
          },
          accessToken,
        ),
      );
    else {
      dispatch(
        runNlValidation(
          null,
          {
            year,
            quarter,
            type: NL_TYPE_GIC,
          },
          accessToken,
        ),
      );
    }
  };

  const changeCompanies = (options) => {
    setExcelCompany(options);
  };

  const changeTemplate = (option) => {
    setNlTemplate(option);
  };

  const handleError = (message, type) => {
    setError({ message, type });
  };

  console.log("VALIDATE L:", selectedTemplate);

  if (!uid) return <Redirect to="/SignIn" />;

  return (
    <div className="row" style={{ padding: 10 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error ? true : false}
        autoHideDuration={4000}
        onClose={() => {
          setError(null);
        }}
      >
        <Alert
          onClose={() => {
            setError(null);
          }}
          severity={error?.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error?.message}
        </Alert>
      </Snackbar>
      <div className="col s12">
        {validateLoading ? (
          <Alert severity="info">Validation is running please wait</Alert>
        ) : (
          <QueryHead
            changeYear={changeYear}
            years={years}
            year={year}
            changeQuarter={changeQuarter}
            quarters={quarters}
            quarter={quarter}
            changeCompany={changeCompany}
            company={company}
            companies={companies}
            nl={nl}
            changeNL={changeNL}
            nlList={nlList}
            getIssues={getIssues}
            isInsuranceCompany={isInsuranceCompany}
            isLifeCompany={isLifeCompany}
            getPdf={getPdf}
            runValidation={runValidation}
            generateExcel={generateExcel}
            nlPdfLoading={nlPdfLoading}
            changeCompanies={changeCompanies}
            selectedCompanies={selectedCompanies}
            nlTemplates={nlTemplates?.filter(
              (tem) => tem.companyType === selectedInsuranceType,
            )}
            selectedTemplate={selectedTemplate}
            changeTemp={changeTemplate}
            selectedInsuranceType={selectedInsuranceType}
            setSelectedInsuranceType={setSelectedInsuranceType}
          />
        )}
        <div className="col s12">
          {validationIssuesLoading ? (
            <Skeleton variant="rectangular" height={200} />
          ) : (
            <>
              <DataTable
                enableToolbar={true}
                selectable={true}
                loading={validationIssuesLoading}
                data={validationIssues}
                minRowInPage={10}
                columns={[
                  "id",
                  "company",
                  "nl",
                  "error",
                  "row",
                  "column",
                  "priority",
                  "valueType",
                ]}
                bulkActions={[
                  {
                    label: "Mark Inactive",
                    action: (ids) => {
                      markIssue(ids, INACTIVE);
                    },
                    loading: markNlIssueLoading,
                  },
                  {
                    label: "Mark PDF Issue",
                    action: (ids) => {
                      markIssue(ids, PDF_ISSUE);
                    },
                    loading: markNlIssueLoading,
                  },
                ]}
                filterOrders={{ nl: nlOrderMap }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
