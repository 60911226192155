import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetPass, signIn } from "../../store/action/authActions";
import "./style.css";
import ForgotPass from "../layout/ForgotPass";
import M from "materialize-css";
import PapersDownload from "./PapersDownload";

export class SignIn extends Component {
  state = {
    email: "",
    password: "",
    name: "",
    company: "",
    contact: null,
    resetPassEmail: "",
  };

  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { preventScrolling: false });
  };
  componentDidUpdate = () => {
    var elems = document.getElementById("forgot-pass-modal");
    if (elems) var instance = M.Modal.getInstance(elems);
    if (elems && this.props.forgotPassSuccess && instance?.isOpen) {
      instance.close();
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.SignIn(this.state);
  };

  HandleForgotPass = (e) => {
    var elems = document.getElementById("forgot-pass-modal");
    if (elems) {
      var instance = M.Modal.getInstance(elems);
      instance?.open();
    }
  };

  ForgotPass = (e) => {
    this.props.ResetPass(this.state.resetPassEmail);
  };
  render() {
    const { authError, auth, authLoading, authErrorMessage } = this.props;
    if (auth.uid) return <Redirect to="/dashboard" />;
    if (authLoading) {
      return (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      );
    }
    console.log(this.props);
    return (
      <div
        className="container"
        style={{ transform: "translate(0%, 20%)", width: "80%" }}
      >
        <ForgotPass
          HandleChange={this.handleChange}
          state={this.state}
          OnSubmit={this.ForgotPass}
          status={this.props.forgotPassLoading}
        />
        <PapersDownload />
        <div className="row">
          <div className="col s12" style={{ padding: 20 }}>
            <div className="card style-card">
              <div
                className="row card-content center center-align"
                style={{ paddingTop: 90, paddingBottom: 70 }}
              >
                <form onSubmit={this.handleSubmit} style={{ marginTop: 0 }}>
                  <div className="row">
                    <div className="col s12 l6 m6 style-block">
                      <h1 className="type-animation">Textplor</h1>
                      <h3>Explore the text</h3>
                    </div>
                    <div className="col s12 l6 m6 form-block">
                      <h5>Sign In</h5>
                      <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-field">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          onChange={this.handleChange}
                          required
                        />
                      </div>

                      <div
                        className="input-field center center-align"
                        style={{ marginTop: 50 }}
                      >
                        <button
                          className="btn"
                          style={{ backgroundColor: "#2487ce" }}
                        >
                          Login
                        </button>
                        <div className="red-text center">
                          {authError ? (
                            <p>{authErrorMessage || "Login failed"}</p>
                          ) : null}
                        </div>
                      </div>
                      <p>
                        <span
                          onClick={this.HandleForgotPass}
                          className="left left-align forgot-pass"
                        >
                          Forgot Password?
                        </span>
                        <span className="right right-align forgot-pass">
                          <a className="modal-trigger" href="#modal-papers">
                            Research papers
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth,
    authLoading: state.auth.authLoading,
    authErrorMessage: state.auth.authErrorMessage,
    forgotPassError: state.auth.resetPasswordError,
    forgotPassSuccess: state.auth.resetPasswordSuccess,
    forgotPassLoading: state.auth.resetPasswordLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: (credentials) => {
      dispatch(signIn(credentials));
    },
    ResetPass: (email) => {
      dispatch(resetPass(email));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
