import { dispatch } from "d3-dispatch";
import config from "../../config/config"

var backendUrl = config.backendUrl

export const getAuditFirms = (token) => {
    return dispatch => {
      var url = backendUrl + "/getAuditFirms"; 
      dispatch({
        type: "AUDIT_FIRMS_FETCH_LOADING"
      }); 
      fetch(url,{
        headers:{
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response =>
        response.json().then(data => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "AUDIT_FIRMS_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "AUDIT_FIRMS_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "AUDIT_FIRMS_FETCH_ERROR"
        });
      });
    };
  };


export const getAuditPartners = (token) => {
    return dispatch => {
      var url = backendUrl + "/getAuditPartners"; 
      dispatch({
        type: "AUDIT_PARTNERS_FETCH_LOADING"
      }); 
      fetch(url,{
        headers:{
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response =>
        response.json().then(data => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "AUDIT_PARTNERS_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "AUDIT_PARTNERS_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "AUDIT_PARTNERS_FETCH_ERROR"
        });
      });
    };
  };


export const getAuditYears = (id, token) => {
    return dispatch => {
      var url = backendUrl + "/company/"+id+"/getAuditYears"; 
      dispatch({
        type: "AUDIT_YEARS_FETCH_LOADING"
      }); 
      fetch(url,{
        headers:{
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response =>
        response.json().then(data => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "AUDIT_YEARS_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "AUDIT_YEARS_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "AUDIT_YEARS_FETCH_ERROR"
        });
      });
    };
  };


  export const submitAudit = (id, year, type, extraData, token) => {
    return dispatch => {
      dispatch({type: 'AUDIT_DATA_FETCH_LOADING'});
      var url = backendUrl
      if(type == "company"){
        url += '/company/'+id+'/year/'+year+'/getAuditScore'
      }
      else if(type == "auditor"){
        url += '/auditFirm/'+id+'/year/'+year+'/getAuditScore'
      }
      else if(type == "partner"){
        url += '/auditPartner/'+id+'/year/'+year+'/getAuditScore'
      }
      else if(type == "continuation"){
        if(id)
        url += '/year/'+year+'/auditFirmContinuation?noOfYear='+id
        else
        url += '/year/'+year+'/auditFirmContinuation'
      }
      else if(type == "alerts"){
        if(id)
        url += '/year/'+year+'/auditOnAlerts?noOfAlerts='+id
        else
        url += '/year/'+year+'/auditOnAlerts'
      }
      fetch(url,{
        method: "get",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            console.log("action", data)
            dispatch({
              type: "AUDIT_DATA_FETCH_SUCCESS",
              payload: {data:data.data, extra:{...extraData, type, year}, single:data.data&&data.data.length==1}
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "AUDIT_DATA_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "AUDIT_DATA_FETCH_ERROR"
        });
      });
    };
  }

  

  export const filterAudit = (id, data) => {
    return dispatch => {
      dispatch({
        type: "AUDIT_DATA_FETCH_SUCCESS",
        payload: {data:data, extraId:id, single:false}
      });
    };
  }

  export const getSameAuditPartner = (year, id, token) => {
    return dispatch => {
      dispatch({type: 'AUDIT_SAME_PARTNER_FETCH_LOADING'});
      var url = backendUrl
        url += '/auditPartner/'+id+'/year/'+year+'/getAuditScore'
      fetch(url,{
        method: "get",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            console.log("action", data)
            dispatch({
              type: "AUDIT_SAME_PARTNER_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "AUDIT_SAME_PARTNER_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "AUDIT_SAME_PARTNER_FETCH_ERROR"
        });
      });
    };
  }


  export const getSameAuditFirm = (year, id, token) => {
    return dispatch => {
      dispatch({type: 'AUDIT_SAME_FIRM_FETCH_LOADING'});
      var url = backendUrl
        url += '/auditFirm/'+id+'/year/'+year+'/getAuditScore'
      fetch(url,{
        method: "get",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            console.log("action", data)
            dispatch({
              type: "AUDIT_SAME_FIRM_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "AUDIT_SAME_FIRM_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "AUDIT_SAME_FIRM_FETCH_ERROR"
        });
      });
    };
  }

  export const getSecondaryComAudit = (idList, year, token) => {
    return dispatch => {
      var url = backendUrl + "/year/" + year + "/secComAuditScores"; 
      dispatch({
        type: "SEC_AUDIT_DATA_FETCH_LOADING"
      }); 
      fetch(url,{
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({idList, year}),
      })
      .then(response =>
        response.json().then(data => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "SEC_AUDIT_DATA_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "SEC_AUDIT_DATA_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "SEC_AUDIT_DATA_FETCH_ERROR"
        });
      });
    };
  };

  export const getAccPolChange = (id, secIdList, year, token) => {
    return dispatch => {
      console.log("DATA", {id, secIdList, year})
      var url = backendUrl + "/getAccPolicyChange"; 
      dispatch({
        type: "ACC_POLICY_CHANGE_FETCH_LOADING"
      }); 
      fetch(url,{
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id, secIdList, year}),
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            dispatch({
              type: "ACC_POLICY_CHANGE_FETCH_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "ACC_POLICY_CHANGE_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "ACC_POLICY_CHANGE_FETCH_ERROR"
        });
      });
    };
  };

export const getAccPolChangeList = (year, noOfChange, token) => {
  return (dispatch) => {
    dispatch({ type: "AUDIT_DATA_FETCH_LOADING" });
    
    var url = noOfChange? backendUrl + "/year/"+year+"/accPolChangeList?noOfChange="+noOfChange
    :backendUrl + "/year/"+year+"/accPolChangeList"
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "AUDIT_DATA_FETCH_SUCCESS",
              message: data.message,
              payload: {data:data.data, extra:{noOfChange, year, type:'accpol'}, single:data.data&&data.data.length==1}
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "AUDIT_DATA_FETCH_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "AUDIT_DATA_FETCH_ERROR",
        });
      });
  };
};

  
  

export const switchAudit = (id) => {
  return dispatch => {
    dispatch({
      type: "SWITCH_AUDIT", payload:id
    }); 
  };
};

export const setAuditReducer = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_AUDIT_REDUCER", data: data });
  };
};
  