export const onHome = () => {
    return dispatch => {
        // //console.log("home action")
        dispatch({
            type: "HOME_CLICK"
        });
    }
  };

export const setDefaultCompany = (data) => {
    return dispatch => {
        console.log("home action", data)
        dispatch({
            type: "SAVE_DEFAULT",
            data: data
        });
    }
  };
