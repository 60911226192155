import config from "../../config/config"

var backendUrl = config.backendUrl

export const getAnnouncement = (body, token) => {
    return dispatch => {
      dispatch({type: 'LOADING_ANNOUNCEMENT'});
      var url = backendUrl + "/getTranscriptScore";
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            data['body'] = body
            // //console.log("action", data)
            dispatch({
              type: "ANNOUNCEMENT_FETCH_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "ANNOUNCEMENT_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err => {
        dispatch({
          type: "ANNOUNCEMENT_FETCH_ERROR"
        });
      })
    };
  };

  export const getPieChart = (data) => {
    return dispatch => {
      if(data){
        dispatch({
          type:"PIE_CHART_SUCCESS",
          payload: data
        });
      }else{
        dispatch({
          type:"PIE_CHART_ERROR"
        });
      }

    }
  }