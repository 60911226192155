import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { registerRequest } from "../../store/action/authActions";

export class RegisterRequest extends Component {
  state = {
    firstName:'',
    lastName:'',
    orgName:'',
    orgType:'',
    designation:'',
    department:'',
    email: "",
    password: "",
    name: "",
    company: "",
    contact: null,
  };
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  
  handleRegister = (e) => {
    e.preventDefault();
    this.props.Register(this.state);
  };

  OnCancel=(e)=>{
    this.props.history.push('/')
  }
  render() {
    //console.log("props", this.props);
    return (
      <div className="container" style={{ transform: "translate(0%, 5%)", width:"100%"}} >
        <div className="row">
          <div className="col s12" style={{padding:20}}>
            <div className="card">
              <div className="row card-content center center-align" style={{margin:50, paddingTop:70}}>
                <form onSubmit={this.handleRegister}  style={{marginTop:0}}>
                    <h3 style={{color:'#2487ce'}}>Register</h3>  
                    <div className="row">
                      <div className="input-field col s12 l6 m6">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" onChange={this.handleChange} required/>
                        </div>
                        <div className="input-field col s12 l6 m6">
                          <label htmlFor="lastName">Last Name</label>
                          <input type="text" id="lastName" onChange={this.handleChange} required/>
                        </div>

                        <div className="input-field col s12 l6 m6">
                            <label htmlFor="email">Business Email</label>
                            <input type="email" id="email" onChange={this.handleChange} required/>
                        </div>
                        <div className="input-field col s12 l6 m6">
                          <label htmlFor="contact">Phone Number</label>
                          <input type="text" id="contact" onChange={this.handleChange} required/>
                        </div>

                        <div className="input-field col s12 l6 m6">
                            <label htmlFor="orgType">Organization Type</label>
                            <input type="text" id="orgType" onChange={this.handleChange} required/>
                        </div>
                        <div className="input-field col s12 l6 m6">
                          <label htmlFor="orgName">Organization Name</label>
                          <input type="text" id="orgName" onChange={this.handleChange} required/>
                        </div>

                        <div className="input-field col s12 l6 m6">
                            <label htmlFor="designation">Designation</label>
                            <input type="text" id="designation" onChange={this.handleChange} required/>
                        </div>
                        
                        <div className="input-field col s12 l6 m6">
                          <label htmlFor="department">Department</label>
                          <input type="text" id="department" onChange={this.handleChange} required/>
                        </div>
                    </div>                        

                    <div className="input-field center center-align">
                        <button disabled={this.props.reqLoading} className="btn" onClick={this.handleRegister} style={{backgroundColor:'#2487ce'}}>
                            {
                                this.props.reqLoading?
                                    "Sending Request"
                                :
                                    "Submit"
                            }
                        </button>
                        <button className="btn" onClick={this.OnCancel} style={{backgroundColor:'red', marginLeft:20, opacity:'70%'}}>
                          Cancel
                        </button>
                        <div className="red-text center">
                            {this.props.reqError? <p>Error</p> :null}
                        </div>
                        <div className="green-text center">
                            {this.props.reqSuccess? <p>Request sent</p> :null}
                        </div>
                      </div>
                    
                </form> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reqError: state.auth.registerRequestError,
    reqSuccess: state.auth.registerRequestSuccess,
    reqLoading: state.auth.registerRequestLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Register: (credentials) => {
      dispatch(registerRequest(credentials));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterRequest);
