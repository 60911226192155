import React, { Component } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainScreen from "./components/layout/MainScreen";
import Dashboard from "./components/dashboard/Dashboard";
import SignIn from "./components/auth/SignIn";
import Navbar from "./components/layout/Navbar";
import { connect } from "react-redux";
import Report from "./components/dashboard/Report";
import AnnounceDashboard from "./components/dashboard/AnnounceDashboard";
import stockPulseDashboard from "./components/dashboard/stockPulseDashboard";
import NISDashboard from "./components/dashboard/NISDashboard";
import SMDashboard from "./components/dashboard/SMDashboard";
import { refreshToken } from "./store/action/authActions";
import { refreshInterval } from "./config/constants";
import MyDashboard from "./components/dashboard/MyDashboard";
import RegisterRequest from "./components/auth/RegisterRequest";
import ChangePassword from "./components/auth/ChangePassword";
import ConCall from "./components/dashboard/ConCall";
import TwitterDashboard from "./components/dashboard/TwitterDashboard";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import AuditDashboard from "./components/dashboard/AuditDashboard";
import AuditorDashboard from "./components/dashboard/AuditorDashboard";
import stockPulseDashboardNoTweet from "./components/dashboard/stockPulseDashboardNoTweet";
import AccPolicyDashboard from "./components/dashboard/AccPolicyDashboard";
import BroadFinance from "./components/dashboard/BroadFinance";
import AuditMatter from "./components/dashboard/AuditMatter";
import Lenders from "./components/layout/LendersScreen";
import Accounts from "./components/layout/Accounts";
import Stocks from "./components/layout/Stocks";
import ArticleNews from "./components/layout/AricleNews";
import ArticlePd from "./components/layout/AriclePd";
import NLDashboard from "./components/dashboard/NLDashboard";
import NLTrack from "./components/dashboard/NLTrack";
import NLReportDashboard from "./components/dashboard/NLReportDashboard";
import NlValidator from "./components/dashboard/nlValidation/NlValidator";
import NLData from "./components/dashboard/nlValidation/NLData";
import NLExcelStructure from "./components/dashboard/nlValidation/NLExcelStructure";
import NLPdfUploader from "./components/dashboard/nlValidation/NLPdfUploader";
import LifePdfUploader from "./components/dashboard/lifeValidation.js/LifePdfUploader";
import LifeValidator from "./components/dashboard/lifeValidation.js/LifeValidator";
import EditLife from "./components/dashboard/lifeValidation.js/EditLife";
import EditRawNL from "./components/dashboard/nlValidation/EditRawNL";
import DeleteNL from "./components/dashboard/deleteNl/DeleteNL";
import EditRawDynamicNL from "./components/dashboard/nlValidation/EditRawDynamicNL";

class App extends Component {
  componentDidMount() {
    const { auth, RefreshToken } = this.props;
    if (auth && auth.refreshToken && RefreshToken) {
      // console.log("%%%%%HERE")
      if (this.refreshTimer) clearInterval(this.refreshTimer);
      this.refreshTimer = setInterval(() => {
        // console.log("%%%%%%%%%%%%%%%%%%%%%%Refresh")
        RefreshToken(auth.accessToken, auth.refreshToken);
      }, refreshInterval);
    }
  }

  componentDidUpdate() {
    const { auth, RefreshToken } = this.props;
    if (auth && auth.refreshToken && RefreshToken) {
      // console.log("%%%%%HERE")
      if (this.refreshTimer) clearInterval(this.refreshTimer);
      this.refreshTimer = setInterval(() => {
        // console.log("%%%%%%%%%%%%%%%%%%%%%%Refresh")
        RefreshToken(auth.accessToken, auth.refreshToken);
      }, refreshInterval);
    }
  }
  componentWillUnmount() {
    if (this.refreshTimer) clearInterval(this.refreshTimer);
  }

  render() {
    const { auth } = this.props;
    return (
      <BrowserRouter>
        <div className="App">
          {/* <MainScreen/> */}
          <Navbar />
          <Switch>
            <Route exact path="/" component={MainScreen} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/report" component={Report} />
            <Route path="/SignIn" component={SignIn} />
            <Route path="/register" component={RegisterRequest} />
            <Route path="/announce" component={AnnounceDashboard} />
            <Route path="/changePass" component={ChangePassword} />
            <Route path="/stockTweet" component={stockPulseDashboard} />
            <Route path="/stock" component={stockPulseDashboardNoTweet} />
            <Route path="/nis" component={NISDashboard} />
            <Route path="/sm" component={SMDashboard} />
            <Route path="/user/:id/mydashboard" component={MyDashboard} />
            <Route path="/user/:id/admindashboard" component={AdminDashboard} />
            <Route path="/concall" component={ConCall} />
            <Route path="/twitter" component={TwitterDashboard} />
            <Route path="/audit" component={AuditDashboard} />
            <Route path="/auditor" component={AuditorDashboard} />
            <Route path="/accPolicy" component={AccPolicyDashboard} />
            <Route path="/broadFinance" component={BroadFinance} />
            <Route path="/auditMatter" component={AuditMatter} />
            <Route path="/lenders" component={Lenders} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/mfs" component={Stocks} />
            <Route path="/article-news" component={ArticleNews} />
            <Route path="/ariticle-pd" component={ArticlePd} />
            {/* <Route path="/userStat" component={UserStat} /> */}
            <Route path="/userStat" component={NLTrack} />
            <Route path="/nlDashboard" component={NLDashboard} />
            <Route path="/nl/reports" component={NLReportDashboard} />
            <Route path="/nl/reports/life" component={NLReportDashboard} />
            <Route path="/nl/validation" component={NlValidator} />
            <Route path="/nl/data/fix" component={NLData} />
            <Route path="/nl/structure/fix" component={NLExcelStructure} />
            <Route path="/nl/upload/pdf" component={NLPdfUploader} />
            <Route path="/life/upload/pdf" component={LifePdfUploader} />
            <Route path="/life/validation" component={LifeValidator} />
            <Route path="/life/data/fix" component={EditLife} />
            <Route path="/nl/data/raw/fix" component={EditRawNL} />
            <Route path="/nl/delete" component={DeleteNL} />
            <Route
              path="/nl/data/raw/dynamic/fix"
              component={EditRawDynamicNL}
            />
          </Switch>
          {/* {auth?<Footer />:null} */}
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    RefreshToken: (token, rToken) => {
      dispatch(refreshToken(token, rToken));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
