import React, { Component } from "react";
import BarChart from "../layout/BarChart";
import { connect } from "react-redux";
import { initResponse, submitPD } from "../../store/action/uploadAction";
import Dashboard from "./Dashboard";
import { Redirect } from "react-router-dom";
import moment from "moment";

class Report extends Component {
  state = {
    data: [12, 5],
    width: 700,
    height: 500,
    id: "root",
    year: null,
    companyData:null
  };

  componentDidMount() {
    document.body.style.overflow = "visible";
    if (
      this.props.submitPD &&
      this.props.submitPD.data &&
      this.props.submitPD.data[0] &&
      !this.state.year
    ) {
      console.log("UPDATE", this.props.submitPD.data[0]);
      this.setState({
        year: this.props.submitPD.data[0].year,
        companyData: this.props.submitPD.data[0].company_id,
      });
    }
  }
  componentDidUpdate(){
    if (
      this.props.submitPD &&
      this.props.submitPD.data &&
      this.props.submitPD.data[0] &&
      !this.state.year
    ) {
      console.log("UPDATE", this.props.submitPD.data[0]);
      this.setState({
        year: this.props.submitPD.data[0].year,
        companyData: this.props.submitPD.data[0].company_id,
      });
    }
  }
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };
  onYearChange = (year) => {
    const { auth } = this.props;
    this.setState({ year });
    if (
      this.props.yearData &&
      this.props.yearData.years &&
      this.props.yearData.years.includes(Number(year))
    ) {
      this.props.SubmitPD(
        this.state.companyData,
        year,
        auth.accessToken
      );
    } else {
      alert("Data Not found...");
      // //console.log(this.props.yearData.years, year);
    }
  };

  render() {
    const { auth, uploadResponse, submitPDSuccess, submitPD, submitPDLoading } = this.props;
    console.log("loc props", this.props)
    console.log("loc state", this.state)
    // //console.log("ubload response", uploadResponse);
    let barChartData = [];
    // const submitPD = submitPD.data['0'];
    if (!auth.uid) return <Redirect to="/SignIn" />;
    if (uploadResponse) {
      barChartData = [
        parseFloat(uploadResponse.sunshine_score),
        parseFloat(uploadResponse.fear_score),
      ];

      return (
        <div className="container valign-wrapper">
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="col s12 m12 l12">
              <div
                className="card cardDiv"
                style={{
                  backgroundColor: "#eee",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div className="card-content">
                  {/* <h4 className="card-title" style={{ color: "#23431b",fontWeight:'bold', marginTop:0, marginBottom:0 }}>
                    <center >{uploadResponse?uploadResponse.company_name:''}</center>
                  </h4> */}
                  <h3 className="card-title" style={{ marginTop: 0 }}>
                    <strong style={{ color: "#2d49a6" }}>
                      Sentiment Report:
                      {uploadResponse
                        ? " " + uploadResponse.company_name
                        : ""}{" "}
                    </strong>
                  </h3>
                  <hr />
                  <div
                    className="col s12 m6 l6"
                    style={{ marginBottom: 0, marginTop: 10 }}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ padding: 5 }}>Warnings:</td>
                          {/* <td>{uploadResponse?uploadResponse.errormessage:''}</td> */}

                          <div
                            style={{
                              width: 100,
                              height: 20,
                              marginTop: 5,
                              marginBottom: 0,
                              backgroundColor:
                                uploadResponse.em_score <= 0.3
                                  ? "#23431b"
                                  : uploadResponse.em_score > 0.3 &&
                                    uploadResponse.em_score <= 0.5
                                  ? "orange"
                                  : uploadResponse.em_score > 0.5
                                  ? "#DF0101"
                                  : "#99004c",
                            }}
                          />
                        </tr>
                        <tr>
                          {/* <div className="row" style={{padding:5, fontSize:12}}> */}
                          <td style={{ padding: 5 }}>
                            <div
                              className="col s6 l6 m6"
                              style={{ marginLeft: 0, fontSize: 12 }}
                            >
                              <span>{"PD<= 0.3"}</span>
                              <div
                                className="col s12 l12 m12"
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 0,
                                  marginRight: 5,
                                  backgroundColor: "#23431b",
                                }}
                              ></div>
                            </div>
                            <div
                              className="col s6 l6 m6"
                              style={{ marginLeft: 0, fontSize: 12 }}
                            >
                              <span>{"0.3 < PD <= 0.5"}</span>
                              <div
                                className="col s12 l12 m12"
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 0,
                                  marginRight: 5,
                                  backgroundColor: "orange",
                                }}
                              ></div>
                            </div>
                          </td>
                          <td style={{ padding: 5 }}>
                            <div
                              className="col s12 l12 m12"
                              style={{ marginLeft: 0, fontSize: 12 }}
                            >
                              <span>{"PD> 0.5"}</span>
                              <div
                                className="col s12 l12 m12"
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 0,
                                  marginRight: 5,
                                  backgroundColor: "#DF0101",
                                }}
                              ></div>
                            </div>
                          </td>
                          {/* </div> */}
                        </tr>
                        <tr>
                          <td style={{ padding: 5 }}>Sunshine Score</td>
                          <td style={{ padding: 5 }}>
                            {uploadResponse
                              ? uploadResponse.sunshine_score
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 5 }}>Fear Score</td>
                          <td style={{ padding: 5 }}>
                            {uploadResponse ? uploadResponse.fear_score : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 5 }}>Distress Intensity</td>
                          <td style={{ padding: 5 }}>
                            {uploadResponse
                              ? uploadResponse.distress_probability
                              : ""}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ padding: 5 }}>Probability of Default</td>
                          <td style={{ padding: 5 }}>
                            {uploadResponse ? Number(uploadResponse.em_score).toFixed(2) : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {uploadResponse.sunshine_score &&
                  uploadResponse.fear_score ? (
                    <div className="col s12 m12 l6" style={{ marginBottom: 0 }}>
                      <BarChart
                        data={[
                          {
                            name: "",
                            color: "",
                            sunshine_score: parseFloat(
                              uploadResponse.sunshine_score
                            ),
                            fear_score: parseFloat(uploadResponse.fear_score),
                          },
                        ]}
                        dimensions={[{ name: "name" }]}
                        measures={[
                          { name: "sunshine_score" },
                          { name: "fear_score" },
                        ]}
                        height="180"
                        width="430"
                      />
                    </div>
                  ) : null}
                  <div
                    className="row"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    <div
                      className="col s12 m6 l6 "
                      style={{
                        marginTop: 10,
                        marginBottom: 0,
                        backgroundColor: "#eee",
                      }}
                    >
                      <p>
                        <strong>Sunshine Sentences</strong>
                      </p>
                      <div
                        style={{
                          height: 300,
                          overflowY: "scroll",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        <ul className="collection">
                          {uploadResponse &&
                            uploadResponse.sun_sentences.map((sentence) => (
                              <li className="collection-item">{sentence}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="col s12 m6 l6 "
                      style={{
                        marginTop: 10,
                        marginBottom: 0,
                        backgroundColor: "#eee",
                      }}
                    >
                      <p>
                        <strong>Fear Sentences</strong>
                      </p>
                      <div
                        style={{
                          height: 300,
                          overflowY: "scroll",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        <ul className="collection">
                          {uploadResponse &&
                            uploadResponse.fear_sentences.map((sentence) => (
                              <li className="collection-item">{sentence}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <button type="button" className="btn btn-success btn-block" onClick={this.handleBack} style={{marginTop:10,backgroundColor:'#23431b'}} >Back to Home</button>  */}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (submitPDSuccess||(submitPDLoading&&submitPD)) {
      // //console.log(submitPD);
      barChartData = [
        submitPD.data["0"] != undefined
          ? parseFloat(submitPD.data["0"].sunshine)
          : "-",
        submitPD.data["0"] != undefined
          ? parseFloat(submitPD.data["0"].fear_score)
          : "-",
      ];
      return (
        <div className="container valign-wrapper" style={{width: '90%'}}>
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="col s12 m12 l12">
              <div
                className="card cardDiv"
                style={{
                  backgroundColor: "#eee",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div className="card-content">
                  {/* <h4 className="card-title" style={{ color: "#23431b",fontWeight:'bold', marginTop:0, marginBottom:0 }}>
                      <center >{uploadResponse?uploadResponse.company_name:''}</center>
                    </h4> */}
                  <h3 className="card-title" style={{ marginTop: 0 }}>
                    <div className="col s12 m6 l6" style={{ marginBottom: 0 }}>
                      <strong style={{ color: "#2d49a6" }}>
                        Sentiment Report:
                        {submitPD&&submitPD.data&&submitPD.data[0]&&submitPD.data[0].company_id
                        &&this.props.companies
                        &&this.props.companies.find(c=>c.id==submitPD.data[0].company_id)
                          ? " " + this.props.companies.find(c=>c.id==submitPD.data[0].company_id).name
                          : ""}{" "}
                      </strong>
                    </div>
                    <div className="col s12 m12 l12" style={{ marginBottom: 0 }}>
                    <table className="pdTableY" style={{marginLeft:0, fontSize:14}}>
                      <thead>
                      {
                        this.props.yearData&&
                        this.props.yearData.years&&
                        this.props.yearData.years.map(year => 
                              <th key={year} style={Number(year)==Number(this.state.year)
                              ?{backgroundColor:"#2d49a6", color:"white", padding:0}
                              :{color:"#2d49a6", padding:0}}>
                                  <b>{year}</b>
                              </th>
                          )
                      }
                      </thead>
                      <tbody>
                          <tr style={{border:1, borderStyle:"solid", borderColor:"#2d49a6"}}>
                            {
                              this.props.yearData&&
                              this.props.yearData.years&&
                              this.props.yearData.years.map(year => 
                                <td key={year} style={Number(year)==Number(this.state.year)?
                                  {backgroundColor:"#2d49a6", color:"white", padding:0}                                
                                  :{color:"#2d49a6", padding:0}} id={year}>
                                    {submitPDLoading && this.state.year == year?
                                    <div className="col s2">
                                      <div className="small-loader"></div>
                                    </div>
                                    :
                                    <i style={{
                                      cursor:'pointer',
                                    }} onClick={()=>{this.onYearChange(year)}} className="material-icons">chevron_right</i>
                                  }
                                </td>
                              )
                            }
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </h3>
                  <div
                    className="col s12 m6 l6"
                    style={{ marginBottom: 0, marginTop: 10 }}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ padding: 5 }}>Warnings:</td>
                          {/* <td>{uploadResponse?uploadResponse.errormessage:''}</td> */}

                          <div
                            style={{
                              width: 100,
                              height: 20,
                              marginTop: 5,
                              marginBottom: 0,
                              backgroundColor:
                                submitPD.data["0"] != undefined &&
                                (submitPD.data["0"].pd <= 0.5
                                  ? "#23431b"
                                  : submitPD.data["0"].pd > 0.5 &&
                                    submitPD.data["0"].pd <= 0.75
                                  ? "orange"
                                  : submitPD.data["0"].pd > 0.75
                                  ? "#DF0101"
                                  : "#99004c"),
                            }}
                          />
                        </tr>
                        <tr>
                          {/* <div className="row" style={{padding:5, fontSize:12}}> */}
                          <td style={{ padding: 5 }}>
                            <div
                              className="col s4 l4 m4"
                              style={{ marginLeft: 0, fontSize: 12 }}
                            >
                              <span>{"PD<= 0.5"}</span>
                              <div
                                className="col s12 l12 m12"
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 0,
                                  marginRight: 5,
                                  backgroundColor: "#23431b",
                                }}
                              ></div>
                            </div>
                            <div
                              className="col s4 l4 m4"
                              style={{ marginLeft: 0, fontSize: 12 }}
                            >
                              <span>{"0.5 < PD <= 0.75"}</span>
                              <div
                                className="col s12 l12 m12"
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 0,
                                  marginRight: 5,
                                  backgroundColor: "orange",
                                }}
                              ></div>
                            </div>
                            <div
                              className="col s4 l4 m4"
                              style={{ marginLeft: 0, fontSize: 12 }}
                            >
                              <span>{"PD> 0.75"}</span>
                              <div
                                className="col s12 l12 m12"
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 0,
                                  marginRight: 5,
                                  backgroundColor: "#DF0101",
                                }}
                              ></div>
                            </div>
                          </td>
                          {/* </div> */}
                        </tr>
                        <tr>
                          <td style={{ padding: 5 }}>Sunshine Score</td>
                          <td style={{ padding: 5 }}>
                            {submitPD.data["0"] != undefined
                              ? submitPD.data["0"].sunshine
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 5 }}>Fear Score</td>
                          <td style={{ padding: 5 }}>
                            {submitPD.data["0"] != undefined
                              ? submitPD.data["0"].fear
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 5 }}>Distress Intensity</td>
                          <td style={{ padding: 5 }}>
                            {submitPD.data["0"] != undefined
                              ? Number(submitPD.data["0"].di).toFixed(2)
                              : ""}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ padding: 5 }}>Probability of Default</td>
                          <td style={{ padding: 5 }}>
                            {submitPD.data["0"] != undefined
                              ? Number(submitPD.data["0"].pd).toFixed(2)
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {submitPD.data["0"] != undefined &&
                  submitPD.data["0"].sunshine &&
                  submitPD.data["0"].fear ? (
                    <div className="col s12 m12 l6" style={{ marginBottom: 0 }}>
                      <BarChart
                        data={[
                          {
                            name: "",
                            color: "",
                            sunshine_score: parseFloat(
                              submitPD.data["0"].sunshine
                            ),
                            fear_score: parseFloat(submitPD.data["0"].fear),
                          },
                        ]}
                        dimensions={[{ name: "name" }]}
                        measures={[
                          { name: "sunshine_score" },
                          { name: "fear_score" },
                        ]}
                        height="160"
                        width="430"
                      />
                    </div>
                  ) : null}
                  <div
                    className="row"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    <div
                      className="col s12 m6 l6 "
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        backgroundColor: "#eee",
                      }}
                    >
                      <p>
                        <strong>Sunshine Sentences</strong>
                      </p>
                      <div
                        style={{
                          height: 230,
                          overflowY: "scroll",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        <ul className="collection">
                          {submitPD.data &&
                            submitPD.data["0"] != undefined &&
                            submitPD.data["0"].pos_sen.map((sentence) => (
                              <li className="collection-item">{sentence}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="col s12 m6 l6 "
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        backgroundColor: "#eee",
                      }}
                    >
                      <p>
                        <strong>Fear Sentences</strong>
                      </p>
                      <div
                        style={{
                          height: 230,
                          overflowY: "scroll",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        <ul className="collection">
                          {submitPD.data &&
                            submitPD.data["0"] != undefined &&
                            submitPD.data["0"].neg_sen.map((sentence) => (
                              <li className="collection-item">{sentence}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <button type="button" className="btn btn-success btn-block" onClick={this.handleBack} style={{marginTop:10,backgroundColor:'#23431b'}} >Back to Home</button>  */}
              </div>
            </div>
          </div>
        </div>
      );
    } 
    else {
      return <Dashboard/>
    }
  }
}

const mapStateToProps = (state) => {
  // //console.log(state);
  return {
    companies: state.NIS.companyList,
    uploadResponse: state.uploadedDataResponse.uploadResponse,
    submitPDLoading: state.uploadedDataResponse.submitPDLoading,
    submitPDLoading: state.uploadedDataResponse.submitPDLoading,
    submitPDSuccess: state.uploadedDataResponse.submitPDSuccess,
    submitPD: state.uploadedDataResponse.submitPD,
    yearData: state.uploadedDataResponse.yearData,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initResponse: () => {
      dispatch(initResponse());
    },
    SubmitPD: (company, year, token) => {
      dispatch(submitPD(company, year, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
