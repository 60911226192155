import React, { Component } from "react";
// import {isBrowser,isMobile
// } from "react-device-detect";
import LineChart from "../layout/LineChart";
import { connect } from "react-redux";
// import { initResponse } from "../../store/action/uploadAction";
// import Dashboard from "./Dashboard";
import { getNewsNIS, getNISTopNews } from "../../store/action/nisActions";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import News from "../layout/News";
import LineBarChart from "../layout/LineBarChart";

class NISDashboard extends Component {
  state = {
    data: [12, 5],
    width: 1050,
    height: 300,
    id: "root",
    name:"News Score",
    frequency: this.props.initData ? this.props.initData.frequency : null,
    company: this.props.initData ? this.props.initData.company : null,
    startDate: this.props.initData ? this.props.initData.startDate : null,
    endDate: this.props.initData ? this.props.initData.endDate : null,
    relevanceThreshold: this.props.initData
      ? this.props.initData.relevanceThreshold
      : null,
    noveltyThreshold: this.props.initData
      ? this.props.initData.noveltyThreshold
      : null,
  };

  componentDidMount = () => {
    // //console.log("nis", this.state)
    document.body.style.overflow = 'visible'
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, { 
      preventScrolling:false,
     });
    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      onClose: () => {
        this.onDateClose();
      },
      autoClose:true,
    });
    // this.updateDimensions();
    // window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
    });
    document.body.style.overflow = 'visible'
    // //console.log(this.state)
  };
  componentDidUpdate = () => {};
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };

  onNISChangeHandler = (event) => {
    // //console.log(event.target.id, event.target.value)
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    // //console.log(this.props)
    this.props.GetNewsNIS(this.state, this.props.auth.accessToken);
  };

  // updateDimensions() {
  //   // //console.log("WIDTH", window.innerWidth)
  //   if (window.innerWidth <= 500) {
  //     this.setState({
  //       ...this.state,
  //       width: window.innerWidth - 60,
  //       height: 250,
  //       tickRotation: true,
  //     });
  //   } else if (window.innerWidth < 1400 && window.innerWidth > 1000) {
  //     this.setState({ ...this.state, width: 700, height: 350 });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       width: 1050,
  //       height: 300,
  //       tickRotation: false,
  //     });
  //   }
  //   // //console.log("here", this.state);
  // }
  OnClick = (d, selected) => {
    // //console.log("CLICKED", d);
    var data = {
      date: d,
      relevanceThreshold: this.state.relevanceThreshold,
      noveltyThreshold: this.state.noveltyThreshold,
      frequency: this.state.frequency,
      company: this.state.company,
    };
    this.props.GetNISTopNews(data, selected, this.props.auth.accessToken);
  };
  /**
   * Remove event listener
   */
  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateDimensions.bind(this));
  // }

  render() {
    const {
      auth,
      ChartData,
      TopNews,
      TopNewsStock,
      CompanyName,
      loading,
      error,
      newsLoading,
    } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    if (!ChartData || ChartData.length === 0)
      return <Redirect to="/dashboard" />;
    return (
      <div className="row" style={{ marginBottom: 0}}>
        <div className="col s12 l2 m5" style={{ backgroundColor: "#109ea3"}}>
          <div className="row center-align"
            style={{
              position: "fixed",
              top: 60,
              width: "inherit",
              backgroundColor: "#109ea3",
              height: "100vh",
              overflow: 'auto'
            }}
          >
            <p style={{ color: "#fff", fontSize: 20, margin: 10 }}>
              <strong> Modify Parameters</strong>
            </p>
            <form className="col s12" onSubmit={this.onSubmit}>
              <div className="col s12">
                <div className="row left-align">
                  <p style={{ color: "#fff", marginTop: 0, marginBottom: 5 }}>
                    Company Name
                  </p>
                  <input
                    style={{
                      backgroundColor: "#eee",
                      borderBottom: "none",
                      borderBottomColor: "transparent",
                      outline: "none",
                      height: 25,
                      marginBottom: 0,
                    }}
                    id="company"
                    // onChange={this.onNISChangeHandler}
                    value={CompanyName}
                    className="validate center-align"
                    disabled={true}
                    required
                  />
                </div>
                <div className="row left-align">
                  <p style={{ color: "#fff", marginTop: 0, marginBottom: 5 }}>
                    Start Date
                  </p>
                  <input
                    type="text"
                    className="datepicker validate center-align"
                    id="startDate"
                    value={this.state.startDate}
                    style={{
                      backgroundColor: "#eee",
                      borderBottom: "none",
                      borderBottomColor: "transparent",
                      outline: "none",
                      height: 25,
                    }}
                    required
                  />
                </div>
                <div className="row left-align">
                  <p style={{ color: "#fff", marginTop: 0, marginBottom: 5 }}>
                    End Date
                  </p>
                  <input
                    type="text"
                    className="datepicker validate center-align"
                    id="endDate"
                    value={this.state.endDate}
                    style={{
                      backgroundColor: "#eee",
                      borderBottom: "none",
                      borderBottomColor: "transparent",
                      outline: "none",
                      height: 25,
                    }}
                    required
                  />
                </div>
                <div className="row left-align" style={{marginBottom:0}}>
                  <p
                    className="range-field"
                    style={{ color: "#fff", marginTop: 0, marginBottom: 0 }}
                  >
                    Relevance Threshold :{" "}
                    <strong> {this.state.relevanceThreshold}</strong>
                  </p>
                  <input
                    type="range"
                    step="0.1"
                    min="0.8"
                    max="1.0"
                    value={this.state.relevanceThreshold}
                    style={{
                      border: "none",
                      borderBottom: "none",
                      borderBottomColor: "none",
                      outline: "none",
                      height: 10,
                    }}
                    id="relevanceThreshold"
                    onChange={this.onNISChangeHandler}
                    className=" validate center-align"
                    required
                  />
                </div>
                <div className="row left-align" style={{marginBottom:0}}>
                  <p
                    className="range-field"
                    style={{ color: "#fff", marginTop: 0, marginBottom: 0 }}
                  >
                    Novelty Threshold :{" "}
                    <strong> {this.state.noveltyThreshold}</strong>
                  </p>
                  <input
                    type="range"
                    step="0.1"
                    min="0.4"
                    max="1.0"
                    onChange={this.onNISChangeHandler}
                    style={{
                      border: "none",
                      borderBottom: "none",
                      borderBottomColor: "transparent",
                      outline: "none",
                      height: 20,
                    }}
                    id="noveltyThreshold"
                    value={this.state.noveltyThreshold}
                    className="validate center-align"
                    required
                  />
                </div>
                <div className="row left-align" style={{ marginBottom: 0 }}>
                  <p style={{ color: "#fff", marginTop: 0, marginBottom: 5 }}>
                    Frequency
                  </p>
                  <p style={{marginBottom:0 }}>
                    <label>
                      <input
                        id="frequency"
                        type="radio"
                        name="frequency"
                        className="with-gap"
                        defaultChecked={this.state.frequency === "daily"}
                        onChange={this.onNISChangeHandler}
                        value="daily"
                        height="10"
                        required
                      />
                      <span
                        style={{
                          color: "#fff",
                          // fontFamily: "Roboto",
                          // fontSize: 20,
                        }}
                      >
                        Daily
                      </span>
                    </label>
                  </p>
                  <p style={{marginBottom:0 }}>
                    <label>
                      <input
                        id="frequency"
                        type="radio"
                        name="frequency"
                        className="with-gap"
                        defaultChecked={this.state.frequency === "weekly"}
                        onChange={this.onNISChangeHandler}
                        value="weekly"
                        height="10"
                        required
                      />
                      <span
                        style={{
                          color: "#fff",
                          // fontFamily: "Roboto",
                          // fontSize: 20,
                        }}
                      >
                        Weekly
                      </span>
                    </label>
                  </p>
                  <p style={{marginBottom:0 }}>
                    <label>
                      <input
                        id="frequency"
                        type="radio"
                        name="frequency"
                        className="with-gap"
                        defaultChecked={this.state.frequency === "monthly"}
                        onChange={this.onNISChangeHandler}
                        value="monthly"
                        height="10"
                        required
                      />
                      <span
                        style={{
                          color: "#fff",
                          // fontFamily: "Roboto",
                          // fontSize: 20,
                        }}
                      >
                        Monthly
                      </span>
                    </label>
                  </p>
                </div>

                <div className="center center-align">
                  {loading ? (
                    <div className="preloader-wrapper small active">
                      <div className="spinner-layer spinner-yellow-only">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                          <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col s12 l12 m12 center center-align"
                      style={{ marginTop: 10}}
                    >
                      <button
                        className="btn"
                        style={{ backgroundColor: "#eee", color: "#23431b" }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  )}
                  <div className="red-text">
                    {error ? <p>Data not available</p> : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col s12 l10 m7 ">
          <div className="row center-align">
            <p style={{ color: "#23431b" }}>
              <strong>News Impact</strong>
            </p>
            <div
              className="col s10 m10 l10 offset-s1 offset-l1 offset-m1"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <LineBarChart
                can_height={this.state.height}
                can_width={this.state.width}
                dataset={ChartData}
                onClick={this.OnClick}
                selected={this.props.selected ? this.props.selected : null}
                name={this.state.name}
                bgcolor="white"
                lineColor="#73e7eb"
              />
            </div>
            <div className="container" style={{width:'90%'}}>
            <div
              className="col s12 m12 l12">
              <div
                className={newsLoading ? "center-align" : "left-align"}
              >
                {newsLoading ? (
                  <div style={{marginTop: 50}}>
                    <div className="preloader-wrapper large active">
                      <div className="spinner-layer spinner-green-only">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                          <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : TopNews ? 
                <div>
                  <div className="col s6">
                    <div
                      className="row center center-align"
                      style={{ marginBottom: 0, marginTop: 40 }}
                    >
                      <p style={{ color: "#23431b" }}>
                        <strong>All News</strong>
                      </p>
                    </div>
                    <News textColor={"#23431b"} height={200} TopNews={TopNews}/>
                  </div>
                  <div className="col s6">
                  <div
                    className="row center center-align"
                    style={{ marginBottom: 0, marginTop: 40 }}
                  >
                    <p style={{ color: "#23431b" }}>
                      <strong>Stock related News </strong>
                    </p>
                  </div>
                    <News textColor={"#23431b"} height={200} TopNews={TopNewsStock}/>
                  </div>
                </div>
                : (
                  <p
                    className="center-align"
                    style={{ color: "#23431b", fontSize: 20, marginTop:100 }}
                  >
                    Click on bars to get top news
                  </p>
                )}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    uploadResponse: state.uploadedDataResponse.uploadResponse,
    ChartData: state.NIS && state.NIS.NewsNISData ? state.NIS.NewsNISData : [],
    TopNews:
      state.NIS && state.NIS.NewsNISTopNews ? state.NIS.NewsNISTopNews : null,
    TopNewsStock:
      state.NIS && state.NIS.NewsStockTopNews ? state.NIS.NewsStockTopNews : null,
    CompanyName:
      state.NIS && state.NIS.NewsNISCompany ? state.NIS.NewsNISCompany : "",
    initData: state.NIS && state.NIS.initData ? state.NIS.initData : null,
    loading: state.NIS && state.NIS.NISLoading ? state.NIS.NISLoading : false,
    newsLoading:
      state.NIS && state.NIS.NISTopNewsLoading
        ? state.NIS.NISTopNewsLoading
        : false,
    selected: state.NIS ? state.NIS.selected : null,
    error: state.NIS && state.NIS.NISError ? true : false,
    auth: state.auth,
    profile: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetNewsNIS: (data, token) => {
      dispatch(getNewsNIS(data, token));
    },
    GetNISTopNews: (data, selected, token) => {
      dispatch(getNISTopNews(data, selected, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NISDashboard);