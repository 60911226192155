import React from "react";

export default function Announcements({ announcements, component }) {
  return (
    <div>
      <ul style={{ backgroundColor: "rgb(93, 27, 143)" }}>
        {component == "my_dashboard" ? (
          <div className="row" style={{ paddingTop: 9 }}>
            <div className="col s2  center-align">
              <p style={{ color: "white", fontWeight: 500 }}>Date</p>
            </div>
            <div className="col s2 center-align">
              <p style={{ color: "white", fontWeight: 500 }}>Topic</p>
            </div>
            <div className="col s6 center-align" style={{ color: "#23431b" }}>
              <p style={{ color: "white", fontWeight: 500 }}>Headline</p>
            </div>
            <div className="col s2 center-align">
              <p style={{ color: "white", fontWeight: 500 }}>Score</p>
            </div>
          </div>
        ) : null}
      </ul>
      <div
        className="top-news-ud"
        style={
          component == "my_dashboard"
            ? {
                height: 300,
                overflowY: "auto",
                marginTop: -20,
                marginBottom: 10,
              }
            : {
                height: 300,
                overflowY: "auto",
                marginTop: 50,
                marginBottom: 10,
              }
        }
      >
        <ul className="collection" style={{ backgroundColor: "white" }}>
          {announcements &&
            announcements.map((news) => (
              <div className="row top-news-item">
                <div className="col s2 center center-align">
                  <p style={{ color: "black" }}>{news.date}</p>
                </div>
                <div className="col s2 center center-align">
                  <p style={{ color: "coral" }}>{news.topic}</p>
                </div>
                <div className="col s6 left-align">
                  <a
                    href={news.fileUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="collection-item"
                    style={{ color: "#5d1b8f" }}
                  >
                    {news.headline}
                  </a>
                </div>
                <div
                  className="col s2"
                  style={
                    news.score > 0
                      ? { color: "green" }
                      : news.score < 0
                      ? { color: "red" }
                      : { color: "blue" }
                  }
                >
                  <p>
                    <strong>
                      {news.score > 0
                        ? "High"
                        : news.score < 0
                        ? "Low"
                        : "Moderate"}
                    </strong>
                  </p>
                </div>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
}
