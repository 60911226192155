import React, { Component } from "react";
import StackBarChart from "../layout/StackBarChart";
import PieChart from "../layout/PieChart";

export class TwitterDashboard extends Component {
  state = {
    data: [
      { topic: "Stock", count: 10 },
      { topic: "Salary", count: 40 },
      { topic: "Tech", count: 20 },
      { topic: "Launch", count: 30 },
    ],
    dataN: [
      { topic: "Stock", count: 20 },
      { topic: "Salary", count: 10 },
      { topic: "Tech", count: 50 },
      { topic: "Launch", count: 40 },
    ],
    data2: [
      {
        date: "01/02/2020",
        user: "Billie Jean",
        tag: "#TCS",
        tweet: "Nobody is doing it like her!! This is amazing",
        sentiment: "Positive",
      },
      {
        date: "04/10/2021",
        user: "Ben",
        tag: "#TCS",
        tweet:
          "Sigh...Just performative nonsense because he has.   Politics were so less stupid before Twitter.",
        sentiment: "Neutral",
      },
      {
        date: "01/02/2020",
        user: "StopLagayen",
        tag: "#TCS",
        tweet: "Nobody is doing it like her!! This is amazing âœ¨",
        sentiment: "Negative",
      },
      // {date:"01/02/2020", user:"Jam", tag:"#YUI", tweet:"That was a godd one"},
    ],
  };
  render() {
    return (
      <div className="row">
        <div className="col s12">
          <p className="twitter-upper-header">Social Media Dashboard</p>
        </div>
        <div className="col s3">
          <div className="row">
            <div className="col s6">
              <div className="card">
                <div className="card-content">
                  <h5>
                    Follower{" "}
                    <i
                      style={{ color: "#1DA1F2" }}
                      className="bx bxl-twitter"
                    />
                  </h5>

                  <h2>4,66,236</h2>
                </div>
              </div>
            </div>
            <div className="col s6">
              <div className="card">
                <div className="card-content">
                  <h5>
                    Likes
                    <i
                      style={{ color: "#1DA1F2" }}
                      className="bx bxl-twitter"
                    />
                  </h5>
                  <h2>3011</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 0 }}>
            <div className="col s12">
              <div className="card">
                <div className="row card-content">
                  <h5>No of tweets</h5>
                  <div className="col s6">
                    <h1>18134</h1>
                  </div>
                  <div className="col s6">
                    <h1>
                      <i className="material-icons" style={{ color: "red" }}>
                        arrow_drop_down
                      </i>
                      2
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 0 }}>
            <div className="col s12">
              <div className="card">
                <div className="row card-content">
                  <h5>No of retweets</h5>
                  <div className="col s6">
                    <h1>1713</h1>
                  </div>
                  <div className="col s6">
                    <h1>
                      <i className="material-icons" style={{ color: "green" }}>
                        arrow_drop_up
                      </i>
                      45
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 0 }}>
            <div className="col s12">
              <div className="card">
                <div className="row card-content">
                  <h5>Users talking about</h5>
                  <div className="col s6">
                    <h1>9625</h1>
                  </div>
                  <div className="col s6">
                    <h1>
                      <i className="material-icons" style={{ color: "green" }}>
                        arrow_drop_up
                      </i>
                      43
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s9">
          <div className="row" style={{ marginBottom: 0 }}>
            <p className="twitter-header">Past Month Trend</p>
            <StackBarChart
              data={[
                {
                  year: "2006",
                  Stock: "10",
                  Tech: "15",
                  Launch: "9",
                  Salary: "6",
                },
                {
                  year: "2007",
                  Stock: "12",
                  Tech: "18",
                  Launch: "9",
                  Salary: "4",
                },
                {
                  year: "2008",
                  Stock: "05",
                  Tech: "20",
                  Launch: "8",
                  Salary: "2",
                },
                {
                  year: "2009",
                  Stock: "01",
                  Tech: "15",
                  Launch: "5",
                  Salary: "4",
                },
                {
                  year: "2010",
                  Stock: "02",
                  Tech: "10",
                  Launch: "4",
                  Salary: "2",
                },
                {
                  year: "2011",
                  Stock: "03",
                  Tech: "12",
                  Launch: "6",
                  Salary: "3",
                },
                {
                  year: "2012",
                  Stock: "04",
                  Tech: "15",
                  Launch: "8",
                  Salary: "1",
                },
                {
                  year: "2013",
                  Stock: "06",
                  Tech: "11",
                  Launch: "9",
                  Salary: "4",
                },
                {
                  year: "2014",
                  Stock: "10",
                  Tech: "13",
                  Launch: "9",
                  Salary: "5",
                },
                {
                  year: "2015",
                  Stock: "16",
                  Tech: "19",
                  Launch: "6",
                  Salary: "9",
                },
                {
                  year: "2016",
                  Stock: "19",
                  Tech: "17",
                  Launch: "5",
                  Salary: "7",
                },
              ]}
              dimensions={[{ name: "name" }]}
              measures={[{ name: "sunshine_score" }, { name: "fear_score" }]}
              can_height="220"
              can_width="1000"
              id="stack-bar-1"
            />
          </div>
          <div className="row" style={{ marginBottom: 0 }}>
            <div className="col s3">
              <p className="twitter-header">Praise stat</p>
              <PieChart
                data={this.state.data}
                key={this.state.data}
                height="200"
                width="200"
                id="pie-1"
              />
            </div>
            <div className="col s3">
              <p className="twitter-header">Pain stat</p>
              <PieChart
                data={this.state.dataN}
                key={this.state.dataN}
                height="200"
                width="200"
                id="pie-2"
              />
            </div>
            <div className="col s6">
              <div className="col s12">
                <div className="row">
                  <div className="col s2">
                    <p className="twitter-header-small">Date</p>
                  </div>
                  <div className="col s4">
                    <p className="twitter-header-small">Tweet</p>
                  </div>
                  <div className="col s2">
                    <p className="twitter-header-small">Tweeted By</p>
                  </div>
                  <div className="col s2">
                    <p className="twitter-header-small">Hashtags</p>
                  </div>
                  <div className="col s2">
                    <p className="twitter-header-small">Sentiment</p>
                  </div>
                </div>
                <div className="row">
                  {this.state.data2.map((d) => (
                    <div className="row">
                      <div className="col s2">
                        <p>{d.date}</p>
                      </div>
                      <div className="col s4">
                        <p>{d.tweet}</p>
                      </div>
                      <div className="col s2">
                        <p>{d.user}</p>
                      </div>
                      <div className="col s2">
                        <p>{d.tag}</p>
                      </div>
                      <div className="col s2">
                        <p
                          style={{
                            color:
                              d.sentiment == "Positive"
                                ? "green"
                                : d.sentiment == "Neutral"
                                ? "blue"
                                : "red",
                          }}
                        >
                          {d.sentiment}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwitterDashboard;
