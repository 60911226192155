import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // getGdpByType,
  // getGdpCompany,
  // getGdpMonthly,
  // getGdpSegmented,
  // getGdpYearly,
  getPortfolioMix,
} from "../../../store/action/nlActions";
// import LineChartGeneric from "../../layout/LineChartGeneric";
import StackBar from "./laylout/StackBar";
import {
  gic_map,
  seg_orders,
  specialColorMap,
} from "../../../config/constants";
import Legends from "./laylout/Legends";
// import IndicationCard from "./laylout/IndicationCard";
// import GdpCard from "./laylout/GdpCard";
// import FilterBlock from "./laylout/FilterBlock";
// import moment from "moment";
// import PieChartLegend from "../../layout/PieChartLegend";

export default function PortfolioMixDashboard() {
  const dispatch = useDispatch();

  const [myCompany, setMyCompany] = useState(null);

  const assessToken = useSelector((state) => state?.auth?.accessToken);
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const portfolioMix = useSelector((state) => state?.nl?.portfolioMix);
  const portfolioMixLoading = useSelector(
    (state) => state?.nl?.portfolioMixLoading,
  );

  useEffect(() => {
    let insSector = sectors?.find(
      (sec) => sec?.name?.toLowerCase() === "insurance",
    );
    let com = userCompany?.find(
      (com) => com?.detail?.sector_id === insSector?.id,
    );
    setMyCompany(com);
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(getPortfolioMix({ no_of_years: 4 }, assessToken));
  };
  console.log("USER COM", portfolioMix, myCompany);

  return (
    <div className="row" style={{ magin: 0 }}>
      <div className="col s12">
        {/* <div className="col s12">
          <FilterBlock
            months={moment.months().map((month, i) => {
              return { value: i + 1, label: month };
            })}
            years={nlYears}
            segments={nlSegs}
            onMonthChange={onMonthChange}
            onYearChange={onYearChange}
            onSegmentChange={onSegmentChange}
            selectedMonth={month}
            selectedYear={year}
            selectedSegment={segment}
          />
        </div> */}
        <div className="col s4">
          {portfolioMix && myCompany && (
            <p className="center-align" style={{ margin: 0 }}>
              <strong>{portfolioMix[myCompany?.detail?.id].company}</strong>
            </p>
          )}
          <StackBar
            id="mix-primary"
            data={
              portfolioMix && myCompany
                ? portfolioMix[myCompany?.detail?.id]?.data
                : []
            }
            dimensions={[{ text: "year" }]}
            measures={[{ text: "data" }]}
            group={"segment"}
            can_height={220}
            can_width={400}
            hideLegend={true}
            colorMap={specialColorMap}
            // charLength={10}
            margins={{ right: 10, bottom: 20, top: -10 }}
          />
        </div>
        {myCompany?.secondaries?.map((sec, i) => (
          <div className="col s4">
            {portfolioMix && myCompany && (
              <p className="center-align" style={{ margin: 0 }}>
                <strong>{portfolioMix[sec?.id].company}</strong>
              </p>
            )}
            <StackBar
              id={"mix-" + sec.id}
              data={portfolioMix ? portfolioMix[sec?.id]?.data : []}
              dimensions={[{ text: "year" }]}
              measures={[{ text: "data" }]}
              group={"segment"}
              can_height={220}
              can_width={400}
              hideLegend={true}
              // charLength={10}
              margins={{ right: 10, bottom: 20, top: -10 }}
              colorMap={specialColorMap}
            />
          </div>
        ))}
      </div>
      <Legends />
    </div>
  );
}
