import moment from "moment";

export const createFormData = (file,body) => {
    const data = new FormData();
    // //console.log("IN form data", file, body)
    data.append("file", file);
    // //console.log("IN form data", data)
    Object.keys(body).forEach(key => {
      data.append(key, body[key]);
    });
    // //console.log("data inside foreach",data);
  
    return data;
  };

  export const getStartEnd=(stTime, endTime, timeSpan)=>{
    switch (timeSpan) {
      case "1hr":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).subtract(1, 'hours').format('MM/DD/YYYY,HH:MM')
        break;
      case "1d":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).subtract(1, 'days').format('MM/DD/YYYY,HH:MM')
        break;
      case "1w":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).subtract(7, 'days').format('MM/DD/YYYY,HH:MM')
        break;
      case "1m":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).subtract(1, 'months').format('MM/DD/YYYY,HH:MM')
        break;
      case "6m":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).subtract(6, 'months').format('MM/DD/YYYY,HH:MM')
        break;
      case "1y":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).subtract(1, 'years').format('MM/DD/YYYY,HH:MM')
        break;
      case "sp":
        stTime = moment(stTime).format('MM/DD/YYYY,HH:MM')
        endTime = moment(endTime).format('MM/DD/YYYY,HH:MM')
        break;
    
      default:
        break;
    }
    return [stTime, endTime]
  }

  export default createFormData;