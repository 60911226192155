import { dispatch } from "d3-dispatch";
import config from "../../config/config";
import { queryBuilder } from "../../utils/helper";

var backendUrl = config.backendUrl;

export const getCompanyList = (token) => {
  return (dispatch) => {
    var url = backendUrl + "/companies";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "COMPANY_LIST_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "COMPANY_LIST_FETCH_ERROR",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "COMPANY_LIST_FETCH_ERROR",
        });
      });
  };
};
export const getNewsNIS = (body, token) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_NIS" });
    var url = backendUrl + "/getCompanyScores";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            data["body"] = body;
            // //console.log("action", data)
            dispatch({
              type: "NEWS_NIS_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NEWS_NIS_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NEWS_NIS_FETCH_ERROR",
        });
      });
  };
};

export const getNISTopNews = (body, selected, token) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_NIS_TOP_NEWS", payload: selected });
    var url = backendUrl + "/getCompanyTopnews";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            data["body"] = body;
            data["selected"] = selected;
            // //console.log("action", data)
            dispatch({
              type: "NIS_TOP_NEWS_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NIS_TOP_NEWS_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NIS_TOP_NEWS_FETCH_ERROR",
        });
      });
  };
};

export const getAuditCom = (token) => {
  var data = {
    companies: [
      { id: 943, name: "Tata consultancy Services pvt. Ltd" },
      { id: 745, name: "Infosys Limited" },
      { id: 991, name: "Wipro Limited" },
      { id: 1383, name: "Rane(Madras) Limited" },
    ],
    auditor: [{ id: 1, name: "Deloitte Haskin & Sells. " }],
    partner: [
      { id: 1, name: "Amit Somani" },
      { id: 2, name: "Sanjiv V. Pilgaonkar" },
      { id: 3, name: "Vikas Bagaria" },
    ],
  };
  return (dispatch) => {
    dispatch({ type: "AUDIT_COMPANY_FETCH_SUCCESS", payload: data });
  };
};

export const getNews = (start, end, company, token) => {
  console.log("access token", token);
  return (dispatch) => {
    dispatch({ type: "LOADING_NIS_TOP_NEWS", payload: {} });
    var url = backendUrl + "/getNews";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ startDate: start, endDate: end, company }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "NIS_TOP_NEWS_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NIS_TOP_NEWS_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NIS_TOP_NEWS_FETCH_ERROR",
        });
      });
  };
};

export const addCompany = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: "ADD_COMPANY_LOADING",
      payload: { message: "Adding Company..." },
    });
    var url = backendUrl + "/newCompany";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "ADD_COMPANY_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "ADD_COMPANY_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "ADD_COMPANY_ERROR",
        });
      });
  };
};
