import React  from 'react';

const AuditModal = ({type, name, data, loading}) => {
  return (
      <div>
        <div id="audit-modal" className="modal">
            <div className='row modal-content'>
                <div className="row modal-title">
                    <h3 style={{color:"rgb(93, 27, 143)"/* , paddingTop:20 */}}>
                    {
                        type == "firm"?"Audit Firm: "+name:"Audit Partner: "+name
                    }
                    </h3>
                </div>
                <div className="row center-align"> 
                {
                loading?
                    <div className="preloader-wrapper small active">
                        <div className="spinner-layer spinner-green-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div><div className="gap-patch">
                            <div className="circle"></div>
                        </div><div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                        </div>
                    </div>
                :
                    <table>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Company</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map(d => 
                                    <tr>
                                        <td>{d.year}</td>
                                        <td>{d.Company?d.Company.name:""}</td>
                                        <td>
                                            {d.score?d.score.toFixed(3):0}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>}
                </div>
            </div>
        </div>
    </div>
  );  

}

export default AuditModal;  
