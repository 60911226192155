import React, { useEffect, useState } from "react";
import {
  newColorMap,
  specialColorMap,
  specialColorMapLife,
} from "../../../../config/constants";
import BarChartGeneric from "../../../layout/BarChartGeneric";
import Legends from "../laylout/Legends";
import StackBar from "../laylout/StackBar";
import * as d3 from "d3";
import LineChartGeneric from "../../../layout/LineChartGeneric";

export default function BarStackCombination({
  barOneDetails,
  barTwoDetails,
  stackBarDetails,
  lineChartDetails,
  life,
  handleSwitch,
  switchActive,
}) {
  const [colorMapCustom, setColorMap] = useState({});

  useEffect(() => {
    if (stackBarDetails?.data)
      handleColorMap(stackBarDetails?.data, stackBarDetails?.group);
  }, [stackBarDetails]);

  const handleColorMap = (data, group) => {
    // console.log("DATA", data);
    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d[group]))
      .range(
        d3.quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), 14).reverse(),
      );
    let colorMap = {};
    data?.map((d) => {
      colorMap[d[group]] = color(d[group]);
    });
    setColorMap(colorMap);
  };
  // console.log("COLOR MAP", colorMapCustom);
  // console.log("COLOR MAP", barOneDetails?.data);
  return (
    <div className="row">
      <div className="col s4">
        {barOneDetails && (
          <div className="col s12">
            <div style={{ fontSize: 18 }}>
              <strong>{barOneDetails?.label}</strong>
            </div>
            <BarChartGeneric
              id={barOneDetails?.id}
              data={barOneDetails?.data || []}
              dimensions={barOneDetails?.dimensions}
              measures={barOneDetails?.measures}
              height={barOneDetails?.height}
              width={barOneDetails?.width}
              hideLegend={true}
              margins={{ left: 100, bottom: 10 }}
              fontSize={12}
              boldFont={true}
              noLabelWrapY={true}
              noXLabel={true}
              labelInBar={false}
              barFontSize={10}
            />
          </div>
        )}

        {barTwoDetails && (
          <div className="col s12">
            <div style={{ fontSize: 18 }}>
              <strong>{barTwoDetails?.label}</strong>
            </div>
            <BarChartGeneric
              id={barTwoDetails?.id}
              data={barTwoDetails?.data || []}
              dimensions={barTwoDetails?.dimensions}
              measures={barTwoDetails?.measures}
              height={barTwoDetails?.height}
              width={barTwoDetails?.width}
              hideLegend={false}
              margins={{ left: 100, bottom: 10 }}
              fontSize={12}
              boldFont={true}
              noLabelWrapY={true}
              noXLabel={true}
              labelInBar={false}
              barFontSize={10}
            />
          </div>
        )}
      </div>
      <div className="col s8">
        <div className="" style={{ fontSize: 18 }}>
          {switchActive ? (
            <div>
              <div className="col s8">{stackBarDetails?.label}</div>
              <div className="col s4 switch">
                <label>
                  None
                  <input type="checkbox" onChange={handleSwitch} />
                  <span class="lever"></span>
                  Per GDP
                </label>
              </div>
            </div>
          ) : (
            <strong>{stackBarDetails?.label}</strong>
          )}
        </div>
        <Legends
          marginTop={16}
          maxChar={12}
          gic={stackBarDetails?.gic}
          life={life}
        />
        <StackBar
          id={stackBarDetails?.id}
          data={stackBarDetails?.data || []}
          dimensions={stackBarDetails?.dimensions}
          measures={stackBarDetails?.measures}
          group={stackBarDetails?.group}
          can_height={stackBarDetails?.height}
          can_width={stackBarDetails?.width}
          hideLegend={true}
          margins={{ bottom: 20, left: 60 }}
          colorMap={life ? specialColorMapLife : newColorMap}
          fontSize={12}
          life={life}
        />
        {lineChartDetails && (
          <div className="col s12">
            <div style={{ fontSize: 18 }}>
              <strong>{lineChartDetails?.label}</strong>
            </div>
            <LineChartGeneric
              id={lineChartDetails.id}
              data={lineChartDetails?.data}
              dimensions={lineChartDetails?.dimensions}
              measures={lineChartDetails?.measures}
              can_width={lineChartDetails.width}
              can_height={lineChartDetails.height}
              group={lineChartDetails.group}
              tooltip={false}
              labelInBar={true}
              margins={{ bottom: 20, left: 60, right: 20, top: 50 }}
              legend={true}
              rotateXTick={true}
              legendOrientation="top"
            />
          </div>
        )}
      </div>
    </div>
  );
}
