import React, { Component } from "react";
import * as d3 from "d3";
import moment from "moment"

class PRLineChartSmall extends Component {
  componentDidUpdate() {
    //console.log(this.props);
    this.drawChart();
  }
  componentDidMount() {
    this.drawChart();
    // //console.log(this.props.dataset)
  }

  drawChart() {
    const {dataset, can_width, can_height, onClick, selected, name, bgcolor,lineColor, id} = this.props
    
    // Calculate Margins and canvas dimensions
    var margin = {top: 5, right: 5, bottom: 5, left: 5},
        width = can_width - margin.left - margin.right,
        height = can_height - margin.top - margin.bottom;
    
    //Parsers and Formaters
    // var parseTime = d3.timeParse("%d/%m/%Y");
    // var formatTime = d3.timeFormat("%a/%b/%Y");
    
    // Scales
     // Scales
     var x = d3
     .scaleBand()
     .rangeRound([0, width])
     .padding(1);
 
 
 var y = d3.scaleLinear()
     .range([height, 0]);
    
    // Line
    var line = d3.line()
        .x(function(d) { return x(d.date); })
        .y(function(d) { return y(d.prScore); })
    
    //Remove already redered graph otherwise repeat graph will be created
    d3.select("#svg-element-"+id)
      .select("svg")
      .remove();
    
    var selectedData = selected?selected: dataset[0]

    var chart = d3.select("#svg-element-"+id).append("svg")
        .style("background-color", bgcolor?bgcolor: '#F5F6CE')
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("fill", 'none')
        .attr("line-stroke", 'blue')
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
     
     //Arguments for axes : Ranges for X, y  
     x.domain(dataset.map(function(d) { return d.date }));
     y.domain(d3.extent(dataset, function(d) { return d.prScore; }));
    
    // Axes
    // chart.append("g")
    //     .attr("class", "axis axis--x")
    //     .attr("transform", "translate(0," + height + ")")
    //     .call(d3.axisBottom(x).ticks(10))
    //     .selectAll("text")	
    //       .style("text-anchor", "end")
    //       .attr("dx", "-.8em")
    //       .attr("dy", ".15em")
    //       .attr("transform", "rotate(-65)")
    //       // .attr("font-weight", "100")
    //       .style("font-size", "12px")
    //       .style("fill", "#000")
    //     .call(d3.axisBottom(x));
  
    // chart.append("g")
    //     .attr("class", "axis axis--y")
    //     .call(d3.axisLeft(y));

    // Labels
    // chart.append("text")
    // .attr("text-anchor", "middle")
    // .style("font-size", "14px")
    // .style("stroke", "#000")
    // .attr("transform", "translate("+ (margin.left - 94 ) +","+(height/2)+")rotate(-90)")  
    // .text(name);

    // chart.append("text")
    //     .style("font-size", "14px")
    //     .attr("text-anchor", "middle") 
    //     .style("stroke", "#000")
    //     .attr("transform", "translate("+ (width/2) +","+(height-(margin.bottom -170))+")")
    //     .text("Date");

    //  Chart Title
    // chart.append("text")
    //     .attr("x", (width / 2))             
    //     .attr("y", 10 - (margin.top / 2))
    //     .attr("text-anchor", "middle")  
    //     .style("font-size", "16px") 
    //     .style("stroke", "#000")
    //     .text("NIS Graph");

  // Data Lines:

    chart.append("path")
        .datum(dataset)
        .attr("class", "line")
        .attr('stroke', lineColor?lineColor:'#01579B')
        .attr('stroke-width', 4)
        .attr("d", line)
        .enter()
        ;

    chart.selectAll("circle")
    .data(dataset)
      .enter().append("circle")
        .attr("class", "circle")
        .attr("cx", function(d) { return x(d.date); })
        .attr("cy", function(d) { return y(d.prScore); })
        .attr("r", 4)
        .attr('stroke', lineColor?lineColor:'#01579B')
        .attr('fill', lineColor?lineColor:'#01579B');

  //Grid Lines
  // svg.append('g')
  // .attr('class', 'grid')
  // .attr('transform', `translate(0, ${height})`)
  // .call(d3.axisBottom()
  //     .scale(x)
  //     .tickSize(-height, 0, 0)
  //     .tickFormat(''))
    
  // chart.append('g')
  //   .attr('class', 'grid')
  //   .call(d3.axisLeft()
  //   .scale(y)
  //   .tickSize(-width, 0, 0)
  //   .tickFormat(''))

  }

  render() {
    return <div className='row' id={"svg-element-"+this.props.id}style={{marginLeft:0}}></div>;
  }
}
export default PRLineChartSmall;