import React from "react";
import BarStackCombination from "./commonDashboards/BarStackCombination";

export default function SegmentCommission({ data }) {
  console.log("REPORT DATA", data);
  const BAR_CHART_HEIGHT = 250;
  const BAR_CHART_WIDTH = 400;
  return (
    <BarStackCombination
      barOneDetails={{
        id: "gctogwp",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "gctogwp" }],
        label: "Gross Commission to GWP",
        data: data["gctogwp"],
      }}
      barTwoDetails={{
        id: "gwctogwp",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "gwctogwp" }],
        label: "GWC to GWP",
        data: data["gwctogwp"],
      }}
      stackBarDetails={{
        id: "seg-rev",
        width: 800,
        height: 210,
        dimensions: [{ text: "company" }],
        measures: [{ text: "percentage" }],
        group: "segment",
        label: "Contribution of products",
        data: data["gc-seg"],
        gic: false,
      }}
      lineChartDetails={{
        id: "gwc-trend",
        height: 220,
        width: 800,
        dimensions: [{ text: "fyq" }],
        measures: [{ text: "gwc-trend" }],
        group: "company",
        label: "GWC Trend",
        data: data
          ? data["gwc-trend"]?.map((row) => {
              return { ...row, fyq: row["year"] + ", " + row["quarter"] };
            })
          : [],
        gic: false,
      }}
      // handleSwitch={(e) => {
      //   setGdpFlag(!gdpFlag);
      // }}
      // gdpFlag={gdpFlag}
      // switchActive={type === NL_TYPE_LIFE}
    />
  );
}
