const initState = {
  NISSuccess: false, // make true when auth succdessful
  NewsNISSuccess: false, // make true when auth succdessful
  NISMessage: "", //change when auth failed
  NISLoading: false,
  NISError: "",
  NISResponse: null,
  companyList: null,
  NISTopNewsLoading: false,
  NISTopNewsSuccess: false,
  selected: null,
  initData: null,
  auditComLoading: false,
  auditComSucc: false,
  auditComErr: false,
  auditComData: [],
  NewsNISTopNews: null,
  NewsStockTopNews: null,
};

const NISReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING_NIS":
      return {
        ...state,
        NISLoading: true,
        NISSuccess: false,
        NISMessage: "",
        NISError: "",
        NISResponse: null,
      };

    case "LOADING_NIS_TOP_NEWS":
      return {
        ...state,
        NISTopNewsLoading: true,
        NISTopNewsSuccess: false,
        NewsNISTopNews: null,
        selected: action.payload,
      };

    case "COMPANY_LIST_FETCH_SUCCESS":
      return {
        ...state,
        companyList: action.payload,
      };
    case "NEWS_NIS_FETCH_SUCCESS":
      return {
        ...state,
        NISLoading: false,
        NISSuccess: true,
        NISError: "",
        NewsNISSuccess: true,
        NewsNISData: action.payload.data,
        NewsNISFrequency: action.payload.frequency,
        // NewsNISTopNews:action.payload.news,
        NewsNISCompany: action.payload.companyName,
        initData: action.payload.body,
      };
    case "NIS_TOP_NEWS_FETCH_SUCCESS":
      return {
        ...state,
        NISTopNewsLoading: false,
        NISTopNewsSuccess: true,
        NewsNISTopNews: action.payload.nisData,
        NewsStockTopNews: action.payload.stockData,
        selected: action.payload.selected,
      };
    case "NEWS_NIS_FETCH_ERROR":
      return {
        ...state,
        NISLoading: false,
        NISSuccess: false,
        NISMessage: "",
        NISError: "NIS failed",
        NISResponse: null,
      };
    case "AUDIT_COMPANY_FETCH_SUCCESS":
      return {
        ...state,
        auditComLoading: false,
        auditComSucc: true,
        auditComErr: false,
        auditComData: action.payload,
      };
    case "AUDIT_DATA_FETCH_SUCCESS":
      return {
        ...state,
        initData: action.payload,
      };
    case "INITIAL_STATE":
      return initState;
    case "HOME_CLICK":
      return initState;
    default:
      return state;
  }
};

export default NISReducer;
