import React from "react";
import "./style.css";

export const ToolCard = ({
  ModalClick,
  text,
  toolId,
  color,
  image,
  modal,
  noModal,
}) => {
  return (
    <div className="col l3 m3 s12">
      <div
        className={noModal ? "tool-card card" : "tool-card card modal-trigger"}
        data-target={modal ? modal : "dashboard-modal"}
        style={{
          borderColor: color,
        }}
        onClick={() => {
          ModalClick(toolId);
        }}
      >
        <div className="row card-content" style={{ padding: 0, margin: 0 }}>
          <div className="col l5 s5 m5" style={{ padding: 10 }}>
            <img
              src={"assets/img/" + image}
              className="img-fluid"
              alt=""
              width="90"
              height="90"
              id={toolId}
              onClick={ModalClick}
            />
          </div>
          <div className="col l7 s7 m7">
            <h5>{text}</h5>
            <p style={{ fontSize: 14 }}>
              {toolId === "distress predictor" ? (
                <span>Text Based Distress Score from Annual Reports</span>
              ) : toolId === "news impact" ? (
                <span>
                  measures impact of firm-specific news crawled from reliable
                  sources.
                </span>
              ) : toolId === "sector meter" ? (
                <span>
                  Measures vulnerability of a sector on the basis of
                  sector-specific news.
                </span>
              ) : toolId === "announcement" ? (
                <span>
                  Sentiment of every corporate action filed with the stock
                  exchange.
                </span>
              ) : toolId === "stock pulse" ? (
                <span>
                  Stock News and Tweet in one place with reliabity filter.
                </span>
              ) : toolId === "concall" ? (
                <span>Measures tonal quality of analysts transcripts</span>
              ) : toolId === "acc-policy" ? (
                <span>
                  Accounting Policy changes and comparison with peers/
                </span>
              ) : toolId === "audit-matter" ? (
                <span>
                  Audit Matters with auditor partner and auditor change alert
                </span>
              ) : toolId === "audit-monitor" ? (
                <span>
                  Audit Report with audit alert and other cool features
                </span>
              ) : toolId === "broad-finance" ? (
                <span>Peer analysis of important financials of companies</span>
              ) : toolId === "add-company" ? (
                <span>Can't find your Company? Request to Add.</span>
              ) : toolId === "download-report" ? (
                <span>Download report for company in a compact PDF</span>
              ) : toolId === "statistics" ? (
                <span>Data issues logged conveniently</span>
              ) : toolId === "nl-download" ? (
                <span>
                  Download NL data for multiple companies in Excel format.
                </span>
              ) : toolId === "nl-download-life" ? (
                <span>
                  Download NL data for life insurance companies in Excel format.
                </span>
              ) : toolId === "nl-reports" ? (
                <span>View different analysis and reports on NL data.</span>
              ) : toolId === "nl-reports-life" ? (
                <span>Reports on NL data for life-insurance companies.</span>
              ) : toolId === "nl-monthly" ? (
                <span>Monthly data analysis from NL data.</span>
              ) : toolId === "nl-dashboard" ? (
                <span>Insurance company public data visualization.</span>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
