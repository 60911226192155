import config from "../../config/config";
import createFormData from "../../help/CreateFormData";
import { queryBuilder } from "../../utils/helper";
import {
  GET_NL_REPORT_LIST_ERROR,
  GET_NL_REPORT_LIST_LOADING,
  GET_NL_REPORT_LIST_SUCCESS,
  GET_NL_REPORT_DATA_SUCCESS,
  GET_NL_REPORT_DATA_ERROR,
  GET_NL_REPORT_DATA_LOADING,
  GET_GDP_YEARLY_LOADING,
  GET_GDP_YEARLY_SUCCESS,
  GET_GDP_YEARLY_ERROR,
  GET_GDP_MONTHLY_LOADING,
  GET_GDP_MONTHLY_SUCCESS,
  GET_GDP_MONTHLY_ERROR,
  GET_GDP_SEGMENTED_LOADING,
  GET_GDP_SEGMENTED_SUCCESS,
  GET_GDP_SEGMENTED_ERROR,
  GET_GDP_COMPANY_LOADING,
  GET_GDP_COMPANY_SUCCESS,
  GET_GDP_COMPANY_ERROR,
  GET_NL_SEGS_LOADING,
  GET_NL_SEGS_SUCCESS,
  GET_NL_SEGS_ERROR,
  GET_NL_STAT_YEARS_SUCCESS,
  GET_NL_STAT_YEARS_ERROR,
  GET_NL_STAT_YEARS_LOADING,
  GET_GDP_BY_TYPE_LOADING,
  GET_GDP_BY_TYPE_SUCCESS,
  GET_GDP_BY_TYPE_ERROR,
  GET_PORTFOLIO_MIX_LOADING,
  GET_PORTFOLIO_MIX_SUCCESS,
  GET_PORTFOLIO_MIX_ERROR,
  GET_NL_VALIDATION_ISSUES_LOADING,
  GET_NL_VALIDATION_ISSUES_SUCCESS,
  GET_NL_VALIDATION_ISSUES_ERROR,
  MARK_NL_ISSUES_LOADING,
  MARK_NL_ISSUES_SUCCESS,
  MARK_NL_ISSUES_ERROR,
  RUN_NL_VALIDATION_LOADING,
  RUN_NL_VALIDATION_SUCCESS,
  RUN_NL_VALIDATION_ERROR,
  GET_NL_PDF_ERROR,
  GET_NL_PDF_LOADING,
  GET_NL_PDF_SUCCESS,
  EXCEL_GENERATE_SUCCESS,
  EXCEL_GENERATE_ERROR,
  GET_STATE_POP_LOADING,
  GET_STATE_POP_SUCCESS,
  GET_STATE_POP_ERROR,
} from "../constants/nlConstants";

var backendUrl = config.backendUrl;

export const downloadNLReport = (query, body, token, type = "gic") => {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_NL_REPORT_LOADING",
    });
    const filename = body.filename;
    delete body.filename;
    var newUrl = queryBuilder("/nl/report/download", query);
    fetch(backendUrl + newUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Content-disposition": `attachment; filename=${filename}`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.blob().then((blob) => {
          if (response.status === 500) {
            response
              .json()
              .then((data) => {
                dispatch({
                  type: "DOWNLOAD_NL_REPORT_ERROR",
                  message: data.message || "No report available",
                });
              })
              .catch((err) => {
                dispatch({
                  type: "DOWNLOAD_NL_REPORT_ERROR",
                  message: "No report available",
                });
              });
          } else if (response.status === 200) {
            const data = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = data;
            link.download = filename;
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
            dispatch({
              type: "DOWNLOAD_NL_REPORT_SUCCESS",
              data: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "DOWNLOAD_NL_REPORT_ERROR",
              message: "No report available",
            });
          }
        }),
      )
      .catch((err) => {
        dispatch({
          type: "DOWNLOAD_NL_REPORT_ERROR",
          message: "internal Error",
        });
      });
  };
};

export const getNlData = (query, token) => {
  console.log("access token", token);
  return (dispatch) => {
    dispatch({ type: "NL_DATA_LOADING" });
    var url = queryBuilder(backendUrl + "/getNlData", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "NL_DATA_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NL_DATA_ERROR",
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "NL_DATA_ERROR",
        });
      });
  };
};

export const getNlErrors = (body, token) => {
  console.log("access token", token);
  return (dispatch) => {
    dispatch({ type: "NL_ERRORS_LOADING" });
    var url = backendUrl + "/NLstatus";
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ dummy: "adasd" }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "NL_ERRORS_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NL_ERRORS_ERROR",
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "NL_ERRORS_ERROR",
        });
      });
  };
};

export const getAvailableNlYearquarter = (token) => {
  return (dispatch) => {
    dispatch({ type: "AVAILABLE_NL_YEAR_QUARTER_LOADING" });
    var url = backendUrl + "/available/nl/year/quarter";
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "AVAILABLE_NL_YEAR_QUARTER_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "AVAILABLE_NL_YEAR_QUARTER_ERROR",
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "AVAILABLE_NL_YEAR_QUARTER_ERROR",
        });
      });
  };
};

export const getNLTemplates = (token) => {
  return (dispatch) => {
    dispatch({ type: "NL_TEMPLATES_LOADING" });
    var url = backendUrl + "/nl/user/templates";
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "NL_TEMPLATES_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NL_TEMPLATES_ERROR",
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "NL_TEMPLATES_ERROR",
        });
      });
  };
};

export const getNlList = (query, token) => {
  console.log("access token", token);
  return (dispatch) => {
    dispatch({ type: "NL_LIST_LOADING" });
    var url = queryBuilder(backendUrl + "/nl", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "NL_LIST_SUCCESS",
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NL_LIST_ERROR",
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "NL_LIST_ERROR",
        });
      });
  };
};

export const getMissingNLReport = (query, token) => {
  console.log("access token", token);
  return (dispatch) => {
    dispatch({ type: "MISSING_NL_REPORT_LOADING" });
    var url = queryBuilder(backendUrl + "/missingNLReport", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "MISSING_NL_REPORT_SUCCESS",
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "MISSING_NL_REPORT_ERROR",
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "MISSING_NL_REPORT_ERROR",
        });
      });
  };
};

export const getNLReportsList = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_NL_REPORT_LIST_LOADING });
    var url = queryBuilder(backendUrl + "/nl/reports", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_NL_REPORT_LIST_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_NL_REPORT_LIST_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_NL_REPORT_LIST_ERROR,
        });
      });
  };
};

export const getNLReportsData = (id, query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_NL_REPORT_DATA_LOADING });
    var url = queryBuilder(
      backendUrl + "/nl/report/" + id + "/data/new",
      query,
    );
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_NL_REPORT_DATA_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_NL_REPORT_DATA_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_NL_REPORT_DATA_ERROR,
        });
      });
  };
};

export const getGdpYearly = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_GDP_YEARLY_LOADING });
    var url = queryBuilder(backendUrl + "/nl/gdp/yearly", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_GDP_YEARLY_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_GDP_YEARLY_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_GDP_YEARLY_ERROR,
        });
      });
  };
};

export const getGdpMonthly = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_GDP_MONTHLY_LOADING });
    var url = queryBuilder(backendUrl + "/nl/gdp/monthly", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_GDP_MONTHLY_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_GDP_MONTHLY_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_GDP_MONTHLY_ERROR,
        });
      });
  };
};

export const getGdpSegmented = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_GDP_SEGMENTED_LOADING });
    var url = queryBuilder(backendUrl + "/nl/gdp/segmented", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_GDP_SEGMENTED_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_GDP_SEGMENTED_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_GDP_SEGMENTED_ERROR,
        });
      });
  };
};

export const getGdpCompany = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_GDP_COMPANY_LOADING });
    var url = queryBuilder(backendUrl + "/nl/gdp/company", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_GDP_COMPANY_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_GDP_COMPANY_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_GDP_COMPANY_ERROR,
        });
      });
  };
};

export const getGdpByType = (query, token) => {
  console.log("QUERY", query);

  return (dispatch) => {
    dispatch({ type: GET_GDP_BY_TYPE_LOADING });
    var url = queryBuilder(backendUrl + "/nl/gdp/type", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_GDP_BY_TYPE_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_GDP_BY_TYPE_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_GDP_BY_TYPE_ERROR,
        });
      });
  };
};

export const getPortfolioMix = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_PORTFOLIO_MIX_LOADING });
    var url = queryBuilder(backendUrl + "/nl/portfolio/mix", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // console.log("action", data);
            dispatch({
              type: GET_PORTFOLIO_MIX_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_PORTFOLIO_MIX_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_PORTFOLIO_MIX_ERROR,
        });
      });
  };
};

export const getNLSegments = (token) => {
  return (dispatch) => {
    dispatch({ type: GET_NL_SEGS_LOADING });
    var url = backendUrl + "/nl/segment";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_NL_SEGS_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_NL_SEGS_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_NL_SEGS_ERROR,
        });
      });
  };
};

export const getNLStatYears = (token) => {
  return (dispatch) => {
    dispatch({ type: GET_NL_STAT_YEARS_LOADING });
    var url = backendUrl + "/nl/stat/years";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_NL_STAT_YEARS_SUCCESS,
              payload: data?.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_NL_STAT_YEARS_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_NL_STAT_YEARS_ERROR,
        });
      });
  };
};

export const getNLValidationIssues = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_NL_VALIDATION_ISSUES_LOADING });
    var url = queryBuilder(backendUrl + "/nl/validate/errors", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          response.json().then((data) => {
            if (response.status === 200) {
              // //console.log("action", data)
              dispatch({
                type: GET_NL_VALIDATION_ISSUES_SUCCESS,
                payload: data?.data,
              });
            } else {
              dispatch({
                type: GET_NL_VALIDATION_ISSUES_ERROR,
                payload: data,
              });
            }
          });
        }
      })
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_NL_VALIDATION_ISSUES_ERROR,
        });
      });
  };
};

export const markNlIssue = (body, token) => {
  return (dispatch) => {
    dispatch({ type: MARK_NL_ISSUES_LOADING });
    var url = backendUrl + "/nl/issues/mark";
    fetch(url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: MARK_NL_ISSUES_SUCCESS,
          });
        } else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: MARK_NL_ISSUES_ERROR,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: MARK_NL_ISSUES_ERROR,
        });
      });
  };
};

export const runNlValidation = (file, body, token) => {
  return (dispatch) => {
    dispatch({ type: RUN_NL_VALIDATION_LOADING });
    var url = backendUrl + "/nl/validate";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: createFormData(file, body),
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: RUN_NL_VALIDATION_SUCCESS,
          });
        } else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: RUN_NL_VALIDATION_ERROR,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: RUN_NL_VALIDATION_ERROR,
        });
      });
  };
};

export const downloadCompanyPdf = (query, token) => {
  return (dispatch) => {
    dispatch({
      type: GET_NL_PDF_LOADING,
    });
    const filename =
      query.year +
      "_" +
      query.quarter +
      "_" +
      query.company_id +
      "_" +
      query.nl_id +
      ".pdf";

    var newUrl = "/nl/pdf";
    fetch(queryBuilder(backendUrl + newUrl, query), {
      headers: {
        "Content-Type": "application/json",
        "Content-disposition": `attachment; filename=${filename}`,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status == 500) {
          console.error("ERROR in blob");
          response
            .json()
            .then((data) => {
              dispatch({
                type: GET_NL_PDF_ERROR,
                message: data.message,
              });
            })
            .catch((err) => {
              console.error("ERROR in blob", err);
              dispatch({
                type: GET_NL_PDF_ERROR,
                message: "internal Error",
              });
            });
        } else if (response.status === 401 || response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const data = window.URL.createObjectURL(blob);
              var link = document.createElement("a");
              link.href = data;
              link.download = filename;
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
              }, 100);
              dispatch({
                type: GET_NL_PDF_SUCCESS,
              });
            })
            .catch((err) => {
              console.error("ERROR in blob", err);
              dispatch({
                type: GET_NL_PDF_ERROR,
                message: "internal Error",
              });
            });
        } else {
          console.error("ERROR in blob");
          dispatch({
            type: GET_NL_PDF_ERROR,
            message: "Internal error",
          });
        }
      })
      .catch((err) => {
        console.error("ERROR in catch", err);
        dispatch({
          type: GET_NL_PDF_ERROR,
          message: "internal Error",
        });
      });
  };
};

export const runExcelGeneration = (body, serverUrl, token) => {
  return (dispatch) => {
    var finalUrl = serverUrl + "/nl/report/download";
    fetch(finalUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const data = window.URL.createObjectURL(blob);
              var link = document.createElement("a");
              link.href = data;
              link.download =
                body.year + "_" + body.quarter + "_" + body.template + ".xlsx";
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
              }, 100);
              dispatch({
                type: EXCEL_GENERATE_SUCCESS,
              });
            })
            .catch((err) => {
              console.error("ERROR in blob", err);
              dispatch({
                type: EXCEL_GENERATE_ERROR,
                message: "internal Error",
              });
            });
        } else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          response
            .json()
            .then((data) => {
              dispatch({
                type: EXCEL_GENERATE_ERROR,
                message: data.message || "Internal error",
              });
            })
            .catch((err) => {
              console.error("Error in excel generate", err);
              dispatch({
                type: EXCEL_GENERATE_ERROR,
                message: "Internal error",
              });
            });
        }
      })
      .catch((err) => {
        console.error("Error in excel generate", err);
        dispatch({
          type: EXCEL_GENERATE_ERROR,
          message: "Internal error",
        });
      });
  };
};

export const getStatePop = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_STATE_POP_LOADING });
    var url = backendUrl + "/state/population";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: GET_STATE_POP_SUCCESS,
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: GET_STATE_POP_ERROR,
              payload: data,
            });
          }
        }),
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: GET_STATE_POP_ERROR,
        });
      });
  };
};
