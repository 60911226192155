import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";
import { NL_TYPE_LIFE, specialColorMap } from "../../../config/constants";
import { useSelector } from "react-redux";
import BarChartGeneric from "../../layout/BarChartGeneric";
import BarChartVertical from "../../layout/BarChartGenericVertical";
import PieChartLegend from "../../layout/PieChartLegend";
import Legends from "./laylout/Legends";
import StackBar from "./laylout/StackBar";
import BarStackCombination from "./commonDashboards/BarStackCombination";
import BarTwoStackCombination from "./commonDashboards/BarTwoStackCombination";

export default function RevenuePerfLife({ label, data }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [myCompany, setMyCompany] = useState(null);
  const [secondaries, setSecondaries] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportDataTimeWise, setReportTimeWise] = useState([]);
  const [reportDataTwo, setReportDataTwo] = useState([]);

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com = userCompany?.find(
        (com) =>
          com?.detail?.sector_id === insSector?.id &&
          com?.detail?.insuranceType === NL_TYPE_LIFE,
      );
      let secs = com?.secondaries;
      setMyCompany(com?.detail);
      setSecondaries(com?.secondaries);
      // setReportData(makeSegWise(data));
      let coms = [...secs];
      coms.push(com?.detail);
      setReportData(makeFlatData(data, coms));
      setReportTimeWise(makeFlatData(data, coms, true));
      setReportDataTwo(getBarChartData(data, coms));
    }
  }, [data]);

  const getBarChartData = (data, coms) => {
    let newData = [];
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          gdp: d.GDP,
        });
      }
    });
    return newData;
  };

  const makeFlatData = (arrayData, coms, timeFlag) => {
    let newData = [];
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let segKeys = timeFlag
        ? Object.keys(d)?.filter(
            (key) => key.includes("%") && key.includes("GDP_"),
          )
        : Object.keys(d)?.filter(
            (key) => key.includes("%") && !key.includes("GDP_"),
          );
      for (let j = 0; j < segKeys.length; j++) {
        let comFound = coms.find((com) => com.name == d.Company);
        if (comFound)
          newData.push({
            company: d.Company,
            segment: segKeys[j].replace("(%)", "").trim(),
            data: d[segKeys[j]],
          });
      }
    }

    return newData;
  };

  // console.log("COLS", cols);
  console.log("LOBS", secondaries);
  console.log("REPORT DATA", reportData, reportDataTwo);

  const BAR_CHART_HEIGHT = 250;
  const BAR_CHART_WIDTH = 440;
  return (
    <BarTwoStackCombination
      barOneDetails={{
        id: "gdp",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "gdp" }],
        label: "Gross Direct Premium",
        data: reportDataTwo?.sort((a, b) => (a.gdp < b.gdp ? 1 : -1)),
      }}
      stackBarDetails={{
        id: "seg-rev",
        height: 500,
        width: 800,
        dimensions: [{ text: "company" }],
        measures: [{ text: "data" }],
        group: "segment",
        label: "Contribution of products",
        data: reportData,
        gic: false,
      }}
      stackBarTwoDetails={{
        id: "time-rev",
        height: 225,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "data" }],
        group: "segment",
        label: "Premium collection",
        data: reportDataTimeWise,
        gic: false,
      }}
      life={true}
    />
  );
}
