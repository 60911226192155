import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { NLCard } from "../layout/NLCard";
import BarChartGeneric from "../layout/BarChartGeneric";
// import { Chart } from "react-google-charts";
import { NLTable } from "../layout/NLTable ";
import BarChartVertical from "../layout/BarChartGenericVertical";
import PieChartLegend from "../layout/PieChartLegend";

class NLDashboard extends Component {
  state = {};

  componentDidMount = () => {};
  componentDidUpdate = () => {};

  getCompanyName = (name) => {
    if (name.includes(" ")) {
      let words = name.split(" ");
      return words[0] + " " + words[1];
    } else return name;
  };

  render() {
    const { auth, nlData } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    if (!nlData) return <Redirect to="/dashboard" />;
    return (
      <div className="row" style={{ marginBottom: 0 }}>
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="col s6">
            <div className="col s6">
              <NLCard
                height="10vh"
                title={""}
                body={
                  <>
                    <p className="nl-table-text">
                      {this.getCompanyName(nlData.companyName)}
                    </p>
                    <p>
                      <span>{nlData?.year?.replace("_", "-")}, </span>{" "}
                      <span>{nlData?.quarter?.toUpperCase()}</span>
                    </p>
                  </>
                }
              />
              <NLCard
                title="Claims by age"
                height="38vh"
                body={
                  <BarChartGeneric
                    id="top-5-age"
                    data={nlData.claimsByAge}
                    dimensions={[{ text: "age" }]}
                    measures={[{ text: "count" }]}
                    height={170}
                    width={250}
                    hideLegend={false}
                    charLength={10}
                    margins={{ left: 60 }}
                  />
                }
              />
              <NLCard
                title="Break up by LOBs "
                height="38vh"
                body={
                  <BarChartGeneric
                    id="top-5-cost"
                    data={nlData.lob}
                    dimensions={[{ text: "Line of Business" }]}
                    measures={[{ text: "cost" }]}
                    height={170}
                    width={250}
                    hideLegend={false}
                    charLength={10}
                    margins={{ left: 60 }}
                  />
                }
              />
            </div>
            <div className="col s6">
              <NLCard
                height="90vh"
                title="Top line & Bottom line"
                body={<NLTable data={nlData.bottomTopLine} />}
              />
            </div>
          </div>
          <div className="col s6">
            <div className="row  dense-row">
              <div className="col s6">
                <NLCard
                  height="11vh"
                  title={"Number of Claims"}
                  body={<h3>{nlData.claims}</h3>}
                />
              </div>
              <div className="col s6">
                <NLCard
                  height="11vh"
                  title="Amount of Claims"
                  body={<h3>{nlData.costs}</h3>}
                />
              </div>
            </div>

            <NLCard
              title="Claims by state"
              height="39vh"
              body={
                <BarChartVertical
                  id="top-5-stateData"
                  data={nlData.stateData}
                  dimensions={[{ text: "state" }]}
                  measures={[{ text: "overall" }]}
                  height={170}
                  width={630}
                  hideLegend={false}
                  // charLength={10}
                  margins={{ left: 60 }}
                />
                // <Chart
                //   width="100%"
                //   height={400}
                //   chartType="GeoChart"
                //   data={getStateDataArray(nlData.stateData)}
                //   options={{
                //     displayMode: "markers",
                //     resolution: "provinces",
                //     region: "IN",
                //   }}
                //   // Note: you will need to get a mapsApiKey for your project.
                //   // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                //   mapsApiKey="AIzaSyAe-QUA7jPD3j3ZwLObQXXCDjo1inx_4KY"
                //   // rootProps={{ "data-testid": "1" }}
                // />
              }
            />
            <div className="row dense-row">
              <div className="col s6">
                <NLCard
                  height="34vh"
                  title={"Reinsurance"}
                  body={
                    <PieChartLegend
                      id="reinsure"
                      data={nlData.reinsure}
                      // key={this.props.chartData}
                      dimensions={[{ text: "topic" }]}
                      measures={[{ text: "count" }]}
                      height="130"
                      width="130"
                    />
                  }
                />
              </div>
              <div className="col s6">
                <NLCard
                  height="34vh"
                  title="Product Count"
                  body={
                    <PieChartLegend
                      id="products"
                      data={nlData.products}
                      // key={this.props.chartData}
                      dimensions={[{ text: "topic" }]}
                      measures={[{ text: "count" }]}
                      height="140"
                      width="140"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    auth: state.auth,
    profile: state.auth,
    nlData: state.nl.nlData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NLDashboard);
