import React from "react";
import {
  gic_map,
  newColorMap,
  norm_map,
  seg_orders,
  specialColorMap,
  specialColorMapLife,
} from "../../../../config/constants";

export default function Legends({
  marginBottom,
  marginTop,
  gic,
  maxChar = 15,
  colorMapCustom = null,
  life = false,
  colSize = 2,
  legendGap = 110,
  multiLine = false,
}) {
  return (
    <div
      className="col s12"
      style={{
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: marginBottom || 0,
        marginTop: marginTop || 0,
      }}
    >
      <div
        style={
          multiLine
            ? { width: "100%" }
            : {
                flexDirection: "row",
                display: "flex",
              }
        }
      >
        {life
          ? Object.keys(colorMapCustom || specialColorMapLife).map((seg) => (
              <div
                className={"col s" + colSize}
                title={seg}
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    minHeight: 20,
                    minWidth: 20,
                    height: 20,
                    width: 20,
                    marginRight: 10,
                    backgroundColor: colorMapCustom
                      ? colorMapCustom[seg]
                      : specialColorMapLife[seg],
                  }}
                ></div>
                <div>
                  {seg.length > maxChar ? seg.slice(0, maxChar) + "..." : seg}
                </div>
              </div>
            ))
          : Object.keys(colorMapCustom || newColorMap)
              // .sort((a, b) => (seg_orders[a] > seg_orders[b] ? 1 : -1))
              .map(
                (seg) => (
                  // (colorMapCustom ||
                  //   (gic && gic_map[seg]) ||
                  //   (!gic && norm_map[seg])) && (
                  <div
                    className={multiLine ? "col s2" : ""}
                    title={seg}
                    style={{
                      width: multiLine ? null : legendGap,
                      // minWidth: 110,
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        minHeight: 15,
                        minWidth: 15,
                        height: 15,
                        width: 15,
                        marginRight: 10,
                        marginTop: 5,
                        backgroundColor: colorMapCustom
                          ? colorMapCustom[seg]
                          : newColorMap[seg],
                      }}
                    ></div>
                    <div>
                      {seg.length > maxChar
                        ? seg.slice(0, maxChar) + "..."
                        : seg}
                    </div>
                  </div>
                ),
                // ),
              )}
      </div>
    </div>
  );
}
