import React from "react";
import { useHistory } from "react-router";
import M from "materialize-css";
import { ToolCard } from "./ToolCard";
import Description from "./DescriptionModal";

export default function Lenders() {
  const history = useHistory();
  const [setTool, SetTool] = React.useState(null);
  const onSubmit = (e) => {
    // e.target.reset();
    history.push({
      pathname: "/SignIn",
    });
  };
  const onRegister = (e) => {
    // e.target.reset();
    history.push({
      pathname: "/register",
    });
  };

  const ModalClick = (toolId) => {
    SetTool(toolId);
  };

  React.useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { dismissible: false });
  }, []);

  return (
    <div>
      <Description toolId={setTool} />
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto">
            <a href="/">
              <h2>Textplor</h2>
            </a>
          </h1>
        </div>
      </header>
      <section className="d-flex align-items-center">
        <div className="container" data-aos="fade-up" style={{ width: "90%" }}>
          <div className="row prod-detail">
            <h3 className="center center-align">Lender Dashboard</h3>
            <div className="row  prod-detail">
              <ToolCard
                ModalClick={ModalClick}
                text={"Distress Predictor"}
                toolId={"distress predictor"}
                color={"#2d49a6"}
                image={"line-bar.jpg"}
                modal="description-modal"
              />
              <ToolCard
                ModalClick={ModalClick}
                text={"News Impact"}
                toolId={"news impact"}
                color={"#2d49a6"}
                image={"report.png"}
                modal="description-modal"
              />
              <ToolCard
                ModalClick={ModalClick}
                toolId={"audit-matter"}
                text={"Audit Matters"}
                color={"#2d49a6"}
                image={"budget.png"}
                modal="description-modal"
              />
              <ToolCard
                ModalClick={ModalClick}
                text={"Corporate Announcements"}
                toolId={"announcement"}
                color={"#bfbf0d"}
                image={"order-1.jpg"}
                modal="description-modal"
              />
              <ToolCard
                ModalClick={ModalClick}
                text={"Accounting Policy Alert"}
                toolId={"acc-policy"}
                color={"#bf750d"}
                image={"policy.png"}
                modal="description-modal"
              />
              <ToolCard
                ModalClick={ModalClick}
                text={"Broad Financial"}
                toolId={"broad-finance"}
                color={"#bfbf0d"}
                image={"analysis.png"}
                modal="description-modal"
              />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <img src="assets/img/pd-sc.jpeg" className="img-fluid" alt="" />
              </div>

              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Distress Predictor</h3>
                <p className="font-italic">
                  A unique text-based default predictor that uses content of
                  Annual Report/ Corporate Filings.
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Corporate annual
                    report contains significant narrative information.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Standard default
                    prediction models ONLY use quantitative information and
                    ignore narrative information.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> Our product focuses
                    on the tonal quality of the narrative of an annual report
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>It is an early warning
                    measure of financial distress based on qualitative
                    information present in corporate annual reports.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> The tool uses a
                    proprietary text-based model for estimating default
                    probability of firms- listed as well as unlisted.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> The methodology
                    constructs a systemic financial distress prediction process
                    based on the tone of corporate annual report text
                    information.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> The tool has a strong
                    predictor power and hence can be used as an early warning
                    system.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>News Impact Score</h3>
                <p className="font-italic">
                  Aggregates news sentiments for any company from online
                  structured news of reliable media house.
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>There is a saying that
                    ‘no news is a good news’
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>NIS tracks every news
                    about a company that appears in the media (excluding social
                    media and TV).
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> It assigns sentiments
                    to every news article that is relevant for a given company
                    and then aggregates at daily, weekly or monthly level.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> Every article is also
                    checked for its novelty (uniqueness).
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> Once relevance and
                    novelty is ensured, NIS estimates sentiments for every news
                    article.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> We use cutting edge
                    machine learning techniques to generate sentiment scores.
                  </li>
                </ul>
              </div>
              <div
                className="col s12 l6 m6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/nis-sc.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <img
                  src="assets/img/audit-matter-sc.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Audit Matters</h3>
                <p className="font-italic">
                  Explores audit reports to highlight essential information and
                  uses deep learning to identify audit alerts.
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Name of auditors
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Peer companies under
                    same auditor
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Change of auditor
                    alert
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Audit report alerts
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 l6 m6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/announcement-sc.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Corporate Announcement</h3>
                <p className="font-italic">
                  It captures all the announcement filings with the stock
                  exchanges as and when these are filed with the exchange
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Captures all
                    announcements by listed companies
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Identifies a topic for
                    every announcement
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> Labels every
                    announcement in terms of importance- HIGH, MODERATE and LOW
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> Users can choose one
                    or more topics for a company for a given period.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> If required, users
                    can access the full text of any corporate action.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Broad Financial</h3>
                <p className="font-italic">
                  Provides peer-group comparison on select financial parameters
                  on annual basis
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Leverage
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Profitability
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> Efficiency
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Intangibility
                  </li>
                </ul>
              </div>
              <div
                className="col s12 l6 m6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/broad-finance-sc.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 l6 m6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/acc-policy-sc.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Accounting Policies</h3>
                <p className="font-italic">
                  Tracks any change in accounting policies and highlights areas
                  of change for immediate attention
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Change alerts along
                    with relevant sections
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Option to browse
                    through the accounting policies of two consecutive years.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Highlights any change
                    in accounting policies of select peers.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>

            <div className="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-toggle="collapse"
                    className="collapsed"
                    href="#faq-list-1"
                  >
                    FAQ - Distress Predictor{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p>
                      <strong>How does distress predictor work? </strong>{" "}
                    </p>
                    <p>
                      The Distress predictor (PD) uses text of annual report and
                      ignores the financial statements, tables, and pictures. It
                      mainly uses Directors Report, Auditors Report, Management
                      Discussion and Analysis, and Notes to Accounts sections of
                      any annual report to generate PD score. Higher the PD,
                      greater is the chance of default.
                    </p>
                    <p>
                      <strong>Does PD only capture financial distress? </strong>{" "}
                    </p>
                    <p>
                      No. PD captures any kind of trouble- financial or
                      otherwise. Therefore, it highlights default or
                      restructuring of debt, as well as other turmoil (e.g.,
                      loss of revenue or customer).
                    </p>

                    <p>
                      <strong>What is the frequency of PD? </strong>{" "}
                    </p>
                    <p>
                      Since PD is derived from corporate annual reports, it is
                      available on annual basis.
                    </p>

                    <p>
                      <strong>
                        Can a user generate PD of a company not available in
                        Textplor database?{" "}
                      </strong>{" "}
                    </p>
                    <p>
                      Yes. One can upload annual report of any company, listed
                      or unlisted, in the cloud and generate PD score. The file
                      uploaded should be in a prescribed format.
                    </p>

                    <p>
                      <strong>What is the reliability of the PD score?</strong>{" "}
                    </p>
                    <p>
                      PD is tested empirically with around 4000 healthy and
                      financially weak (including actual default) companies. It
                      is observed that PD can predict financial default up to
                      two years prior to the actual year of the default
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-toggle="collapse"
                    className="collapsed"
                    href="#faq-list-2"
                  >
                    FAQ - News Impact{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p>
                      <strong> What is News Impact Score? </strong>{" "}
                    </p>
                    <p>
                      News Impact Score (NIS) captures any positive and negative
                      information about a company. Such information could be in
                      terms of business growth (positive), higher profitability
                      (positive), financial distress (negative), deceleration of
                      growth (negative), losses (negative), factory lockdown
                      (negative). NIS aggregates such scores at the article
                      level- higher the score, more severe is the negative
                      impact. NIS can be correlated with several indicators such
                      as, a firm’s profitability, and credit rating.
                    </p>
                    <p>
                      <strong>How reliable are the news sources? </strong>{" "}
                    </p>
                    <p>
                      NIS uses newspaper articles of reliable media houses and
                      does not use social media text.
                    </p>

                    <p>
                      <strong> What is the frequency of NIS?</strong>{" "}
                    </p>
                    <p>
                      NIS can be generated at three frequency level- daily,
                      weekly, and monthly.
                    </p>

                    <p>
                      <strong>
                        {" "}
                        What is the period for which NIS data is available?{" "}
                      </strong>{" "}
                    </p>
                    <p>NIS for every company is available since 2010.</p>

                    <p>
                      <strong>
                        {" "}
                        Does NIS tool generate scores for all companies
                        registered in India?
                      </strong>{" "}
                    </p>
                    <p>
                      {" "}
                      Textplor presently provides NIS of all listed companies
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-toggle="collapse"
                    className="collapsed"
                    href="#faq-list-5"
                  >
                    FAQ - Audit Matter{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p>
                      <strong> What does it capture? </strong>{" "}
                    </p>
                    <p>
                      It captures different fees, values, auditor changes from
                      the annual Reports of companies. Gives alert on Change of
                      auditor and partner. It also shows alert sentences from
                      auditors report
                    </p>
                    <p>
                      <strong> How useful is this tool? </strong>{" "}
                    </p>
                    <p>
                      Not only this shows the data from one report it
                      simultaneously show the changes and alert over different
                      year of audit report
                    </p>

                    <p>
                      <strong>How does the tool work?</strong>{" "}
                    </p>
                    <p>
                      The tool capture the financial informations from annual
                      reports with help of NLP and stores 10 years of data for
                      over 5000 companies to give a clear view of companies
                      financial. Also, the tool uses state of the art deep
                      learning techniques to spot the alerts in a audit report
                    </p>

                    <p>
                      <strong> Who are the possible users? </strong>{" "}
                    </p>
                    <p>
                      auditor, accounting firms, lenders and investors are
                      potential users
                    </p>

                    <p>
                      <strong> How does it benefit any user? </strong>{" "}
                    </p>
                    <p>
                      The tool saves lot of time for the user as one can only
                      concentrate on highly important filings and read the
                      entire text for ARs. This obviates the need to read each
                      and every AR.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-toggle="collapse"
                    className="collapsed"
                    href="#faq-list-5"
                  >
                    FAQ - Corporate Announcement{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p>
                      <strong> What does it capture? </strong>{" "}
                    </p>
                    <p>
                      It captures all the announcement filings with the stock
                      exchanges as and when these are filed with the exchange.
                      So, this tool presents in one place all the corporate
                      actions and other filings by listed companies in India.
                    </p>
                    <p>
                      <strong> How useful is this tool? </strong>{" "}
                    </p>
                    <p>
                      Not only does it list all the announcements in one place,
                      the tool also classifies each announcement into relevant
                      topics and importance.
                    </p>

                    <p>
                      <strong>How does the tool work?</strong>{" "}
                    </p>
                    <p>
                      One can search market news about a company or a sector or
                      even from a broker. The tool processes text of each
                      corporate filing using NLP and AI to identify topics and
                      then uses a proprietary machine learning algorithm to
                      label importance (High, Moderate, and Low) to each
                      announcement.
                    </p>

                    <p>
                      <strong> Who are the possible users? </strong>{" "}
                    </p>
                    <p>
                      Corporate announcements tool could be of great use to
                      mutual fund managers and other long-term institutional
                      investors who can take action on the basis of price
                      sensitive corporate announcements almost on real time.
                    </p>

                    <p>
                      <strong> How does it benefit any user? </strong>{" "}
                    </p>
                    <p>
                      The tool saves lot of time for the user as one can only
                      concentrate on highly important filings and read the
                      entire text for those items. This obviates the need to
                      read each and every announcement.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-toggle="collapse"
                    className="collapsed"
                    href="#faq-list-5"
                  >
                    FAQ - Broad Financials{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p>
                      <strong> What does it capture? </strong>{" "}
                    </p>
                    <p>
                      It captures different fees, values, auditor changes from
                      the annual Reports of companies. It also shows peer
                      analysis over different companies at a glance
                    </p>
                    <p>
                      <strong> How useful is this tool? </strong>{" "}
                    </p>
                    <p>
                      Not only this shows the data from one report it
                      simultaneously show the same figures of different similar
                      companies at once.
                    </p>

                    <p>
                      <strong>How does the tool work?</strong>{" "}
                    </p>
                    <p>
                      The tool capture the financial informations from annual
                      reports with help of NLP and stores 10 years of data for
                      over 5000 companies to give a clear view of companies
                      financial.
                    </p>

                    <p>
                      <strong> Who are the possible users? </strong>{" "}
                    </p>
                    <p>
                      auditor, accounting firms, lenders and investors are
                      potential users
                    </p>

                    <p>
                      <strong> How does it benefit any user? </strong>{" "}
                    </p>
                    <p>
                      The tool saves lot of time for the user as one can only
                      concentrate on highly important filings and read the
                      entire text for ARs. This obviates the need to read each
                      and every AR.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-toggle="collapse"
                    className="collapsed"
                    href="#faq-list-5"
                  >
                    FAQ - Accounting Policies{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p>
                      <strong> What does it capture? </strong>{" "}
                    </p>
                    <p>
                      It shows the accounting policy from the annual reports.
                      The tool also shows the
                    </p>
                    <p>
                      <strong> How useful is this tool? </strong>{" "}
                    </p>
                    <p>
                      Accounting poilicies for companies are long and containes
                      many information. Changes of the policies are difficut to
                      notice . Our Tolls gives section wise added and removed
                      sentences of the companies over two consecutive year
                    </p>

                    <p>
                      <strong>How does the tool work?</strong>{" "}
                    </p>
                    <p>
                      The tool capture the financial informations from annual
                      reports with help of NLP and stores 10 years of data for
                      over 5000 companies to give a clear view of companies
                      financial.
                    </p>

                    <p>
                      <strong> Who are the possible users? </strong>{" "}
                    </p>
                    <p>
                      auditor, accounting firms, lenders and investors are
                      potential users
                    </p>

                    <p>
                      <strong> How does it benefit any user? </strong>{" "}
                    </p>
                    <p>
                      The tool saves lot of time for the user as one can only
                      concentrate on the changes sentences over different
                      sections. Not only that it also shows the changes of other
                      companies in the same sector at a glance.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>Vixplor Analytics Private Limited</p>
            </div>

            {/* <div>
              <iframe
                style={{ border: 0, width: "100%", height: "270px" }}
                src="https://www.google.com/maps/place/Vixplor+Analytics+Private+Limited/@22.5697023,88.4339727,17z/data=!4m12!1m6!3m5!1s0x3a0275abee2bb7a3:0x3dc193eb3c24f60b!2sVixplor+Analytics+Private+Limited!8m2!3d22.5697023!4d88.4361614!3m4!1s0x3a0275abee2bb7a3:0x3dc193eb3c24f60b!8m2!3d22.5697023!4d88.4361614"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div> */}

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="icofont-google-map"></i>
                    <h4>Location:</h4>
                    <p>
                      Webel Bhaban, Block: EP & GP, Sector V, Salt Lake,
                      Kolkata: 700091{" "}
                    </p>
                  </div>

                  <div className="email">
                    <i className="icofont-envelope"></i>
                    <h4>Email:</h4>
                    <p>contact@vixplor.com </p>
                  </div>

                  <div className="phone">
                    <i className="icofont-phone"></i>
                    <h4>Call:</h4>
                    <p>+91 98315 22750</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col s12 m6 l6 footer-contact">
                <h3>Vixplor Analytics Pvt. Ltd.</h3>
                <p>
                  Webel Bhaban, Block: EP & GP <br />
                  Sector V, Salt Lake
                  <br />
                  Kolkata: 700091 <br />
                  <br />
                  <strong>Phone:</strong> +91 98315 22750
                  <br />
                  <strong>Email:</strong> contact@vixplor.com
                  <br />
                </p>
              </div>
              <div
                className="col s12 m6 l6 footer-links"
                style={{ paddingTop: 9, paddingRight: 350 }}
              >
                <h4>Quick Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={onSubmit}>SignIn</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={onRegister}>Register</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="mr-md-auto text-center text-md-left">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <a href="http://www.vixplor.com/">
                  Vixplor Analytics Pvt. Ltd.
                </a>
              </strong>
              . All Rights Reserved
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="http://www.vixplor.com/" className="home">
              <i className="bx bxs-home"></i>
            </a>
            <a href="https://www.facebook.com/Vixplor/" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/vixplor/?originalSubdomain=in"
              className="linkedin"
            >
              <i className="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>
      </footer>

      <a href="#" className="back-to-top">
        <i className="ri-arrow-up-line"></i>
      </a>
    </div>
  );
}
