import React from "react";
import { LoaderOne } from "./loader1";
import Select from 'react-select';
import SentenceBankRow from "./SentenceBankRow";

export const MiscellsneousTab = (props) => {
  return (
    <div>
      <div className="row">
        <div className="col s12 l12 m12">
          <div className="row">
            <div className="col s12 l6 m6">
              <a
                className="col s12 btn admin-button modal-trigger"
                href="#user-modal"
              >
                Add User
              </a>
            </div>
            <div className="col s12 l6 m6">
              <a
                className="col s12 btn admin-button modal-trigger"
                href="#company-modal"
                onClick={props.sector}
              >
                Add Company
              </a>
            </div>
          </div>
        </div>
        <div className="col s12 l12 m12">
          <div className="card darken-1">
            <div className="card-content black-text">
              <div className="row">
                <div className="col l6 m6 s12">
                    <div className="col s12 l12 m12">
                      <div className="card-title">Daily News</div>
                    </div>
                    <div className="col s12 l5 m5">
                      <div className="col s12 l2 m2"><p style={{marginTop:30}}>From:</p></div>
                      <div className="col s12 l10 m10">
                        <div className="input-field inline">
                          <input
                            type="datetime-local"
                            id="from"
                            //onChange={this.handleUser}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 l5 m5">
                    <div className="col s12 l2 m2"><p style={{marginTop:30}}>To:</p></div>
                      <div className="col s12 l10 m10">
                        <div className="input-field inline">
                          <input
                            type="datetime-local"
                            id="to"
                            //onChange={this.handleUser}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 l2 m2">
                      <div className="input-field inline">
                        <a className="btn admin-button" style={{marginTop:10}}>
                          <i className="material-icons Medium">arrow_forward</i>
                        </a>
                      </div>
                    </div>
                    <table className="tableFixed">
                      <thead>
                        <tr>
                          <th>Channel</th>
                          <th>Headline</th>
                          <th>News</th>
                        </tr>
                      </thead>

                      <tbody style={{height:150}}>
                        <tr>
                          <td>Alvin</td>
                          <td>Eclair</td>
                          <td>$0.87</td>
                        </tr>
                        <tr>
                          <td>Alan</td>
                          <td>Jellybean</td>
                          <td>$3.76</td>
                        </tr>
                        <tr>
                          <td>Jonathan</td>
                          <td>Lollipop</td>
                          <td>$7.00</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div className="col l6 m6 s12">
                    <div className="col s12 l12 m12">
                      <span className="card-title">Company Add requests</span>
                    </div>
                    <table className="tableFixed">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Company</th>
                          <th>Requested On</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody style={{height:200}}>{
                        props.companyRequests?
                        props.companyRequests.length>0?
                        props.companyRequests.map(cr=>
                          <tr>
                            <td>{cr.User?cr.User.name:""}</td>
                            <td>{cr.data?cr.data.company:""}</td>
                            <td>{cr.createdAt}</td>
                            <td>
                              {
                                cr.varified?
                                <i className="material-icons green-text">check_circle</i>
                                :
                                <a className="modal-trigger" href="#add-company-modal" onClick={()=>{props.editRequest(cr.id)}}>
                                  <i className="material-icons">edit</i>
                                </a>
                              }
                              
                            </td>
                          </tr>
                        )
                        :<tr>
                          <td className="center center-align" colSpan={3}>No Request found</td>
                        </tr>
                        :null
                      }</tbody>
                    </table>
                </div>
              </div>
              <div className="row">
                {props.companyLoading?
                <div className="col s12 l12 m12">
                  <LoaderOne height={50} width={50}/>
                </div>
                :
                <div className="col s12 l12 m12">
                    <div className="col s12 l2 m2">
                      <span className="card-title">Company, Sector & Keyword Edit</span>
                    </div>
                    <div className="col s12 l6 m6">
                      <div className="col s12 l8 m8">
                        <Select
                          // value={selectedOptions}
                          onChange={props.HandleSelection}
                          options={props.companiesOnly}
                          placeholder="Select Company..."
                        />
                      </div>
                      <div className="col s12 l2 m2">
                        <button style={{marginTop:20, backgroundColor:'#124265'}} className="btn" onClick={props.GetKeywords}>
                          Get Data
                        </button>
                      </div>
                    </div>
                    <table className="tableFixed">
                      <thead>
                        <tr>
                          <th width="5%">ID</th>
                          <th>Company</th>
                          <th>Sector</th>
                          <th>keywords</th>
                          <th>Add Keyword</th>
                        </tr>
                      </thead>

                      <tbody style={{height:200}}>{
                      props.companies?
                        props.companies.length>0?
                          props.companies.map(c=>
                            <tr>
                              <td width="5%">
                              {c.id}
                              </td>
                              <td className="row">
                                <p className="col s11 l11 m11">
                                  {c.name}
                                </p>
                                <p className="col s1 l1 m1">
                                  <a className="modal-trigger" href={"#edit-company-modal"} 
                                  onClick={()=>{props.editCompany('company', c.id,null)}}>
                                    <i style={{marginLeft:10}} className="material-icons tiny">edit</i>
                                  </a>                             
                                </p>
                              </td>
                              <td className="row">
                                <p className="col s11 l11 m11">
                                  {c.Sector?c.Sector.name:""}
                                </p>
                                <p className="col s1 l1 m1">
                                  <a className="modal-trigger" href={"#edit-company-modal"} 
                                  onClick={()=>{props.editCompany('sector', c.id,c.Sector.id)}}>
                                    <i style={{marginLeft:10}} className="material-icons tiny">edit</i>
                                  </a>                             
                                </p>
                              </td>
                              <td className="row">
                                {
                                  c.Keywords&&
                                  c.Keywords.map(k =>
                                    <>
                                      <p className="col s11 l11 m11">
                                        {k.name}
                                      </p>
                                      <p className="col s1 l1 m1">
                                        <a className="modal-trigger" href={"#edit-company-modal"} 
                                        onClick={()=>{props.editCompany('keyword', c.id, k.id)}}>
                                          <i style={{marginLeft:10}} className="material-icons tiny">edit</i>
                                        </a>                             
                                      </p>
                                    </>
                                  )
                                }
                              </td>
                              <td className="row">
                                <div className="col s8 l8 m8">
                                  <input
                                    type="text"
                                    id={"add-keyword-"+c.id}
                                    value={props.state.keywords["add-keyword-"+c.id]}
                                    onChange={props.ChangeHandler}
                                    />
                                </div>
                                <div className="col s4 l4 m4">
                                  <button style={{backgroundColor:"#124265"}} 
                                  id={"keybutton-"+c.id} 
                                  className="btn btn-small" 
                                  onClick={props.addKeyword}>
                                    add
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        :<tr>
                          <td className="center center-align" colSpan={4}>No Request found</td>
                        </tr>
                        :<tr>
                          <td className="center center-align" colSpan={4}>Select a company and press get to view and edit details</td>
                        </tr>
                      }</tbody>
                    </table>
                </div>
              }
              </div>
              <div className="row">
                <div className="col s12 m12 l12">
                  <div className="col s6 m6 l6">
                    <span className="card-title">Sentence Bank</span>
                  </div>
                  <div className="input-field col s6">
                    <span>Filter Sentiment</span>
                    <span>
                      <select
                      className="browser-default"
                      onChange={props.HandleSentimentChange}
                      value={props.state.selSentiment}
                      id="selSentiment"
                      >
                        <option value="" disabled selected>No filter</option>
                        <option value="0">Neutral</option>
                        <option value="-1">Negative</option>
                        <option value="+1">Positive</option>
                      </select>
                    </span>
                  </div>
                </div>
                <div className="col s12 m12 l12">
                  <table className="tableFixed">
                    <thead>
                      <tr>
                        <th width="15%">Change</th>
                        <th width="75%">Sentence</th>
                        <th width="10%">Approve</th>
                      </tr>
                    </thead>
                    <tbody style={{height:300}}>
                      {
                        props.state.selSentiment && props.state.selSentiment != ""?
                          props.sentenceBank&&
                            props.sentenceBank
                            .filter(s=>s.sentiment==props.state.selSentiment)
                            .map(s => 
                              <SentenceBankRow
                              s={s}
                              OnVerify={props.OnVerify}
                              /> 
                            )
                        :
                          props.sentenceBank&&
                          props.sentenceBank.map(s => 
                            <SentenceBankRow
                            s={s}
                            OnVerify={props.OnVerify}
                            /> 
                          )

                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
