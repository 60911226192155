import config from "../../config/config";
const backendUrl = config.backendUrl;

export const fetchLifeList = (token) => {
  return (dispatch) => {
    dispatch({
      type: "LIFE_FETCH_LOADING",
    });
    fetch(backendUrl + "/life", {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "LIFE_FETCH_SUCCESS",
              payload: data?.data,
            });
          } else {
            dispatch({
              type: "LIFE_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "LIFE_FETCH_ERROR",
        });
      });
  };
};

export const setLifeReducer = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIFE_REDUCER",
      payload: data,
    });
  };
};

export const uploadLifePdf = (
  companyId,
  year,
  quarter,
  contentPages,
  forceUpdate,
  isPdf,
  particularLife,
  lifeId,
  file,
  token
) => {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_LIFE_PDF_LOADING",
    });
    let formData = new FormData();
    formData.append("company_id", companyId);
    formData.append("year", year);
    formData.append("quarter", quarter);
    formData.append("content_pages", contentPages);
    formData.append("is_pdf", String(isPdf));
    formData.append("force_update", String(forceUpdate));
    formData.append("particular_life", String(particularLife));
    formData.append("life_id", lifeId);
    formData.append("file", file);
    fetch(backendUrl + "/life/pdf/upload", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "UPLOAD_LIFE_PDF_SUCCESS",
              message: data?.message || "Life Data updated Successfully",
            });
          } else {
            dispatch({
              type: "UPLOAD_LIFE_PDF_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "UPLOAD_LIFE_PDF_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const fetchLifeData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_LIFE_DATA_LOADING",
    });
    fetch(backendUrl + "/life/fetch/data", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "FETCH_LIFE_DATA_SUCCESS",
              message: data?.message,
              data: data?.data,
              lifePathDetailsId: data?.lifePathDetailsId,
            });
          } else {
            dispatch({
              type: "FETCH_LIFE_DATA_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "FETCH_LIFE_DATA_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const lifeUpdateData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "LIFE_UPDATE_DATA_LOADING",
    });
    fetch(backendUrl + "/life/update/data", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "LIFE_UPDATE_DATA_SUCCESS",
              message: data?.message,
              data: data?.data,
              lifePathDetailsId: data?.lifePathDetailsId,
            });
          } else {
            dispatch({
              type: "LIFE_UPDATE_DATA_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "LIFE_UPDATE_DATA_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};
