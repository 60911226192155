import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCompanyListForNLReport } from "../../../utils/nlHelper";
import { useSelector } from "react-redux";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxHeight: 570,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function NLRowColAddModal({
  show,
  onClose,
  onSubmit,
  currentOrder,
  dimension = "row",
}) {
  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const [locCompanies, setLocCompanies] = useState([]);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (currentOrder) {
      setData({ ...data, order: parseInt(currentOrder) + 1 });
    }
  }, [currentOrder]);

  const isInsuranceCompany = (company) => {
    return getCompanyListForNLReport(company, sectors);
  };

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
  };

  const addAlterNative = (e) => {
    let alternatives = data?.alternatives || [];
    alternatives.push({
      id: `custom${alternatives.length}`,
      add: true,
    });
    setData({ ...data, alternatives: alternatives });
  };

  const deleteAlternative = (e, item) => {
    let alternatives = data?.alternatives;
    let index = alternatives?.findIndex((f) => f.id === item.id);
    if (index !== null && index !== undefined) {
      alternatives.splice(index, 1);
      setData({ ...data, alternatives: alternatives });
    }
  };

  const editAlternative = (e, item) => {
    let alternatives = data?.alternatives;
    let index = alternatives?.findIndex((f) => f.id === item.id);
    if (index !== null && index !== undefined) {
      alternatives[index]["name"] = e.target.value;
      setData({ ...data, alternatives: alternatives });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form>
          <div className="row">
            <Button
              variant="contained"
              className="right"
              onClick={(e) =>
                onSubmit(e, {
                  ...data,
                  companies: locCompanies.map((com) => {
                    return com.value;
                  }),
                })
              }
            >
              Submit
            </Button>
          </div>
          <div className="row">
            <div className="col s12">
              <label htmlFor="name">Name</label>
              <input id="name" value={data?.name} onChange={onChange} />
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <label htmlFor="lable">Label</label>
              <input id="lable" value={data?.lable} onChange={onChange} />
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <label htmlFor="order">Order</label>
              <input id="order" defaultValue={data?.order} disabled />
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <label htmlFor="order">Alternatives</label>
              <div className="row">
                <Button variant="text" onClick={addAlterNative}>
                  Add Alternative
                </Button>
              </div>
              {data?.alternatives?.map((item, index) => (
                <div className="row" key={item.id}>
                  <div className="col s6">
                    <input
                      id={item.id}
                      value={item.name}
                      onChange={(e) => {
                        editAlternative(e, item);
                      }}
                    />
                  </div>
                  <div className="col s2">
                    <Button
                      variant="text"
                      style={{ marginTop: "15px" }}
                      onClick={(e) => deleteAlternative(e, item)}
                    >
                      delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {dimension === "row" && (
            <div className="row">
              <div className="col s12">
                <Select
                  isMulti={true}
                  onChange={(value) => {
                    setLocCompanies(value);
                  }}
                  value={locCompanies?.map((com, index) => {
                    return { id: index, value: com.value, label: com.label };
                  })}
                  options={companies
                    ?.filter(isInsuranceCompany)
                    ?.map((com, index) => {
                      return { id: index, value: com.id, label: com.name };
                    })}
                  placeholder="Select companies..."
                />
              </div>
            </div>
          )}
          <div className="row">
            <Button
              variant="contained"
              className="right"
              onClick={(e) =>
                onSubmit(e, {
                  ...data,
                  companies: locCompanies.map((com) => {
                    return com.value;
                  }),
                })
              }
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
