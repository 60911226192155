import React from "react";

export default function StockPriceTable({ tableData, clickForPrimary, tableTweetData }) {
  return (
    <div style={{ height: "38vh", overflowY: "auto" }}>
      <table className="stockPriceTable">
        <thead>
          <tr className="heading">
            <th style={{width:'30%'}}>
              <b>Company</b>
            </th>
            <th style={{width:'10%'}}>
              <b>Vpos</b>
            </th>
            <th style={{width:'10%'}}>
              <b>pos</b>
            </th>
            <th style={{width:'10%'}}>
              <b>neut</b>
            </th>
            <th style={{width:'10%'}}>
              <b>neg</b>
            </th>
            <th style={{width:'10%'}}>
              <b>Vneg</b>
            </th>
            {tableTweetData&&
            <th style={{width:'20%'}}>
              <b>Tweet Count</b>
            </th>}
            {/* <td>
                            <b>Price chg (%)</b>
                        </td> */}
          </tr>
        </thead>
        <tbody>
          <tr
            className="primary"
            // onClick={() =>
            //   clickForPrimary(tableData.primary_company[0].companyInfo.id)
            // }
          >
            <td style={{width:'30%'}}>
              <b>{tableData.primary_company[0].companyInfo.name}</b>
            </td>
            <td style={{width:'10%'}}>{tableData.primary_company[0].sentiment_freq.very_pos}</td>
            <td style={{width:'10%'}}>{tableData.primary_company[0].sentiment_freq.pos}</td>
            <td style={{width:'10%'}}>{tableData.primary_company[0].sentiment_freq.neu}</td>
            <td style={{width:'10%'}}>{tableData.primary_company[0].sentiment_freq.neg}</td>
            <td style={{width:'10%'}}>{tableData.primary_company[0].sentiment_freq.very_neg}</td>
            {tableTweetData&&
            <td style={{width:'20%'}}>{tableTweetData.primary_company[0].sentiment_freq.very_pos
            +tableTweetData.primary_company[0].sentiment_freq.pos
            +tableTweetData.primary_company[0].sentiment_freq.neu
            +tableTweetData.primary_company[0].sentiment_freq.neg
            +tableTweetData.primary_company[0].sentiment_freq.very_neg
            }</td>}
            {/* <td>
                        {tableData.primary_company[0].change_in_price?Number(tableData.primary_company[0].change_in_price).toFixed(3):0}
                        </td> */}
          </tr>
          {tableData.secondary_company.map((secCompany) => (
            <tr
              className="secondary"
              onClick={() => clickForPrimary(secCompany.companyInfo.id)}
            >
              <td style={{width:'30%'}}><b>{secCompany.companyInfo.name}</b></td>
              <td style={{width:'10%'}}>{secCompany.sentiment_freq.very_pos}</td>
              <td style={{width:'10%'}}>{secCompany.sentiment_freq.pos}</td>
              <td style={{width:'10%'}}>{secCompany.sentiment_freq.neu}</td>
              <td style={{width:'10%'}}>{secCompany.sentiment_freq.neg}</td>
              <td style={{width:'10%'}}>{secCompany.sentiment_freq.very_neg}</td>
              {tableTweetData && tableTweetData.secondary_company.find(c=>c.companyInfo.id==secCompany.companyInfo.id)&&
              <td style={{width:'20%'}}>{ 
                tableTweetData.secondary_company.find(c=>c.companyInfo.id==secCompany.companyInfo.id).sentiment_freq.very_pos
              +  tableTweetData.secondary_company.find(c=>c.companyInfo.id==secCompany.companyInfo.id).sentiment_freq.pos
              +  tableTweetData.secondary_company.find(c=>c.companyInfo.id==secCompany.companyInfo.id).sentiment_freq.neu
              +  tableTweetData.secondary_company.find(c=>c.companyInfo.id==secCompany.companyInfo.id).sentiment_freq.neg
              +  tableTweetData.secondary_company.find(c=>c.companyInfo.id==secCompany.companyInfo.id).sentiment_freq.very_neg
              }</td>}
              {/* <td>
                                {secCompany.change_in_price?Number(secCompany.change_in_price).toFixed(3):0}
                                </td> */}
            </tr>
          ))}
        </tbody>
        {/* <tfoot>
                   
                </tfoot> */}
      </table>
    </div>
  );
}
