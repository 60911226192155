import React, { Component } from "react";
import M from "materialize-css";
import "../stylesheets/modals.css";
import "../stylesheets/common.css";
import { connect } from "react-redux";
import { getAuditCom, getNews } from "../../store/action/nisActions";
import { submitAudit } from "../../store/action/auditAction";
import { Redirect } from "react-router-dom";
import moment, { months } from "moment";

class AuditDashboardModal extends Component {
  state = {
    criteria: null,
    audit: null,
    audit_year: null,
    company: null,
    com_year: null,
    file: null,
    submitFlag: false,
  };

  componentDidMount = (e) => {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {
      preventScrolling: false,
      dismissible: true,
    });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id == "file") {
      this.setState({ [e.target.id]: e.target.files[0] });
    }
    if (e.target.id == "criteria") {
      this.props.GetAuditCompany(this.props.profile.accessToken);
    }
  };

  onClick = (e) => {
    e.preventDefault();
    if (this.state.criteria == "company") {
      if (this.state.company && this.state.audit_year) {
        this.props.SubmitAudit(this.state.company, this.state.audit_year, 'company')
        var start_date = moment().subtract(12, 'months').format('MM-DD-yyyy')
        var end_date = moment().format('MM-DD-yyyy')
        this.props.GetNews(start_date, end_date, this.state.company, this.props.auth.accessToken)
        this.setState({ submitFlag: true });

      } else if (this.state.file) {
        this.setState({ submitFlag: true });
      } else {
        alert("Either Select company and year or upload file");
      }
    } else if(this.state.criteria == "auditor") {
      if (this.state.audit && this.state.audit_year) {
        this.props.SubmitAudit(this.state.audit, this.state.audit_year, 'auditor')
        this.setState({ submitFlag: true });

      } else {
        alert("Select company and year both");
      }
    } else {
      if (this.state.audit && this.state.audit_year) {
        this.props.SubmitAudit(this.state.audit, this.state.audit_year, 'partner')
        this.setState({ submitFlag: true });

      } else {
        alert("Select company and year both");
      }
    }
  };

  render() {
    console.log("state : ", this.state);
    if (this.state.submitFlag && this.state.criteria == "company") {
      return <Redirect to="/audit" />;
    }
    if (this.state.submitFlag &&( this.state.criteria == "auditor" || this.state.criteria == "partner")) {
      return <Redirect to="/auditor" />;
    }
    return (
      <div
        id="audit-dashboard-modal"
        className="modal dashboard-modal modal-fixed-footer"
      >
        <div className="row modal-content dashboard-modal-content">
          <div
            className="row modal-title dashboard-modal-title valign-wrapper"
            style={{ backgroundColor: "#2d49a6", minHeight: 10 }}
          >
            <span className="dashboard-modal-name">Audit Report</span>
          </div>
          <div className="row">
            <div className="col s12">
              <form style={{ marginTop: 22 }}>
                <div className="input-field file-field col s12 center" />
                <div>
                  <label>Search with criteria : </label>
                  <select
                    required
                    name="criteria"
                    id="criteria"
                    className="browser-default validate"
                    onChange={this.onChange}
                  >
                    <option value="" key="" defaultValue="">
                      Choose type
                    </option>
                    <option value="auditor">Auditor</option>
                    <option value="company">Company</option>
                    <option value="partner">Partner</option>
                  </select>
                  {this.state.criteria && this.state.criteria == "auditor" ? (
                    <div>
                      <label>Select an audit company : </label>
                      <select
                        required
                        name="audit"
                        id="audit"
                        className="browser-default validate"
                        onChange={this.onChange}
                      >
                        <option value="" key="" defaultValue="">
                          Choose Audit Company
                        </option>
                        {this.props.auditorData &&
                          this.props.auditorData.map((i) => (
                            <option value={i.id}>{i.name}</option>
                          ))}
                      </select>
                      <label>Select Year : </label>
                      <select
                        required
                        name="audit_year"
                        id="audit_year"
                        className="browser-default validate"
                        onChange={this.onChange}
                      >
                        <option value="" key="" defaultValue="">
                          Choose Year
                        </option>
                        <option value="2021" key="" defaultValue="">
                          2021
                        </option>
                      </select>
                    </div>
                  ) : this.state.criteria &&
                    this.state.criteria == "company" ? (
                    <div>
                      <label>Select from existing company : </label>
                      <select
                        required
                        id="company"
                        className="browser-default validate"
                        onChange={this.onChange}
                      >
                        <option value="" key="" defaultValue="">
                          Choose Company
                        </option>
                        {this.props.auditComData &&
                          this.props.auditComData.map((i) => (
                            <option value={i.id}>{i.name}</option>
                          ))}
                      </select>
                      <label>Select Year : </label>
                      <select
                        required
                        id="audit_year"
                        className="browser-default validate"
                        onChange={this.onChange}
                      >
                        <option value="" key="" defaultValue="">
                          Choose Year
                        </option>
                        <option value="2021" key="" defaultValue="">
                          2021
                        </option>
                      </select>
                      <div style={{ paddingTop: 10, paddingLeft: "50%" }}>
                        <b>Or</b>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>Choose your own :-</label>
                      </div>
                      <input
                        type="file"
                        id="file"
                        disabled
                        accept=".pdf,.txt "
                        onChange={this.onChange}
                        style={{ marginTop: 20, marginBottom: 20 }}
                      />
                    </div>
                  ) :  
                  this.state.criteria &&
                    this.state.criteria == "partner" ?
                    <div>
                      <label>Select an audit partner : </label>
                      <select
                        required
                        name="audit"
                        id="audit"
                        className="browser-default validate"
                        onChange={this.onChange}
                      >
                        <option value="" key="" defaultValue="">
                          Choose Audit Partner
                        </option>
                        {this.props.auditPartnersData &&
                          this.props.auditPartnersData.map((i) => (
                            <option value={i.id}>{i.name}</option>
                          ))}
                      </select>
                      <label>Select Year : </label>
                      <select
                        required
                        name="audit_year"
                        id="audit_year"
                        className="browser-default validate"
                        onChange={this.onChange}
                      >
                        <option value="" key="" defaultValue="">
                          Choose Year
                        </option>
                        <option value="2021" key="" defaultValue="">
                          2021
                        </option>
                      </select>
                    </div>
                  :null  
                }
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="modal-close waves-effect waves btn-flat">
            CANCEL
          </button>
          <button
            // type="submit"
            // form="dashboard"
            className="waves-effect waves btn-flat"
            onClick={this.onClick}
          >
            SUBMIT
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.auth,
    user: state.auth.user,

    auditComData: state.NIS.auditComData.companies,
    auditorData: state.NIS.auditComData.auditor,
    auditPartnersData: state.NIS.auditComData.partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAuditCompany: (token) => {
      dispatch(getAuditCom(token));
    },
    SubmitAudit: (id, year, type) => {
      dispatch(submitAudit(id, year, type));
    },
    GetNews: (start, end, company, token) => {
      dispatch(getNews(start, end, company, token));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditDashboardModal);
