import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PapersDownload from "../auth/PapersDownload";
import M from "materialize-css";
import "./style.css";

class MainScreen extends Component {
  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, { preventScrolling: false });
    var elems1 = document.querySelectorAll(".carousel");
    M.Carousel.init(elems1, {
      indicators: true,
      duration: 200,
    });

    // $(".counter").each(function () {
    //   $(this)
    //     .prop("Counter", 0)
    //     .animate(
    //       {
    //         Counter: $(this).text(),
    //       },
    //       {
    //         duration: 8000,
    //         easing: "swing",
    //         step: function (now) {
    //           $(this).text(Math.ceil(now));
    //         },
    //       }
    //     );
    // });
    if (this.refreshTimer) clearInterval(this.refreshTimer);
    console.log("HERE");
    this.refreshTimer = setInterval(() => {
      var elem = document.getElementById("slider");
      var instance = M.Carousel.getInstance(elem);
      console.log("CHANGING");
      instance.next();
    }, 2000);
  }

  componentDidUpdate() {
    if (this.refreshTimer) clearInterval(this.refreshTimer);
    console.log("HERE");
    this.refreshTimer = setInterval(() => {
      var elem = document.getElementById("slider");
      var instance = M.Carousel.getInstance(elem);
      console.log("CHANGING");
      instance.next();
    }, 2000);
  }

  componentWillUnmount() {
    if (this.refreshTimer) clearInterval(this.refreshTimer);
  }

  onSubmit = (e) => {
    // e.target.reset();
    this.props.history.push({
      pathname: "/SignIn",
    });
  };
  onRegister = (e) => {
    // e.target.reset();
    this.props.history.push({
      pathname: "/register",
    });
  };

  onChange = (id) => {
    // e.target.reset();
    this.props.history.push({
      pathname: "/" + id,
    });
  };

  render() {
    return (
      <div>
        <PapersDownload />
        <header id="header" className="fixed-top hide-on-small-only">
          <div className="container d-flex align-items-center">
            <h1 className="logo mr-auto">
              <a href="/">
                <h2>Textplor</h2>
              </a>
            </h1>
            <Link to="/SignIn" className="nav-item">
              Login
            </Link>
            <Link to="/register" onClick={this.onRegister} className="nav-item">
              Get Demo
            </Link>
            <Link className="nav-item modal-trigger" data-target="modal-papers">
              Research
            </Link>
          </div>
        </header>
        <section id="hero" className="d-flex align-items-center">
          <div
            className="container position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
            style={{ marginTop: "-110px", width: "90%" }}
          >
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9 text-center">
                <h1>Textplor- Explore Long Text</h1>
                <h2>A financial analytics platform</h2>
              </div>
            </div>
            <div
              className="row icon-boxes"
              // style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="col s12 m6 l4"
                data-aos="zoom-in"
                data-aos-delay="200"
                // style={{ padding: 10 }}
              >
                <div className="icon-box">
                  <p className="description">
                    <img
                      src="assets/img/lender-1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </p>
                  <div className="icon-submerge">
                    <i className="material-icons">equalizer</i>
                  </div>
                  <h4 className="title center-align">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.onChange("Lenders");
                      }}
                    >
                      Lenders and Credit Rating Agencies
                    </a>
                  </h4>
                  <p className="sub-title">
                    predicts distress, and provides qualitative information on
                    large and medium sized borrowers.
                  </p>
                </div>
              </div>

              <div
                className="col s12 m6 l4"
                data-aos="zoom-in"
                data-aos-delay="300"
                // style={{ padding: 10 }}
              >
                <div className="icon-box">
                  <p className="description">
                    <img
                      src="assets/img/accounting-1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </p>
                  <div className="icon-submerge">
                    <i className="material-icons">account_balance</i>
                  </div>
                  <h4 className="title center-align">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.onChange("accounts");
                      }}
                    >
                      Accounting Firms
                    </a>
                  </h4>
                  <p className="sub-title">
                    provides critical quantitative and qualitative information
                    on auditors, and auditees.
                  </p>
                </div>
              </div>

              <div
                className="col s12 m6 l4"
                data-aos="zoom-in"
                data-aos-delay="400"
                // style={{ padding: 10 }}
              >
                <div className="icon-box">
                  <p className="description">
                    <img
                      src="assets/img/stocks-2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </p>
                  <div className="icon-submerge">
                    <i className="material-icons">graphic_eq</i>
                  </div>
                  <h4 className="title center-align">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.onChange("mfs");
                      }}
                    >
                      Mutual Funds/Hedge Funds{" "}
                    </a>
                  </h4>
                  <p className="sub-title">
                    Provides news and social media data in high frequency for
                    users like brokers & hedge funds
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          {/* <section id="about" className="about">
            <div className="container" data-aos="fade-up" style={{marginTop:"-100px"}}>
              <div className="section-title">
                <img src="assets/img/product.png" className="img-fluid" alt="" />
              </div>
            </div>
          </section> */}

          {/* <section className="counters" style={{marginTop:"5%"}}>
            <div className="container">
              <div className="row justify-content-end">
                <div className="col s6 m6 l3">
                  <div className="count-box">
                    <div>
                      <div className="counter" data-target="5000">
                        5000
                      </div>
                      <p>Companies</p>
                    </div>
                  </div>
                </div>
                <div className="col s6 m6 l3">
                  <div className="count-box">
                    <div>
                      <div className="counter" data-target="3">
                        3
                      </div>
                      <p>Trusted Media</p>
                    </div>
                  </div>
                </div>
                <div className="col s6 m6 l3">
                  <div className="count-box">
                    <div>
                      <div className="counter" data-target="10">
                        10
                      </div>
                      <p>Year's Annual Reports</p>
                    </div>
                  </div>
                </div>
                <div className="col s6 m6 l3">
                  <div className="count-box">
                    <div>
                      <div className="counter" data-target="12">
                        12
                      </div>
                      <p>Months Corporate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <section id="clients" className="clients" style={{ marginTop: "6%" }}>
            <div className="container" data-aos="zoom-in">
              <div className="section-title">
                <h2>Clients</h2>
              </div>
              <div className="row">
                <div className="col l4 m4">
                  <img
                    src="assets/img/hdfcergo.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col l4 m4">
                  <img
                    src="assets/img/sbigic.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col l4 m4">
                  <img
                    src="assets/img/magma.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Achivements</h2>
                {/* <p>A</p> */}
              </div>
              <div className="carousel carousel-slider center" id="slider">
                <div className="carousel-item" href="#one!">
                  <div className="row">
                    <img
                      height="300"
                      src="assets/img/testimonials/testimonials-1.jpg"
                      alt=""
                    />

                    <h5 style={{ marginTop: 20 }}>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Vixplor Analytics became second runner up in IIMC Ideas to
                      Implementation Contest on 30th November 2019.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </h5>
                  </div>
                </div>
                <div className="carousel-item" href="#two!">
                  <div className="row">
                    <img
                      height="300"
                      src="assets/img/testimonials/testimonials-5.png"
                      alt=""
                    />
                    <h5 style={{ marginTop: 20 }}>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Vixplor was incubated by NASSCOM & Govt. of West Bengal.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </h5>
                  </div>
                </div>
                <div className="carousel-item" href="#three!">
                  <div className="row">
                    <img
                      height="300"
                      src="assets/img/testimonials/Certificate-2.jpg"
                      alt=""
                    />
                    <h5 style={{ marginTop: 20 }}>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Vixplor was finalist of uti-mf ignite innovation summit
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </h5>
                  </div>
                </div>
                <div className="carousel-item" href="#four!">
                  <div className="row">
                    <img
                      height="300"
                      src="assets/img/testimonials/Certificate-3.jpg"
                      alt=""
                    />
                    <h5 style={{ marginTop: 20 }}>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      Vixplor was semi- finalist of IIT Bombay edureka
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="testimonials" className="testimonials">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Articles</h2>
              </div>
              <div className="row">
                <div className="col s12 l5 m5 card article-card">
                  <div className="row">
                    <img
                      src="assets/img/article/textplor2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col s12 article-card-low">
                    <div className="col s10">
                      <h5>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.props.history.push("/article-news");
                          }}
                        >
                          News Impact Score and Stock Market Quality:
                          Information Content in Business News
                        </a>
                      </h5>
                      <p>Ashok Banerjee | 21 Aug 2021</p>
                    </div>
                    <div className="col s2">
                      <img
                        type="image"
                        className="circle"
                        height="50"
                        width="50"
                        src="assets/img/team/ashok.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col s12">
                    <div className="row badgerow">
                      <span className="mybadge-info">Textplor</span>
                      <span className="mybadge-primary">News Analysis</span>
                      <span className="mybadge-warning">Finance</span>
                    </div>
                  </div>
                </div>

                <div className="col s12 l5 m5 card article-card">
                  <div className="row">
                    <img
                      src="assets/img/article/textplor-2.jpeg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col s12 article-card-low">
                    <div className="col s10">
                      <h5 style={{ cursor: "pointer" }}>
                        <a
                          onClick={() => {
                            this.props.history.push("/ariticle-pd");
                          }}
                        >
                          Predicting Corporate Distress: A Textual Analysis
                        </a>
                      </h5>
                      <p>Ashok Banerjee | 04 June 2021</p>
                    </div>
                    <div className="col s2">
                      <img
                        type="image"
                        className="circle"
                        height="50"
                        width="50"
                        src="assets/img/team/ashok.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col s12">
                    <div className="row badgerow" style={{ marginTop: 30 }}>
                      <span className="mybadge-info">Textplor</span>
                      <span className="mybadge-primary">
                        Distress Prediction
                      </span>
                      <span className="mybadge-warning">Finance</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col s12 m6 l6 footer-contact">
                  <h3>Vixplor Analytics Pvt. Ltd.</h3>
                  <p>
                    Webel Bhaban, Block: EP & GP <br />
                    Sector V, Salt Lake
                    <br />
                    Kolkata: 700091 <br />
                    <br />
                    <strong>Phone:</strong> +91 98315 22750
                    <br />
                    <strong>Email:</strong> contact@vixplor.com
                    <br />
                  </p>
                </div>
                <div
                  className="col s12 m6 l6 footer-links"
                  style={{ paddingTop: 9, paddingRight: 350 }}
                >
                  <h4>Quick Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a onClick={this.onSubmit}>SignIn</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a onClick={this.onRegister}>Register</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container d-md-flex py-4">
            <div className="mr-md-auto text-center text-md-left">
              <div className="copyright">
                &copy; Copyright{" "}
                <strong>
                  <a href="http://www.vixplor.com/">
                    Vixplor Analytics Pvt. Ltd.
                  </a>
                </strong>
                . All Rights Reserved
              </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
              <a href="http://www.vixplor.com/" className="home">
                <i className="bx bxs-home"></i>
              </a>
              <a href="https://www.facebook.com/Vixplor/" className="facebook">
                <i className="bx bxl-facebook"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/vixplor/?originalSubdomain=in"
                className="linkedin"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </div>
          </div>
        </footer>

        <a href="#" className="back-to-top">
          <i className="ri-arrow-up-line"></i>
        </a>
      </div>
    );
  }
}

export default connect()(MainScreen);
