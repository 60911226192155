import React from "react";
import Select from "react-select";
import "../stylesheets/modals.css";
import "../stylesheets/common.css";
import {
  companyNameMap,
  getCompanyListForNLReport,
} from "../../utils/nlHelper";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../config/constants";

const DashboardModal = ({
  auth,
  state,
  id,
  onChangeHandler,
  yearData,
  company,
  sector,
  brokers,
  HandleChange,
  selectedOptions,
  selectedMulOptions,
  onFileChangeHandler,
  onClickHandler,
  onNISSubmit,
  uploadError,
  nis,
  onSMSubmit,
  onAnnounceSubmit,
  onConcallSubmit,
  broker,
  onStockSubmit,
  uploadLoading,
  stockError,
  stockLoading,
  annLoading,
  annError,
  nisLoading,
  nisError,
  smLoading,
  smError,
  HandleMulSelChange,
  concallLoading,
  concallError,
  onConcollFileChangeHandler,
  auditChange,
  auditorData,
  auditCriteria,
  auditPartnersData,
  auditComData,
  onAuditSubmit,
  onAccPolSubmit,
  auditLoading,
  auditError,
  accPolSearch,
  selectedAuditPartner,
  selectedAuditor,
  HandleAuditPartnerChange,
  HandleAuditorChange,
  audit_year,
  yearLoading,
  year,
  year_quarter,
  onNLDownload,
  onNLDashboard,
  nlDataLoading,
  nlDataError,
  downloadNLReportLoading,
  nlTemplates,
  changeAllNl,
  onNLDownloadLife,
}) => {
  return (
    <div
      id="dashboard-modal"
      className="modal dashboard-modal modal-fixed-footer"
    >
      <div className="row modal-content dashboard-modal-content">
        <div
          className="row modal-title dashboard-modal-title valign-wrapper"
          style={
            id == "distress predictor"
              ? { backgroundColor: "#2d49a6", minHeight: 10 }
              : id == "news impact"
                ? { backgroundColor: "#109ea3", minHeight: 10 }
                : id == "sector meter"
                  ? { backgroundColor: "#3f8c23", minHeight: 10 }
                  : id == "announcement"
                    ? { backgroundColor: "#bfbf0d", minHeight: 10 }
                    : id == "stock pulse"
                      ? { backgroundColor: "#bfbf0d", minHeight: 10 }
                      : id == "acc-policy"
                        ? { backgroundColor: "#bf750d", minHeight: 10 }
                        : id == "concall"
                          ? { backgroundColor: "#5d1b8f", minHeight: 10 }
                          : id == "audit-monitor"
                            ? { backgroundColor: "#2d49a6", minHeight: 10 }
                            : id == "audit-matter"
                              ? { backgroundColor: "#2d49a6", minHeight: 10 }
                              : id == "broad-finance"
                                ? { backgroundColor: "#2d49a6", minHeight: 10 }
                                : id === "nl-download" ||
                                    id === "nl-download-life"
                                  ? {
                                      backgroundColor: "#2d49a6",
                                      minHeight: 10,
                                    }
                                  : id === "nl-dashboard"
                                    ? {
                                        backgroundColor: "#109ea3",
                                        minHeight: 10,
                                      }
                                    : null
          }
        >
          {id == "distress predictor" ? (
            <span className="dashboard-modal-name">Distress Predictor</span>
          ) : id == "news impact" ? (
            <span className="dashboard-modal-name">News Impact</span>
          ) : id == "sector meter" ? (
            <span className="dashboard-modal-name">Sector Meter</span>
          ) : id == "announcement" ? (
            <span className="dashboard-modal-name">Announcement</span>
          ) : id == "stock pulse" ? (
            <span className="dashboard-modal-name">Stock Pulse</span>
          ) : id == "concall" ? (
            <span className="dashboard-modal-name">Con Call</span>
          ) : id == "acc-policy" ? (
            <span className="dashboard-modal-name">Accounting Policy</span>
          ) : id == "audit-matter" ? (
            <span className="dashboard-modal-name">Audit Matter</span>
          ) : id == "audit-monitor" ? (
            <span className="dashboard-modal-name">Audit Report</span>
          ) : id == "broad-finance" ? (
            <span className="dashboard-modal-name">Broad Finance</span>
          ) : id === "nl-download" ? (
            <span className="dashboard-modal-name">NL Data</span>
          ) : id === "nl-download-life" ? (
            <span className="dashboard-modal-name">NL Data Life</span>
          ) : id === "nl-dashboard" ? (
            <span className="dashboard-modal-name">IRDAI Data</span>
          ) : null}
        </div>
        <div className="row">
          <div className="col s12">
            <form
              id="dashboard"
              onSubmit={
                id == "distress predictor"
                  ? onClickHandler
                  : id == "news impact"
                    ? onNISSubmit
                    : id == "sector meter"
                      ? onSMSubmit
                      : id == "announcement"
                        ? onAnnounceSubmit
                        : id == "stock pulse"
                          ? onStockSubmit
                          : id == "concall"
                            ? onConcallSubmit
                            : id == "audit-monitor"
                              ? onAuditSubmit
                              : id == "acc-policy"
                                ? onAccPolSubmit
                                : id == "audit-matter"
                                  ? onAuditSubmit
                                  : id == "broad-finance"
                                    ? onAuditSubmit
                                    : id === "nl-download"
                                      ? onNLDownload
                                      : id === "nl-download-life"
                                        ? onNLDownloadLife
                                        : id === "nl-dashboard"
                                          ? onNLDashboard
                                          : null
              }
              style={{ marginTop: 22 }}
            >
              {id == "acc-policy" && (
                <div style={{ paddingTop: 10 }}>
                  <div className="col s12 m12 l6">
                    <label>
                      <input
                        value="company"
                        name="accPolSearch"
                        type="radio"
                        checked={accPolSearch == "company"}
                        onChange={onChangeHandler}
                      />
                      <span>Search by company</span>
                    </label>
                  </div>
                  <div className="col s12 m12 l6">
                    <label>
                      <input
                        value="change"
                        name="accPolSearch"
                        type="radio"
                        checked={accPolSearch == "change"}
                        onChange={onChangeHandler}
                      />
                      <span>Search by change</span>
                    </label>
                  </div>
                </div>
              )}
              {id == "audit-monitor" ? (
                <div className="row dashboard-modal-content">
                  <div className="row">
                    <div className="col s12">
                      <form style={{ marginTop: 22 }}>
                        <label>Search by</label>
                        <div className="col s12" />
                        <div
                          className="col s4"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <label>
                            <input
                              onChange={auditChange}
                              value="company"
                              checked={auditCriteria == "company"}
                              name="criteria"
                              type="radio"
                            />
                            <span>Company</span>
                          </label>
                        </div>
                        <div
                          className="col s4"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <label>
                            <input
                              onChange={auditChange}
                              value="auditor"
                              checked={auditCriteria == "auditor"}
                              name="criteria"
                              type="radio"
                            />
                            <span>Auditor</span>
                          </label>
                        </div>
                        <div
                          className="col s4"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <label>
                            <input
                              onChange={auditChange}
                              value="partner"
                              checked={auditCriteria == "partner"}
                              name="criteria"
                              type="radio"
                            />
                            <span>Partner</span>
                          </label>
                        </div>
                        <div
                          className="col s4"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <label>
                            <input
                              onChange={auditChange}
                              value="alerts"
                              checked={auditCriteria == "alerts"}
                              name="criteria"
                              type="radio"
                            />
                            <span>Alerts</span>
                          </label>
                        </div>
                        <div
                          className="col s4"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <label>
                            <input
                              onChange={auditChange}
                              value="continuation"
                              checked={auditCriteria == "continuation"}
                              name="criteria"
                              type="radio"
                            />
                            <span>Continuation</span>
                          </label>
                        </div>
                        <div>
                          {auditCriteria == "auditor" ? (
                            <div className="col s12">
                              <label>Select an audit company : </label>
                              <Select
                                value={selectedAuditor}
                                onChange={HandleAuditorChange}
                                options={
                                  auditorData &&
                                  auditorData.map((ap) => {
                                    return { label: ap.name, value: ap.id };
                                  })
                                }
                                placeholder="Select Audit Firm..."
                              />
                              {/* <select
                                  required
                                  name="audit"
                                  id="audit"
                                  className="browser-default validate"
                                  onChange={auditChange}
                                >
                                  <option value="" key="" defaultValue="">
                                    Choose Audit Company
                                  </option>
                                  {auditorData &&
                                    auditorData.map((i) => (
                                      <option value={i.id}>{i.name}</option>
                                    ))}
                                </select> */}
                              <label>Select Year : </label>
                              <select
                                required
                                name="audit_year"
                                id="audit_year"
                                className="browser-default validate"
                                onChange={auditChange}
                                value={audit_year}
                              >
                                <option value="" key="" defaultValue="">
                                  Choose Year
                                </option>
                                <option value="2022" key="" defaultValue="">
                                  2022
                                </option>
                                <option value="2021" key="" defaultValue="">
                                  2021
                                </option>
                                <option value="2020" key="" defaultValue="">
                                  2020
                                </option>
                                <option value="2019" key="" defaultValue="">
                                  2019
                                </option>
                              </select>
                            </div>
                          ) : auditCriteria && auditCriteria == "company" ? (
                            <div className="col s12">
                              <label>Select from existing company : </label>
                              <Select
                                value={selectedOptions}
                                onChange={HandleChange}
                                options={company}
                                disabled={yearLoading}
                                placeholder="Select Company..."
                              />
                              <div>
                                <div
                                  className={
                                    yearLoading ? "col s10" : "col s12"
                                  }
                                >
                                  <label>Select Year : </label>
                                  <select
                                    required
                                    id="audit_year"
                                    className="browser-default validate"
                                    onChange={auditChange}
                                    value={audit_year}
                                    disabled={yearLoading}
                                  >
                                    <option value="" key="" defaultValue="">
                                      Choose Year
                                    </option>
                                    {yearData &&
                                      yearData.map((y) => (
                                        <option value={y.year}>{y.year}</option>
                                      ))}
                                  </select>
                                </div>
                                {yearLoading && (
                                  <div
                                    className="col s2"
                                    style={{ marginTop: 30 }}
                                  >
                                    <div className="small-loader"></div>
                                  </div>
                                )}
                              </div>
                              {/* <div style={{ paddingTop: 10, paddingLeft: "50%" }}>
                                  <b>Or</b>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <label>Choose your own :-</label>
                                </div>
                                <input
                                  type="file"
                                  id="file"
                                  disabled
                                  accept=".pdf,.txt "
                                  onChange={auditChange}
                                  style={{ marginTop: 20, marginBottom: 20 }}
                                /> */}
                            </div>
                          ) : auditCriteria && auditCriteria == "partner" ? (
                            <div className="col s12">
                              <label>Select an audit partner : </label>
                              <Select
                                value={selectedAuditPartner}
                                onChange={HandleAuditPartnerChange}
                                options={
                                  auditPartnersData &&
                                  auditPartnersData.map((ap) => {
                                    return { label: ap.name, value: ap.id };
                                  })
                                }
                                placeholder="Select Audit partner..."
                              />
                              <label>Select Year : </label>
                              <select
                                required
                                name="audit_year"
                                id="audit_year"
                                className="browser-default validate"
                                onChange={auditChange}
                                value={audit_year}
                              >
                                <option value="" key="" defaultValue="">
                                  Choose Year
                                </option>
                                <option value="2022" key="" defaultValue="">
                                  2022
                                </option>
                                <option value="2021" key="" defaultValue="">
                                  2021
                                </option>
                                <option value="2020" key="" defaultValue="">
                                  2020
                                </option>
                                <option value="2019" key="" defaultValue="">
                                  2019
                                </option>
                              </select>
                            </div>
                          ) : auditCriteria &&
                            (auditCriteria == "continuation" ||
                              auditCriteria == "alerts") ? (
                            <div className="row dashboard-modal-content">
                              <div className="col s12">
                                <label>Select Year : </label>
                                <select
                                  required
                                  id="audit_year"
                                  className="browser-default validate"
                                  onChange={auditChange}
                                  value={audit_year}
                                >
                                  <option value="" key="" defaultValue="">
                                    Choose Year
                                  </option>
                                  <option value="2010">2010</option>
                                  <option value="2011">2011</option>
                                  <option value="2012">2012</option>
                                  <option value="2013">2013</option>
                                  <option value="2014">2014</option>
                                  <option value="2015">2015</option>
                                  <option value="2016">2016</option>
                                  <option value="2017">2017</option>
                                  <option value="2018">2018</option>
                                  <option value="2019">2019</option>
                                  <option value="2020">2020</option>
                                  <option value="2021">2021</option>
                                  <option value="2022">2022</option>
                                </select>
                              </div>
                              <div className="col s12">
                                {auditCriteria == "continuation" ? (
                                  <label>No. of year : </label>
                                ) : (
                                  <label>No. of alerts : </label>
                                )}
                                <input
                                  type="number"
                                  id="noOfAuditChange"
                                  onChange={onChangeHandler}
                                  value={state.noOfAuditChange}
                                  min="0"
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (id == "acc-policy" && accPolSearch == "company") ||
                id == "audit-matter" ||
                id == "broad-finance" ? (
                <div className="row dashboard-modal-content">
                  <div className="row">
                    <div className="col s12">
                      <form style={{ marginTop: 22 }}>
                        <label>Select from existing company : </label>
                        <Select
                          value={selectedOptions}
                          onChange={HandleChange}
                          options={company}
                          placeholder="Select Company..."
                        />

                        <label>Select Year : </label>
                        <select
                          required
                          id="audit_year"
                          className="browser-default validate"
                          onChange={auditChange}
                          value={audit_year}
                          disabled={yearLoading}
                        >
                          <option value="" key="" defaultValue="">
                            Choose Year
                          </option>
                          {yearData &&
                            yearData.map((y) => (
                              <option value={y.year}>{y.year}</option>
                            ))}
                        </select>
                        {(id == "broad-finance" || id == "acc-policy") && (
                          <>
                            <label>Select secondary Companies: </label>
                            <Select
                              // value={selectedMulOptions}
                              onChange={HandleMulSelChange}
                              options={company}
                              isMulti={true}
                              placeholder="Select Company..."
                            />
                          </>
                        )}
                        {/* <div style={{ paddingTop: 10, paddingLeft: "50%" }}>
                          <b>Or</b>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <label>Choose your own :-</label>
                        </div>
                        <input
                          type="file"
                          id="file"
                          disabled
                          accept=".pdf,.txt "
                          onChange={auditChange}
                          style={{ marginTop: 20, marginBottom: 20 }}
                        /> */}
                      </form>
                    </div>
                  </div>
                </div>
              ) : id == "acc-policy" && accPolSearch == "change" ? (
                <div className="row dashboard-modal-content">
                  <div className="row">
                    <label>Select Year : </label>
                    <select
                      required
                      id="audit_year"
                      className="browser-default validate"
                      onChange={auditChange}
                      value={audit_year}
                    >
                      <option value="" key="" defaultValue="">
                        Choose Year
                      </option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                    </select>
                  </div>
                  <div className="row">
                    <label>No. of change : </label>
                    <input
                      type="number"
                      id="noOfAuditChange"
                      onChange={onChangeHandler}
                      value={state.noOfAuditChange}
                      min="0"
                    />
                  </div>
                </div>
              ) : null}
              <div className="input-field file-field col s12 center"></div>
              {id == "distress predictor" ? (
                <div>
                  <label>Select from existing company</label>
                  <Select
                    value={selectedOptions}
                    onChange={HandleChange}
                    options={company}
                    name="distress_predictor"
                  />

                  <label>Select year</label>
                  {yearData.message == "success" &&
                  yearData.years.length === 0 ? (
                    <div style={{ color: "red" }}>
                      <b>Sorry!! No year found</b>
                    </div>
                  ) : (
                    <div>
                      <div className={yearLoading ? "col s10" : "col s12"}>
                        <select
                          // required
                          name="company"
                          id="year"
                          className="browser-default validate"
                          onChange={onChangeHandler}
                          value={year}
                          disabled={yearLoading}
                        >
                          <option value="" key="">
                            Choose year
                          </option>
                          {yearData && yearData.years
                            ? yearData.years.map((year) => (
                                <option value={year} key={year}>
                                  {year}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      {yearLoading && (
                        <div className="col s2" style={{ marginTop: 10 }}>
                          <div className="small-loader"></div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* <div style={{paddingTop:10, paddingLeft:"50%"}}><b>Or</b></div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>Choose your own</label>
                    <input
                      type="file"
                      name="file"
                      accept=".pdf,.txt "
                      onChange={onFileChangeHandler}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    />
                  </div> */}

                  <div className="blue-text">
                    {uploadLoading ? (
                      <div
                        className="col s12 l12 m12 center center-align"
                        style={{ marginTop: 30 }}
                      >
                        <p>Loading distress results...</p>
                        <div className="preloader-wrapper active">
                          <div className="spinner-layer spinner-green-only">
                            <div className="circle-clipper left">
                              <div className="circle"></div>
                            </div>
                            <div className="gap-patch">
                              <div className="circle"></div>
                            </div>
                            <div className="circle-clipper right">
                              <div className="circle"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="red-text">
                    {uploadError ? (
                      <p>Sorry! Unpredictable output or, pasing error</p>
                    ) : null}
                  </div>
                </div>
              ) : id == "stock pulse" ? (
                <div>
                  <label>Select Type</label>
                  <select
                    required
                    name="stock_type"
                    id="stock_type"
                    className="browser-default validate"
                    onChange={onChangeHandler}
                  >
                    <option value="" key="" defaultValue="">
                      Choose type
                    </option>
                    <option value="market">Market</option>
                    <option value="sector">Sector</option>
                    <option value="company">Company</option>
                    <option value="broker">Broker</option>
                  </select>
                  <label>
                    {state.stock_type == "sector"
                      ? "Select Sector"
                      : state.stock_type == "company"
                        ? "Select Company"
                        : state.stock_type == "broker"
                          ? "Select Broker"
                          : null}
                  </label>
                  {state.stock_type == "sector" ? (
                    <Select
                      value={selectedOptions}
                      onChange={HandleChange}
                      options={sector}
                      placeholder="Select sector..."
                    />
                  ) : state.stock_type == "company" ? (
                    <>
                      <Select
                        value={selectedOptions}
                        onChange={HandleChange}
                        options={company}
                        placeholder="Select company..."
                      />
                      <p>
                        <label>Select secondary company</label>
                        <Select
                          isMulti={true}
                          value={selectedMulOptions}
                          onChange={HandleMulSelChange}
                          options={company}
                          placeholder="Select secondary companies..."
                        />
                      </p>
                    </>
                  ) : state.stock_type == "broker" ? (
                    <Select
                      value={selectedOptions}
                      onChange={HandleChange}
                      options={brokers}
                      placeholder="Select broker..."
                    />
                  ) : null}
                  {/* <label>Select Broker</label>
                  <select
                    required
                    name="broker"
                    id="broker"
                    className="browser-default validate"
                    onChange={onChangeHandler}
                  >
                    <option value="" key="">
                      Choose Broker
                    </option>
                    <option value="null">All</option>
                    {broker && broker.data
                      ? broker.data.map((broker) => (
                          <option value={broker.id} key={broker.id}>
                            {broker.name}
                          </option>
                        ))
                      : null}
                  </select> */}
                  {/* <div style={{ display: "flex", alignItems: "baseline" }}>
                    <label>Novelty: (between 0.1 and 1.0)</label>
                    <input
                      type="number"
                      id="novelty"
                      name="novelty"
                      min="0.1"
                      max="1.0"
                      step="0.1"
                      onChange={onChangeHandler}
                      style={{ width: "50%" }}
                    />
                  </div> */}
                  <div className="blue-text">
                    {stockLoading ? (
                      <div
                        className="col s12 l12 m12 center center-align"
                        style={{ marginTop: 30 }}
                      >
                        <div className="preloader-wrapper active">
                          <div className="spinner-layer spinner-green-only">
                            <div className="circle-clipper left">
                              <div className="circle"></div>
                            </div>
                            <div className="gap-patch">
                              <div className="circle"></div>
                            </div>
                            <div className="circle-clipper right">
                              <div className="circle"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="red-text">
                    {stockError ? <p>Sorry! internal server error</p> : null}
                  </div>
                </div>
              ) : id == "news impact" ||
                id == "announcement" ||
                id == "concall" ? (
                <div className="input-field left col s12">
                  <Select
                    value={selectedOptions}
                    onChange={HandleChange}
                    options={
                      id === "news impact"
                        ? company
                            .filter((f) => companyNameMap[f.value])
                            .sort((a, b) => (a.label > b.label ? 1 : -1))
                        : company
                    }
                    placeholder="Select company..."
                  />
                  {id == "concall" && (
                    <div>
                      <p>
                        <label>Select secondary company</label>
                        <Select
                          isMulti={true}
                          value={selectedMulOptions}
                          onChange={HandleMulSelChange}
                          options={company}
                          placeholder="Select secondary companies..."
                        />
                      </p>
                      {/* <div style={{paddingTop:10, paddingLeft:"50%"}}><b>Or</b></div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>Choose your own</label>
                      <input
                        type="file"
                        name="file"
                        accept=".pdf,.txt "
                        onChange={onConcollFileChangeHandler}
                        style={{ marginTop: 20, marginBottom: 20 }}
                      />
                    </div> */}
                    </div>
                  )}
                </div>
              ) : id == "sector meter" ? (
                <div
                  className="input-field left col s12"
                  // style={{ backgroundColor: "#fff", height: 50 }}
                >
                  <Select
                    value={selectedOptions}
                    onChange={HandleChange}
                    options={sector.filter((f) => f.value == 151)}
                    placeholder="Select sector..."
                  />
                </div>
              ) : null}
              {id == "distress predictor" ? null : (
                <div>
                  {/* <div>
                    <div className="right col s12 m12 l12">
                      <input
                        type="text"
                        className="datepicker validate "
                        id="startDate"
                        placeholder="Start Date"
                        defaultValue={state.startDate}
                        disabled
                        onSelect={onChangeHandler}
                      />
                    </div>
                    <div className="right col s12 m12 l12">
                      <input
                        type="text"
                        className="datepicker validate "
                        // id={id == "news impact" ? "endDate" : id=="sector meter" ? "smEndDate" : null}
                        id="endDate"
                        placeholder="End Date"
                        defaultValue={state.endDate}
                        disabled
                        onSelect={onChangeHandler}
                      />
                    </div>
                  </div> */}
                  {id == "news impact" || id == "sector meter" ? (
                    <div
                      className="col s12 m12 l12"
                      style={{
                        height: 20,
                        marginLeft: "6%",
                      }}
                    >
                      <p
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label style={{ marginRight: 4 }}>
                          <input
                            id="frequency"
                            type="radio"
                            className="with-gap"
                            name="frequency"
                            defaultChecked={state.frequency === "daily"}
                            onChange={onChangeHandler}
                            value="daily"
                            required
                          />
                          <span
                            style={{
                              color: "#000",
                              // fontFamily: "Roboto",
                              // fontSize: 20,
                            }}
                          >
                            Daily
                          </span>
                        </label>
                        <label style={{ marginRight: 2 }}>
                          <input
                            id="frequency"
                            type="radio"
                            className="with-gap"
                            name="frequency"
                            defaultChecked={state.frequency === "weekly"}
                            onChange={onChangeHandler}
                            value="weekly"
                            required
                          />
                          <span
                            style={{
                              color: "#000",
                              // fontFamily: "Roboto",
                              // fontSize: 20,
                            }}
                          >
                            Weekly
                          </span>
                        </label>
                        <label style={{ marginRight: 0 }}>
                          <input
                            id="frequency"
                            type="radio"
                            className="with-gap"
                            name="frequency"
                            // style={{backgroundColor:"#23431b"}}
                            defaultChecked={state.frequency === "monthly"}
                            onChange={onChangeHandler}
                            value="monthly"
                            required
                          />
                          <span
                            style={{
                              color: "#000",
                              // fontFamily: "Roboto",
                              // fontSize: 20,
                            }}
                          >
                            Monthly
                          </span>
                        </label>
                      </p>
                    </div>
                  ) : id === "nl-download" || id === "nl-dashboard" ? (
                    <div className="row">
                      <div className="col s12 m12 l12">
                        {id === "nl-download" ? (
                          <>
                            <label>Template</label>

                            <select
                              className="browser-default"
                              id="nlTemplate"
                              value={state?.nlTemplate}
                              onChange={onChangeHandler}
                            >
                              <option disabled selected value={null}>
                                Select
                              </option>
                              {nlTemplates
                                ?.filter((tem) => tem.companyType !== "life")
                                ?.map((template) => (
                                  <option value={template.id} key={template.id}>
                                    {template.label}
                                  </option>
                                ))}
                            </select>

                            <p style={{ marginTop: 10, marginBottom: 0 }}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={state.allNlCompany}
                                  onChange={changeAllNl}
                                />
                                <span>Select all companies</span>
                              </label>
                            </p>
                            <label>Or, Select from list</label>
                            <Select
                              value={selectedOptions}
                              onChange={HandleChange}
                              options={company?.filter((f) =>
                                getCompanyListForNLReport(f, sector),
                              )}
                              placeholder="Select Company..."
                              isDisabled={state.allNlCompany}
                            />
                          </>
                        ) : (
                          <Select
                            value={selectedOptions}
                            onChange={HandleChange}
                            options={company?.filter((f) =>
                              getCompanyListForNLReport(f, sector),
                            )}
                            placeholder="Select Company..."
                          />
                        )}
                      </div>
                      <div className="col s12 m12 l12">
                        <label>Year-Quarter</label>
                        <select
                          className="browser-default"
                          id="nl-id"
                          value={state["nl-id"] ? state["nl-id"] : null}
                          onChange={onChangeHandler}
                        >
                          <option disabled selected value={null}>
                            Select
                          </option>
                          {year_quarter
                            ?.filter((yq) => yq.type === NL_TYPE_GIC)
                            ?.map((d, i) => (
                              <option value={d.fyq} key={d.id}>
                                {d.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  ) : id === "nl-download-life" ? (
                    <div className="row">
                      <div className="col s12 m12 l12">
                        <label>Template</label>

                        <select
                          className="browser-default"
                          id="nlTemplate"
                          value={state?.nlTemplate}
                          onChange={onChangeHandler}
                        >
                          <option disabled selected value={null}>
                            Select
                          </option>
                          {nlTemplates
                            ?.filter((tem) => tem.companyType === "life")
                            ?.map((template) => (
                              <option value={template.id} key={template.id}>
                                {template.label}
                              </option>
                            ))}
                        </select>

                        {/* <p style={{ marginTop: 10, marginBottom: 0 }}>
                          <label>
                            <input
                              type="checkbox"
                              checked={state.allNlCompany}
                              onChange={changeAllNl}
                            />
                            <span>Select all companies</span>
                          </label>
                        </p>
                        <label>Or, Select from list</label>
                        <Select
                          value={selectedOptions}
                          onChange={HandleChange}
                          options={company?.filter((f) =>
                            getCompanyListForNLReport(f, sector, "life"),
                          )}
                          placeholder="Select Company..."
                          isDisabled={state.allNlCompany}
                        /> */}
                      </div>
                      <div className="col s12 m12 l12">
                        <label>Year-Quarter</label>
                        <select
                          className="browser-default"
                          id="nl-id"
                          value={state["nl-id"] ? state["nl-id"] : null}
                          onChange={onChangeHandler}
                        >
                          <option disabled selected value={null}>
                            Select
                          </option>
                          {year_quarter
                            ?.filter((yq) => yq.type === NL_TYPE_LIFE)
                            ?.map((d, i) => (
                              <option value={d.fyq} key={d.id}>
                                {d.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="col s12 l12 m12 center center-align"
                    style={{ marginTop: 30, padding: 30 }}
                  >
                    {annLoading ||
                    nisLoading ||
                    smLoading ||
                    concallLoading ||
                    auditLoading ||
                    nlDataLoading ||
                    downloadNLReportLoading ? (
                      <div className="preloader-wrapper active">
                        <div className="spinner-layer spinner-green-only">
                          <div className="circle-clipper left">
                            <div className="circle"></div>
                          </div>
                          <div className="gap-patch">
                            <div className="circle"></div>
                          </div>
                          <div className="circle-clipper right">
                            <div className="circle"></div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="red-text">
                    {annError ||
                    nisError ||
                    smError ||
                    concallError ||
                    nlDataError ||
                    auditError ? (
                      <p>Sorry! internal server error</p>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="red-text center"></div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        <button
          type="submit"
          form="dashboard"
          className="waves-effect waves btn-flat"
          disabled={
            annLoading ||
            uploadLoading ||
            stockLoading ||
            nisLoading ||
            smLoading ||
            auditLoading
          }
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default DashboardModal;
