import React, { Component } from "react";
import { Chart } from "react-google-charts";
const MAP_API_KEY = "AIzaSyBBsDBKK5FaGjmtJRac7_XmUrN8RLkuYFc";

export class GeoChart extends Component {
  state = {
    data: [
      ["City", "Population", "Area"],
      ["Delhi", 2761477, 1285.31],
      ["west bengal", 1324110, 181.76],
      ["uttar pradesh", 959574, 117.27],
      ["rajasthan", 959574, 117.27],
      ["andhra pradesh", 959574, 117.27],
      ["kerala", 959574, 117.27],
    ],
    region: "IN",
    map: false,
  };

  componentDidMount = () => {
    // M.updateTextFields();
  };

  ChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  SwitchChangeHandler = (e) => {
    this.setState({ [e.target.id]: !this.state.map });
  };
  render() {
    const { width, height, ChartSelect, displayMode, resolution, region } =
      this.props;

    let { data } = this.props;

    let newData = data?.map((row) => {
      console.log("ROW", typeof row[0]);
      if (typeof row[0] === "string" && row[0].toLowerCase() === "odisha") {
        return ["Orissa"].concat(row.slice(1));
      } else if (
        typeof row[0] === "string" &&
        row[0].toLowerCase() === "govt. of nct of delhi"
      ) {
        return ["Delhi"].concat(row.slice(1));
      } else {
        return row;
      }
    });

    return (
      <div className="col s12">
        <Chart
          width={width}
          height={height}
          chartType="GeoChart"
          data={newData || this.state.data}
          options={{
            displayMode: displayMode || "regions",
            resolution: resolution || "provinces",
            region: region || "IN",
          }}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                //  this.props.ChartClick({ chartWrapper })
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 0) return;
                const region = newData[selection[0].row + 1];
                // console.log("Selected : " + region);
                if (ChartSelect) ChartSelect(region, newData[0]);
              },
            },
          ]}
          // Note: you will need to get a mapsApiKey for your project.
          // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
          mapsApiKey={MAP_API_KEY}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}

export default GeoChart;
