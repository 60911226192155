import {
  EXCEL_GENERATE_ERROR,
  EXCEL_GENERATE_SUCCESS,
  GET_GDP_BY_TYPE_ERROR,
  GET_GDP_BY_TYPE_LOADING,
  GET_GDP_BY_TYPE_SUCCESS,
  GET_GDP_COMPANY_ERROR,
  GET_GDP_COMPANY_LOADING,
  GET_GDP_COMPANY_SUCCESS,
  GET_GDP_MONTHLY_ERROR,
  GET_GDP_MONTHLY_LOADING,
  GET_GDP_MONTHLY_SUCCESS,
  GET_GDP_SEGMENTED_ERROR,
  GET_GDP_SEGMENTED_LOADING,
  GET_GDP_SEGMENTED_SUCCESS,
  GET_GDP_YEARLY_LOADING,
  GET_GDP_YEARLY_SUCCESS,
  GET_NL_PDF_ERROR,
  GET_NL_PDF_LOADING,
  GET_NL_PDF_SUCCESS,
  GET_NL_REPORT_DATA_ERROR,
  GET_NL_REPORT_DATA_LOADING,
  GET_NL_REPORT_DATA_SUCCESS,
  GET_NL_REPORT_LIST_ERROR,
  GET_NL_REPORT_LIST_LOADING,
  GET_NL_REPORT_LIST_SUCCESS,
  GET_NL_SEGS_SUCCESS,
  GET_NL_STAT_YEARS_SUCCESS,
  GET_NL_VALIDATION_ISSUES_LOADING,
  GET_NL_VALIDATION_ISSUES_SUCCESS,
  GET_PORTFOLIO_MIX_ERROR,
  GET_PORTFOLIO_MIX_LOADING,
  GET_PORTFOLIO_MIX_SUCCESS,
  GET_STATE_POP_SUCCESS,
  MARK_NL_ISSUES_ERROR,
  MARK_NL_ISSUES_LOADING,
  MARK_NL_ISSUES_SUCCESS,
  RUN_NL_VALIDATION_ERROR,
  RUN_NL_VALIDATION_LOADING,
  RUN_NL_VALIDATION_SUCCESS,
  SET_NL_REDUCER,
} from "../constants/nlConstants";

const initState = {
  downloadNLReportLoading: false,
  downloadNLReportSuccess: false,
  downloadNLReportError: false,
  downloadNLReport: null,
  downloadNLReportMsg: null,

  nlDataLoading: false,
  nlDataSuccess: false,
  nlDataError: false,
  nlData: null,
  nlDataMsg: null,

  nlErrorsDataLoading: false,
  nlErrorsDataSuccess: false,
  nlErrorsDataError: false,
  nlErrorsData: null,
  nlErrorsDataMsg: null,

  nlYearQuarterAvailableLoading: false,
  nlYearQuarterAvailableSuccess: false,
  nlYearQuarterAvailableError: false,
  nlYearQuarterAvailable: null,
  nlYearQuarterAvailableMsg: null,

  nlTemplatesLoading: false,
  nlTemplatesSuccess: false,
  nlTemplatesError: false,
  nlTemplates: null,
  nlTemplatesMsg: null,

  nlListLoading: false,
  nlListSuccess: false,
  nlListError: false,
  nlList: null,

  missingNLReportLoading: false,
  missingNLReportSuccess: false,
  missingNLReportError: false,
  missingNLReport: null,

  nlReportDataLoading: false,
  nlReportDataSucc: false,
  nlReportDataErr: false,
  nlReportData: null,
  nlReportsLoading: false,
  nlReportsSucc: false,
  nlReportsErr: false,
  nlReports: null,

  gdpYearlyLoading: false,
  gdpYearlySucc: false,
  gdpYearlyErr: false,
  gdpYearly: null,

  gdpMonthlyLoading: false,
  gdpMonthlySucc: false,
  gdpMonthlyErr: false,
  gdpMonthly: null,

  gdpSegmentedLoading: false,
  gdpSegmentedSucc: false,
  gdpSegmentedErr: false,
  gdpSegmented: null,

  gdpCompanyLoading: false,
  gdpCompanySucc: false,
  gdpCompanyErr: false,
  gdpCompany: null,

  nlSegs: [],
  nlStatYears: [],

  gdpByTypeLoading: false,
  gdpByTypeSucc: false,
  gdpByTypeErr: false,
  gdpByType: null,

  gdpByTypeLoading: false,
  gdpByTypeSucc: false,
  gdpByTypeErr: false,
  gdpByType: null,

  portfolioMixLoading: false,
  portfolioMixSucc: false,
  portfolioMixErr: false,
  portfolioMix: null,

  validationIssuesLoading: false,
  validationIssues: [],

  markNlIssueLoading: false,
  markNlIssueSuccess: false,
  markNlIssueError: false,

  validateLoading: false,
  validateSuccess: false,
  validateError: false,

  excelGenerateSuccess: false,
  excelGenerateError: false,

  nlPdfLoading: false,
  nlPdfError: false,
  message: null,
  states: null,
};

const NLReducer = (state = initState, action) => {
  switch (action.type) {
    case "DOWNLOAD_NL_REPORT_LOADING":
      return {
        ...state,
        downloadNLReportLoading: true,
        downloadNLReportSuccess: false,
        downloadNLReportError: false,
        downloadNLReport: null,
        downloadNLReportMsg: null,
      };
    case "DOWNLOAD_NL_REPORT_SUCCESS":
      return {
        ...state,
        downloadNLReportLoading: false,
        downloadNLReportSuccess: true,
        downloadNLReportError: false,
        downloadNLReport: action.data,
        downloadNLReportMsg: action.message,
      };
    case "DOWNLOAD_NL_REPORT_ERROR":
      return {
        ...state,
        downloadNLReportLoading: false,
        downloadNLReportSuccess: false,
        downloadNLReportError: true,
        downloadNLReport: null,
        downloadNLReportMsg: action?.message,
      };

    case "NL_DATA_LOADING":
      return {
        ...state,
        nlDataLoading: true,
        nlDataSuccess: false,
        nlDataError: false,
        nlData: null,
        nlDataMsg: null,
      };
    case "NL_DATA_SUCCESS":
      return {
        ...state,
        nlDataLoading: false,
        nlDataSuccess: true,
        nlDataError: false,
        nlData: action?.payload?.data,
        nlDataMsg: action?.payload.message,
      };
    case "NL_DATA_ERROR":
      return {
        ...state,
        nlDataLoading: false,
        nlDataSuccess: false,
        nlDataError: true,
        nlData: null,
        nlDataMsg: action.message,
      };

    case "NL_ERRORS_LOADING":
      return {
        ...state,
        nlErrorsDataLoading: true,
        nlErrorsDataSuccess: false,
        nlErrorsDataError: false,
        nlErrorsData: null,
        nlErrorsDataMsg: null,
      };
    case "NL_ERRORS_SUCCESS":
      return {
        ...state,
        nlErrorsDataLoading: false,
        nlErrorsDataSuccess: true,
        nlErrorsDataError: false,
        nlErrorsData: action?.payload?.data,
        nlErrorsDataMsg: action?.payload.message,
      };
    case "NL_ERRORS_ERROR":
      return {
        ...state,
        nlErrorsDataLoading: false,
        nlErrorsDataSuccess: false,
        nlErrorsDataError: true,
        nlErrorsData: null,
        nlErrorsDataMsg: action.message,
      };

    case "AVAILABLE_NL_YEAR_QUARTER_LOADING":
      return {
        ...state,
        nlYearQuarterAvailableLoading: true,
        nlYearQuarterAvailableSuccess: false,
        nlYearQuarterAvailableError: false,
        nlYearQuarterAvailable: null,
        nlYearQuarterAvailableMsg: null,
      };
    case "AVAILABLE_NL_YEAR_QUARTER_SUCCESS":
      return {
        ...state,
        nlYearQuarterAvailableLoading: false,
        nlYearQuarterAvailableSuccess: true,
        nlYearQuarterAvailableError: false,
        nlYearQuarterAvailable: action?.payload?.data,
        nlYearQuarterAvailableMsg: action?.payload.message,
      };
    case "AVAILABLE_NL_YEAR_QUARTER_ERROR":
      return {
        ...state,
        nlYearQuarterAvailableLoading: false,
        nlYearQuarterAvailableSuccess: false,
        nlYearQuarterAvailableError: true,
        nlYearQuarterAvailable: null,
        nlYearQuarterAvailableMsg: action.message,
      };
    case "NL_TEMPLATES_LOADING":
      return {
        ...state,
        nlTemplatesLoading: true,
        nlTemplatesSuccess: false,
        nlTemplatesError: false,
        nlTemplates: null,
        nlTemplatesMsg: null,
      };
    case "NL_TEMPLATES_SUCCESS":
      return {
        ...state,
        nlTemplatesLoading: false,
        nlTemplatesSuccess: true,
        nlTemplatesError: false,
        nlTemplates: action?.payload?.data,
        nlTemplatesMsg: action?.payload.message,
      };
    case "NL_TEMPLATES_ERROR":
      return {
        ...state,
        nlTemplatesLoading: false,
        nlTemplatesSuccess: false,
        nlTemplatesError: true,
        nlTemplates: null,
        nlTemplatesMsg: action.message,
      };
    case "NL_LIST_LOADING":
      return {
        ...state,
        nlListLoading: true,
        nlListSuccess: false,
        nlListError: false,
        nlList: null,
      };
    case "NL_LIST_SUCCESS":
      return {
        ...state,
        nlListLoading: false,
        nlListSuccess: true,
        nlListError: false,
        nlList: action.payload,
      };
    case "NL_LIST_ERROR":
      return {
        ...state,
        nlListLoading: false,
        nlListSuccess: false,
        nlListError: true,
        nlList: null,
      };
    case "MISSING_NL_REPORT_LOADING":
      return {
        ...state,
        missingNLReportLoading: true,
        missingNLReportSuccess: false,
        missingNLReportError: false,
        missingNLReport: null,
      };
    case "MISSING_NL_REPORT_SUCCESS":
      return {
        ...state,
        missingNLReportLoading: false,
        missingNLReportSuccess: true,
        missingNLReportError: false,
        missingNLReport: action.payload,
      };
    case "MISSING_NL_REPORT_ERROR":
      return {
        ...state,
        missingNLReportLoading: false,
        missingNLReportSuccess: false,
        missingNLReportError: true,
        missingNLReport: null,
      };

    case GET_NL_REPORT_LIST_SUCCESS:
      return {
        ...state,
        nlReportsLoading: false,
        nlReportsSucc: true,
        nlReportsErr: false,
        nlReports: action.payload,
      };
    case GET_NL_REPORT_LIST_ERROR:
      return {
        ...state,
        nlReportsLoading: false,
        nlReportsSucc: false,
        nlReportsErr: true,
      };
    case GET_NL_REPORT_LIST_LOADING:
      return {
        ...state,
        nlReportsLoading: true,
        nlReportsSucc: false,
        nlReportsErr: false,
      };
    case GET_NL_REPORT_DATA_SUCCESS:
      return {
        ...state,
        nlReportDataLoading: false,
        nlReportDataSucc: true,
        nlReportDataErr: false,
        nlReportData: action.payload,
      };
    case GET_NL_REPORT_DATA_ERROR:
      return {
        ...state,
        nlReportDataLoading: false,
        nlReportDataSucc: false,
        nlReportDataErr: true,
      };
    case GET_NL_REPORT_DATA_LOADING:
      return {
        ...state,
        nlReportDataLoading: true,
        nlReportDataSucc: false,
        nlReportDataErr: false,
        nlReportData: null,
      };

    case GET_GDP_YEARLY_SUCCESS:
      return {
        ...state,
        gdpYearlyLoading: false,
        gdpYearlySucc: true,
        gdpYearlyErr: false,
        gdpYearly: action.payload,
      };
    case GET_NL_REPORT_DATA_ERROR:
      return {
        ...state,
        gdpYearlyLoading: false,
        gdpYearlySucc: false,
        gdpYearlyErr: true,
      };
    case GET_GDP_YEARLY_LOADING:
      return {
        ...state,
        gdpYearlyLoading: true,
        gdpYearlySucc: false,
        gdpYearlyErr: false,
        gdpYearly: null,
      };

    case GET_GDP_MONTHLY_SUCCESS:
      return {
        ...state,
        gdpMonthlyLoading: false,
        gdpMonthlySucc: true,
        gdpMonthlyErr: false,
        gdpMonthly: action.payload,
      };
    case GET_GDP_MONTHLY_ERROR:
      return {
        ...state,
        gdpMonthlyLoading: false,
        gdpMonthlySucc: false,
        gdpMonthlyErr: true,
      };
    case GET_GDP_MONTHLY_LOADING:
      return {
        ...state,
        gdpMonthlyLoading: true,
        gdpMonthlySucc: false,
        gdpMonthlyErr: false,
        gdpMonthly: null,
      };

    case GET_GDP_SEGMENTED_SUCCESS:
      return {
        ...state,
        gdpSegmentedLoading: false,
        gdpSegmentedSucc: true,
        gdpSegmentedErr: false,
        gdpSegmented: action.payload,
      };
    case GET_GDP_SEGMENTED_ERROR:
      return {
        ...state,
        gdpSegmentedLoading: false,
        gdpSegmentedSucc: false,
        gdpSegmentedErr: true,
      };
    case GET_GDP_SEGMENTED_LOADING:
      return {
        ...state,
        gdpSegmentedLoading: true,
        gdpSegmentedSucc: false,
        gdpSegmentedErr: false,
        gdpSegmented: null,
      };

    case GET_GDP_COMPANY_SUCCESS:
      return {
        ...state,
        gdpCompanyLoading: false,
        gdpCompanySucc: true,
        gdpCompanyErr: false,
        gdpCompany: action.payload,
      };
    case GET_GDP_COMPANY_ERROR:
      return {
        ...state,
        gdpCompanyLoading: false,
        gdpCompanySucc: false,
        gdpCompanyErr: true,
      };
    case GET_GDP_COMPANY_LOADING:
      return {
        ...state,
        gdpCompanyLoading: true,
        gdpCompanySucc: false,
        gdpCompanyErr: false,
        gdpCompany: null,
      };

    case GET_GDP_BY_TYPE_LOADING:
      return {
        ...state,
        gdpByTypeLoading: true,
        gdpByTypeSucc: false,
        gdpByTypeErr: false,
        gdpByType: null,
      };
    case GET_GDP_BY_TYPE_SUCCESS:
      return {
        ...state,
        gdpByTypeLoading: false,
        gdpByTypeSucc: true,
        gdpByTypeErr: false,
        gdpByType: action.payload,
      };
    case GET_GDP_BY_TYPE_ERROR:
      return {
        ...state,
        gdpByTypeLoading: false,
        gdpByTypeSucc: false,
        gdpByTypeErr: true,
      };
    case GET_PORTFOLIO_MIX_LOADING:
      return {
        ...state,
        portfolioMixLoading: true,
        portfolioMixSucc: false,
        portfolioMixErr: false,
        portfolioMix: null,
      };
    case GET_PORTFOLIO_MIX_SUCCESS:
      return {
        ...state,
        portfolioMixLoading: false,
        portfolioMixSucc: true,
        portfolioMixErr: false,
        portfolioMix: action.payload,
      };
    case GET_PORTFOLIO_MIX_ERROR:
      return {
        ...state,
        portfolioMixLoading: false,
        portfolioMixSucc: false,
        portfolioMixErr: true,
      };
    case GET_NL_SEGS_SUCCESS:
      return {
        ...state,
        nlSegs: action.payload,
      };
    case GET_NL_STAT_YEARS_SUCCESS:
      return {
        ...state,
        nlStatYears: action.payload,
      };
    case GET_NL_VALIDATION_ISSUES_LOADING:
      return {
        ...state,
        validationIssuesLoading: true,
        validationIssues: [],
      };
    case GET_NL_VALIDATION_ISSUES_SUCCESS:
      return {
        ...state,
        validationIssuesLoading: false,
        validationIssues: action.payload,
      };
    case MARK_NL_ISSUES_LOADING:
      return {
        ...state,
        markNlIssueLoading: true,
        markNlIssueSuccess: false,
        markNlIssueError: false,
      };
    case MARK_NL_ISSUES_SUCCESS:
      return {
        ...state,
        markNlIssueLoading: false,
        markNlIssueSuccess: true,
        markNlIssueError: false,
      };
    case MARK_NL_ISSUES_ERROR:
      return {
        ...state,
        markNlIssueLoading: false,
        markNlIssueSuccess: false,
        markNlIssueError: true,
      };

    case RUN_NL_VALIDATION_LOADING:
      return {
        ...state,
        validateLoading: true,
        validateSuccess: false,
        validateError: false,
      };
    case RUN_NL_VALIDATION_SUCCESS:
      return {
        ...state,
        validateLoading: false,
        validateSuccess: true,
        validateError: false,
      };
    case RUN_NL_VALIDATION_ERROR:
      return {
        ...state,
        validateLoading: false,
        validateSuccess: false,
        validateError: true,
      };
    case EXCEL_GENERATE_SUCCESS:
      return {
        ...state,
        excelGenerateSuccess: true,
        excelGenerateError: false,
      };
    case EXCEL_GENERATE_ERROR:
      return {
        ...state,
        excelGenerateSuccess: false,
        excelGenerateError: true,
        errorMessage: action.message,
      };
    case GET_NL_PDF_LOADING:
      return {
        ...state,
        nlPdfLoading: true,
        nlPdfError: false,
      };
    case GET_NL_PDF_SUCCESS:
      return {
        ...state,
        nlPdfLoading: false,
        nlPdfError: false,
        message: action.message,
      };
    case GET_NL_PDF_ERROR:
      return {
        ...state,
        nlPdfLoading: false,
        nlPdfError: true,
        message: action.message,
      };

    case GET_STATE_POP_SUCCESS:
      return {
        ...state,
        states: action.payload?.data,
      };
    case SET_NL_REDUCER:
      return {
        ...state,
        ...(action.payload || {}),
      };
    case "INITIAL_STATE":
      return initState;
    case "HOME_CLICK":
      return initState;
    default:
      return state;
  }
};

export default NLReducer;
