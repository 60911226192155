import { Backdrop, Button, CircularProgress, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyListForLifeReport } from "../../../utils/nlHelper";
import {
  fetchLifeList,
  setLifeReducer,
  uploadLifePdf,
} from "../../../store/action/lifeAction";
import swal from "sweetalert";

function LifePdfUploader() {
  const dispatch = useDispatch();

  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [isPdf, setIsPdf] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [particularLife, setParticularLife] = useState(false);
  const [lifeId, setLifeId] = useState(false);
  const [file, setFile] = useState(null);
  const [contentPages, setContentPages] = useState(0);

  const accessToken = useSelector((state) => state.auth?.accessToken);
  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);
  const nlYearQuarter = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const life = useSelector((state) => state.life?.life);
  const uploadLifePdfLoading = useSelector(
    (state) => state.life?.uploadLifePdfLoading
  );
  const uploadLifePdfSuccess = useSelector(
    (state) => state.life?.uploadLifePdfSuccess
  );
  const uploadLifePdfError = useSelector(
    (state) => state.life?.uploadLifePdfError
  );
  const uploadLifePdfMessage = useSelector(
    (state) => state.life?.uploadLifePdfMessage
  );

  useEffect(() => {
    let years = [];
    let quarters = [];
    let fys = [];
    let qs = [];
    for (let i = 0; i < nlYearQuarter?.length; i++) {
      if (!fys.includes(nlYearQuarter[i].fyq.split("-")[0])) {
        years.push({
          value: nlYearQuarter[i].fyq.split("-")[0],
          label: nlYearQuarter[i].label.split(",")[0],
        });
      }
      if (!qs.includes(nlYearQuarter[i].fyq.split("-")[1])) {
        quarters.push({
          value: nlYearQuarter[i].fyq.split("-")[1],
          label: nlYearQuarter[i].label.split(",")[1],
        });
      }
      fys.push(nlYearQuarter[i].fyq.split("-")[0]);
      qs.push(nlYearQuarter[i].fyq.split("-")[1]);
    }
    setYears(years);
    setQuarters(quarters);
    if (years?.length > 0 && quarters?.length > 0) {
      setYear(years[years.length - 1].value);
      setQuarter(quarters[quarters.length - 1].value);
    }
    dispatch(fetchLifeList(accessToken));
  }, []);

  useEffect(() => {
    if (uploadLifePdfSuccess) {
      swal("Success", uploadLifePdfMessage, "success");
      dispatch(
        setLifeReducer({
          uploadLifePdfSuccess: false,
        })
      );
    }
    if (uploadLifePdfError) {
      swal("Oops!", uploadLifePdfMessage, "error");
      dispatch(
        setLifeReducer({
          uploadLifePdfError: false,
        })
      );
    }
  }, [uploadLifePdfSuccess, uploadLifePdfError]);

  const isInsuranceCompany = (company) => {
    return getCompanyListForLifeReport(company, sectors);
  };

  const upload = (e) => {
    e.preventDefault();
    dispatch(
      uploadLifePdf(
        companyId,
        year,
        quarter,
        contentPages,
        forceUpdate,
        isPdf,
        particularLife,
        lifeId,
        file,
        accessToken
      )
    );
  };

  return (
    <div className="row" style={{ padding: 10 }}>
      {uploadLifePdfLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="col s12" style={{ textAlign: "center" }}>
        <fieldset style={{ borderRadius: "7px" }}>
          <legend>Upload Life Pdf</legend>
          <form onSubmit={upload}>
            <div className="row">
              <div className="col s4">
                <label htmlFor="name">Company</label>
                <select
                  required
                  id="companyId"
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setCompanyId(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {companies?.filter(isInsuranceCompany)?.map((com, index) => (
                    <option value={com.id} key={index}>
                      {com.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col s4">
                <label htmlFor="name">Year</label>
                <select
                  required
                  id="year"
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {years?.map((year, index) => (
                    <option value={year.value} key={index}>
                      {year.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col s4">
                <label htmlFor="name">Quarter</label>
                <select
                  required
                  id="quarter"
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setQuarter(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {quarters?.map((quarter, index) => (
                    <option value={quarter.value} key={index}>
                      {quarter.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col s4" style={{ textAlign: "left" }}>
                <label htmlFor="content_pages">
                  Number of table of content pages
                </label>
                <input
                  id="content_pages"
                  name="content_pages"
                  type="number"
                  value={contentPages}
                  onChange={(e) => setContentPages(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12" style={{ textAlign: "left" }}>
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={isPdf}
                      onChange={() => {
                        setIsPdf(!isPdf);
                      }}
                    />
                    <span>PDF</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={forceUpdate}
                      onChange={() => {
                        setForceUpdate(!forceUpdate);
                      }}
                    />
                    <span>Force Update</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={particularLife}
                      onChange={() => {
                        setParticularLife(!particularLife);
                        setIsPdf(true);
                      }}
                    />
                    <span>Paricular Life</span>
                  </label>
                </p>
              </div>
            </div>
            {particularLife && (
              <div className="row">
                <div className="col s3" style={{ textAlign: "left" }}>
                  <select
                    required
                    id="life"
                    className="browser-default custom-select"
                    onChange={(e) => {
                      setLifeId(e.target.value);
                    }}
                  >
                    <option value={""}>Select</option>
                    {life
                      ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                      .map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.lable}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col s12" style={{ textAlign: "left" }}>
                <input
                  required
                  id="file"
                  type="file"
                  accept={isPdf ? ".pdf" : ".zip"}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12" style={{ textAlign: "left" }}>
                <Button variant="contained" type="submit">
                  Upload
                  <Icon>upload</Icon>
                </Button>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    </div>
  );
}

export default LifePdfUploader;
