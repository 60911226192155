import React, { Component } from "react";
import * as d3 from "d3";

class BarChartGeneric extends Component {
  componentDidMount() {
    this.drawChart();
  }
  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const {
      id,
      width,
      height,
      data,
      dimensions,
      measures,
      hideLegend = false,
      charLength,
      margins,
      noX = false,
      noY = false,
      noXLabel = false,
      noYLabel = false,
      noLabelWrapX = false,
      noLabelWrapY = false,
      fontSize = 10,
      labelInBar = false,
      barFontSize = "14px",
      xDomain = null,
      barNegPosLabel = false,
    } = this.props;

    var axisMargin = {
      top: margins?.top || 10,
      right: margins?.right ? margins?.right : labelInBar ? 20 : 10,
      bottom: margins?.bottom || 30,
      left: margins?.left || 30,
    };

    var wrap = function () {
      var maxLen = charLength || 50;
      var self = d3.select(this),
        textLength = self.node().getComputedTextLength(),
        text = self.text();
      while (textLength > maxLen && text.length > 0) {
        text = text.slice(0, -1);
        self.text(text + "...");
        textLength = self.node().getComputedTextLength();
      }
    };

    // console.log("======data", dimensions, data);
    if (!data || data.length === 0) return;
    let innerHeight = height - axisMargin.top - axisMargin.bottom;
    let innerWidth = width - axisMargin.right - axisMargin.left;

    if (
      !dimensions ||
      dimensions.length === 0 ||
      !measures ||
      measures.length === 0
    )
      return;

    const dims = [dimensions[0].text]; //only 1 dimension assumed
    const meas = measures.map((x) => x.text);
    // console.log("======data 2", dimensions, meas, data);

    // Scale X and Y
    var x = d3.scaleLinear().rangeRound([0, innerWidth]),
      y = d3.scaleBand().rangeRound([0, innerHeight]).paddingInner(0.1),
      z = d3.scaleOrdinal(d3.schemeCategory10),
      yGrp = d3.scaleBand().padding(0.05);

    // console.log("======data", x, y);

    x.domain(
      xDomain || [
        Math.min(
          0,
          d3.min(data, function (d) {
            return d3.min(meas, function (mea) {
              return d[mea];
            });
          }),
        ),
        d3.max(data, function (d) {
          return d3.max(meas, function (mea) {
            return d[mea];
          });
        }),
      ],
    ).nice();

    y.domain(
      data.map(function (d) {
        // console.log("Y:", d[dims[0]]);
        return d[dims[0]];
      }),
    );
    yGrp.domain(meas).rangeRound([0, y.bandwidth()]);
    z.domain(meas);

    d3.select("#" + id)
      .select("svg")
      .remove();

    var g = d3
      .select("#" + id)
      .append("svg")
      .attr("width", Number(width))
      .attr("height", Number(height))
      .attr("class", "bar-chart")
      .append("g")
      .attr(
        "transform",
        "translate(" + axisMargin.left + "," + axisMargin.top + ")",
      );

    g.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        return "translate(0, " + y(d[dims[0]]) + ")";
      })
      .selectAll("rect")
      .data(function (d) {
        return meas.map(function (mea) {
          return { key: mea, value: d[mea] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return Math.min(x(0), x(d.value));
      })
      .attr("y", function (d) {
        return yGrp(d.key);
      })
      .attr("width", function (d) {
        return Math.abs(x(d.value) - x(0));
      })
      .attr("height", yGrp.bandwidth())
      .attr("fill", function (d) {
        return z(d.key);
      })
      .append("title")
      .text((d) => {
        try {
          return d.key + "  " + d?.value?.toFixed(2);
        } catch (err) {
          return d.key + "  " + d?.value;
        }
      });

    if (labelInBar)
      g.append("g")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(0, " + y(d[dims[0]]) + ")";
        })
        .selectAll("text")
        .data(function (d) {
          return meas.map(function (mea) {
            return { key: mea, value: d[mea] };
          });
        })
        .enter()
        .append("text")
        .text(function (d) {
          try {
            if (String(d?.value).includes(".")) return d?.value?.toFixed(2);
            else return d?.value;
          } catch (err) {
            console.error("error:", d);
            return "";
          }
        })
        .attr("x", function (d) {
          return x(d.value) + 5;
        })
        .attr("y", function (d) {
          return yGrp(d.key) + yGrp.bandwidth() / 2;
        })
        .attr("font-family", "sans-serif")
        .attr("font-size", barFontSize)
        .attr("fill", function (d) {
          if (barNegPosLabel) {
            try {
              let num = Number(d.value);
              if (num < 0) {
                return "red";
              } else if (num == 0) {
                return "black";
              } else {
                return "green";
              }
            } catch (err) {
              console.log("ERROR, ", d);
              return "black";
            }
          } else return "black";
        })
        .attr("font-weight", "bold");
    // .attr("text-anchor", "middle");

    // .on('mouseover', tip.show).on('mouseout', tip.hide);

    if (!noX) {
      //Draw X Axis
      g.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + innerHeight + ")")
        .call(
          noXLabel
            ? d3.axisBottom(x).tickFormat("").tickSize(0)
            : d3.axisBottom(x),
        )
        .selectAll("text")
        .style("font-size", fontSize)
        .each(noLabelWrapX ? () => {} : wrap)
        .attr("y", 0)
        .attr("x", 9)
        .attr("dy", ".35em")
        .attr("transform", "rotate(90)")
        .style("text-anchor", "start");
    }

    if (!noY) {
      //Draw Y Axis
      g.append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + x(0) + ", 0)")
        .call(
          noYLabel
            ? d3.axisLeft(y).ticks(0).tickSize(0).tickFormat("")
            : d3.axisLeft(y),
        )
        .selectAll("text")
        .style("font-size", fontSize)
        .each(noLabelWrapY ? () => {} : wrap);
    }

    if (!hideLegend) {
      var legend = g
        .append("g")
        .attr("text-anchor", "end")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .selectAll("g")
        .data(meas)
        .enter()
        .append("g")
        .attr("transform", function (d, i) {
          return "translate(0," + i * 20 + ")";
        });

      legend
        .append("rect")
        .attr("x", width - 19)
        .attr("width", 19)
        .attr("height", 19)
        .attr("fill", z);

      legend
        .append("text")
        .attr("x", width - 24)
        .attr("y", 9.5)
        .attr("dy", "0.32em")
        .text(function (d) {
          return d;
        });
    }
  }

  render() {
    return <div id={this.props.id}></div>;
  }
}
export default BarChartGeneric;
