import React from "react";
import BarStackCombination from "./commonDashboards/BarStackCombination";

export default function SegmentClaims({ label, data, type }) {
  // console.log("COLS", cols);
  console.log("LOBS", data);
  const BAR_CHART_HEIGHT = 250;
  const BAR_CHART_WIDTH = 400;
  const STACK_CHART_HEIGHT = 800;
  const STACK_CHART_WIDTH = 220;
  return (
    <BarStackCombination
      barOneDetails={{
        id: "nic",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [
          {
            text: "nic",
          },
        ],
        label: "Net Incurred Claims",
        data: data["nic"],
      }}
      barTwoDetails={{
        id: "lossratio",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "value" }],
        label: "Loss ratio",
        data: data["loss-ratio"],
      }}
      stackBarDetails={{
        id: "seg-claims",
        width: STACK_CHART_HEIGHT,
        height: STACK_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "percentage" }],
        group: "segment",
        label: "Contribution of products",
        data: data["nic-seg"],
        gic: false,
      }}
      lineChartDetails={{
        id: "loss-ratio-trend",
        height: 230,
        width: 800,
        dimensions: [{ text: "fyq" }],
        measures: [{ text: "value" }],
        group: "company",
        label: "Loss ratio trend",
        data: data
          ? data["loss-ratio-trend"]
              ?.map((row) => {
                return { ...row, fyq: row.year + ", " + row.quarter };
              })
              ?.filter((row) => row.fyq !== "21_22, q4")
          : [],
      }}
      gic={false}
    />
  );
}
