import React from "react";

export const LoaderOne = (props) => {
  return (
    <div className="row center center-align">
        <div className="loader-new" style={props.height&&props.width?{
            ...props
        }:{
            height:50,
            width:50
        }
        }/>
    </div>
  );
};
