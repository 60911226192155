import moment from "moment";
import React from "react";
import { CrawlerModal } from "./CrawlerModal";
import { LoaderOne } from "./loader1";
import { LoaderTwo } from "./loader2";

export const CrawlerIssueAdmin = ({handleSelect, startCrawl, startAnalysis, newsStat, fetchNewsStat,apiStat,newsStatLoading, state, apiStatLoading, GetApiTracks}) => {
  return (
    <div className="row">
      <div className="col s12 m6 l6">
        <div className="row">
          <div className="col s3">
            <label>Time Span</label>
            <select 
            id="timeSpan"
            value={state.timeSpan}
            onChange={handleSelect}
            className="browser-default select-sp">
              <option value="sp" selected>Specific Time</option>
              <option value="1hr" >1 Hour</option>
              <option value="1d">1 Day</option>
              <option value="1w">1 Week</option>
              <option value="1m">1 Month</option>
              <option value="6m">6 Months</option>
              <option value="1y">1 Year</option>
              <option value="5y">5 Years</option>
            </select>
          </div>
          {
          state.timeSpan == "sp"&&
          <>
          <div className="col s4">
            <label>Start Time</label>
            <input value={state.crawlStartTime} type="datetime" id="crawlStartTime" name="birthday" />
          </div>
          <div className="col s4">
            <label>End Time</label>
            <input value={state.crawlEndTime} type="datetime" id="crawlEndTime" name="birthday" />
          </div>
          </>
          }
          <div className="col s1">
            {newsStatLoading?
            <LoaderOne height={10} width={10}/>
            :
            <button onClick={fetchNewsStat} className="btn-small btn-floating btn-circle-admin">
              <i className="material-icons">autorenew</i>
            </button>
            }
            
          </div>
        </div>
        <div className="row">
          <div className="col s12 m12 l12">
            <div className="card" style={{height:195}}>
              {newsStatLoading?
              <LoaderOne height={100} width={100} margin={20}/>
              :
              <div className="card-content">
                <div className="card-title">
                  News: {newsStat?newsStat.newsCount:"NA"}
                </div>
                <div className="row" style={{marginTop:15}}>
                  <div className="col s4">
                    <p>Company News</p>
                    <p>{newsStat?newsStat.nisCount:"NA"}</p>
                  </div>
                  <div className="col s4">
                    <p>Sector News</p>
                    <p>{newsStat?newsStat.smCount:"NA"}</p>
                  </div>
                  <div className="col s4">
                    <p>Stock News</p>
                    <p>{newsStat?newsStat.stockCount:"NA"}</p>
                  </div>
                </div>
                <div className="row" style={{marginBottom:0}}>
                  <button onClick={startCrawl} className="col s12 btn admin-button">
                    Crawl News
                  </button>
                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
      <div className="col s12 m6 l6" >
        <div className="row">
          <div className="col s12 m12 l12">
            <div className="card">
              <div className="card-content" style={{paddingBottom:5}}>
                <span className="card-title">News Analysis Action</span>
                <div className="row" style={{marginTop:40}}>
                  <div className="col s12 m4 l4">
                    <label>Job Type</label>
                    <select 
                    id="analysisType"
                    value={state.analysisType}
                    onChange={handleSelect}
                    className="browser-default">
                      <option value="NIS">NIS</option>
                      <option value="SP">Stock Pulse</option>
                      <option value="SM">Sector Meter</option>
                    </select>
                  </div>
                  <div className="col s12 m4 l4">
                    <label for="birthday">Start Date:</label>
                    <input onChange={handleSelect} value={state.analysisStartTime} type="datetime" id="analysisStartTime" />
                  </div>
                  <div className="col s12 m4 l4">
                    <label for="birthday">End Date:</label>
                    <input onChange={handleSelect} value={state.analysisEndTime} type="datetime" id="analysisEndTime"/>
                  </div>
                </div>
                <div className="row">
                  <button onClick={startAnalysis} className="col s12 btn admin-button" style={{marginTop:30}}>
                    Start Analysis
                  </button>
                </div>
              </div>
              {/* <div className="card-action">
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col s12 m12 m12">
        {
          apiStatLoading?
          <LoaderTwo/>:
          <div className="row">
            <div className="col s1 m1 l1">
              <button onClick={GetApiTracks} className="btn-small btn-floating btn-circle-admin-no-top">
                <i className="material-icons">autorenew</i>
              </button>
            </div>
            <div className="col s11 m11 l11">
              <table>
                <thead>
                  <th>API</th>
                  <th>STATUS</th>
                  <th>Time</th>
                  <th>Description</th>
                </thead>
                <tbody>{
                  apiStat.map(api =>
                    <tr>
                      <td>{api.route}</td>
                      <td>{api.status}</td>
                      <td>{api.time?moment(api.time).format('DD/MM/YYYY, HH:MM'):""}</td>
                      <td>{api.errorDescription}</td>
                    </tr> 
                  )
                  }
                </tbody>
              </table>
            </div>
        </div>
        }
      </div>
    </div>
  );
};
