import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

export default function FilterModal({
  open,
  setFilterModal,
  data,
  headCells,
  filters = {},
  orders = {},
  changeFilters,
}) {
  const styleOb = {
    position: "absolute",
    width: "100%",
    bgcolor: "background.paper",
    BoxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [currentData, setCurrentData] = useState([]);
  const [currentCol, setCurrentCol] = useState(null);
  const [filter, setFilter] = useState(filters || {});
  const theme = useTheme();

  const changeCol = (col) => {
    setCurrentCol(col?.id);
    let colData = data?.map((d) => {
      return d[col?.id];
    });
    let finalData = [];
    for (let i = 0; i < colData?.length; i++) {
      if (!finalData.includes(colData[i])) {
        finalData.push(colData[i]);
      }
    }
    if (orders && orders[col.id]) {
      finalData = finalData?.sort((a, b) => {
        return orders[col.id][a] - orders[col.id][b];
      });
    }
    setCurrentData(finalData);
  };

  const handleChange = (row) => {
    let temp = { ...filter };
    if (temp[currentCol]) {
      if (temp[currentCol][row]) {
        temp[currentCol][row] = !temp[currentCol][row];
      } else {
        temp[currentCol] = {
          ...temp[currentCol],
          [row]: true,
        };
      }
    } else {
      temp[currentCol] = {
        [row]: true,
      };
    }
    setFilter(temp);
  };

  const saveFilter = () => {
    changeFilters(filter);
    closeModal();
  };

  const closeModal = () => {
    setFilterModal(false);
  };

  const makeAllSelect = () => {
    let temp = { ...filter };
    temp[currentCol] = {};
    for (let i = 0; i < currentData?.length; i++) {
      temp[currentCol][currentData[i]] = false;
    }
    setFilter(temp);
  };

  const makeAllClear = () => {
    let temp = { ...filter };
    temp[currentCol] = {};

    for (let i = 0; i < currentData?.length; i++) {
      temp[currentCol][currentData[i]] = true;
    }
    setFilter(temp);
  };

  return (
    <Modal style={styleOb} open={open} onClose={setFilterModal}>
      <Box
        style={{
          ...styleOb,
          minWidth: 800,
          width: 800,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          minHeight: 500,
          padding: 20,
        }}
      >
        <Box className="row">
          <Box className="col s12">
            <h4>Add filters</h4>
          </Box>
          <Box className="col s12">
            <Box className="col s4">
              <List>
                {headCells?.map((headCell) => (
                  <ListItemButton
                    style={
                      currentCol == headCell.id
                        ? {
                            padding: 0,
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.primary.dark,
                          }
                        : { padding: 0 }
                    }
                    onClick={() => {
                      changeCol(headCell);
                    }}
                  >
                    <ListItem>
                      <ListItemText>{headCell.label}</ListItemText>
                    </ListItem>
                  </ListItemButton>
                ))}
              </List>
            </Box>
            <Box className="col s8">
              <Stack direction="row" spacing={2}>
                <Button onClick={makeAllSelect}>Select all</Button>
                <Button onClick={makeAllClear}>Clear All</Button>
              </Stack>
              <Box className="row" style={{ height: 400, overflowY: "scroll" }}>
                <FormGroup>
                  {currentData?.map((row) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            filter && filter[currentCol]
                              ? !filter[currentCol][row]
                              : true
                          }
                          onChange={() => {
                            handleChange(row);
                          }}
                        />
                      }
                      label={row}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="row right rightalign">
          <Stack direction="row-reverse" spacing={2}>
            <Button
              onClick={saveFilter}
              autoCapitalize={false}
              variant="contained"
            >
              Save
            </Button>
            <Button
              onClick={closeModal}
              autoCapitalize={false}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
