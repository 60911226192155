import moment from "moment";
import { ENTITY_STATUS } from "../config/constants";

export const getCompanyListForNLReport = (company, allSector, type) => {
  let insurace_sector = allSector?.find(
    (sec) =>
      sec?.label?.toLowerCase() === "insurance" ||
      sec?.name?.toLowerCase() === "insurance"
  );
  // console.log("INSURANCE SEC", insurace_sector);
  if (type) {
    if (
      (company.sector_id === insurace_sector?.value ||
        company.sector_id === insurace_sector?.id) &&
      company.insuranceType === type &&
      company.status === ENTITY_STATUS["active"]
    ) {
      return true;
    } else return false;
  } else {
    if (
      (company.sector_id === insurace_sector?.value ||
        company.sector_id === insurace_sector?.id) &&
      company.insuranceType !== "life" &&
      company.status === ENTITY_STATUS["active"]
    ) {
      return true;
    } else return false;
  }
};

export const getCompanyListForLifeReport = (company, allSector) => {
  let insurace_sector = allSector?.find(
    (sec) =>
      sec?.label?.toLowerCase() === "insurance" ||
      sec?.name?.toLowerCase() === "insurance"
  );
  // console.log("INSURANCE SEC", insurace_sector);
  if (
    (company.sector_id === insurace_sector?.value ||
      company.sector_id === insurace_sector?.id) &&
    company.insuranceType === "life" &&
    company.status === ENTITY_STATUS["active"]
  ) {
    return true;
  } else return false;
};

export const sortByQuarter = (a, b) => {
  if (a.year < b.year) {
    return 1;
  } else if (a.year === b.year && a.q < b.q) {
    return 1;
  } else {
    return -1;
  }
};

export const getQuarters = () => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let q = null;

  if (month <= 4) {
    q = month - 4 + 12;
  } else {
    q = month - 4;
  }
  let prevYear = year - 1;
  let nextYear = year + 1;
  let qCurr = null;
  let y1 = null;
  let q1 = null;
  let y2 = null;
  let q2 = null;
  let y3 = null;
  let q3 = null;
  let y4 = null;
  let q4 = null;

  if (q <= 3 && q >= 1) {
    qCurr = "q4";
    year = prevYear;
  } else if (q <= 6 && q >= 4) {
    qCurr = "q1";
  } else if (q <= 9 && q >= 7) {
    qCurr = "q2";
  } else {
    qCurr = "q3";
    year = prevYear;
  }

  if (qCurr == "q1") {
    y1 = year;
    q1 = "q1";
    y2 = year - 1;
    q2 = "q4";
    y3 = year - 1;
    q3 = "q3";
    y4 = year - 1;
    q4 = "q2";
  } else if (qCurr == "q2") {
    y1 = year;
    q1 = "q2";
    y2 = year;
    q2 = "q1";
    y3 = year - 1;
    q3 = "q4";
    y4 = year - 1;
    q4 = "q3";
  } else if (qCurr == "q3") {
    y1 = year;
    q1 = "q3";
    y2 = year;
    q2 = "q2";
    y3 = year;
    q3 = "q1";
    y4 = year - 1;
    q4 = "q4";
  } else {
    y1 = year;
    q1 = "q4";
    y2 = year;
    q2 = "q3";
    y3 = year;
    q3 = "q2";
    y4 = year;
    q4 = "q1";
  }

  let result = [
    {
      year: y1,
      q: q1,
    },
    {
      year: y2,
      q: q2,
    },
    {
      year: y3,
      q: q3,
    },
    {
      year: y4,
      q: q4,
    },
  ];

  return result
    .sort((a, b) => sortByQuarter(a, b))
    .sort((a, b) => sortByQuarter(a, b));
};

export const companyNameMap = {
  2929: "ICICI Lombard",
  2945: "New India",
  4587: "GIC",
  4631: "Bajaj Allianz",
  6185: "Reliance GIC",
  6186: "Tata AIG",
  6187: "HDFC ERGO",
  6188: "SBI",
  6221: "Acko ",
  6222: "Aditya Birla Health",
  6223: "Agriculture IC",
  6225: "Cholamandalam MS",
  6226: "ECGC Ltd.",
  6227: "Edelweiss ",
  6228: "Future Generali",
  6229: "Go Digit",
  6230: "IFFCO Tokio",
  6231: "Kotak Mahindra",
  6232: "Liberty",
  6233: "Magma HDI",
  6234: "Manipal Cigna",
  6235: "Niva Bupa",
  6236: "National IC",
  6237: "Navi",
  6238: "Raheja QBE",
  6239: "Care Health",
  6240: "Royal Sundaram",
  6241: "Shriram",
  6242: "Star Health",
  6243: "Oriental",
  6244: "United India",
  6245: "Universal Sompo",
};

export function getArrayOfArray(data, cols) {
  let new_first_row = [cols];
  let new_data = data
    .filter((d) => d.state !== "Total")
    .map((d) => {
      let temp = [];
      for (let i = 0; i < cols.length; i++) {
        temp.push(d[cols[i]]);
      }
      return temp;
    });
  let final_data = new_first_row.concat(new_data);
  return final_data;
}

export function getReportDataFiltered(
  segment,
  state,
  segementWiseData,
  capitaFlag,
  states
) {
  let new_data = [];
  let data = segementWiseData[segment];
  if (capitaFlag) {
    data = data?.map((d) => {
      let stateD = states?.find((state) => state.name === d.state);
      if (stateD) {
        return {
          ...d,
          revenue: Number(
            ((Number(d?.revenue) * 100000) / Number(stateD.population)).toFixed(
              2
            )
          ),
        };
      } else {
        return d;
      }
    });
    console.log("Data filtered", data);
  }
  new_data = data?.filter(
    (row) => row.state.toLowerCase().trim() === state.toLowerCase().trim()
  );
  new_data = new_data?.sort((a, b) => (a.revenue < b.revenue ? 1 : -1));

  // console.log("NEW DATA: ", new_data);
  return new_data;
}

export function getReportDataComFiltered(
  company,
  state,
  segementWiseData,
  aggData
) {
  let new_data = [];
  let segments = Object.keys(segementWiseData);

  if (company !== "Overall") {
    for (let i = 0; i < segments?.length; i++) {
      let seg = segments[i];
      if (seg === "Overall") {
        continue;
      }
      let companySeg = {};
      companySeg = segementWiseData[seg].find(
        (d) =>
          d.company === company &&
          d?.state?.toLowerCase() === state?.toLowerCase()
      );
      // console.log("COM:", company, "DATA:", companySeg);

      new_data.push({ ...companySeg, segment: seg });
    }
  } else {
    if (state === "Overall") {
      new_data = aggData.map((data) => {
        if (data[0] === "state") {
          return { state: "", revenue: 0.0 };
        } else {
          return { state: data[0], revenue: data[1] };
        }
      });
    } else {
      for (let i = 0; i < segments?.length; i++) {
        let seg = segments[i];
        let stateData = segementWiseData[seg].filter(
          (d) => d?.state?.toLowerCase() === state?.toLowerCase()
        );

        let total = 0;
        stateData?.map((sd) => {
          total += sd.revenue;
        });
        new_data.push({
          revenue: total,
          state: state,
          segment: seg,
        });
      }
    }
  }
  new_data = new_data?.sort((a, b) => (a.revenue < b.revenue ? 1 : -1));

  // console.log("NEW DATA: ", new_data);
  return new_data;
}

export function getReportDataSegment(segementWiseData, capitaFlag, statePops) {
  // console.log("SEGMENT: ", segment, "DATA", segementWiseData);
  let data = segementWiseData;
  if (capitaFlag) {
    data = data?.map((d) => {
      let stateD = statePops?.find((state) => state.name === d.state);
      if (stateD) {
        return {
          ...d,
          revenue: Number(
            ((Number(d?.revenue) * 100000) / Number(stateD.population)).toFixed(
              2
            )
          ),
        };
      } else {
        return d;
      }
    });
    // console.log("Data filtered", data);
  }

  segementWiseData?.sort((a, b) => (a.revenue < b.revenue ? 1 : -1));
  let new_data = [["state", "revenue"]];
  // console.log("DATA", data);
  let temp = data?.filter((d) => d.state.toLowerCase() !== "total");
  // console.log("DATA 1", temp);

  let states = [];
  let state_data = {};
  for (let i = 0; i < temp?.length; i++) {
    if (!states?.includes(temp[i]?.state)) {
      state_data[temp[i]?.state] = temp[i]?.revenue;
      states.push(temp[i]?.state);
    } else {
      state_data[temp[i]?.state] += temp[i]?.revenue;
    }
  }

  temp = Object.entries(state_data);
  // console.log("DATA 2", temp);
  new_data = new_data.concat(temp);
  console.log("DATA 3", new_data);

  return new_data;
}

export function getReportDataCompany(company, segementWiseData) {
  if (company === "Overall") {
    company = "Total";
  }

  let segments = Object.keys(segementWiseData);
  let newData = [["state", "revenue"]];

  let finalData = {};

  for (let i = 0; i < segments.length; i++) {
    let seg = segments[i];

    let comData = segementWiseData[seg]?.filter(
      (data) => data.company === company
    );

    for (let j = 0; j < comData.length; j++) {
      let d = comData[j];
      if (finalData[d.state]) {
        finalData[d.state] += comData[j].revenue;
      } else {
        finalData[d.state] = comData[j].revenue;
      }
    }
  }

  let temp = Object.entries(finalData).map(([state, revenue]) => {
    return [state, revenue];
  });

  newData = newData.concat(temp);

  // console.log("Final Data", finalData);

  return newData;
}

/**
 *
 * @param {string array} data
 * @returns unique arrray
 */

export function getUniqueList(data) {
  let newData = [];
  for (let i = 0; i < data.length; i++) {
    if (!newData.includes(data[i])) {
      newData.push(data[i]);
    }
  }

  return newData;
}

const monthOrder = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

/**
 *
 * @param {string array} months
 * @returns ordered onths
 */

export function orderMonths(data) {
  let newData = [];

  newData = data?.sort(function (a, b) {
    return monthOrder[a.toLowerCase()] - monthOrder[b.toLowerCase()];
  });

  return newData;
}

/**
 *
 * @param {string array} months
 * @returns date array
 */

export function monthToDates(data) {
  let newData = data?.map((d) => {
    let date = moment();
    date.set("month", monthOrder[d["Month"].toLowerCase()] - 1);
    date.set("date", 1);
    return {
      ...d,
      Month: date.format("DD-MM-YYYY"),
    };
  });

  return newData;
}

/**
 *
 * @param {array }
 * @param {number }
 * @returns top n keys
 */

export function getTopN(data, top, newMeas) {
  let arrayData = [];
  let newData = data?.map((d) => {
    arrayData = Object.entries(d).map(([company, revenue]) => {
      return { company, revenue };
    });
    arrayData = arrayData.filter((ob) => ob.company !== "Month");
    arrayData = arrayData.sort(function (a, b) {
      return b.revenue - a.revenue;
    });

    if (newMeas) {
      arrayData = arrayData.filter((ad) => newMeas.includes(ad.company));
    }

    arrayData = arrayData.slice(0, top).map((ad) => {
      return ad.company;
    });
    console.log("TOP DATA: ", arrayData);

    arrayData.push("Month");

    let ob = {};
    let allKeys = Object.keys(d);

    for (let i = 0; i < allKeys.length; i++) {
      ob[allKeys[i]] = d[allKeys[i]];
    }

    return ob;
  });

  // console.log("TOP DATA: ", newData, arrayData);

  return [newData, arrayData?.filter((data) => data !== "Month")];
}

export function yearQuarterRev(data) {
  if (!data || data?.length < 1) {
    return data;
  }
  let j = data?.length - 1;
  let newData = [];
  for (let i = 0; i < data?.length; i++, j--) {
    newData.push(data[j]);
  }
  return newData;
}

export const shapeData = (data) => {
  let keys = Object.keys(data);
  let result = [];
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    result.push({ ...data[key], rowName: { colOrder: -1, value: key }, id: i });
  }
  let finalResult = result.sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1));

  return finalResult;
};

export const getQuery = (search = "") => {
  if (search?.length > 0) {
    search = search.replaceAll("%20", "");
    // console.log("SEARCH", search);
    let finalOb = {};
    let query = search.slice(1);
    let parts = query.split("&");
    let key = "";
    let val = "";
    for (let i = 0; i < parts.length; i++) {
      key = parts[i].split("=")[0];
      val = parts[i].split("=")[1];
      // console.log("KEY: ", key, "val: ", val);
      finalOb[key] = val;
    }
    console.log("FINAL", finalOb);
    return finalOb;
  } else {
    return {};
  }
};

export const findPrevOrder = (order, data, colName) => {
  let prevOrder = -999;
  for (let i = 0; i < data.length; i++) {
    if (data[i][colName] < order && data[i][colName] > prevOrder) {
      prevOrder = data[i][colName];
    }
  }
  return prevOrder;
};

export const findNextOrder = (order, data, colName) => {
  let nextOrder = 999;
  for (let i = 0; i < data.length; i++) {
    if (data[i][colName] > order && data[i][colName] < nextOrder) {
      nextOrder = data[i][colName];
    }
  }
  return nextOrder;
};
