export const GET_NL_REPORT_LIST_SUCCESS = "GET_NL_REPORT_LIST_SUCCESS";
export const GET_NL_REPORT_LIST_ERROR = "GET_NL_REPORT_LIST_ERROR";
export const GET_NL_REPORT_LIST_LOADING = "GET_NL_REPORT_LIST_LOADING";
export const GET_NL_REPORT_DATA_SUCCESS = "GET_NL_REPORT_DATA_SUCCESS";
export const GET_NL_REPORT_DATA_ERROR = "GET_NL_REPORT_DATA_ERROR";
export const GET_NL_REPORT_DATA_LOADING = "GET_NL_REPORT_DATA_LOADING";

export const GET_GDP_YEARLY_SUCCESS = "GET_GDP_YEARLY_SUCCESS";
export const GET_GDP_YEARLY_ERROR = "GET_GDP_YEARLY_ERROR";
export const GET_GDP_YEARLY_LOADING = "GET_GDP_YEARLY_LOADING";

export const GET_GDP_MONTHLY_SUCCESS = "GET_GDP_MONTHLY_SUCCESS";
export const GET_GDP_MONTHLY_ERROR = "GET_GDP_MONTHLY_ERROR";
export const GET_GDP_MONTHLY_LOADING = "GET_GDP_MONTHLY_LOADING";

export const GET_GDP_SEGMENTED_SUCCESS = "GET_GDP_SEGMENTED_SUCCESS";
export const GET_GDP_SEGMENTED_ERROR = "GET_GDP_SEGMENTED_ERROR";
export const GET_GDP_SEGMENTED_LOADING = "GET_GDP_SEGMENTED_LOADING";

export const GET_GDP_COMPANY_SUCCESS = "GET_GDP_COMPANY_SUCCESS";
export const GET_GDP_COMPANY_ERROR = "GET_GDP_COMPANY_ERROR";
export const GET_GDP_COMPANY_LOADING = "GET_GDP_COMPANY_LOADING";

export const GET_NL_STAT_YEARS_SUCCESS = "GET_NL_STAT_YEARS_SUCCESS";
export const GET_NL_STAT_YEARS_ERROR = "GET_NL_STAT_YEARS_ERROR";
export const GET_NL_STAT_YEARS_LOADING = "GET_NL_STAT_YEARS_LOADING";

export const GET_NL_SEGS_SUCCESS = "GET_NL_SEGS_SUCCESS";
export const GET_NL_SEGS_ERROR = "GET_NL_SEGS_ERROR";
export const GET_NL_SEGS_LOADING = "GET_NL_SEGS_LOADING";

export const GET_GDP_BY_TYPE_LOADING = "GET_GDP_BY_TYPE_LOADING";
export const GET_GDP_BY_TYPE_SUCCESS = "GET_GDP_BY_TYPE_SUCCESS";
export const GET_GDP_BY_TYPE_ERROR = "GET_GDP_BY_TYPE_ERROR";

export const GET_PORTFOLIO_MIX_LOADING = "GET_PORTFOLIO_MIX_LOADING";
export const GET_PORTFOLIO_MIX_SUCCESS = "GET_PORTFOLIO_MIX_SUCCESS";
export const GET_PORTFOLIO_MIX_ERROR = "GET_PORTFOLIO_MIX_ERROR";

export const GET_NL_VALIDATION_ISSUES_LOADING =
  "GET_NL_VALIDATION_ISSUES_LOADING";
export const GET_NL_VALIDATION_ISSUES_SUCCESS =
  "GET_NL_VALIDATION_ISSUES_SUCCESS";
export const GET_NL_VALIDATION_ISSUES_ERROR = "GET_NL_VALIDATION_ISSUES_ERROR";

export const MARK_NL_ISSUES_LOADING = "MARK_NL_ISSUES_LOADING";
export const MARK_NL_ISSUES_SUCCESS = "MARK_NL_ISSUES_SUCCESS";
export const MARK_NL_ISSUES_ERROR = "MARK_NL_ISSUES_ERROR";

export const RUN_NL_VALIDATION_LOADING = "RUN_NL_VALIDATION_LOADING";
export const RUN_NL_VALIDATION_SUCCESS = "RUN_NL_VALIDATION_SUCCESS";
export const RUN_NL_VALIDATION_ERROR = "RUN_NL_VALIDATION_ERROR";

export const GET_NL_PDF_LOADING = "GET_NL_PDF_LOADING";
export const GET_NL_PDF_ERROR = "GET_NL_PDF_ERROR";
export const GET_NL_PDF_SUCCESS = "GET_NL_PDF_SUCCESS";

export const EXCEL_GENERATE_ERROR = "EXCEL_GENERATE_ERROR";
export const EXCEL_GENERATE_SUCCESS = "EXCEL_GENERATE_SUCCESS";

export const SET_NL_REDUCER = "SET_NL_REDUCER";

export const GET_STATE_POP_LOADING = "GET_STATE_POP_LOADING";
export const GET_STATE_POP_ERROR = "GET_STATE_POP_ERROR";
export const GET_STATE_POP_SUCCESS = "GET_STATE_POP_SUCCESS";
