import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCompanyList } from "../../store/action/nisActions";
import { AnnualReportAdmin, StatisticsAdmin } from "../layout/StatisticsAdmin";
import { CrawlerIssueAdmin } from "../layout/CrawlerIssueAdmin";
import M from "materialize-css";
import { Logindetails } from "../layout/Logindetails";
import { selectYear } from "../../store/action/uploadAction";
import { getSecCompanies } from "../../store/action/stockPulseAction";
import { getConcallSecCompanies } from "../../store/action/concallAction";
import { CrawlerModal } from "../layout/CrawlerModal";
import { getSectorList } from "../../store/action/smActions";
import {
  addCompany,
  addCompanyKeyword,
  editCompany,
  editCompanyKeyword,
  getApiTracks,
  getCompanyListWithKeywords,
  getCompanyRequests,
  getNewsStat,
  getSentenceBank,
  getStat,
  getUsersStat,
  removeCompanyKeyword,
  startAnalysis,
  startCrawl,
  updateSentenceBank,
  varifyCompany,
} from "../../store/action/adminActions";
import moment from "moment";
import { StatTable } from "../layout/StatTable";
import { getStartEnd } from "../../help/CreateFormData";
import { LoaderTwo } from "../layout/loader2";
import { MiscellsneousTab } from "../layout/MiscellsneousTab";
import { signUp } from "../../store/action/authActions";
import { AddCompanyModal } from "../layout/AddCompanyModal";
import { EditCompanyModal } from "../layout/EditCompanyModal";
import { allTools } from "../../config/constants";

function titleCase(str) {
  return str
    .split(" ")
    .map((word) =>
      word.toLowerCase() != "and"
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : word,
    )
    .join(" ");
}
class AdminDashboard extends Component {
  state = {
    leftBarOption: "Statistics",
    // leftBarOption: "Developer's Panel",
    tools: "PD",
    year: null,
    timeSpan: "1d",
    analysisType: "NIS",
    crawlStartTime: moment().hour(0).minute(0).format("DD/MM/YYYY,HH:MM"),
    analysisStartTime: moment().hour(0).minute(0).format("DD/MM/YYYY,HH:MM"),
    crawlEndTime: moment().hour(23).minute(59).format("DD/MM/YYYY,HH:MM"),
    analysisEndTime: moment().hour(23).minute(59).format("DD/MM/YYYY,HH:MM"),
    isAdmin: "off",
    allRole: "off",
    countRemaining: 100,
    allSector: [],

    newCompany: null,
    sectorId: null,
    synonyms: null,
    userAr: [],
    requestId: null,
    companyEditField: null,
    comapnyEditId: null,
    companyEditValue: null,
    keywords: {},
    filterCompany: null,
    selSentiment: "",
    updateFlag: false,
    roles: {},
  };
  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { dismissible: false });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    const { profile } = this.props;
    if (this.props.auth.accessToken) {
      this.props.GetCompanyList(profile.accessToken);
      this.props.GetStat(this.state.tools, this.props.auth.accessToken);
      this.props.GetSectorList(profile.accessToken);
    }
  };
  componentDidUpdate() {
    if (this.state.addUserFlag && !this.props.signUpLoading) {
      if (this.props.signUpSuccess) {
        alert(this.props.signUpMessage);
        var elem = document.getElementById("user-modal");
        var instance = M.Modal.getInstance(elem);
        instance.close();
        this.setState({ addUserFlag: false });
      }
      if (this.props.signUpError) {
        alert(this.props.signUpMessage);
        var elem = document.getElementById("user-modal");
        var instance = M.Modal.getInstance(elem);
        instance.open();
        this.setState({ addUserFlag: false });
      }
    }
    if (this.state.addCompanyFlag && !this.props.addCompanyLoading) {
      if (this.props.addCompanySuccess) {
        alert(this.props.addCompanyMessage);
        var elem = document.getElementById("company-modal");
        var instance = M.Modal.getInstance(elem);
        instance.close();
        this.setState({ addCompanyFlag: false });
      }
      if (this.props.addCompanyError) {
        alert(this.props.addCompanyMessage);
        var elem = document.getElementById("company-modal");
        var instance = M.Modal.getInstance(elem);
        instance.open();
        this.setState({ addCompanyFlag: false });
      }
    }

    if (
      this.state.addCompanyKeywordFlag &&
      !this.props.addCompanyKeywordLoading
    ) {
      if (this.props.addCompanyKeywordSuccess) {
        this.props.GetCompaniesWithKeywords(
          this.state.filterCompany.value,
          this.props.auth.accessToken,
        );
        this.setState({ addCompanyKeywordFlag: false });
      }
      if (this.props.addCompanyKeywordError) {
        alert(this.props.addCompanyKeywordMessage);
        this.setState({ addCompanyKeywordFlag: false });
      }
    }

    if (
      this.state.editCompanyKeywordFlag &&
      !this.props.editCompanyKeywordLoading
    ) {
      if (this.props.editCompanyKeywordSuccess) {
        this.props.GetCompaniesWithKeywords(
          this.state.filterCompany.value,
          this.props.auth.accessToken,
        );
        this.setState({ editCompanyKeywordFlag: false });
      }
      if (this.props.editCompanyKeywordError) {
        alert(this.props.editCompanyKeywordMessage);
        this.setState({ editCompanyKeywordFlag: false });
      }
    }

    if (
      this.state.removeCompanyKeywordFlag &&
      !this.props.removeCompanyKeywordLoading
    ) {
      if (this.props.removeCompanyKeywordSuccess) {
        this.props.GetCompaniesWithKeywords(
          this.state.filterCompany.value,
          this.props.auth.accessToken,
        );
        this.setState({ removeCompanyKeywordFlag: false });
      }
      if (this.props.removeCompanyKeywordError) {
        alert(this.props.removeCompanyKeywordMessage);
        this.setState({ removeCompanyKeywordFlag: false });
      }
    }

    if (this.state.editCompanyFlag && !this.props.editCompanyLoading) {
      if (this.props.editCompanySuccess) {
        this.props.GetCompaniesWithKeywords(
          this.state.filterCompany.value,
          this.props.auth.accessToken,
        );
        this.setState({ editCompanyFlag: false });
      }
      if (this.props.editCompanyError) {
        alert(this.props.editCompanyMessage);
        this.setState({ editCompanyFlag: false });
      }
    }

    if (this.state.updateFlag && !this.props.updateSentenceBankLoading) {
      if (this.props.updateSentenceBankSuccess) {
        if (this.state.selSentiment && this.state.selSentiment != "")
          this.props.GetSentenceBank(
            { verified: false, limit: 200, sentiment: this.state.selSentiment },
            this.props.auth.accessToken,
          );
        else
          this.props.GetSentenceBank(
            { verified: false, limit: 200 },
            this.props.auth.accessToken,
          );
        this.setState({ updateFlag: false });
      }
      if (this.props.editCompanyError) {
        alert(this.props.updateSentenceBankMessage);
        this.setState({ updateFlag: false });
      }
    }
  }

  handleSelect = (e) => {
    if (e.target.id == "tools")
      this.props.GetStat(e.target.value, this.props.auth.accessToken);
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ leftBarOption: e.target.id, year: null });
    switch (e.target.id) {
      case "Statistics":
        this.props.GetStat(this.state.tools, this.props.auth.accessToken);
        break;
      case "Login Info":
        this.props.GetUserStat(this.props.auth.accessToken);
        break;
      case "Developer's Panel":
        this.props.GetNewsStat(
          this.state.crawlStartTime,
          this.state.crawlEndTime,
          this.props.auth.accessToken,
        );
        this.props.GetApiTracks(this.props.auth.accessToken);
        break;
      case "Miscellaneous":
        this.props.GetSentenceBank(
          { verified: "0", limit: 100 },
          this.props.auth.accessToken,
        );
        this.props.GetCompanyRequests(this.props.auth.accessToken);
        break;
      default:
        break;
    }
  };

  RefreshNewsStat = () => {
    var [stTime, endTime] = getStartEnd(
      this.state.crawlStartTime,
      this.state.crawlEndTime,
      this.state.timeSpan,
    );
    this.props.GetNewsStat(endTime, stTime, this.props.auth.accessToken);
  };

  StartNewsCrawling = () => {
    var [stTime, endTime] = getStartEnd(
      this.state.crawlStartTime,
      this.state.crawlEndTime,
      this.state.timeSpan,
    );
    // //console.log("stTime", stTime, "endTime", endTime);
    // this.props.StartCrawl(stTime, endTime, this.props.auth.accessToken)
  };

  StartNewsAnalysis = () => {
    var [stTime, endTime] = getStartEnd(
      this.state.analysisStartTime,
      this.state.analysisEndTime,
      "sp",
    );
    this.props.StartAnalysis(
      stTime,
      endTime,
      this.state.analysisType,
      this.props.auth.accessToken,
    );
  };

  sectorList = (e) => {
    var sector = [];
    var SM = this.props.SM.sectorList;
    for (var i = 0; i < SM.length; i++) {
      sector.push({
        value: SM[i].id,
        label:
          SM[i].name == "it & ites" || SM[i].name == "fmcg"
            ? SM[i].name.toUpperCase()
            : titleCase(SM[i].name),
      });
    }
    // //console.log(sector);
    this.setState({
      allSector: sector,
    });
  };
  handleUser = (e) => {
    if (e.target.type == "checkbox") {
      this.setState({
        roles: { ...this.state.roles, [e.target.id]: e.target.checked },
      });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  onUserAdd = (e) => {
    e.preventDefault();
    let pers = Object.keys(this.state.roles);
    pers = pers?.filter((per) => this.state.roles[per]);
    pers = pers.map((per) => {
      return {
        role: per,
        expiry: moment(this.state.expiry).format("MM-DD-YYYY"),
        countRemaining: this.state.countRemaining,
      };
    });
    var data = {
      name: this.state.first_name + " " + this.state.last_name,
      email: this.state.email,
      isAdmin: this.state.isAdmin == "on" ? true : false,
      allRole: this.state.allRole == "on" ? true : false,
      password: this.state.password,
      expiry: moment(this.state.expiry).format("MM-DD-YYYY"),
      roles: this.state.allRole == "on" ? null : pers,
    };
    this.setState({ addUserFlag: true });
    // //console.log("Add User", data, this.props.auth.accessToken);
    this.props.SignUp(data, this.props.auth.accessToken);
  };

  addCompany = (e) => {
    var data = {
      sectorId: this.state.sectorId,
      company: this.state.company,
    };
    this.setState({ addCompanyFlag: true });
    this.props.AddCompany(data, this.props.auth.accessToken);
  };

  ChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  FileChangeHandler = (e) => {
    var ars = this.state.userAr;
    if (e.target.type == "file")
      ars[e.target.id.split("-")[1]]["file"] = e.target.files;
    else ars[e.target.id.split("-")[1]]["year"] = e.target.value;

    this.setState({ userAr: ars });
  };

  editNewCompany = (e) => {
    e.preventDefault();
    var body = {
      company: this.state.newCompany,
      sectorId: this.state.sectorId,
      keywords: this.state.synonyms.split(","),
      annualReports: this.state.userAr,
    };
    console.log("DAta", body);
    this.props.VarifyCompany(
      this.state.requestId,
      body,
      this.props.auth.accessToken,
    );
  };

  editRequest = (id) => {
    var c = this.props.companyRequests.find((cr) => cr.id == id);
    var k = "";
    for (var i = 0; i < c.data.keywords.length; i++) {
      k += c.data.keywords[i] + ",";
    }
    var uks = c.data.userKeywords ? c.data.userKeywords.split(",") : [];
    for (var i = 0; i < uks.length; i++) {
      k += uks[i] + ",";
    }
    var sector = [];
    var SM = this.props.SM.sectorList;
    for (var i = 0; i < SM.length; i++) {
      sector.push({
        value: SM[i].id,
        label:
          SM[i].name == "it & ites" || SM[i].name == "fmcg"
            ? SM[i].name.toUpperCase()
            : titleCase(SM[i].name),
      });
    }
    this.setState({
      newCompany: c.data.company,
      userAr: c.data.annualReports,
      synonyms: k,
      sectorId: c.data.sector.id,
      userKeywords: c.data.userKeywords,
      allSector: sector,
      requestId: c.id,
    });
  };

  editCompany = (type, companyId, sectorOrKeywordId) => {
    if (type == "company") {
      this.setState({
        companyEditField: type,
        comapnyEditId: companyId,
        companyEditValue: this.props.companyWithKeywords.find(
          (c) => c.id == companyId,
        ).name,
      });
    } else if (type == "sector") {
      this.setState({
        companyEditField: type,
        comapnyEditId: companyId,
        companyEditValue: sectorOrKeywordId,
      });
    } else if (type == "keyword") {
      var com = this.props.companyWithKeywords.find((c) => c.id == companyId);
      var k = com ? com.Keywords.find((k) => k.id == sectorOrKeywordId) : null;
      this.setState({
        companyEditField: type,
        comapnyEditId: k.id,
        companyEditValue: k.name,
      });
    }
  };

  SubmitEditCompany = (e) => {
    e.preventDefault();
    if (this.state.companyEditField == "company") {
      this.props.EditCompany(
        this.state.comapnyEditId,
        { name: this.state.companyEditValue },
        this.props.auth.accessToken,
      );
      this.setState({ editCompanyFlag: true });
    } else if (this.state.companyEditField == "sector") {
      this.props.EditCompany(
        this.state.comapnyEditId,
        { sector: this.state.companyEditValue },
        this.props.auth.accessToken,
      );
      this.setState({ editCompanyFlag: true });
    } else if (this.state.companyEditField == "keyword") {
      this.props.EditCompanyKeyword(
        this.state.comapnyEditId,
        { keyword: this.state.companyEditValue },
        this.props.auth.accessToken,
      );
      this.setState({ editCompanyKeywordFlag: true });
    }
  };

  RemoveKeyword = (e) => {
    e.preventDefault();
    this.props.RemoveCompanyKeyword(
      this.state.comapnyEditId,
      this.props.auth.accessToken,
    );
    this.setState({ removeCompanyKeywordFlag: true });
  };

  AddKeyword = (e) => {
    e.preventDefault();
    var i = e.target.id.split("-")[1];
    var d = this.state.keywords["add-keyword-" + i];
    this.props.AddCompanyKeyword(
      i,
      { keyword: d },
      this.props.auth.accessToken,
    );
    this.setState({ addCompanyKeywordFlag: true });
  };

  keyChangeHandler = (e) => {
    var d = this.state.keywords;
    d[e.target.id] = e.target.value;
    this.setState({ keywords: d });
  };

  handleSelection = (selectedOptions) => {
    this.setState({ filterCompany: selectedOptions });
  };
  getKeywords = () => {
    this.props.GetCompaniesWithKeywords(
      this.state.filterCompany.value,
      this.props.auth.accessToken,
    );
  };

  OnVerifySenBank = (id, sentiment) => {
    this.setState({ updateFlag: true });
    this.props.VerifySenBank(
      id,
      { verified: true, sentiment },
      this.props.auth.accessToken,
    );
  };

  render() {
    console.log("loca state", this.props);
    // if (!this.props.auth.uid) return <Redirect to="/SignIn" />;
    return (
      <div className="row" style={{ width: "90%", marginTop: 20 }}>
        <AddCompanyModal
          onChange={this.ChangeHandler}
          success={this.props.verifyCompanySuccess}
          error={this.props.verifyCompanyError}
          message={this.props.verifyCompanyMessage}
          state={this.state}
          addCompany={this.editNewCompany}
          handleFileChange={this.FileChangeHandler}
          off={true}
        />
        <CrawlerModal />
        <EditCompanyModal
          state={this.state}
          sectors={this.props.SM.sectorList}
          OnSubmit={this.SubmitEditCompany}
          deleteKeyword={this.RemoveKeyword}
          ChangeHandler={this.ChangeHandler}
        />
        {/* ............Add user Modal............. */}
        <div id="user-modal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <div
              className="modal-title dashboard-modal-title valign-wrapper"
              style={{ "background-color": "#124265", minHeight: 10 }}
            >
              <span className="dashboard-modal-name">Add User</span>
            </div>
            <div className="row" style={{ marginTop: 20 }}>
              <form id="addUser" className="col s12" onSubmit={this.onUserAdd}>
                <div className="row">
                  <div className="input-field col s6">
                    <input
                      id="first_name"
                      type="text"
                      className="validate"
                      onChange={this.handleUser}
                      required
                    />
                    <label for="first_name">First Name</label>
                  </div>
                  <div className="input-field col s6">
                    <input
                      id="last_name"
                      type="text"
                      className="validate"
                      onChange={this.handleUser}
                      required
                    />
                    <label for="last_name">Last Name</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6">
                    <input
                      id="email"
                      type="email"
                      className="validate"
                      onChange={this.handleUser}
                      required
                    />
                    <label for="email">Email</label>
                  </div>
                  <div className="input-field col s6">
                    <input
                      id="password"
                      type="password"
                      className="validate"
                      onChange={this.handleUser}
                      required
                    />
                    <label for="password">Password</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col s4">
                    <p>
                      <span>Is Admin:</span>
                      <label>
                        <input
                          className="with-gap"
                          name="isAdmin"
                          type="radio"
                          id="isAdmin"
                          value="on"
                          onClick={this.handleUser}
                        />
                        <span>True</span>
                      </label>
                      <label>
                        <input
                          className="with-gap"
                          name="isAdmin"
                          type="radio"
                          checked={this.state.isAdmin == "off"}
                          id="isAdmin"
                          value="off"
                          onClick={this.handleUser}
                        />
                        <span>False</span>
                      </label>
                    </p>
                  </div>
                  <div className="col s4">
                    <p>
                      <span>All Role:</span>
                      <label>
                        <input
                          className="with-gap"
                          name="allRole"
                          type="radio"
                          id="allRole"
                          value="on"
                          onClick={this.handleUser}
                        />
                        <span>True</span>
                      </label>
                      <label>
                        <input
                          className="with-gap"
                          name="allRole"
                          type="radio"
                          value="off"
                          checked={this.state.allRole == "off"}
                          id="allRole"
                          onClick={this.handleUser}
                        />
                        <span>False</span>
                      </label>
                    </p>
                  </div>
                  <div className="col s12 l4 m4">
                    <div className="input-field inline">
                      <input
                        type="date"
                        id="expiry"
                        onChange={this.handleUser}
                        required
                      />
                      <label for="email_inline">Expiry Date</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div for="email_inline">Role Permission:</div>
                  {this.state.allRole == "on" ? null : (
                    <div className="col s12">
                      <form action="#">
                        {allTools?.map((tool) => (
                          <label style={{ marginLeft: 8 }}>
                            <input
                              type="checkbox"
                              id={tool.name}
                              onChange={this.handleUser}
                            />
                            <span>{tool.name}</span>
                          </label>
                        ))}
                      </form>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button className="modal-close waves-effect waves-green btn-flat">
              Close
            </button>
            <button
              className="waves-effect waves-green btn-flat"
              form="addUser"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
        {/* ............Add company Modal............. */}
        <div id="company-modal" className="modal modal-fixed-footer">
          <div className="modal-content">
            <div
              className="modal-title dashboard-modal-title valign-wrapper"
              style={{ "background-color": "#124265", minHeight: 10 }}
            >
              <span className="dashboard-modal-name">Add Company</span>
            </div>
            <div className="row" style={{ marginTop: 23 }}>
              <form
                id="addCompany"
                className="col s12"
                onSubmit={this.addCompany}
              >
                <div className="row">
                  <div className="col s12 l12 m12">
                    <label>Sector</label>
                    <select
                      className="browser-default"
                      id="sectorId"
                      onChange={this.handleUser}
                      required
                    >
                      <option value="" disabled selected>
                        Choose your option
                      </option>
                      {this.state.allSector.length != 0 &&
                        this.state.allSector.map((sector) => (
                          <option value={sector.value} key={sector.value}>
                            {sector.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="input-field col s12 l12 m12">
                    <input
                      id="company"
                      type="text"
                      className="validate"
                      onChange={this.handleUser}
                      required
                    />
                    <label for="company">Company Name</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button className="modal-close waves-effect waves-green btn-flat">
              Close
            </button>
            <button
              className="waves-effect waves-green btn-flat"
              form="addCompany"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
        {/* ....End Modal Section..... */}
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col s12 l3 m3">
            <button
              className={
                this.state.leftBarOption == "Statistics"
                  ? "admintab-active col s12"
                  : "admintab col s12"
              }
              id="Statistics"
              style={{
                backgroundColor:
                  this.state.leftBarOption == "Statistics" ? "#124265" : "",
              }}
              onClick={this.handleChange}
            >
              Statistics
            </button>
          </div>

          <div className="col s12 l3 m3">
            <button
              className={
                this.state.leftBarOption == "Developer's Panel"
                  ? "admintab-active col s12"
                  : "admintab col s12"
              }
              id="Developer's Panel"
              onClick={this.handleChange}
            >
              Developer's Panel
            </button>
          </div>

          <div className="col s12 l3 m3">
            <button
              className={
                this.state.leftBarOption == "Miscellaneous"
                  ? "admintab-active col s12"
                  : "admintab col s12"
              }
              id="Miscellaneous"
              onClick={this.handleChange}
            >
              Miscellaneous
            </button>
          </div>
          <div className="col s12 l3 m3">
            <button
              className={
                this.state.leftBarOption == "Login Info"
                  ? "admintab-active col s12"
                  : "admintab col s12"
              }
              id="Login Info"
              onClick={this.handleChange}
            >
              Login Info
            </button>
          </div>
        </div>

        <div className="row">
          {this.state.leftBarOption == "Statistics" ? (
            <StatisticsAdmin
              handleSelect={this.handleSelect}
              state={this.state}
              overall={this.props.stat ? this.props.stat.aggregate : null}
              pdData={this.props.stat ? this.props.stat.yearly : []}
              nisData={this.props.stat ? this.props.stat.company : []}
              smData={this.props.stat ? this.props.stat.sector : []}
              spData={this.props.stat ? this.props.stat.spData : []}
              annData={this.props.stat ? this.props.stat.company : []}
              ccData={this.props.stat ? this.props.stat.company : []}
              loading={this.props.statLoading}
            />
          ) : this.state.leftBarOption == "Developer's Panel" ? (
            <CrawlerIssueAdmin
              state={this.state}
              handleSelect={this.handleSelect}
              newsStat={this.props.newsStat}
              apiStat={this.props.apiStat ? this.props.apiStat : []}
              startAnalysis={this.StartNewsAnalysis}
              startCrawl={this.StartNewsCrawling}
              fetchNewsStat={this.RefreshNewsStat}
              newsStatLoading={this.props.newsStatLoading}
              apiStatLoading={this.props.apiStatLoading}
              GetApiTracks={() => {
                this.props.GetApiTracks(this.props.auth.accessToken);
              }}
            />
          ) : this.state.leftBarOption == "Login Info" ? (
            this.props.userStatLoading ? (
              <LoaderTwo />
            ) : (
              <StatTable
                headers={[
                  {
                    width: 400,
                    name: "User",
                    f: (d) => {
                      return d.name;
                    },
                  },
                  {
                    width: 400,
                    name: "E mail",
                    f: (d) => {
                      return d.email;
                    },
                  },
                  {
                    width: 200,
                    name: "Role",
                    f: (d) => {
                      return d.isAdmin
                        ? "Admin"
                        : d.allRole
                          ? "All Role"
                          : "Normal";
                    },
                  },
                  {
                    width: 200,
                    name: "Last Login ",
                    f: (d) => {
                      return d.lastLogin;
                    },
                  },
                  {
                    width: 200,
                    name: "Expiry",
                    f: (d) => {
                      return d.expiry
                        ? moment(d.expiry).format("DD-MM-YYYY")
                        : "";
                    },
                  },
                ]}
                data={this.props.userStat ? this.props.userStat : []}
                height="80%"
              />
            )
          ) : this.state.leftBarOption == "Miscellaneous" ? (
            <MiscellsneousTab
              state={this.state}
              sector={this.sectorList}
              companyRequests={this.props.companyRequests}
              editRequest={this.editRequest}
              editCompany={this.editCompany}
              companies={this.props.companyWithKeywords}
              companyLoading={this.props.companyWithKeywordsLoading}
              ChangeHandler={this.keyChangeHandler}
              addKeyword={this.AddKeyword}
              companiesOnly={
                this.props.NIS.companyList
                  ? this.props.NIS.companyList.map((c) => {
                      return { value: c.id, label: c.name };
                    })
                  : []
              }
              HandleSelection={this.handleSelection}
              sentenceBank={this.props.sentenceBank}
              sentenceBankLoading={this.props.getSentenceBankLoading}
              GetKeywords={this.getKeywords}
              OnVerify={this.OnVerifySenBank}
              HandleSentimentChange={this.ChangeHandler}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // //console.log("State", state);
  return {
    auth: state.auth,
    profile: state.auth,
    user: state.auth.user,

    NIS: state.NIS,
    SM: state.SM,

    statLoading: state.admin.statLoading,
    statSuccess: state.admin.statSuccess,
    statError: state.admin.statError,
    stat: state.admin.stat,

    userStatLoading: state.admin.userStatLoading,
    userStatSuccess: state.admin.userStatSuccess,
    userStatError: state.admin.userStatError,
    userStat: state.admin.userStat,

    newsStatLoading: state.admin.newsStatLoading,
    newsStatSuccess: state.admin.newsStatSuccess,
    newsStatError: state.admin.newsStatError,
    newsStat: state.admin.newsStat,

    apiStatLoading: state.admin.apiStatLoading,
    apiStatSuccess: state.admin.apiStatSuccess,
    apiStatError: state.admin.apiStatError,
    apiStat: state.admin.apiStat,

    signUpLoading: state.auth.signUpLoading,
    signUpError: state.auth.signUpError,
    signUpSuccess: state.auth.signUpSuccess,
    signUpMessage: state.auth.signUpMessage,

    addCompanyLoading: state.admin.addCompanyLoading,
    addCompanySuccess: state.admin.addCompanySuccess,
    addCompanyError: state.admin.addCompanyError,
    addCompanyMessage: state.admin.addCompanyMessage,

    companyRequestsLoading: state.admin.companyRequestsLoading,
    companyRequestsSuccess: state.admin.companyRequestsSuccess,
    companyRequestsError: state.admin.companyRequestsError,
    companyRequests: state.admin.companyRequests,

    verifyCompanyLoading: state.admin.verifyCompanyLoading,
    verifyCompanySuccess: state.admin.verifyCompanySuccess,
    verifyCompanyError: state.admin.verifyCompanyError,
    verifyCompanyMessage: state.admin.verifyCompanyMessage,

    companyWithKeywords: state.admin.companies,
    companyWithKeywordsLoading: state.admin.companiesLoading,

    addCompanyKeywordLoading: state.admin.addCompanyKeywordLoading,
    addCompanyKeywordSuccess: state.admin.addCompanyKeywordSuccess,
    addCompanyKeywordError: state.admin.addCompanyKeywordError,
    addCompanyKeywordMessage: state.admin.addCompanyKeywordMessage,

    removeCompanyKeywordLoading: state.admin.removeCompanyKeywordLoading,
    removeCompanyKeywordSuccess: state.admin.removeCompanyKeywordSuccess,
    removeCompanyKeywordError: state.admin.removeCompanyKeywordError,
    removeCompanyKeywordMessage: state.admin.removeCompanyKeywordMessage,

    editCompanyKeywordLoading: state.admin.editCompanyKeywordLoading,
    editCompanyKeywordSuccess: state.admin.editCompanyKeywordSuccess,
    editCompanyKeywordError: state.admin.editCompanyKeywordError,
    editCompanyKeywordMessage: state.admin.editCompanyKeywordMessage,

    editCompanyLoading: state.admin.editCompanyLoading,
    editCompanySuccess: state.admin.editCompanySuccess,
    editCompanyError: state.admin.editCompanyError,
    editCompanyMessage: state.admin.editCompanyMessage,

    sentenceBank: state.admin.sentenceBank,
    getSentenceBankLoading: state.admin.getSentenceBankLoading,
    getSentenceBankSuccess: state.admin.getSentenceBankSuccess,
    getSentenceBankError: state.admin.getSentenceBankError,
    getSentenceBankMessage: state.admin.getSentenceBankMessage,

    updateSentenceBankLoading: state.admin.updateSentenceBankLoading,
    updateSentenceBankSuccess: state.admin.updateSentenceBankSuccess,
    updateSentenceBankError: state.admin.updateSentenceBankError,
    updateSentenceBankMessage: state.admin.updateSentenceBankMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanyList: (token) => {
      dispatch(getCompanyList(token));
    },
    GetConcallSecCompanies: (id, token) => {
      dispatch(getConcallSecCompanies(id, token));
    },
    GetSecCompanies: (id, token) => {
      dispatch(getSecCompanies(id, token));
    },
    SelectYear: (companyId, token) => {
      dispatch(selectYear(companyId, token));
    },
    GetStat: (tool, token) => {
      dispatch(getStat(tool, token));
    },
    GetUserStat: (token) => {
      dispatch(getUsersStat(token));
    },
    GetNewsStat: (startDate, endDate, token) => {
      dispatch(getNewsStat(startDate, endDate, token));
    },
    StartAnalysis: (stTime, endTime, analysisType, token) => {
      dispatch(startAnalysis(stTime, endTime, analysisType, token));
    },
    StartCrawl: (stTime, endTime, token) => {
      dispatch(startCrawl(stTime, endTime, token));
    },
    GetApiTracks: (token) => {
      dispatch(getApiTracks(token));
    },
    SignUp: (body, token) => {
      dispatch(signUp(body, token));
    },
    GetSectorList: (token) => {
      dispatch(getSectorList(token));
    },
    AddCompany: (body, token) => {
      dispatch(addCompany(body, token));
    },
    GetCompanyRequests: (token) => {
      dispatch(getCompanyRequests(token));
    },
    VarifyCompany: (id, body, token) => {
      dispatch(varifyCompany(id, body, token));
    },
    GetCompaniesWithKeywords: (cid, token) => {
      dispatch(getCompanyListWithKeywords(cid, token));
    },
    EditCompany: (comId, body, token) => {
      dispatch(editCompany(comId, body, token));
    },
    EditCompanyKeyword: (keyId, body, token) => {
      dispatch(editCompanyKeyword(keyId, body, token));
    },
    RemoveCompanyKeyword: (keyId, token) => {
      dispatch(removeCompanyKeyword(keyId, token));
    },
    AddCompanyKeyword: (comId, body, token) => {
      dispatch(addCompanyKeyword(comId, body, token));
    },
    GetSentenceBank: (query, token) => {
      dispatch(getSentenceBank(query, token));
    },
    VerifySenBank: (id, body, token) => {
      dispatch(updateSentenceBank(id, body, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
