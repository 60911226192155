import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";
import Segments from "./laylout/Segments";

export default function IndustryPerf({ label, data }) {
  const [selLob, setSelLob] = useState(null);
  const [lobs, setLobs] = useState(null);
  const [reportData, setReportData] = useState([]);
  useEffect(() => {
    if (data && data["all"]) {
      let temp_lobs = [];

      for (let i = 0; i < data?.all?.length; i++) {
        if (!temp_lobs?.includes(data.all[i].particular)) {
          temp_lobs.push(data.all[i].particular);
        }
      }
      setLobs(temp_lobs);
      setSelLob(temp_lobs[0]);

      let temp_coms = [];

      for (let i = 0; i < data?.all?.length; i++) {
        if (!temp_coms?.includes(data.all[i].company)) {
          temp_coms.push(data.all[i].company);
        }
      }

      let lob_ob = {};
      let temp_rows = ["Company"];
      for (let i = 0; i < temp_lobs.length; i++) {
        let temp = data["all"]?.filter(
          (row) => row.particular === temp_lobs[i],
        );
        let lob = temp_lobs[i];
        lob_ob[lob] = [];
        for (let j = 0; j < temp_coms.length; j++) {
          let ob = { Company: temp_coms[j] };
          let data_com_lob = temp.filter((row) => row.company === ob.Company);
          for (let k = 0; k < data_com_lob.length; k++) {
            ob[data_com_lob[k].year + "-" + data_com_lob[k].quarter] =
              data_com_lob[k].value;
          }
          let cols = Object.keys(ob);
          ob["growth"] = Number(
            (
              (ob[cols[1]] -
                ob[cols[cols.length - 2]] / ob[cols[cols.length - 2]]) *
              100
            ).toFixed(2),
          );
          ob = Object.assign({ Company: null, growth: null }, ob);
          lob_ob[lob].push(ob);
        }
      }

      setReportData(lob_ob);
    }
  }, [data]);

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  console.log("REPORT DATA", reportData);
  return (
    <div className="row">
      <div className="col s12">
        <Segments
          lobs={lobs}
          selLob={selLob}
          handleLobChange={(temp_lob) => {
            setSelLob(temp_lob);
          }}
        />
        {reportData && selLob && (
          <DataTable data={reportData[selLob] || []} minRowInPage={32} />
        )}
        <p className="notes-text">
          ** OPEX Ratio = Expense of Management to Net Written Premium Ratio
          <br />
          Claim Ratio = Net Incurred Claims to Net Earned Premium
          <br />
          Combined Ratio = OPEX + Claim
        </p>
      </div>
    </div>
  );
}
