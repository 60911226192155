import React, { useEffect, useState } from "react";
import BarChartGeneric from "../../../layout/BarChartGeneric";
import Legends from "../laylout/Legends";
import PieChartLegend from "../../../layout/PieChartLegend";
import * as d3 from "d3";
import LineChartGeneric from "../../../layout/LineChartGeneric";

export default function BarPieCombinationGic({
  barDetails,
  pieDetails,
  lengendMaxChar,
  barTwoDetails,
  lineChartDetails,
}) {
  const [colorMapCustom, setColorMap] = useState({});
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < pieDetails?.data?.length; i++) {
      if (!temp.includes(pieDetails.data[i].company)) {
        temp.push(pieDetails.data[i].company);
      }
    }
    if (pieDetails?.data)
      handleColorMap(pieDetails?.data, pieDetails?.dimensions);
    setCompanies(temp);
  }, [pieDetails]);

  const handleColorMap = (data, dimensions) => {
    let allFields = data
      .filter((d) => d.company === pieDetails.data[0].company)
      .sort((a, b) => (a.value < b.value ? 1 : -1))
      .map((d) => d[dimensions[0].text]);

    console.log("DOM", allFields);
    const color = d3
      .scaleOrdinal()
      .domain(allFields)
      .range(
        d3
          .quantize(
            (t) => d3.interpolateSpectral(t * 0.8 + 0.1),
            allFields.length,
          )
          .reverse()
          .slice(1),
      );
    let colorMap = {};
    data?.map((d) => {
      colorMap[d[dimensions[0].text]] = color(d[dimensions[0].text]);
    });
    console.log("COLOR MAP", colorMap);
    setColorMap(colorMap);
  };

  // console.log(allFields);

  return (
    <>
      <div className="col s4">
        <div className="col s12" style={{ marginTop: 8 }}>
          <div className="">
            <strong>{barDetails?.label}</strong>
          </div>
          <BarChartGeneric
            id="total-one"
            data={barDetails?.data || []}
            dimensions={barDetails?.dimensions}
            measures={barDetails?.measures}
            height={barDetails?.height}
            width={barDetails?.width}
            hideLegend={false}
            margins={{ left: 90, bottom: 10 }}
            fontSize={12}
            boldFont={true}
            noLabelWrapY={true}
            // noYLabel={true}
            noXLabel={true}
            labelInBar={false}
            // barFontSize={10}
          />
        </div>
        {barTwoDetails && (
          <div className="col s12" style={{ marginTop: 8 }}>
            <div className="">
              <strong>{barTwoDetails?.label}</strong>
            </div>
            <BarChartGeneric
              id="total-two"
              data={barTwoDetails?.data || []}
              dimensions={barTwoDetails?.dimensions}
              measures={barTwoDetails?.measures}
              height={barTwoDetails?.height}
              width={barTwoDetails?.width}
              hideLegend={false}
              margins={{ left: 90, bottom: 10 }}
              fontSize={12}
              boldFont={true}
              noLabelWrapY={true}
              // noYLabel={true}
              noXLabel={true}
              labelInBar={false}
              // barFontSize={10}
            />
          </div>
        )}
      </div>
      <div className="col s8">
        {lineChartDetails && (
          <>
            <div className="" style={{ marginTop: 8 }}>
              <strong>{lineChartDetails?.label}</strong>
            </div>
            <LineChartGeneric
              id={lineChartDetails.id}
              data={lineChartDetails?.data}
              dimensions={lineChartDetails?.dimensions}
              measures={lineChartDetails?.measures}
              can_width={lineChartDetails.width}
              can_height={lineChartDetails.height}
              group={lineChartDetails.group}
              tooltip={false}
              labelInBar={true}
              margins={{ bottom: 20, left: 60, right: 20, top: 50 }}
              legend={true}
              rotateXTick={true}
              legendOrientation="top"
            />
          </>
        )}
      </div>
      <div className="col s12">
        <div className="col s12">
          <strong>{pieDetails?.label}</strong>
        </div>
        {companies?.map((sec, i) => (
          <div className="col s2" style={{ marginTop: 8 }}>
            <div className="center">
              <strong>{sec}</strong>
            </div>
            <PieChartLegend
              id={"data-" + i}
              data={
                pieDetails?.data
                  ?.filter((row) => row.company === sec)
                  ?.sort((a, b) => (a.percentage > b.percentage ? -1 : 1)) || []
              }
              dimensions={pieDetails?.dimensions}
              measures={pieDetails?.measures}
              height={700}
              width={700}
              noLegend={true}
              colorMap={colorMapCustom}
            />
          </div>
        ))}
        <Legends
          marginTop={20}
          maxChar={lengendMaxChar}
          colorMapCustom={colorMapCustom}
          legendGap={160}
          multiLine={true}
        />
      </div>
    </>
  );
}
