import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ArcChart from "../../layout/ArcChart";
import ThumbsComponents from "./ThumbsComponents";

export default function GoalDashboard({ data, fyq }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [myCompany, setMyCompany] = useState(null);
  const [marketChange, setMarketChange] = useState(null);

  useEffect(() => {
    let insSector = sectors?.find(
      (sec) => sec?.name?.toLowerCase() === "insurance",
    );
    let com = userCompany?.find(
      (com) => com?.detail?.sector_id === insSector?.id,
    );
    setMyCompany(com);
  }, []);

  useEffect(() => {
    console.log("fyq", fyq);

    let year = fyq?.split("-")[0];
    let quarter = fyq?.split("-")[1];
    let prev_year = getPreviousYear(year);
    let market_cur = data.marketShare.find(
      (row) => row.year === prev_year && row.quarter === quarter,
    )?.marketShare;
    let market_prev = data.marketShare.find(
      (row) => row.year === year && row.quarter === quarter,
    )?.marketShare;
    let change = Number(
      (((market_cur - market_prev) / market_prev) * 100).toFixed(2),
    );
    setMarketChange(change);
  }, [data]);

  const getPreviousYear = (year) => {
    let prev_year =
      String(Number(year.split("_")[0]) - 1) +
      "_" +
      String(Number(year.split("_")[1]) - 1);
    return prev_year;
  };

  const getNItems = (rows, type, key) => {
    const top_n = 3;
    let text = "";
    let problem_rows = data?.lossRatio
      ?.filter(
        (row) =>
          !row.particular.includes("_Previous Period") && row.value > 100,
      )
      ?.map((row) => {
        return row.particular;
      });

    if (key === "segment") {
      rows = rows.filter((row) => !problem_rows.includes(row[key]));
    }
    for (let i = 0; i < top_n; i++) {
      if (!rows[i]) {
        break;
      }
      if (type === "promoter") {
        text += rows[i][key];
      } else if (type === "passive") {
      } else if (type === "detractor") {
        text += rows[rows.length - i - 1][key];
      }
      if (i < top_n - 1) {
        text += ", ";
      }
    }
    return text;
  };
  console.log(data);

  const HEIGHT = 200;
  const MAX_HEIGHT = 200;
  const MIN_HEIGHT = 280;

  return (
    data && (
      <div className="col s12" style={{ magin: 0 }}>
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="col s4">
            <div
              className="card"
              style={{ maxHeight: MAX_HEIGHT, minHeight: MIN_HEIGHT }}
            >
              <div className="card-content">
                <strong>Market Share</strong>
                {marketChange && (
                  <ArcChart
                    id="arc-new"
                    height={HEIGHT}
                    width={HEIGHT}
                    dimensions={[{ text: "company" }]}
                    measures={[{ text: "percentage" }]}
                    ranges={{
                      pos: [
                        { start: 0, end: 20, color: "rgb(187, 219, 182)" },
                        { start: 20, end: 60, color: "rgb(169, 220, 162)" },
                        { start: 60, color: "rgb(49, 207, 27)" },
                      ],
                      neg: [
                        {
                          start: 0,
                          end: 20,
                          color: "rgb(201, 149, 149)",
                        },
                        { start: 20, end: 60, color: "rgb(212, 110, 120)" },
                        { start: 60, color: "rgb(209, 60, 75)" },
                      ],
                    }}
                    data={[
                      {
                        company: "MY",
                        percentage: marketChange,
                      },
                      {
                        company: "REST",
                        percentage: 100 - Math.abs(marketChange),
                      },
                    ]}
                    meter={true}
                  />
                )}
              </div>
            </div>
          </div>
          {data && data.segmentGrowth && (
            <div className="col s4">
              <div
                className="card"
                style={{ maxHeight: MAX_HEIGHT, minHeight: MIN_HEIGHT }}
              >
                <div className="card-content">
                  <ThumbsComponents
                    headline="Segment wise"
                    dataHead="Segment"
                    promoter={getNItems(
                      data.segmentGrowth,
                      "promoter",
                      "segment",
                    )}
                    detractor={getNItems(
                      data.segmentGrowth,
                      "detractor",
                      "segment",
                    )}
                    data={{
                      data: data.segmentGrowth,
                      colKey: "segment",
                      valKey: "segmentGrowth",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col s4">
            <div
              className="card"
              style={{ maxHeight: MAX_HEIGHT, minHeight: MIN_HEIGHT }}
            >
              <div className="card-content">
                {data && data.geoGrowth && (
                  <ThumbsComponents
                    headline="Geography wise"
                    promoter={getNItems(
                      data.geoGrowth,
                      "promoter",
                      "particular",
                    )}
                    detractor={getNItems(
                      data.geoGrowth,
                      "detractor",
                      "particular",
                    )}
                    dataHead="State"
                    data={{
                      data: data.geoGrowth,
                      colKey: "particular",
                      valKey: "geoGrowth",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s4">
            <div
              className="card"
              style={{ maxHeight: MAX_HEIGHT, minHeight: MIN_HEIGHT }}
            >
              <div className="card-content">
                <strong>GDPI </strong>
                <ArcChart
                  id="arc-gdp"
                  height={HEIGHT}
                  width={HEIGHT}
                  dimensions={[{ text: "company" }]}
                  measures={[{ text: "percentage" }]}
                  data={[
                    {
                      company: "MY",
                      percentage: data?.gdpGrowth[0]?.gdpGrowth,
                    },
                    {
                      company: "REST",
                      percentage:
                        data?.gdpGrowth[0]?.gdpGrowth > 100
                          ? 0
                          : 100 - Math.abs(data?.gdpGrowth[0]?.gdpGrowth),
                    },
                  ]}
                  ranges={{
                    pos: [
                      { start: 0, end: 20, color: "rgb(187, 219, 182)" },
                      { start: 20, end: 60, color: "rgb(169, 220, 162)" },
                      { start: 60, color: "rgb(49, 207, 27)" },
                    ],
                    neg: [
                      {
                        start: 0,
                        end: 20,
                        color: "rgb(169, 220, 162)",
                      },
                      { start: 20, end: 60, color: "rgb(252, 172, 99)" },
                      { start: 60, color: "rgb(209, 60, 75)" },
                    ],
                  }}
                  meter={true}
                  subtitle={
                    data.gdpGrowthLeader && data.gdpGrowthLeader[0]
                      ? data.gdpGrowthLeader[0].gdpGrowthLeader.toFixed(2)
                      : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="col s4">
            <div
              className="card"
              style={{ maxHeight: MAX_HEIGHT, minHeight: MIN_HEIGHT }}
            >
              <div className="card-content">
                {data.channelGrowth && (
                  <ThumbsComponents
                    headline="Channel wise"
                    promoter={getNItems(
                      data.channelGrowth,
                      "promoter",
                      "particular",
                    )}
                    detractor={getNItems(
                      data.channelGrowth,
                      "detractor",
                      "particular",
                    )}
                    data={{
                      data: data.channelGrowth,
                      colKey: "particular",
                      valKey: "channelGrowth",
                    }}
                    dataHead="Channel"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col s4">
            <div
              className="card"
              style={{ maxHeight: MAX_HEIGHT, minHeight: MIN_HEIGHT }}
            >
              <div className="card-content">
                <ThumbsComponents
                  headline="Operational Expenses"
                  promoter={getNItems(
                    data.opexGrowth,
                    "detractor",
                    "particular",
                  )}
                  detractor={getNItems(
                    data.opexGrowth,
                    "promoter",
                    "particular",
                  )}
                  data={{
                    data: data.opexGrowth,
                    colKey: "particular",
                    valKey: "opexGrowth",
                  }}
                  detractorLabel={"Expense significantly increased in"}
                  promoterLabel={"Expense reduced in"}
                  passiveLabel={"No major changes happened in"}
                  dataHead="OPEX"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
