import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Button, Icon, Stack } from "@mui/material";
import FilterModal from "./FilterModal";

export function DataTableToolbar(props) {
  const {
    selected,
    bulkActions,
    data,
    headCells,
    changeFilters,
    filters,
    filterOrders,
  } = props;
  const [open, filterOpen] = useState(false);

  const setFilterModal = () => {
    filterOpen(!open);
  };

  // console.log("bulkActions", data);
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected?.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      <FilterModal
        open={open}
        setFilterModal={setFilterModal}
        data={data}
        headCells={headCells}
        changeFilters={changeFilters}
        filters={filters}
        orders={filterOrders}
      />
      {selected?.length > 0 ? (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selected?.length} selected
          </Typography>
          <Stack direction={"row"} spacing={3}>
            {bulkActions?.map((action) => (
              <Button
                onClick={() => (action.action ? action.action(selected) : {})}
                variant="contained"
                disabled={action.loading}
              >
                {action.label}
              </Button>
            ))}
          </Stack>
        </>
      ) : (
        <IconButton onClick={setFilterModal}>
          <Icon>filter_alt</Icon>
        </IconButton>
      )}
    </Toolbar>
  );
}

DataTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
};
