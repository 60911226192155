import React from "react";
import GeoChart from "./laylout/GeoChart";
import { useState } from "react";
import { useEffect } from "react";
import { getArrayOfArray } from "../../../utils/nlHelper";
import { useDispatch, useSelector } from "react-redux";
import { getStatePop } from "../../../store/action/nlActions";
import LineChartGeneric from "../../layout/LineChartGeneric";

export default function GeoDashboard({ data }) {
  const accessToken = useSelector((state) => state?.auth?.accessToken);
  const [reportData, setReportData] = useState(null);
  const [trendData, setTrendData] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selSegment, setSelSegment] = useState("Overall");
  const [tableData, setTableData] = useState(null);
  const [selState, setSelState] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatePop({}, accessToken));
  }, []);

  useEffect(() => {
    if (data && data?.all?.length > 0 && data?.grouped?.length > 0) {
      const overall_col = "Overall";
      let overallSegmentData = data?.grouped.filter(
        (row) => row.segment === overall_col,
      );
      setReportData(getArrayOfArray(overallSegmentData, ["state", "value"]));
      let segs = [overall_col];
      for (let i = 0; i < data.all?.length; i++) {
        if (!segs.includes(data.all[i].segment)) {
          segs.push(data.all[i].segment);
        }
      }
      setSegments(segs);
      setSelSegment(overall_col);
      let state = overallSegmentData[0].state;
      let tableD = data?.all?.filter(
        (row) => row.particular === state && row.segment === overall_col,
      );
      let trendD = data?.trend?.filter(
        (row) => row.particular === state && row.segment === overall_col,
      );
      console.log(overallSegmentData);

      setTableData(tableD);
      setTrendData(trendD);
      setSelState(state);
    }
  }, [data]);

  const OnStateSelect = (state) => {
    let tempState = state[0] === "Orissa" ? "Odisha" : state[0];
    setSelState(tempState);
    let tempData = data.all
      .filter(
        (row) => row.segment === selSegment && row.particular === tempState,
      )
      ?.sort((a, b) => (a.value > b.value ? -1 : 1));
    let trendD = data?.trend?.filter(
      (row) => (row.segment === selSegment && row.particular) === tempState,
    );
    setTrendData(trendD);
    setTableData(tempData);
  };

  const OnSetSegment = (segment) => {
    setSelSegment(segment);
    let temp = data?.grouped?.filter((row) => row.segment === segment);
    console.log("temp", temp);

    setReportData(getArrayOfArray(temp, ["state", "value"]));
    let tempData = data?.all
      ?.filter((row) => row.particular === selState && row.segment === segment)
      ?.sort((a, b) => (a.value > b.value ? -1 : 1));
    setTableData(tempData);

    let trendD = data?.trend?.filter(
      (row) => (row.segment === segment && row.particular) === selState,
    );
    setTrendData(trendD);
  };

  console.log("REP DATA", selSegment, selState, segments);
  return (
    <>
      <div className="row">
        <div className="col s2">
          <p style={{ marginBottom: 8, marginTop: 0 }}>
            <strong>State wise distribution</strong>
          </p>
          <table className="tableFixed">
            <tbody style={{ height: 510 }}>
              {segments?.map((segment) => (
                <tr
                  onClick={() => {
                    OnSetSegment(segment);
                  }}
                >
                  <td
                    style={
                      segment === selSegment
                        ? { backgroundColor: "#dcdce2", cursor: "pointer" }
                        : { cursor: "pointer" }
                    }
                  >
                    {segment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col s10">
          <div>
            <div className="col s8">
              <p style={{ marginBottom: 8, marginTop: 0 }}>
                <strong>State wise distribution</strong>
              </p>
              <GeoChart
                height={360}
                key={"RevChart"}
                width="100%"
                data={reportData || []}
                ChartSelect={OnStateSelect}
              />
            </div>
            <div className="col s4">
              {
                <p style={{ marginBottom: 8, marginTop: 0 }}>
                  <strong>Top insurers for {selState}</strong>
                </p>
              }
              {tableData && (
                <table className="tableFixed">
                  {/* <thead>
                    <tr>
                      <th>Company</th>
                      <th>Revenue</th>
                    </tr>
                  </thead> */}
                  <tbody style={{ height: 360 }}>
                    {tableData?.map((td) => (
                      <tr>
                        <td title={td?.company}>
                          {td?.company?.length > 14
                            ? td.company.slice(0, 14) + "..."
                            : td.company}
                        </td>
                        <td className="right right-align">
                          {td.value.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div>
            <div className="col s12">
              {
                <p style={{ marginBottom: 8, marginTop: 0 }}>
                  <strong>GDP Trend for {selState}</strong>
                </p>
              }
            </div>
            {trendData && trendData.length > 0 && (
              <LineChartGeneric
                id="trend"
                data={trendData.map((row) => {
                  return { ...row, fyq: row.year + ", " + row.quarter };
                })}
                dimensions={[{ text: "fyq" }]}
                measures={[{ text: "value" }]}
                can_width={1000}
                can_height={150}
                group={"company"}
                tooltip={false}
                labelInBar={true}
                margins={{ bottom: 20, left: 60, right: 140, top: 10 }}
                legend={true}
                // legendDiff={105}
                rotateXTick={true}
                legendOrientation="vertical"
              />
            )}
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col s12">
          <p className="notes-text">
            ** Initially the state with highest revenue is selected and company
            shares are shown in right panel
          </p>
        </div>
      </div> */}
    </>
  );
}
