import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";
import Segments from "./laylout/Segments";
import { orderFilters } from "../../../utils/tableHelper";
import { useSelector } from "react-redux";
import Legends from "./laylout/Legends";
import BarPieCombination from "./commonDashboards/BarPieCombination";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../../config/constants";

export default function OpexComparisionLife({ label, data, type }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [myCompany, setMyCompany] = useState(null);
  const [secondaries, setSecondaries] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportDataTwo, setReportDataTwo] = useState([]);
  const [reportDataGDP, setReportDataGDP] = useState([]);
  const [selLob, setSelLob] = useState(null);
  const [lobs, setLobs] = useState([]);
  const [elemenate, setElemCats] = useState([]);

  const FILTER_PERCENTAGE = 2;

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com =
        !type || type === NL_TYPE_GIC
          ? userCompany?.find(
              (com) =>
                com?.detail?.sector_id === insSector?.id &&
                com?.detail?.insuranceType !== NL_TYPE_LIFE,
            )
          : userCompany?.find(
              (com) =>
                com?.detail?.sector_id === insSector?.id &&
                com?.detail?.insuranceType === NL_TYPE_LIFE,
            );
      let secs = com?.secondaries;
      setMyCompany(com?.detail);
      setSecondaries(com?.secondaries);

      let temp_lobs = [];
      for (let i = 0; i < data?.length; i++) {
        if (!temp_lobs.includes(data[i]["Segments"])) {
          temp_lobs.push(data[i]["Segments"]);
        }
      }
      temp_lobs = orderFilters(temp_lobs);
      setLobs(temp_lobs);
      setSelLob(temp_lobs[0]);

      let temp = data?.filter((row) => row.Segments === temp_lobs[0]);
      let coms = [...secs];
      coms.push(com.detail);
      setData(temp, coms);
    }
  }, [data]);

  const handleLobChange = (lob) => {
    let temp_data = data?.filter((row) => row.Segments === lob);
    let coms = [...secondaries];
    coms.push(myCompany);
    setData(temp_data, coms);
    setSelLob(lob);
  };

  const setData = (arrayData, coms) => {
    let segData = makeSegWise(arrayData);
    let newSegData = {};
    for (let i = 0; i < Object.keys(segData).length; i++) {
      let key = Object.keys(segData)[i];
      newSegData[key] = segData[key]?.filter(
        (val) => !val?.segment?.includes("_per_gdp"),
      );
    }
    setReportData(newSegData);

    setReportDataTwo(getBarChartData(arrayData, coms));
    if (type === NL_TYPE_LIFE)
      setReportDataGDP(getBarChartDataGDP(arrayData, coms));
  };

  const makeSegWise = (arrayData) => {
    let filterSets = {};
    let newData = {};
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let newArr = [];
      let newLowSet = [];
      let segKeys = Object.keys(d)?.filter((key) => key.includes("%"));
      for (let j = 0; j < segKeys.length; j++) {
        newArr.push({
          segment: segKeys[j].replace("(%)", "").trim(),
          data: d[segKeys[j]],
        });
        if (d[segKeys[j]] < FILTER_PERCENTAGE) {
          newLowSet.push(segKeys[j].replace("(%)", "").trim());
        }
      }
      newData[d.Company] = newArr;
      filterSets[d.Company] = newLowSet;
    }

    let elemSet = getElemenateCategories(filterSets);
    elemSet.push("Total_per_gdp");
    let coms = Object.keys(newData);
    let finalData = {};
    for (let i = 0; i < coms?.length; i++) {
      let com = coms[i];
      finalData[com] = newData[com]?.filter(
        (data) => !elemSet?.includes(data.segment),
      );
    }
    console.log("FINAL", finalData, newData);
    return finalData;
  };

  const getElemenateCategories = (filterSets) => {
    let arrOfArr = Object.values(filterSets);
    let count = {};
    for (let i = 0; i < arrOfArr.length; i++) {
      for (let j = 0; j < arrOfArr[i].length; j++) {
        if (count[arrOfArr[i][j]]) {
          count[arrOfArr[i][j]] += 1;
        } else {
          count[arrOfArr[i][j]] = 1;
        }
      }
    }
    let elemenateCats = [];

    for (let i = 0; i < Object.keys(count).length; i++) {
      let key = Object.keys(count)[i];
      if (count[key] == arrOfArr.length) {
        elemenateCats.push(Object.keys(count)[i]);
      }
    }

    console.log("ELEM", elemenateCats, count);
    return elemenateCats;
  };

  const getBarChartData = (data, coms) => {
    let newData = [];
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          total: d.Total,
        });
      }
    });

    newData = newData.sort((a, b) => (a.total < b.total ? 1 : -1));
    return newData;
  };

  const getBarChartDataGDP = (data, coms) => {
    let newData = [];
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          total: d["Total_per_gdp (%)"],
        });
      }
    });

    newData = newData.sort((a, b) => (a.total < b.total ? 1 : -1));
    return newData;
  };
  console.log("LOBS", lobs);
  console.log("REPORT DATA", reportData, reportDataTwo, reportDataGDP);

  const BAR_CHART_HEIGHT = type === NL_TYPE_LIFE ? 220 : 400;
  const BAR_CHART_WIDTH = type === NL_TYPE_LIFE ? 450 : 450;

  return (
    <div className="row">
      <Segments lobs={lobs} selLob={selLob} handleLobChange={handleLobChange} />
      <BarPieCombination
        barDetails={{
          label: "Operating Expences",
          data: reportDataTwo,
          height: BAR_CHART_HEIGHT,
          width: BAR_CHART_WIDTH,
          dimensions: [{ text: "company" }],
          measures: [{ text: "total" }],
        }}
        barTwoDetails={
          type === NL_TYPE_LIFE
            ? {
                label: "Operating Expences per GDP",
                data: reportDataGDP,
                height: BAR_CHART_HEIGHT,
                width: BAR_CHART_WIDTH,
                dimensions: [{ text: "company" }],
                measures: [{ text: "total" }],
              }
            : null
        }
        pieDetails={{
          data: reportData,
          title: "Operating expenses by type",
          dimensions: [{ text: "segment" }],
          measures: [{ text: "data" }],
        }}
        myCompany={myCompany}
        secondaries={secondaries}
        lengendMaxChar={8}
      />
      {/* <div className="col s12">
        <p className="notes-text">
          ** All cost figures are in percentage calculated by ( cost in
          department/ total cost) * 100 for the segment
        </p>
      </div> */}
    </div>
  );
}
