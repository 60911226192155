import {
  Button,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { updateNLData } from "../../../store/action/nlFixAction";
import { useDispatch, useSelector } from "react-redux";

export default function EditDynamicNL({
  data,
  text,
  year,
  quarter,
  cid,
  nlid,
}) {
  const tableContainerRef = useRef(null);
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.auth?.accessToken);

  const [locData, setLocdata] = useState(null);

  useEffect(() => {
    setLocdata(data);
  }, [data]);

  const addRow = (e) => {
    let newRow = JSON.parse(JSON.stringify(data[0]));
    let keys = Object.keys(newRow);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (newRow[key]?.hasOwnProperty("value")) {
        newRow[key]["value"] = "";
      }
    }

    const rowOrderValues = locData.map((obj) => obj.rowOrder);
    const maxRowOrder = Math.max(...rowOrderValues);
    newRow.rowOrder = maxRowOrder + 1;

    const slValues = locData.map((obj) => parseInt(obj["SL No"]["value"]));
    const maxSl = Math.max(...slValues);
    newRow["SL No"]["value"] = maxSl + 1;

    // console.log(slValues);

    let localData = [...locData];
    localData.push(newRow);
    setLocdata(localData);
  };

  const deleteRow = (e, row) => {
    e.preventDefault();
    let localData = [...locData];
    const rowIndex = localData.findIndex((f) => f.rowOrder === row.rowOrder);
    localData.splice(rowIndex, 1);
    setLocdata(localData);
    updateDB(localData);
  };

  const editValue = (e, row, col) => {
    e.preventDefault();
    if (col.toLowerCase() === "sl no") {
      return;
    }
    let localData = [...locData];
    const rowIndex = localData.findIndex((f) => f.rowOrder === row.rowOrder);
    const userInput = prompt(`${col} : ${localData[rowIndex][col]["value"]}`);
    if (userInput !== null && userInput !== undefined) {
      localData[rowIndex][col]["value"] = userInput;
      setLocdata(localData);
      updateDB(localData);
    }
  };

  const updateDB = (localData) => {
    dispatch(
      updateNLData(
        {
          year: year,
          quarter: quarter,
          cid: cid,
          nlid: parseInt(nlid),
          value: localData,
          colId: "",
          rowId: "",
        },
        accessToken
      )
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <h5 style={{ textAlign: "center" }}>{text + "(Edit dynamic NL)"}</h5>
      {locData?.length > 0 ? (
        <TableContainer
          component={Paper}
          ref={tableContainerRef}
          style={{ maxHeight: "800px" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Action
                </TableCell>
                {Object.keys(locData[0])
                  .filter((f) => !["id", "rowName", "rowOrder"].includes(f))
                  .sort((a, b) =>
                    locData[0][a]["colOrder"] > locData[0][b]["colOrder"]
                      ? 1
                      : -1
                  )
                  .map((key) => (
                    <TableCell
                      key={locData[0][key]["colOrder"]}
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {key}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {locData
                .sort((row_a, row_b) =>
                  row_a.rowOrder > row_b.rowOrder ? 1 : -1
                )
                .map((row, rowIndex) => (
                  <TableRow key={row["rowOrder"]}>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <IconButton
                        sx={{ height: 5 }}
                        color="error"
                        onClick={(e) => deleteRow(e, row)}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </TableCell>
                    {Object.keys(locData[0])
                      .filter((f) => !["id", "rowName", "rowOrder"].includes(f))
                      .sort((a, b) =>
                        locData[0][a]["colOrder"] > locData[0][b]["colOrder"]
                          ? 1
                          : -1
                      )
                      .map((col) => (
                        <TableCell
                          key={`${row["rowOrder"]}-${locData[0][col]["colOrder"]}`}
                          onClick={(e) => {
                            editValue(e, row, col);
                          }}
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row[col] && row[col]["value"]}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    className="left"
                    onClick={addRow}
                  >
                    Add New Row
                    <Icon>add</Icon>
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span>No data found</span>
      )}
    </div>
  );
}
