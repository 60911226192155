import React from "react";
import GeoChart from "./laylout/GeoChart";
import { useState } from "react";
import { useEffect } from "react";
import { getArrayOfArray } from "../../../utils/nlHelper";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import StackBar from "./laylout/StackBar";
import StackBarHorizontal from "./laylout/StackBarHorizontal";
import {
  NL_TYPE_GIC,
  NL_TYPE_LIFE,
  graphColors,
  specialColorMap,
  specialColorMapLifeGeo,
} from "../../../config/constants";
import Legends from "./laylout/Legends";

export default function GeoCompDashboard({ label, data, type }) {
  const [reportData, setReportData] = useState(null);
  const [reportDataSecondary, setReportDataSecondary] = useState(null);
  const [secondaryCompanies, setSecCompanies] = useState(null);
  const [selCompany, setSelCompany] = useState(null);
  const [selState, setSelState] = useState(null);
  const [segments, setSegments] = useState([]);
  const [myCompany, setMyCompany] = useState(null);
  const [initial, setInitial] = useState(false);
  const [segData, setSegData] = useState([]);
  const [segDataSec, setSegDataSec] = useState([]);
  const [overallCol, setOverallCol] = useState("Overall");
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  useEffect(() => {
    if (data && data.all) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let myCom = userCompany?.find(
        (com) =>
          com?.detail?.sector_id === insSector?.id &&
          com?.detail?.insuranceType !== NL_TYPE_LIFE,
      );
      setMyCompany(myCom);
      let overallSegmentData = data.all.filter(
        (row) => row.segment === overallCol,
      );
      let myCompanyData = overallSegmentData?.filter(
        (row) => row?.company === myCom?.detail?.name,
      );
      let segs = [];
      for (let i = 0; i < data.all?.length; i++) {
        if (
          !segs.includes(data.all[i].segment) &&
          data.all[i].segment !== overallCol
        )
          segs.push(data.all[i].segment);
      }
      setSegments(segs);
      console.log("My company", myCom, overallSegmentData);
      console.log("My company data", myCompanyData, segs);

      let sec = myCom?.secondaries ? myCom?.secondaries[0] : null;
      setSecCompanies(myCom?.secondaries);
      setSelCompany(sec);
      let mySecCompanyData = overallSegmentData?.filter(
        (row) => row?.company === sec?.name,
      );

      console.log("mySecCompanyData", mySecCompanyData);

      mySecCompanyData = getArrayOfArray(mySecCompanyData, [
        "particular",
        "value",
      ]);
      // console.log("My company data", myCompanyData, mySecCompanyData);
      myCompanyData = getArrayOfArray(myCompanyData, ["particular", "value"]);
      // console.log("My sec com", mySecCompanyData, myCompanyData);

      setReportData(myCompanyData);
      setReportDataSecondary(mySecCompanyData);
      setInitial(true);
      setStackData(myCom, sec, segs);
    }
  }, [data]);

  useEffect(() => {
    if (selState) {
      onStateSelectSec([selState]);
    } else {
      let segs = [];
      for (let i = 0; i < data.all?.length; i++) {
        if (
          !segs.includes(data.all[i].segment) &&
          data.all[i].segment !== overallCol
        )
          segs.push(data.all[i].segment);
      }
      setStackData(myCompany, selCompany, segs);
    }
  }, [selCompany]);

  const setStackData = (myCom, secCom, segs) => {
    let finalData = [];
    console.log(myCom, secCom, segs);

    for (let i = 0; i < segs.length; i++) {
      let dSeg = data.all.filter((row) => row.segment === segs[i]);
      let segsum = 0.0;
      let selData = dSeg.filter((d) => d["company"] === myCom?.detail?.name);
      for (let j = 0; j < selData?.length; j++) {
        segsum += selData[j].value;
      }
      finalData.push({
        company: myCom?.detail?.name,
        segment: segs[i],
        revenue: segsum,
      });
    }
    setSegData(finalData);
    finalData = [];
    for (let i = 0; i < segs.length; i++) {
      let dSeg = data.all.filter((row) => row.segment === segs[i]);
      let segsum = 0.0;
      let selData = dSeg.filter((d) => d["company"] === secCom?.name);
      for (let j = 0; j < selData?.length; j++) {
        segsum += selData[j].value;
      }
      finalData.push({
        company: secCom?.name,
        segment: segs[i],
        revenue: segsum,
      });
    }
    setSegDataSec(finalData);
  };

  const onCompanyChange = (e) => {
    let comId = e.target.value;
    let com = secondaryCompanies?.find((com) => com.id === Number(comId));
    console.log("COCM", com, comId);
    let mySecCompanyData = data.all?.filter((row) => row?.company === com.name);
    mySecCompanyData = getArrayOfArray(mySecCompanyData, [
      "particular",
      "value",
    ]);
    setReportDataSecondary(mySecCompanyData);
    setSelCompany(com);
  };

  const onStateSelect = (reg, t) => {
    setSelState(reg[0]);
    if (reg[0] === "Orissa") {
      reg[0] = "Odisha";
    }
    let finalData = [];
    let comdata = data.all?.filter(
      (d) =>
        d["company"] === myCompany?.detail?.name && reg[0] === d.particular,
    );
    var totalSum = 0;
    for (let i = 0; i < comdata?.length; i++) {
      console.log(comdata[i].segment, comdata[i].value);

      if (comdata[i].segment !== overallCol) {
        totalSum += comdata[i].value;
        // console.log(comdata[i].value, totalSum);
      }
    }
    // let per = 0;
    for (let i = 0; i < segments.length; i++) {
      let dSeg = comdata.find((row) => row.segment === segments[i]);
      if (dSeg)
        finalData.push({
          segment: segments[i],
          company: myCompany?.detail?.name,
          revenue: Number(((dSeg?.value / totalSum) * 100).toFixed(2)),
        });
      // per += Number(((dSeg?.value / totalSum) * 100).toFixed(2));
      // console.log("per", per, segments[i]);
    }
    // console.log("sum", finalData);

    setSegData(finalData);
  };
  const onStateSelectSec = (reg, t) => {
    setSelState(reg[0]);
    console.log("selCompany", selCompany);
    if (reg[0] === "Orissa") {
      reg[0] = "Odisha";
    }
    let finalData = [];
    let comdata = data.all?.filter(
      (d) => d["company"] === selCompany?.name && reg[0] === d.particular,
    );
    var totalSum = 0;
    for (let i = 0; i < comdata?.length; i++) {
      if (comdata.segment !== overallCol) totalSum += comdata[i].value;
    }

    for (let i = 0; i < segments.length; i++) {
      let dSeg = comdata.find((row) => row.segment === segments[i]);
      if (dSeg)
        finalData.push({
          segment: segments[i],
          company: selCompany?.name,
          revenue: Number(((dSeg?.value / totalSum) * 100).toFixed(2)),
        });
    }
    console.log("sum", finalData);

    setSegDataSec(finalData);
  };

  const onStateChange = (reg, t) => {
    onStateSelect(reg, t);
    onStateSelectSec(reg, t);
  };

  console.log("REPORT DATA: ", reportData, segData);

  return (
    <>
      <div className="row" style={{ marginBottom: 0 }}>
        {initial && !myCompany && !selCompany && (
          <Alert severity="error">
            Configuration for primary & secondary company not added
          </Alert>
        )}
        <div className="col s6">
          <div className="col s12">
            <strong>{myCompany?.detail?.name}</strong>
          </div>
          <GeoChart
            height={400}
            width="100%"
            data={reportData || []}
            ChartSelect={onStateChange}
          />
          <StackBarHorizontal
            id="my-com"
            data={segData}
            dimensions={[{ text: "company" }]}
            measures={[{ text: "revenue" }]}
            group={"segment"}
            can_height={160}
            can_width={580}
            hideLegend={true}
            legendInRow={6}
            noXTick={true}
            colorMap={
              type === NL_TYPE_LIFE ? specialColorMapLifeGeo : specialColorMap
            }
          />
        </div>
        <div className="col s6">
          <div className="col s6">Change Peer</div>
          <div className="col s6">
            <select
              style={{
                padding: 0,
                height: "1.5rem",
                borderBottom: "1px solid black",
                // borderRadius: 10,
                fontSize: 12,
              }}
              id="selectedSegment"
              className="browser-default"
              onChange={onCompanyChange}
              value={selCompany?.id}
            >
              <option value="" disabled>
                Set Peer
              </option>
              {secondaryCompanies?.map((company) => (
                <option value={company.id}>{company.name}</option>
              ))}
            </select>
          </div>
          <GeoChart
            height={400}
            width="100%"
            data={reportDataSecondary || []}
            ChartSelect={onStateChange}
          />
          <StackBarHorizontal
            id="my-sec-com"
            data={segDataSec}
            dimensions={[{ text: "company" }]}
            measures={[{ text: "revenue" }]}
            group={"segment"}
            can_height={160}
            can_width={580}
            hideLegend={true}
            noXTick={true}
            colorMap={
              type === NL_TYPE_LIFE ? specialColorMapLifeGeo : specialColorMap
            }
            margins={{ bottom: 0 }}
          />
        </div>
        <Legends
          marginTop={-60}
          maxChar={12}
          gic={!type === NL_TYPE_LIFE}
          life={type === NL_TYPE_LIFE}
          colorMapCustom={specialColorMap}
          multiLine={true}
        />
      </div>
    </>
  );
}
