import {
  Button,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  findNextOrder,
  findPrevOrder,
  getQuery,
  shapeData,
} from "../../../utils/nlHelper";
import {
  fetchRawNLData,
  setReducer,
  updateRawNLData,
} from "../../../store/action/nlFixAction";
import NLCommonEditModal from "./NLCommonEditModal";

export default function EditRawNL() {
  const tableContainerRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const [value, setValue] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowCol, setSelectedRowCol] = useState({});

  const accessToken = useSelector((state) => state.auth?.accessToken);

  const data = useSelector((state) => state?.nlFix?.nlRawData);

  const nlRawDataUpdateSuccess = useSelector(
    (state) => state?.nlFix?.nlRawDataUpdateSuccess
  );
  const nlRawDataUpdateError = useSelector(
    (state) => state?.nlFix?.nlRawDataUpdateError
  );
  const nlRawDataUpdateMsg = useSelector(
    (state) => state?.nlFix?.nlRawDataUpdateMsg
  );

  const [locData, setLocdata] = useState(null);
  const [basicDetails, setBasicDetails] = useState(null);

  useEffect(() => {
    const urlQuery = getQuery(location?.search);
    const year = urlQuery?.year;
    const quarter = urlQuery?.quarter;
    const cid = urlQuery?.cid;
    const nlid = urlQuery?.nlid;
    const company = urlQuery?.company;
    const nl = urlQuery?.nl;
    setBasicDetails({ year, quarter, cid, nlid, company, nl });

    dispatch(
      fetchRawNLData(
        {
          year: year,
          quarter: quarter,
          cid: cid,
          nlid: nlid,
        },
        accessToken
      )
    );
  }, []);

  useEffect(() => {
    console.log("data", data);
    if (data) {
      let modData = shapeData(data);
      setLocdata(modData);
    }
  }, [data]);

  useEffect(() => {
    if (nlRawDataUpdateError) {
      alert("Error to update.");
      dispatch(
        setReducer({
          nlRawDataUpdateLoading: false,
          nlRawDataUpdateSuccess: false,
          nlRawDataUpdateError: false,
          nlRawDataUpdateMsg: null,
        })
      );
    }
    if (nlRawDataUpdateSuccess) {
      dispatch(
        setReducer({
          nlRawDataUpdateLoading: false,
          nlRawDataUpdateSuccess: false,
          nlRawDataUpdateError: false,
          nlRawDataUpdateMsg: null,
        })
      );
    }
  }, [nlRawDataUpdateSuccess, nlRawDataUpdateError]);

  const addRow = (e, rowData) => {
    let newRow = JSON.parse(JSON.stringify(locData[0]));
    let localData = JSON.parse(JSON.stringify(locData));
    let keys = Object.keys(newRow);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (newRow[key]?.hasOwnProperty("value")) {
        newRow[key]["value"] = "";
      }
    }

    newRow.rowOrder = rowData.rowOrder + 1;
    for (let i = 0; i < localData.length; i++) {
      if (localData[i]?.rowOrder >= rowData.rowOrder + 1) {
        localData[i].rowOrder += 1;
      }
    }

    localData.push(newRow);
    updateDB(localData);
    setLocdata(localData);
  };

  const moveUp = (e, row, type) => {
    let localData = JSON.parse(JSON.stringify(locData));
    let prevOrder = null;
    if (type === "moveup")
      prevOrder = findPrevOrder(row["rowOrder"], localData, "rowOrder");
    else prevOrder = findNextOrder(row["rowOrder"], localData, "rowOrder");
    let otherIndex = localData.findIndex((f) => f.rowOrder === prevOrder);
    let index = localData.findIndex((f) => f.rowOrder === row["rowOrder"]);
    localData[otherIndex]["rowOrder"] = row["rowOrder"];
    localData[index]["rowOrder"] = prevOrder;
    updateDB(localData);
    setLocdata(localData);
  };

  const deleteRow = (e, row) => {
    e.preventDefault();
    let localData = [...locData];
    const rowIndex = localData.findIndex((f) => f.rowOrder === row.rowOrder);
    localData.splice(rowIndex, 1);
    updateDB(localData);
    setLocdata(localData);
  };

  const editValue = (e, row, col) => {
    e.preventDefault();
    if (col.toLowerCase() === "sl no") {
      return;
    }
    let localData = [...locData];
    const rowIndex = localData.findIndex((f) => f.rowOrder === row.rowOrder);
    const value = localData[rowIndex][col]["value"];
    setSelectedRowCol({ row: rowIndex, col: col });
    setValue(value);
    setShowModal(true);

    // const userInput = prompt(`${col} : ${localData[rowIndex][col]["value"]}`);
    // if (userInput !== null && userInput !== undefined) {
    //   localData[rowIndex][col]["value"] = userInput;
    //   setLocdata(localData);
    //   updateDB(localData);
    // }
  };

  const onSubmit = (modalValue) => {
    const localdata = JSON.parse(JSON.stringify(locData));
    localdata[selectedRowCol["row"]][selectedRowCol["col"]]["value"] =
      modalValue;
    setLocdata(localdata);
    updateDB(localdata);
    setShowModal(false);
  };

  const updateDB = (localData) => {
    dispatch(
      updateRawNLData(
        {
          year: basicDetails?.year,
          quarter: basicDetails?.quarter,
          cid: basicDetails?.cid,
          nlid: parseInt(basicDetails?.nlid),
          value: localData,
          colId: "",
          rowId: "",
        },
        accessToken
      )
    );
  };

  console.log("locData", locData);

  return (
    <div style={{ position: "relative" }}>
      <NLCommonEditModal
        show={showModal}
        value={value}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onSubmit}
      />
      <h5 style={{ textAlign: "center" }}>
        {`${basicDetails?.company}-${basicDetails?.nl} ` + "(Edit Raw NL)"}
      </h5>
      {locData?.length > 0 ? (
        <TableContainer
          component={Paper}
          ref={tableContainerRef}
          style={{ maxHeight: "800px" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Action
                </TableCell>
                {Object.keys(locData[0])
                  .filter((f) => !["id", "rowName", "rowOrder"].includes(f))
                  .sort((a, b) =>
                    locData[0][a]["colOrder"] > locData[0][b]["colOrder"]
                      ? 1
                      : -1
                  )
                  .map((key) => (
                    <TableCell
                      key={locData[0][key]["colOrder"]}
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {key}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {locData
                .sort((row_a, row_b) =>
                  row_a.rowOrder > row_b.rowOrder ? 1 : -1
                )
                .map((row, rowIndex) => (
                  <TableRow key={row["rowOrder"]}>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        borderLeft: "1px solid rgba(224, 224, 224, 1)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconButton
                        sx={{ height: 5 }}
                        color="primary"
                        onClick={(e) => addRow(e, row)}
                      >
                        <Icon style={{ fontSize: "15px" }}>add</Icon>
                      </IconButton>
                      {rowIndex !== 0 && (
                        <IconButton
                          sx={{ height: 5 }}
                          color="primary"
                          onClick={(e) => moveUp(e, row, "moveup")}
                        >
                          <Icon style={{ fontSize: "15px" }}>north</Icon>
                        </IconButton>
                      )}
                      {rowIndex !== locData?.length - 1 && (
                        <IconButton
                          sx={{ height: 5 }}
                          color="primary"
                          onClick={(e) => moveUp(e, row, "movedown")}
                        >
                          <Icon style={{ fontSize: "15px" }}>south</Icon>
                        </IconButton>
                      )}
                      <IconButton
                        sx={{ height: 5 }}
                        color="error"
                        onClick={(e) => deleteRow(e, row)}
                      >
                        <Icon style={{ fontSize: "15px" }}>delete</Icon>
                      </IconButton>
                    </TableCell>
                    {Object.keys(locData[0])
                      .filter((f) => !["id", "rowName", "rowOrder"].includes(f))
                      .sort((a, b) =>
                        locData[0][a]["colOrder"] > locData[0][b]["colOrder"]
                          ? 1
                          : -1
                      )
                      .map((col) => (
                        <TableCell
                          key={`${row["rowOrder"]}-${locData[0][col]["colOrder"]}`}
                          onClick={(e) => {
                            editValue(e, row, col);
                          }}
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row[col] && row[col]["value"]}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span>No data found</span>
      )}
    </div>
  );
}
