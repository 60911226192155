import moment from "moment/moment";
import config from "../../config/config";

var backendUrl = config.backendUrl;

export const getData = (companyId, token) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_COMBINE_DATA" });
    var url = backendUrl + "/company/" + companyId + "/getScores";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "COMBINE_DATA_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "COMBINE_DATA_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "COMBINE_DATA_FETCH_ERROR",
        });
      });
  };
};
export const getTranscriptScores = (body, token) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_TRANSCRIPT_DATA" });
    var url = backendUrl + "/company/" + body.company + "/getTranscriptScores";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => {
            if (response.status === 200) {
              // //console.log("action transcript", data)
              data["body"] = body;
              dispatch({
                type: "TRANSCRIPT_DATA_FETCH_SUCCESS",
                payload: data,
              });
            } else if (response.status === 403 || response.status === 401) {
              dispatch({
                type: "AUTHENTICATION_ERROR",
              });
            } else {
              dispatch({
                type: "TRANSCRIPT_DATA_FETCH_ERROR",
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: "TRANSCRIPT_DATA_FETCH_ERROR",
            });
          })
      )
      .catch((err) => {
        dispatch({
          type: "TRANSCRIPT_DATA_FETCH_ERROR",
        });
      });
  };
};

export const getUserReport = (id, name, token) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_REPORTS_LOADING" });
    var url = backendUrl + "/company/" + id + "/getScoresPdf";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.blob().then((blob) => {
          if (response.status === 500) {
            dispatch({
              type: "FETCH_USER_REPORTS_ERROR",
            });
          } else if (response.status === 200) {
            dispatch({
              type: "FETCH_USER_REPORTS_SUCCESS",
            });
            const data = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = data;
            link.download = name + moment().format("YYYYMMDD") + ".pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "FETCH_USER_REPORTS_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "FETCH_USER_REPORTS_ERROR",
        });
      });
  };
};
