import React, { Component } from "react";
import Select from "react-select";
import M from "materialize-css";
import { connect } from "react-redux";
import { getCompanyList, getNews } from "../../store/action/nisActions";
import { filterAudit, submitAudit } from "../../store/action/auditAction";
import "../stylesheets/modals.css";
import "../stylesheets/common.css";
import AuditTable from "../layout/AuditTable";
import moment from "moment";
import { Redirect } from "react-router";

class AuditorDashboard extends Component {
  state = {
    company: [],
    filterCompanies:[],
    totalAsset:{label:"no filter", value:0},
    totalIncome:{label:"no filter", value:0},
    click:false,
  };
  componentDidMount = () => {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var instances = M.Dropdown.init(elems, {});
    const { profile } = this.props;

    this.props.GetCompanyList(profile.accessToken);
  };
  componentDidUpdate = () => {
    if (
      this.props.NIS.companyList &&
      this.props.NIS.companyList.length > 0 &&
      !this.state.companyRefreshed
    ) {
      this.selectOption();
    }
    if (this.props.audit.auditDataSuccess && this.state.click) {
      this.setState({click:false})
      this.props.history.push('/audit')
    }

  };
  selectOption = () => {
    var company = [];
    var NIS = this.props.audit.auditData;
    if (NIS) {
      for (var i = 0; i < NIS.length; i++) {
        if(NIS[i].Company&&!company.find(cc=>cc.value == NIS[i].Company.name))
          company.push({
            value: NIS[i].Company.id,
            label: NIS[i].Company.name,
          });
        else if(NIS[i].company_id){
          company.push({
            value: NIS[i].company_id,
            label: NIS[i].company_name?NIS[i].company_name:NIS[i].companyName,
          });
        }
        else if(NIS[i].companyId){
          company.push({
            value: NIS[i].companyId,
            label: NIS[i].companyName,
          });
        }
      }
    }
    this.setState({
      company: company,
      companyRefreshed: true,
    });
  };
  
  OnAuditSubmit=(id)=>{
    // this.props.AuditSubmit(id, this.props.audit.auditData)
    console.log("SUBMIT", id);
    this.props.SubmitAudit(id, this.props.audit.auditExtraData.year, 'company', this.props.audit.auditExtraData, this.props.auth.accessToken)
    var start_date = moment().subtract(12, 'months').format('MM-DD-yyyy')
    var end_date = moment().format('MM-DD-yyyy')
    this.props.GetNews(start_date, end_date, id, this.props.auth.accessToken)
    this.setState({click:true})
  }
  
  HandleMulSelChange=(options)=>{
    this.setState({filterCompanies:options.map(op=>{return op.value})})
  }

  onOtherChange=(e)=>{
      if (e.target.value == '0'){
        this.setState({[e.target.id]:{label:"no filter", value:0}})
      }
      if (e.target.value == '1'){
        this.setState({[e.target.id]:{label:">=1 million", value:1}})
      }
      else if (e.target.value == '2'){
        this.setState({[e.target.id]:{label:">=500 million", value:2}})
      }
      else if (e.target.value == '3'){
        this.setState({[e.target.id]:{label:">500 million and <1000 million", value:3}})
      }
      else if (e.target.value == '4'){
        this.setState({[e.target.id]:{label:" >1000 million",  value:4}})
      }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: '#2d49a6',
        backgroundColor:'white'
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }
    const { auth, audit} = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;

    console.log("LOC props", this.props);
    return (
      <div className="row" style={{ marginBottom: 0 }}>
        <div className="col s12 l2 m5" style={{}}>
          <div
            className="row center-align"
            style={{
              position: "fixed",
              top: 60,
              width: "inherit",
              borderRight:'solid',
              borderWidth:2,
              borderColor:'#2d49a6',
              backgroundColor: "#2d49a6",
              height: "100vh",
            }}
          >
            <div className="col s10 m10 l10 offset-s1 offset-l1 offset-l1 ">
              <div
                className="row"
                style={{ height: 550, overflowY: "auto", marginTop: 20 }}
              >
                <div className="col s12">
                  <p style={{marginBottom:10, fontWeight:'bold', color:'white'}} >
                    Filter Companies
                  <Select
                    isMulti={true}
                    //value={selectedMulOptions}
                    onChange={this.HandleMulSelChange}
                    options={this.state.company}
                    placeholder="Select companies..."
                    styles= {customStyles}
                  />
                  </p>
                </div>
                <div className="col s12 ">
                  <p
                   style={{marginBottom:10, fontWeight:'bold', color:'white'}} 
                    className="range-field"
                  >
                    Total Asset: {" "}
                    <strong> {this.state.totalAsset?this.state.totalAsset.label:">1 Million"}</strong>
                  </p>
                  <input
                    type="range"
                    step="1"
                    min="0"
                    max="4"
                    onChange={this.onOtherChange}
                    id="totalAsset"
                    value={this.state.totalAsset?this.state.totalAsset.value:1}
                    className="validate center-align"
                    // style={{
                    //   backgroundColor:"#2d49a6",
                    //   color:"#2d49a6"
                    // }}
                    required
                  />
                </div>
                <div className="col s12 ">
                  <p
                   style={{marginBottom:10, fontWeight:'bold', color:'white'}} 
                    className="range-field"
                  >
                    Total Income: {" "}
                    <strong> {this.state.totalIncome?this.state.totalIncome.label:">1 Million"}</strong>
                  </p>
                  <input
                    type="range"
                    step="1"
                    min="0"
                    max="4"
                    onChange={this.onOtherChange}
                    id="totalIncome"
                    value={this.state.totalIncome?this.state.totalIncome.value:1}
                    className="validate center-align"
                    // style={{
                    //   backgroundColor:"#2d49a6",
                    //   color:"#2d49a6"
                    // }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s12 l10 m7 full-div">
          <div className="row center-align">
            <div
              className="col s10 m10 l10 offset-s1 offset-l1 offset-m1"
              style={{ marginBottom: 10 }}
            >
              <div className="center center-align">
                <AuditTable
                  AuditSubmit={this.OnAuditSubmit}
                  curYear={2021}
                  companies={audit.auditTableData}
                  extra={audit.auditExtraData}
                  filter={this.state.filterCompanies}
                  assetFilter={this.state.totalAsset}
                  incomeFilter={this.state.totalIncome}
                  loading={audit.auditDataLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // //console.log(state);
  return {
    auth: state.auth,
    profile: state.auth,
    NIS: state.NIS,
    audit: state.audit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanyList: (token) => {
      dispatch(getCompanyList(token));
    },
    GetNews: (start, end, company, token) => {
      dispatch(getNews(start, end, company, token));
    },
    AuditSubmit: (id, data) => {
      dispatch(filterAudit(id, data));
    },
    SubmitAudit: (id, year, type, extra, token) => {
      dispatch(submitAudit(id, year, type, extra, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditorDashboard);
