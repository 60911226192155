import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getCompanyListForNLReport, getQuery } from "../../../utils/nlHelper";
import {
  fetchRowColumns,
  fetchRowColumnsCompany,
  setReducer,
  updateNLRowColumnsCompanies,
} from "../../../store/action/nlFixAction";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import swal from "sweetalert";

export default function NLRowColCompanyEditTab({ dimension, data,companyId }) {
  const dispatch = useDispatch();

  const [locCompanies, setLocCompanies] = useState([]);

  const accessToken = useSelector((state) => state.auth?.accessToken);

  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);

  const fetchNLRowColCompanyLoading = useSelector(
    (state) => state?.nlFix?.fetchNLRowColCompanyLoading
  );
  const fetchNLRowColCompanySucces = useSelector(
    (state) => state?.nlFix?.fetchNLRowColCompanySuccess
  );
  const fetchNLRowColCompanyError = useSelector(
    (state) => state?.nlFix?.fetchNLRowColCompanyError
  );
  const fetchNLRowColCompanyData = useSelector(
    (state) => state?.nlFix?.fetchNLRowColCompanyData
  );

  const nlRowColCompanyUpdateLoading = useSelector(
    (state) => state?.nlFix?.nlRowColCompanyUpdateLoading
  );
  const nlRowColCompanyUpdateSuccess = useSelector(
    (state) => state?.nlFix?.nlRowColCompanyUpdateSuccess
  );
  const nlRowColCompanyUpdateError = useSelector(
    (state) => state?.nlFix?.nlRowColCompanyUpdateError
  );
  const nlRowColCompanyUpdateMessage = useSelector(
    (state) => state?.nlFix?.nlRowColCompanyUpdateMessage
  );

  useEffect(() => {
    if (data && dimension && data.nl_id && dimension === "row") {
      dispatch(
        fetchRowColumnsCompany(
          data.nl_id,
          dimension,
          encodeURIComponent(data.name),
          {},
          accessToken
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (fetchNLRowColCompanySucces) {
      setLocCompanies(
        fetchNLRowColCompanyData.map((d) => {
          return { value: d.id, label: d.name };
        })
      );
    }
  }, [fetchNLRowColCompanySucces]);

  useEffect(() => {
    if (fetchNLRowColCompanyError) {
      swal("Oops!", "Error to fetch companies", "error");
      dispatch(setReducer({ fetchNLRowColCompanyError: false }));
    }
  }, [fetchNLRowColCompanyError]);

  useEffect(() => {
    if (nlRowColCompanyUpdateSuccess) {
      swal(
        "Success",
        nlRowColCompanyUpdateMessage || "Company assigned",
        "success"
      );
      if (companyId) {
        dispatch(fetchRowColumns(data.nl_id, { companyId: companyId }, accessToken));
      } else {
        dispatch(fetchRowColumns(data.nl_id, {}, accessToken));
      }
      dispatch(
        fetchRowColumnsCompany(
          data.nl_id,
          dimension,
          encodeURIComponent(data.name),
          {},
          accessToken
        )
      );
      dispatch(setReducer({ nlRowColCompanyUpdateSuccess: false }));
    }
  }, [nlRowColCompanyUpdateSuccess]);

  useEffect(() => {
    if (nlRowColCompanyUpdateError) {
      dispatch(setReducer({ nlRowColCompanyUpdateError: false }));
      swal(
        "Oops!",
        nlRowColCompanyUpdateMessage || "Error to assign company",
        "error"
      );
    }
  }, [nlRowColCompanyUpdateError]);

  const isInsuranceCompany = (company) => {
    return getCompanyListForNLReport(company, sectors);
  };

  const submitCompanies = () => {
    dispatch(
      updateNLRowColumnsCompanies(
        data?.nl_id,
        dimension,
        data?.name,
        {
          lable: data?.lable,
          order: data?.order,
          companies: locCompanies.map((item) => {
            return item.value;
          }),
        },
        accessToken
      )
    );
  };

  return (
    <div style={{ paddingTop: "15px" }}>
      {(fetchNLRowColCompanyLoading || nlRowColCompanyUpdateLoading) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="row">
        <div className="col s12">
          <Select
            isMulti={true}
            onChange={(value) => {
              setLocCompanies(value);
            }}
            value={locCompanies?.map((com, index) => {
              return { id: index, value: com.value, label: com.label };
            })}
            options={companies
              ?.filter(isInsuranceCompany)
              ?.map((com, index) => {
                return { id: index, value: com.id, label: com.name };
              })}
            placeholder="Select companies..."
          />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <Button
            variant="contained"
            className="right"
            onClick={submitCompanies}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
