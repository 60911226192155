import React from "react";
import "./style.css"

export default function Description({toolId}) {
  return (
    <div>
      <div id="description-modal" className="modal description-modal">
        <div className="modal-content">
          <div
            className="modal-title row"
          >
            <div className="col s10">
            {
              toolId == "distress predictor" ? (
                <h3>Distress Predictor</h3>
              ) : toolId == "news impact" ? (
                <h3>News Impact</h3>
              ) : toolId == "sector meter" ? (
                <h3>Sector Meter</h3>
              ) : toolId == "announcement" ? (
                <h3>Announcement</h3>
              ) : toolId == "stock pulse" ? (
                <h3>Stock Pulse</h3>
              ) : toolId == "concall" ? (
                <h3>Con Call</h3>
              ) : toolId == "acc-policy" ? (
                <h3>Accounting Policy</h3>
              ) : toolId == "audit-matter" ? (
                <h3>Audit Matter</h3>
              ) : toolId == "audit-monitor" ? (
                <h3>Audit Report</h3>
              ) : toolId == "broad-finance" ? (
                <h3>Broad Finance</h3>
              ): null
            }
            </div>
            <div className="col s2 right right-align">
              <i className="material-icons modal-close"  style={{fontSize:30}}>clear</i>
            </div>
          </div>
          <div className="row">
          {
              toolId == "distress predictor" ? (
                <div className="row"
                data-aos="fade-up"
                data-aos-delay="100"
                >
                  <div
                    className="col s12 m8 l8"
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <img src="assets/img/pd-sc.jpeg" className="img-fluid" alt="" />
                  </div>

                  <div
                    className="col s12 m4 l4 prod-detail"
                    style={{ paddingLeft: 20 }}
                  >
                    <p className="font-italic">
                      A unique text-based default predictor that uses content of
                      Annual Report/ Corporate Filings.
                    </p>
                    <ul>
                      <li>
                        <i className="bx bx-check-double"></i>Corporate annual report
                        contains significant narrative information.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i>Standard default
                        prediction models ONLY use quantitative information and
                        ignore narrative information.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> Our product focuses on
                        the tonal quality of the narrative of an annual report
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i>It is an early warning
                        measure of financial distress based on qualitative
                        information present in corporate annual reports.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> The tool uses a
                        proprietary text-based model for estimating default
                        probability of firms- listed as well as unlisted.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> The methodology
                        constructs a systemic financial distress prediction process
                        based on the tone of corporate annual report text
                        information.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> The tool has a strong
                        predictor power and hence can be used as an early warning
                        system.
                      </li>
                    </ul>
                  </div>
                </div>
              ) : toolId == "news impact" ? (
                <div className="row"
                data-aos="fade-up"
                data-aos-delay="100"
                >
                  <div
                    className="col s12 l8 m8"
                    style={{ paddingTop: 10 }}
                  >
                    <img src="assets/img/nis-sc.jpeg" className="img-fluid" alt="" />
                  </div>
                  <div
                    className="col s12 m4 l4 prod-detail"
                    style={{ paddingLeft: 20 }}
                  >
                    <p className="font-italic">
                      Aggregates news sentiments for any company from online
                      structured news of reliable media house.
                    </p>
                    <ul>
                      <li>
                        <i className="bx bx-check-double"></i>There is a saying that ‘no
                        news is a good news’
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i>NIS tracks every news
                        about a company that appears in the media (excluding social
                        media and TV).
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> It assigns sentiments to
                        every news article that is relevant for a given company and
                        then aggregates at daily, weekly or monthly level.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> Every article is also
                        checked for its novelty (uniqueness).
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> Once relevance and
                        novelty is ensured, NIS estimates sentiments for every news
                        article.
                      </li>
                      <li>
                        <i className="bx bx-check-double"></i> We use cutting edge
                        machine learning techniques to generate sentiment scores.
                      </li>
                    </ul>
                  </div>
                </div>
              ) : toolId == "sector meter" ? (
                  <div className="row"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  >
                    <div
                      className="col s12 m8 l8 prod-detail"
                      style={{ paddingTop: 10 }}
                    >
                      <img
                        src="assets/img/sector-meter-sc.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                        Captures sentiment of a sector using online news about a
                        sector. SM indicates stress of a sector.
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>The Sector Meter captures
                          the heartbeat of a sector.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>The stress level of each
                          sector varies.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>It uses news on sectors
                          and also leading companies within a sector.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>SM uses machine learning
                          technique to generate sentiment scores of a sector.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Higher the value of SM,
                          greater is the expected vulnerability of the sector
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "announcement" ? (
                  <div className="row"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  >
                    <div
                      className="col s12 l8 m8"
                      style={{ paddingTop: 10 }}
                    >
                      <img src="assets/img/announcement-sc.jpeg" className="img-fluid" alt=""/>
                    </div>
                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                      It captures all the announcement filings with the stock exchanges as and when these are filed with the exchange
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Captures all announcements by listed companies
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Identifies a topic for every announcement
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i> Labels every announcement in terms of importance- HIGH, MODERATE and LOW
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i> Users can choose one or more topics for a company for a given period.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i> If required, users can access the full text of any corporate action.
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "stock pulse" ? (
                  <div className="row"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  >
                    <div
                      className="col s12 m8 l8"
                      style={{ paddingTop: 10 }}
                    >
                      <img
                        src="assets/img/stock-pulse-sc.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                      Stock pulse does not recommend any buy, hold, or sell decisions. It simply classifies a news in terms of its likely impact on the future share price
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Captures stock market related news on Companies / Sector /overall Market
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Real time analysis
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Generates sentiment of news in three unambiguous categories
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Also captures data on brokers, and borking houses
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Full text of a news item can be viewed.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>No recommendation- only sentiments.
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "concall" ? (
                  <div className="row"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  >
                    <div
                      className="col s12 m8 l8 prod-detail"
                      style={{ paddingTop: 10 }}
                    >
                      <img
                        src="assets/img/concall-sc-1.jpeg"
                        className="img-fluid"
                        alt=""
                        style={{maxHeight:580}}
                      />
                    </div>
                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                        Captures Concall annoucement data with importrant set of sentences.
                        Sentimantal score of concall report
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Grpahical Represent of over all Quarte
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Impotant sentence tagging
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Peer group analysis
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Sentimental score
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "acc-policy" ? (
                  <div className="row"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  >
                    <div
                      className="col s12 l8 m8"
                      style={{ paddingTop: 10 }}
                    >
                      <img src="assets/img/acc-policy-sc.png" style={{maxHeight:580}} className="img-fluid" alt=""/>
                    </div>

                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                      Tracks any change in accounting policies and highlights areas of change for immediate attention 
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Change alerts along with relevant sections
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Option to browse through the accounting policies of two consecutive years.
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Highlights any change in accounting policies of select peers. 
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "audit-matter" ? (
                  <div className="row"
                  data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div
                      className="col s12 m8 l8"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <img src="assets/img/audit-matter-sc.png" className="img-fluid" alt="" />
                    </div>

                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                      Explores audit reports to highlight essential information 
                      and uses deep learning to identify audit
                      alerts.
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Name of auditors
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Peer companies under same auditor
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Change of auditor alert
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Audit report alerts
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "audit-monitor" ? (
                  <div className="row"
                  data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div
                      className="col s12 m8 l8"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <img src="assets/img/pd-sc.jpeg" className="img-fluid" alt="" />
                    </div>
        
                    <div
                      className="col s12 m4 l4 prod-detail"
                      data-aos="fade-left"
                      data-aos-delay="100"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                      Provides audit-related information and generates audit 
                      complexity score for every corporate entity
                      using financial information and quality of audit report.
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Select financial information
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Audit fees
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Related party transaction details
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Audit complexity score
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Partner rotation  
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i> Auditor rotation
                        </li>
                      </ul>
                    </div>
                  </div>
              ) : toolId == "broad-finance" ? (
                  <div className="row"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div
                      className="col s12 l8 m8"
                      style={{ paddingTop: 10 }}
                    >
                      <img src="assets/img/broad-finance-sc.jpeg" className="img-fluid"alt=""/>
                    </div>
                    <div
                      className="col s12 m4 l4 prod-detail"
                      style={{ paddingLeft: 20 }}
                    >
                      <p className="font-italic">
                      Provides peer-group comparison on select financial parameters on annual basis                
                      </p>
                      <ul>
                        <li>
                          <i className="bx bx-check-double"></i>Leverage
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Profitability
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i> Efficiency
                        </li>
                        <li>
                          <i className="bx bx-check-double"></i>Intangibility
                        </li>
                      </ul>
                    </div>
                  </div>
              ): null
            }
          </div>
        </div>
      </div>
    </div>
  );
}
