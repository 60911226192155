import { Icon, IconButton, Popover } from "@mui/material";
import React from "react";

export default function ThumbsComponents({
  promoter,
  passive,
  detractor,
  promoterLabel,
  passiveLabel,
  detractorLabel,
  headline,
  dataHead,
  data,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        id={"data"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="row" style={{ marginBottom: 0, fontWeight: "bold" }}>
          <div className="col s9">{dataHead}</div>
          <div className="col s3">Growth</div>
        </div>
        {data?.data?.map((row) => (
          <div className="row" style={{ marginBottom: 0 }}>
            <div className="col s9">{row[data?.colKey]}</div>
            <div className="col s3 right-align">
              {row[data?.valKey] && typeof row[data?.valKey] === "number"
                ? row[data?.valKey]?.toFixed(2)
                : 0}
            </div>
          </div>
        ))}
      </Popover>
      <div className="row">
        <strong>{headline}</strong>
        <IconButton onClick={handleClick}>
          <Icon>info-outline</Icon>
        </IconButton>
      </div>
      <div className="row">
        <div className="col s1">
          <i className="material-icons green-text">thumb_up</i>
        </div>
        <div className="col s11">
          {promoterLabel
            ? promoterLabel + " " + promoter
            : "Performing well in " + promoter}
        </div>
      </div>
      {passive && (
        <div className="row">
          <div className="col s1">
            <i className="material-icons orange-text">thumb_up</i>
          </div>
          <div className="col s11">
            {passiveLabel
              ? passiveLabel + " " + passive
              : "Needs improvement in " + passive}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col s1">
          <i className="material-icons red-text">thumb_down</i>
        </div>
        <div className="col s11">
          {detractorLabel
            ? detractorLabel + " " + detractor
            : "Needs significant improvement in " + detractor}
        </div>
      </div>
    </>
  );
}
