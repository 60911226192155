import React from 'react'
import './style.css';

export default function ForgotPass({state, status, HandleChange, OnSubmit}) {
    return (
        <div className='modal forgot-pass-modal modal-fixed-footer' id='forgot-pass-modal'>
            <div className="row modal-content forgot-pass-modal-content">
                <div className="row">
                    <div className="row modal-title">
                        <h1 className='reset-pass'>Reset Password</h1>
                    </div>
                    <div className="input-field col s12">
                        <input
                        type='email'
                        id='resetPassEmail'
                        onChange={HandleChange}
                        value={state.resetPassEmail}
                        />
                        <label for="resetPassEmail">Email Id</label>
                    </div>
                    {
                        status?
                        <p>Loading ...</p>
                        :
                        null
                    }
                </div>
            </div>
            <div className="modal-footer">
                <button className="modal-close waves-effect waves btn-flat">
                CANCEL
                </button>
                <button
                type="submit"
                onClick={OnSubmit}
                disabled={status}
                className="waves-effect waves btn-flat"
                >
                SUBMIT
                </button>
            </div>
        </div>
    )
}
