import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getQuery } from "../../../utils/nlHelper";
import {
  deleteDynamicNL,
  editDynamicNL,
  fetchDynamicNL,
  setReducer,
} from "../../../store/action/nlFixAction";
import {
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NLCommonEditModal from "./NLCommonEditModal";

const shapeData = (data) => {
  let keys = Object.keys(data);
  let result = [];
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let obj = {
      ...data[key],
      rowOrder: parseInt(key),
      id: i,
    };
    let cols = Object.keys(data[key]);
    if (cols?.length > 0) {
      obj["rowName"] = { colId: -1, value: data[key][cols[0]]["rowName"] };
    }
    result.push(obj);
  }
  let finalResult = result.sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1));

  return finalResult;
};

export default function EditRawDynamicNL() {
  const tableContainerRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.auth?.accessToken);

  const fetchDynamicNLData = useSelector(
    (state) => state?.nlFix?.fetchDynamicNLData
  );

  const editDynamicNlLoading = useSelector(
    (state) => state?.nlFix?.editDynamicNlLoading
  );
  const editDynamicNlSuccess = useSelector(
    (state) => state?.nlFix?.editDynamicNlSuccess
  );
  const editDynamicNlError = useSelector(
    (state) => state?.nlFix?.editDynamicNlError
  );
  const editDynamicNlMessage = useSelector(
    (state) => state?.nlFix?.editDynamicNlMessage
  );

  const deleteDynamicNlLoading = useSelector(
    (state) => state.nlFix?.deleteDynamicNlLoading
  );
  const deleteDynamicNlSuccess = useSelector(
    (state) => state.nlFix?.deleteDynamicNlSuccess
  );
  const deleteDynamicNlError = useSelector(
    (state) => state.nlFix?.deleteDynamicNlError
  );
  const deleteDynamicNlMessage = useSelector(
    (state) => state.nlFix?.deleteDynamicNlMessage
  );

  const [basicDetails, setBasicDetails] = useState(null);
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const urlQuery = getQuery(location?.search);
    const year = urlQuery?.year;
    const quarter = urlQuery?.quarter;
    const cid = urlQuery?.cid;
    const nlid = urlQuery?.nlid;
    const company = urlQuery?.company;
    const nl = urlQuery?.nl;
    setBasicDetails({
      year,
      quarter,
      cid: cid,
      nlid: nlid,
      company,
      nl,
    });

    dispatch(
      fetchDynamicNL(
        {
          year: year,
          quarter: quarter,
          cid: cid,
          nlid: nlid,
        },
        accessToken
      )
    );
  }, []);

  useEffect(() => {
    console.log("data", fetchDynamicNLData);
    if (fetchDynamicNLData) {
      let modData = shapeData(fetchDynamicNLData);
      setData(modData);
    }
  }, [fetchDynamicNLData]);

  useEffect(() => {
    if (editDynamicNlSuccess) {
      dispatch(
        fetchDynamicNL(
          {
            year: basicDetails.year,
            quarter: basicDetails.quarter,
            cid: basicDetails.cid,
            nlid: basicDetails.nlid,
          },
          accessToken
        )
      );
      dispatch(setReducer({ editDynamicNlSuccess: false }));
    }
    if (editDynamicNlError) {
      alert(editDynamicNlMessage || "Error to update NL.");
      dispatch(setReducer({ editDynamicNlError: false }));
    }
  }, [editDynamicNlSuccess, editDynamicNlError]);

  useEffect(() => {
    if (deleteDynamicNlSuccess) {
      dispatch(setReducer({ deleteDynamicNlSuccess: false }));
      dispatch(
        fetchDynamicNL(
          {
            year: basicDetails.year,
            quarter: basicDetails.quarter,
            cid: basicDetails.cid,
            nlid: basicDetails.nlid,
          },
          accessToken
        )
      );
    }
    if (deleteDynamicNlError) {
      dispatch(setReducer({ deleteDynamicNlError: false }));
      alert(deleteDynamicNlMessage || "Error to delete NL");
    }
  }, [deleteDynamicNlSuccess, deleteDynamicNlError]);

  const editValue = (e, info) => {
    e.preventDefault();
    setValue(info);
    setShowModal(true);
  };

  const onEdit = (modalValue) => {
    setShowModal(false);
    dispatch(
      editDynamicNL(
        {
          colId: value.colId,
          colOrder: value.colOrder,
          rowId: value.rowId,
          rowName: value.rowName,
          value: modalValue,
          dataid: value.dataId,
          dataType: value.dataType,
        },
        accessToken
      )
    );
  };

  const onDelete = (e, row) => {
    e.preventDefault();
    console.log(row);
    let keys = Object.keys(row);
    let delete_data = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (key !== "id" && key != "rowName" && key != "rowOrder") {
        delete_data.push(row[key]["dataId"]);
      }
    }
    dispatch(deleteDynamicNL({ id_list: delete_data }, accessToken));
  };

  return (
    <div style={{ position: "relative" }}>
      <NLCommonEditModal
        show={showModal}
        value={value?.value}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onEdit}
      />
      <h5 style={{ textAlign: "center" }}>
        {`${basicDetails?.company}-${basicDetails?.nl} ` + "(Edit Raw NL)"}
      </h5>
      {data?.length > 0 ? (
        <TableContainer
          component={Paper}
          ref={tableContainerRef}
          style={{ maxHeight: "800px" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Actions
                </TableCell>
                {Object.keys(data[0])
                  .filter((f) => !["id", "rowOrder", "key"].includes(f))
                  .sort((a, b) =>
                    data[0][a]["colId"] > data[0][b]["colId"] ? 1 : -1
                  )
                  .map((key) => (
                    <TableCell
                      key={data[0][key]["colId"]}
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {key}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .sort((row_a, row_b) =>
                  row_a.rowOrder > row_b.rowOrder ? 1 : -1
                )
                .map((row, rowIndex) => (
                  <TableRow key={row["rowOrder"]}>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <IconButton
                        sx={{ height: 5 }}
                        color="error"
                        onClick={(e) => onDelete(e, row)}
                      >
                        <Icon style={{ fontSize: "15px" }}>delete</Icon>
                      </IconButton>
                    </TableCell>
                    {Object.keys(data[0])
                      .filter((f) => !["id", "rowOrder", "key"].includes(f))
                      .sort((a, b) =>
                        data[0][a]["colId"] > data[0][b]["colId"] ? 1 : -1
                      )
                      .map((col) => (
                        <TableCell
                          key={`${row["rowOrder"]}-${data[0][col]["colId"]}`}
                          onClick={(e) => {
                            editValue(e, row[col]);
                          }}
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {row[col] && row[col]["value"]}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span>No data found</span>
      )}
    </div>
  );
}
