import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

function ApplyFilter(asset, filter){
  if (filter.value == 0){
    return true
  }
  else if (filter.value == 1){
    if (asset>=1) return true
  }
  else if (filter.value == 2){
    if (asset>=500) return true
  }
  else if (filter.value == 3){
    if (asset>500 && asset<=1000) return true
  }
  else if (filter.value == 4){
    if (asset>1000) return true
  }
  return false
}

export default function AuditTable({ curYear, companies, AuditSubmit, extra, filter, assetFilter, incomeFilter, loading }) {
  // console.log("Companies", companies, filter, assetFilter);
  companies = companies&&companies.length>0? 
  filter.length>0?
    assetFilter?
      incomeFilter?
          companies.filter(c=> filter.find(f=> (c.Company&&f == c.Company.id)|| f==c.companyId||c.company_id) 
                      && ApplyFilter( c.total_asset_s , assetFilter)
                      && ApplyFilter( c.total_income_s , incomeFilter)
                    )
        :
          companies.filter(c=> filter.find(f=> (c.Company&&f == c.Company.id)|| f==c.companyId||c.company_id) 
                      && ApplyFilter( c.total_asset_s , assetFilter)
                    )
    :
      incomeFilter?
          companies.filter(c=> filter.find(f=> (c.Company&&f == c.Company.id)|| f==c.companyId||c.company_id) 
                      && ApplyFilter( c.total_income_s , incomeFilter)
                    )
      :
          companies.filter(c=> filter.find(f=> (c.Company&&f == c.Company.id)|| f==c.companyId||c.company_id))
  :
    assetFilter&&companies[0].total_asset_s? 
      incomeFilter&&companies[0].total_income_s?
        companies.filter(c=> ApplyFilter( c.total_asset_s , assetFilter)
                      &&ApplyFilter( c.total_income_s , incomeFilter)
                    )
      :
        companies.filter(c=> ApplyFilter( c.total_asset_s , assetFilter))
    :
      incomeFilter&&companies[0].total_income_s?
        companies.filter(c=> ApplyFilter( c.total_income_s , incomeFilter))
      :
        companies
  :[]
  // console.log("Companies", companies, filter);
  return (
    <div>
      <div
        className="row left-align"
        style={{
          borderBottom: "solid",
          fontWeight: "bolder",
          marginTop: 10,
          marginBottom: 5,
          marginLeft: 0,
          fontSize: 30,
          color:'#2d49a6'
        }}
      >
        {extra?
        extra.type=='auditor'?
          "Auditor Name: "+extra.name
          :
          extra.type=="continuation"?
          "Companies with same audit firms more than "+extra.noOfYear+" Years"
          :extra.type=="alerts"?
          "Companies with alerts more than "+extra.noOfAlerts
          :extra.type=="accpol"?
          "Companies with Accounting policy changes more than "+extra.noOfChange
          :"Partner Name: "+extra.name:null
        }
      </div>
      {
        loading&&
        <div className="progress">
            <div className="indeterminate"></div>
        </div>
      }      
      <table
        className="tableFixed responsive-table"
        style={{ marginLeft: 0, fontSize: 14 }}
      >
        <thead>
          <tr>
            <td style={{width:'30%', fontWeight: "bolder" }}>Company</td>
            {extra&&extra.type=="continuation"
            ||extra.type=="accpol"
            ||extra.type=="alerts"?
            <td style={{width:'20%', fontWeight: "bolder" }}>Audit Firm Name</td>
            :
            <td style={{width:'20%', fontWeight: "bolder" }}>No Of Year</td>}
            <td style={{width:'15%', fontWeight: "bolder" }}>Total Asset</td>
            <td style={{width:'15%', fontWeight: "bolder" }}>Total Income</td>
            {extra&&extra.type=="continuation"?
            <td style={{width:'20%', fontWeight: "bolder" }}>No of Year</td>
            :extra&&extra.type=="alerts"?
            <td style={{width:'20%', fontWeight: "bolder" }}>No of Alerts</td>
            :extra&&extra.type=="allpol"?
            <td style={{width:'20%', fontWeight: "bolder" }}>No of Changes</td>
            :
            <td style={{width:'20%', fontWeight: "bolder" }}>Complexity Score</td>}
          </tr>
        </thead>
        <tbody style={{height:'70vh'}}>
          {companies&&companies.map((c, i) => (
            <tr>
              <td style={{width:'30%',  fontWeight:'bold', backgroundColor:i%2==0?"#0000":"#cfd9ff"}}>{
                c.companyName?c.companyName:c.Company.name
              }</td>
              <td style={{width:'20%', backgroundColor:i%2==0?"#0000":"#cfd9ff"}}>{
              extra&&extra.type=="continuation"
              ||extra.type=="accpol"
              ||extra.type=="alerts"?
              c.auditFirmName
              :
              c.no_of_year
              }</td>
              <td style={{width:'15%', backgroundColor:i%2==0?"#0000":"#cfd9ff"}}>
                {c.total_asset_s?Number(c.total_asset_s).toFixed(2):0}
              </td>
              <td style={{width:'15%', backgroundColor:i%2==0?"#0000":"#cfd9ff"}}>
                {c.total_income_s?Number(c.total_income_s).toFixed(2):0}
              </td>
              <td style={{width:'20%', backgroundColor:i%2==0?"#0000":"#cfd9ff"}}>
                  <button
                    className="center-align"
                    disabled={loading}
                    style={{
                      width: 70,
                      height: 30,
                      marginTop: 5,
                      marginBottom: 0,
                      color: "white",
                      borderRadius: 10,
                      border: 0,
                      backgroundColor:
                      loading?"gray"
                      :
                        c.score == null
                          ? "transparent"
                          : c.score <= 0.3
                          ? "#0F8305"
                          : c.score > 0.3 && c.score <= 0.5
                          ? "coral"
                          : c.score > 0.5
                          ? "#DF0101"
                          : "#99004c",
                    }}
                    onClick={()=>{AuditSubmit(
                    c.Company?
                      c.Company.id
                    :
                      c.companyId?c.companyId:c.company_id)}}
                  >
                    {c.score
                        ? extra && (extra.type=="continuation" || extra.type=="alerts" || extra.type=="accpol")
                          ? Number(c.score)
                          : Number(c.score).toFixed(3)
                        : 0
                    }
                  </button>
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
