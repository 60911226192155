import React, { Component } from "react";
import * as d3 from "d3";

export class ArcChart extends Component {
  componentDidMount() {
    this.drawChart();
  }
  componentDidUpdate() {
    this.drawChart();
  }
  drawChart() {
    const {
      id,
      data,
      width,
      height,
      dimensions,
      measures,
      ex,
      onClick,
      font,
      sort = false,
      ranges,
      meter = false,
    } = this.props;
    console.log(data);
    var pie = d3
      .pie() //this will create arc data for us given a list of values
      .startAngle(-90 * (Math.PI / 180))
      .endAngle(90 * (Math.PI / 180))
      .padAngle(0.02) // some space between slices
      .sort(null) //No! we don't want to order it by size
      .value((d) => Math.abs(d[measures[0].text]));

    const radius = Math.max(width, height);
    const arcLabel = d3
      .arc()
      .startAngle(-90 * (Math.PI / 180))
      .endAngle(90 * (Math.PI / 180))
      .innerRadius(0)
      .outerRadius(20);

    const arc = d3.arc().innerRadius(80).outerRadius(100);
    const arcs = pie(data);
    d3.select("#" + id)
      .select("svg")
      .remove();

    const svg = d3
      .select("#" + id)
      .append("svg")
      .attr("viewBox", [-height / 2, -width / 2, width, height / 2]);

    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d[dimensions[0].text]))
      .range(
        d3
          .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
          .reverse(),
      );

    svg
      .append("g")
      .attr("stroke", "white")
      .selectAll("path")
      .data(arcs)
      .join("path")
      .attr("fill", (d, i) => {
        if (ranges) {
          let effec_ranges =
            data[0][measures[0].text] > 0 ? ranges.pos : ranges.neg;
          if (meter && i > 0) {
            return "#e6e7e8";
          }
          let val = Math.abs(d.data[measures[0].text]);
          for (let k = 0; k < effec_ranges.length; k++) {
            let start = effec_ranges[k].start;
            let end = effec_ranges[k].end;
            if (!end && val >= start) {
              return effec_ranges[k].color;
            }
            if (val >= start && val < end) {
              return effec_ranges[k].color;
            }
          }
        } else {
          return color(d?.data[dimensions[0].text]);
        }
      })
      .attr("d", arc)
      .append("title")
      .text(
        (d) =>
          `${measures[0].text}: ${d.data[measures[0].text].toLocaleString()}`,
      );

    let comment = "";
    if (meter) {
      if (data[0][measures[0].text] > 0) {
        comment =
          Math.abs(data[0][measures[0].text]).toFixed(2) + "% " + "\u2191";
      } else if (data[0][measures[0].text] < 0) {
        comment = Math.abs(data[0][measures[0].text]).toFixed(2) + "\u2193";
      }
    }
    svg
      .append("text")
      .attr("font-size", "1.5rem")
      .attr("text-anchor", "middle")
      .attr("fill", data[0][measures[0].text] > 0 ? "green" : "red")
      .text(comment);
  }

  render() {
    return (
      <div>
        <div id={this.props.id} style={{ margin: 10 }}></div>
        <div style={{ textAlign: "center" }}>
          Over your last year same quarter
          {this.props.subtitle && (
            <span style={{ textAlign: "center" }}>
              , Leader growth {this.props.subtitle} %
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default ArcChart;
