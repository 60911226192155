import React, { Component } from "react";
import BarChart from "../layout/BarChart";
import { connect } from "react-redux";
import { initResponse, submitPD } from "../../store/action/uploadAction";
import Dashboard from "./Dashboard";
import { Redirect } from "react-router-dom";
import moment from "moment";
import BarChartAlert from "../layout/BarChartAlert";
import AuditModal from "../layout/AuditModal";
import M from "materialize-css";
import News from "../layout/News";
import { getAuditYears, switchAudit } from "../../store/action/auditAction";
import "./style.css";

class BroadFinance extends Component {
  state = {
    width: 700,
    height: 500,
    clickType: null,
    name: "",
    year: null,
  };

  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {});
    var elems1 = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems1, { position: "bottom" });
    document.body.style.overflow = "visible";
    if (this.props.initData && this.props.initData.year) {
      this.setState({
        year: this.props.initData.year,
      });
    }

    if (this.props.auditData) {
      this.props.GetAuditYears(
        this.props.auditData.Company.id,
        this.props.auth.accessToken
      );
    }
  }
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };
  onYearChange = (year) => {
    const { auth } = this.props;
    this.setState({ year });
    if (
      this.props.yearData &&
      this.props.yearData.years &&
      this.props.yearData.years.includes(Number(year))
    ) {
      this.props.SubmitPD(this.state.companyData, year, auth.accessToken);
    } else {
      alert("Data Not found...");
      // //console.log(this.props.yearData.years, year);
    }
  };

  onSwitchAudit = (id) => {
    if (!id) alert("Sorry! No data found...");
    else this.props.SwitchAudit(id);
  };

  render() {
    const { auth, auditData, auditYear, secAuditData, sectors } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    console.log("props", this.props);
    const { TopNews } = this.props;
    return (
      <div className="container valign-wrapper" style={{ width: "90%" }}>
        <div className="modal ar-show-modal" id="ar-show-modal">
          <div className="modal-content">
            <h4>
              <span>Annual Report. </span>
              {auditData.rpt && <span>Page:{auditData.rpt}</span>}
            </h4>
            {auditData.ar_link && (
              <iframe
                src={auditData.ar_link}
                width="100%"
                height="600px"
              ></iframe>
            )}
          </div>
          <div className="modal-footer">
            <a
              href="#!"
              className="modal-close waves-effect waves-green btn-flat"
            >
              Agree
            </a>
          </div>
        </div>
        {auditData && (
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="col s12 m12 l12">
              <div
                className="card cardDiv"
                style={{
                  // backgroundColor: "#eee",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div className="card-content">
                  <h3 className="card-title" style={{ marginTop: 0 }}>
                    <div
                      className="col s12 m10 l10"
                      style={{ marginBottom: 0 }}
                    >
                      <strong style={{ color: "#2d49a6" }}>
                        Broad Financials :
                        {auditData ? " " + auditData.Company.name : ""}{" "}
                      </strong>
                    </div>

                    <div
                      className="col s12 m2 l2"
                      style={{ fontSize: 12, fontWeight: 1000 }}
                    >
                      * All figures in million rupees
                    </div>

                    <div
                      className="col s12 m12 l12"
                      style={{ marginBottom: 0 }}
                    >
                      <table
                        className="pdTableY"
                        style={{ marginLeft: 0, fontSize: 14 }}
                      >
                        <thead>
                          {this.props.yearData &&
                            this.props.yearData.years &&
                            this.props.yearData.years.map((year) => (
                              <th
                                key={year}
                                style={
                                  Number(year) == Number(this.state.year)
                                    ? {
                                        backgroundColor: "#2d49a6",
                                        color: "white",
                                      }
                                    : { color: "#2d49a6" }
                                }
                              >
                                <b>{year}</b>
                              </th>
                            ))}
                        </thead>
                        <tbody>
                          <tr
                            style={{
                              border: 1,
                              borderStyle: "solid",
                              borderColor: "#2d49a6",
                            }}
                          >
                            {this.props.yearData &&
                              this.props.yearData.years &&
                              this.props.yearData.years.map((year) => (
                                <td
                                  key={year}
                                  style={
                                    Number(year) == Number(this.state.year)
                                      ? {
                                          backgroundColor: "#2d49a6",
                                          color: "white",
                                        }
                                      : { color: "#2d49a6" }
                                  }
                                  id={year}
                                >
                                  <i
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.onYearChange(year);
                                    }}
                                    className="material-icons"
                                  >
                                    chevron_right
                                  </i>
                                </td>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </h3>
                  <div className="row">
                    <div className="col s12">
                      <div className="col s3" style={{ fontWeight: "bold" }}>
                        Audit Firm
                      </div>
                      <div className="col s3" style={{ fontWeight: "bold" }}>
                        Partner
                      </div>
                      <div className="col s2" style={{ fontWeight: "bold" }}>
                        Place
                      </div>
                      <div className="col s2" style={{ fontWeight: "bold" }}>
                        Date
                      </div>
                      <div className="col s2" style={{ fontWeight: "bold" }}>
                        No. of Year
                      </div>
                    </div>
                    <div className="col s12">
                      <div className="col s3">{auditData.AuditFirm.name}</div>
                      <div className="col s3">
                        {auditData.AuditPartner.name}
                      </div>
                      <div className="col s2">{auditData.audit_location}</div>
                      <div className="col s2">{auditData.audit_date}</div>
                      <div className="col s2">
                        {
                          auditYear.filter(
                            (ay) => ay.audit_firm_id == auditData.audit_firm_id
                          ).length
                        }
                      </div>
                    </div>
                  </div>

                  <div
                    className="row"
                    // style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    <div className="col s5">
                      <h5>Basic Stats</h5>
                      <div
                        className="row"
                        style={{ height: 200, overflowY: "auto" }}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <th>Total Assets</th>

                              <td>
                                {auditData.total_asset_s
                                  ? auditData.total_asset_s.toFixed(3)
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Borrowings</th>

                              <td>
                                {auditData.total_income_s
                                  ? auditData.total_income_s.toFixed(3)
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Total Income</th>
                              <td>{auditData.total_income_s}</td>
                            </tr>
                            <tr>
                              <th>Profit Before Tax</th>
                              <td>
                                {auditData.profit_before_tax_s
                                  ? auditData.profit_before_tax_s.toFixed(3)
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Extraordinary Items</th>
                              <td>
                                {auditData.extraordinary_items_s
                                  ? auditData.extraordinary_items_s.toFixed(3)
                                  : "-"}
                              </td>
                            </tr>
                            {sectors.find(
                              (s) => s.id == auditData.Company.sector_id
                            ) &&
                              sectors.find(
                                (s) => s.id == auditData.Company.sector_id
                              ).name == "financial services" && (
                                <tr>
                                  <th>Claims</th>
                                  <td>
                                    {auditData.claims ? auditData.claims : 0}
                                  </td>
                                </tr>
                              )}
                            {sectors.find(
                              (s) => s.id == auditData.Company.sector_id
                            ) &&
                              sectors.find(
                                (s) => s.id == auditData.Company.sector_id
                              ).name == "financial services" && (
                                <tr>
                                  <th>Premium Earned</th>
                                  <td>
                                    {auditData.premium_earned
                                      ? auditData.premium_earned
                                      : 0}
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col s5">
                      <h5>Critical Items</h5>
                      <div
                        className="row"
                        style={{ height: 200, overflowY: "auto" }}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <th>Allowance</th>
                              <td>{auditData.allowance_s}</td>
                            </tr>
                            <tr>
                              <th>Intangibles</th>

                              <td>{auditData.intangibles_s}</td>
                            </tr>
                            <tr>
                              <th>Impairements</th>

                              <td>{auditData.imparement_s}</td>
                            </tr>
                            <tr>
                              <th>Goodwill</th>

                              <td>{auditData.goodwill_s}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col s2">
                      <div
                        className="row"
                        style={{
                          height: 200,
                          marginTop: 20,
                          overflowY: "auto",
                        }}
                      >
                        <div className="col s12">
                          <p>
                            <h5>
                              <strong>Audit Fees</strong>
                            </h5>
                            <p>{auditData.audit_fees}</p>
                          </p>
                          <h5>RPT</h5>
                          <a
                            className="waves-effect waves-light modal-trigger"
                            href="#ar-show-modal"
                            style={{ backgroundColor: "#2d49a6" }}
                          >
                            <img
                              src="assets/img/audit-monitor.png"
                              className="img-fluid"
                              alt=""
                              width="100"
                              height="100"
                              id="audit-monitor"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col s12">
                      <hr
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          backgroundColor: "#e0e7ff",
                          height: 2,
                        }}
                      ></hr>
                      <h5>Peer Analysis</h5>
                      <table className="tableFixed">
                        <thead>
                          <tr>
                            <th>Company Name</th>
                            <th>
                              Leverage
                              <i
                                className="material-icons tiny tooltipped"
                                data-position="right"
                                data-tooltip="<p>Total Borrowings / Total Asset</p>"
                                style={{ paddingLeft: 5, cursor: "pointer" }}
                              >
                                info
                              </i>
                            </th>
                            <th>
                              Profitability (%)
                              <i
                                className="material-icons tiny tooltipped"
                                data-position="right"
                                data-tooltip="<p>Profit Before tax / Total Income</p>"
                                style={{ paddingLeft: 5, cursor: "pointer" }}
                              >
                                info
                              </i>
                            </th>
                            <th>
                              Efficiency
                              <i
                                className="material-icons tiny tooltipped"
                                data-position="right"
                                data-tooltip="<p>Total Income / Total Asset</p>"
                                style={{ paddingLeft: 5, cursor: "pointer" }}
                              >
                                info
                              </i>
                            </th>
                            <th>
                              Intangibility (%)
                              <i
                                className="material-icons tiny tooltipped"
                                data-position="right"
                                data-tooltip="<p>
                              (Goodwill + Intangibles) / Total Asset
                              </p>"
                                style={{ paddingLeft: 5, cursor: "pointer" }}
                              >
                                info
                              </i>
                            </th>
                            {sectors.find(
                              (s) => s.id == auditData.Company.sector_id
                            ) &&
                              sectors.find(
                                (s) => s.id == auditData.Company.sector_id
                              ).name == "financial services" && (
                                <th>
                                  Claim (%)
                                  <i
                                    className="material-icons tiny tooltipped"
                                    data-position="right"
                                    data-tooltip="<p>
                                Claims / Premium Earned
                                </p>"
                                    style={{
                                      paddingLeft: 5,
                                      cursor: "pointer",
                                    }}
                                  >
                                    info
                                  </i>
                                </th>
                              )}
                          </tr>
                        </thead>
                        <tbody style={{ height: 150 }}>
                          <tr>
                            <td
                              style={{
                                backgroundColor: "#e0e7ff",
                                fontWeight: "bold",
                              }}
                            >
                              {auditData.Company.name}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#e0e7ff",
                                fontWeight: "bold",
                              }}
                            >
                              {auditData.total_asset_s &&
                              auditData.total_asset_s != 0
                                ? Number(
                                    auditData.borrowing_s /
                                      auditData.total_asset_s
                                  ).toFixed(2)
                                : 0}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#e0e7ff",
                                fontWeight: "bold",
                              }}
                            >
                              {auditData.total_income_s &&
                              auditData.total_income_s != 0
                                ? Number(
                                    (auditData.profit_before_tax_s /
                                      auditData.total_income_s) *
                                      100
                                  ).toFixed(2)
                                : 0}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#e0e7ff",
                                fontWeight: "bold",
                              }}
                            >
                              {auditData.total_asset_s &&
                              auditData.total_asset_s != 0
                                ? Number(
                                    auditData.total_income_s /
                                      auditData.total_asset_s
                                  ).toFixed(2)
                                : 0}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#e0e7ff",
                                fontWeight: "bold",
                              }}
                            >
                              {auditData.total_asset_s &&
                              auditData.goodwill_s &&
                              auditData.intangibles_s &&
                              auditData.total_asset_s != 0
                                ? Number(
                                    ((auditData.goodwill_s +
                                      auditData.intangibles_s) /
                                      auditData.total_asset_s) *
                                      100
                                  ).toFixed(2)
                                : 0}
                            </td>
                            {sectors.find(
                              (s) => s.id == auditData.Company.sector_id
                            ) &&
                              sectors.find(
                                (s) => s.id == auditData.Company.sector_id
                              ).name == "financial services" && (
                                <td
                                  style={{
                                    backgroundColor: "#e0e7ff",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {auditData.claims && auditData.premium_earned
                                    ? Number(
                                        (auditData.claims /
                                          auditData.premium_earned) *
                                          100
                                      ).toFixed(2)
                                    : 0}
                                </td>
                              )}
                          </tr>
                          {secAuditData &&
                            secAuditData.map((d) => (
                              <tr
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.onSwitchAudit(d.id);
                                }}
                              >
                                <td>{d.Company.name}</td>
                                <td>
                                  {d.total_asset_s && d.total_asset_s != 0
                                    ? Number(
                                        d.borrowing_s / d.total_asset_s
                                      ).toFixed(2)
                                    : 0}
                                </td>
                                <td>
                                  {d.total_income_s && d.total_income_s != 0
                                    ? Number(
                                        (d.profit_before_tax_s /
                                          d.total_income_s) *
                                          100
                                      ).toFixed(2)
                                    : 0}
                                </td>
                                <td>
                                  {d.total_asset_s && d.total_asset_s != 0
                                    ? Number(
                                        d.total_income_s / d.total_asset_s
                                      ).toFixed(2)
                                    : 0}
                                </td>
                                <td>
                                  {d.total_asset_s &&
                                  d.goodwill_s &&
                                  d.intangibles_s &&
                                  d.total_asset_s != 0
                                    ? Number(
                                        ((d.goodwill_s + d.intangibles_s) /
                                          d.total_asset_s) *
                                          100
                                      ).toFixed(2)
                                    : 0}
                                </td>
                                {sectors.find(
                                  (s) => s.id == d.Company.sector_id
                                ) &&
                                  sectors.find(
                                    (s) => s.id == d.Company.sector_id
                                  ).name == "financial services" && (
                                    <td>
                                      {d.claims && d.premium_earned
                                        ? Number(
                                            (d.claims / d.premium_earned) * 100
                                          ).toFixed(2)
                                        : 0}
                                    </td>
                                  )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditData: state.audit.auditData
      ? state.audit.auditData.find((d) => d.id == state.audit.auditScoreId)
      : null,
    auth: state.auth,
    initData: state.NIS.initData,
    auditYear: state.audit.auditYearsData,
    TopNews:
      state.NIS && state.NIS.NewsNISTopNews ? state.NIS.NewsNISTopNews : null,

    secAuditData: state.audit.secAuditData,
    sectors: state.SM ? state.SM.sectorList : [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initResponse: () => {
      dispatch(initResponse());
    },
    SubmitPD: (company, year, token) => {
      dispatch(submitPD(company, year, token));
    },
    GetAuditYears: (company, token) => {
      dispatch(getAuditYears(company, token));
    },
    SwitchAudit: (id) => {
      dispatch(switchAudit(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadFinance);
