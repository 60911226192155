import moment from "moment";
import config from "../../config/config";
import createFormData from "../../help/CreateFormData";
import { queryBuilder } from "../../utils/helper";

var backendUrl = config.backendUrl;

export const getStat = (tool, token, filter) => {
  var url = backendUrl;
  return (dispatch) => {
    dispatch({
      type: "STAT_FETCH_LOADING",
    });
    switch (tool) {
      case "NEWS":
        url += "/newsStat";
        break;
      case "PD":
        if (filter)
          url += "/pdStat?company="+filter;
        else
          url += "/pdStat"
        break;
      case "NIS":
        url += "/nisStat";
        break;
      case "SM":
        url += "/smStat";
        break;
      case "SP":
        url += "/spStat";
        break;
      case "Ann":
        url += "/annStat";
        break;
      case "CC":
        url += "/ccStat";
        break;

      default:
        return;
    }
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "STAT_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "STAT_FETCH_ERROR",
            });
          } else {
            dispatch({
              type: "STAT_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "STAT_FETCH_ERROR",
        });
      });
  };
};


export const getExtraStat = (filter, token) => {
  var url = backendUrl+"/nisStat";
  return (dispatch) => {
    dispatch({
      type: "EXTRA_STAT_FETCH_LOADING",
    });
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "EXTRA_STAT_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "EXTRA_STAT_FETCH_ERROR",
            });
          } else {
            dispatch({
              type: "EXTRA_STAT_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "EXTRA_STAT_FETCH_ERROR",
        });
      });
  };
};

export const getUsersStat = (token) => {
  return (dispatch) => {
    dispatch({ type: "USERS_STAT_LOADING" });
    var url = backendUrl + "/getUsersStat";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "USERS_STAT_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "USERS_STAT_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "STAT_FETCH_ERROR",
        });
      });
  };
};

export const getNewsStat = (st, end, token) => {
  return (dispatch) => {
    dispatch({ type: "NEWS_STAT_FETCH_LOADING" });
    var url = backendUrl + "/checkAnalysis";
    if (st) url += "?startDate=" + st;
    if (st) url += "&endDate=" + end;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NEWS_STAT_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NEWS_STAT_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "NEWS_STAT_FETCH_ERROR",
        });
      });
  };
};

export const startCrawl = (stTime, endTime, token) => {
  return (dispatch) => {
    dispatch({ type: "CRAWL_STARTED" });
    var body = {
      startDate: stTime,
      endDate: endTime,
    };
    var url = backendUrl + "/crawl";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "CRAWL_COMPLETE",
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "CRAWL_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "CRAWL_ERROR",
        });
      });
  };
};

export const startAnalysis = (stTime, endTime, analysisType, token) => {
  return (dispatch) => {
    dispatch({ type: "NEWS_ANALYSIS_STARTED" });
    var body = {
      startDate: moment(stTime).format("DD/MM/YYYY HH:MM"),
      endDate: moment(endTime).format("DD/MM/YYYY HH:MM"),
    };
    var url = backendUrl;
    if (analysisType == "NIS") {
      url += "/analyzeCompanySentiment";
    } else if (analysisType == "SM") {
      url += "/analyzeSectorSentiment";
    } else if (analysisType == "SP") {
      url += "/analyzeStockPulse";
    }
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NEWS_ANALYSIS_COMPLETE",
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NEWS_ANALYSIS_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "NEWS_ANALYSIS_ERROR",
        });
      });
  };
};

export const getApiTracks = (token) => {
  return (dispatch) => {
    dispatch({ type: "API_TRACK_FETCH_LOADING" });
    var url = backendUrl + "/apiTracks";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "API_TRACK_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "API_TRACK_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "API_TRACK_FETCH_ERROR",
        });
      });
  };
};

export const getCompanyRequests = (token) => {
  return (dispatch) => {
    dispatch({ type: "COMPANY_REQUESTS_FETCH_LOADING" });
    var url = backendUrl + "/companyAddRequests";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "COMPANY_REQUESTS_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "COMPANY_REQUESTS_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "COMPANY_REQUESTS_FETCH_ERROR",
        });
      });
  };
};

export const addCompany = (body, token) => {
  return (dispatch) => {
    dispatch({ type: "ADD_COMPANY_LOADING" });
    var url = backendUrl + "/newCompany";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "ADD_COMPANY_SUCCESS",
              payload: data.data,
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "ADD_COMPANY_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "ADD_COMPANY_ERROR",
        });
      });
  };
};

export const varifyCompany = (id, body, token) => {
  return (dispatch) => {
    dispatch({ type: "VERIFY_COMPANY_LOADING" });
    var url = backendUrl + "/request/"+id+"/verify";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "VERIFY_COMPANY_SUCCESS",
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "VERIFY_COMPANY_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "VERIFY_COMPANY_ERROR",
        });
      });
  };
};

export const uploadOwnAR = (file, token)=> {
  console.log("File", file);
  return (dispatch) => {
    dispatch({type: 'UPLOAD_AR_LOADING'});
    fetch(backendUrl + "/uploadAR", {
      method: 'post',
      body:createFormData(file,{}),
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      response.json().then((data)=>{
          if (response.status === 200) {
          dispatch({
            type: 'UPLOAD_AR_SUCCESSFUL',
            data: data
          });
        }
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: 'UPLOAD_AR_ERROR',
            message:data.message
          });
        }
      })
    })
    .catch(response => {
      dispatch({
        type: 'UPLOAD_AR_ERROR',
        message:"Error"
      });
    })
  };
};


export const getCompanyListWithKeywords = (cid, token) => {
  return dispatch => {
    var url = backendUrl + "/companiesWithKeywords"; 
    if(cid){
      url += "?cid="+cid
    } 
    dispatch({
      type: "COMPANY_KEYWORD_LIST_FETCH_LOADING",
    });
    fetch(url,{
      headers:{
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response =>
      response.json().then(data => {
        // //console.log("LIST FETCH")
        if (response.status === 200) {
          dispatch({
            type: "COMPANY_KEYWORD_LIST_FETCH_SUCCESS",
            payload: data.data
          });
        } 
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: "COMPANY_KEYWORD_LIST_FETCH_ERROR"
          });
        }
      })
    )
    .catch(err =>{
      dispatch({
        type: "COMPANY_KEYWORD_LIST_FETCH_ERROR"
      });
    });
  };
};


export const editCompany = (comId, body, token) => {
  return (dispatch) => {
    dispatch({ type: "EDIT_COMPANY_LOADING" });
    var url = backendUrl + "/company/"+comId;
    fetch(url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "EDIT_COMPANY_SUCCESS",
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "EDIT_COMPANY_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "EDIT_COMPANY_ERROR",
        });
      });
  };
};


export const editCompanyKeyword = ( keyId, body, token) => {
  return (dispatch) => {
    dispatch({ type: "EDIT_COMPANY_KEYWORD_LOADING" });
    var url = backendUrl + "/companyKeyword/"+keyId;
    fetch(url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "EDIT_COMPANY_KEYWORD_SUCCESS",
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "EDIT_COMPANY_KEYWORD_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "EDIT_COMPANY_KEYWORD_ERROR",
        });
      });
  };
};


export const removeCompanyKeyword = ( keyId, token) => {
  return (dispatch) => {
    dispatch({ type: "REMOVE_COMPANY_KEYWORD_LOADING" });
    var url = backendUrl + "/companyKeyword/"+keyId+"/remove";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "REMOVE_COMPANY_KEYWORD_SUCCESS",
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "REMOVE_COMPANY_KEYWORD_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "REMOVE_COMPANY_KEYWORD_ERROR",
        });
      });
  };
};


export const addCompanyKeyword = (comId, body, token) => {
  return (dispatch) => {
    dispatch({ type: "ADD_COMPANY_KEYWORD_LOADING" });
    var url = backendUrl + "/company/"+comId+"/keyword";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "ADD_COMPANY_KEYWORD_SUCCESS",
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "ADD_COMPANY_KEYWORD_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "ADD_COMPANY_KEYWORD_ERROR",
        });
      });
  };
};


export const getSentenceBank = (query, token) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SENTENCE_BANK_LOADING" });
    var url = queryBuilder( backendUrl + "/sentenceBank", query);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "FETCH_SENTENCE_BANK_SUCCESS",
              data: data.data,
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "FETCH_SENTENCE_BANK_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "FETCH_SENTENCE_BANK_ERROR",
        });
      });
  };
};


export const updateSentenceBank = (id, body, token) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_SENTENCE_BANK_LOADING" });
    var url =  backendUrl + "/sentence/"+id+"/update";
    fetch(url, {
      method:"PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "UPDATE_SENTENCE_BANK_SUCCESS",
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "UPDATE_SENTENCE_BANK_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error in fetch", err);
        dispatch({
          type: "UPDATE_SENTENCE_BANK_ERROR",
        });
      });
  };
};
